<template>
	<div class="all_body">
				<div v-if="info.addhole" class="lf_match_info_box">
					<div class="d_title">{{systemlang =='cn'?'加洞赛':'Playoff'}} | <span>{{systemlang =='cn'?'Playoff':'加洞赛'}}</span></div>
				</div>
				<div v-if="info.addhole" class="lf_Playoff_box">
					<div class="lf_Playoff_tit">
						<div class="lf_off_1"></div>
						<div class="lf_off_2">Player</div>
						<div class="lf_off_3" v-if="info.addhole&&WebModel" v-for="b in info.addhole.round"><text  style="color: #C0153E;margin-right: 0.1rem;">#{{b}} </text>
							H{{info.addhole&&info.addhole.roundarr[b].hole?info.addhole.roundarr[b].hole:'-'}}
							Par{{info.addhole&&info.addhole.roundarr[b].par?info.addhole.roundarr[b].par:'-'}}
						</div>
						<div class="lf_off_3" v-if="info.addhole&&!WebModel" v-for="b in info.addhole.round">
							H{{info.addhole&&info.addhole.roundarr[b].hole?info.addhole.roundarr[b].hole:'-'}}
						</div>
					</div>
					<div v-for="b in info.addhole.list" class="lf_Playoff_con">
						<div class="lf_off_1 " :class="b.win =='1'?'lf_off_1_win':''">{{b.win =='1'?'WIN':'ELM'}}</div>
						<div class="lf_off_2"><img v-if="b.pl_country" :src="b.pl_country" alt=""><router-link target="_blank" :to="{path:'/playerInfo',query:{bFlag:'career',pl_id:b.mp_player_id,lang:systemlang}}">
												{{b.pl_name}}
												</router-link></div>
						<div class="lf_off_3" v-for="s in b.hole">{{s}}</div>
					</div>
				</div>
				<div class="lf_match_info_box lf_match_info_box_s top_to" v-if="info.info  && rd && rd.list.length > 1">
					<div class="d_title">{{systemlang =='cn'?'球员成绩表':'Leaderboard'}} | <span>{{systemlang !='cn'?'球员成绩表':'Leaderboard'}}</span></div>
					<div  class="d_right" v-if="((!isMobile()) && jscj!='1' )|| info.tmflag == '1'">
						<div class="search hand">
						<div class="search_pos">
							<span class="kindbox" @click.stop="azshow=!azshow">
								{{langs.cj_zd}}
							</span>
							<img :style="azshow?'transform: rotate(270deg)':''" src="../../assets/images/u182.png" @click.stop="azshow=!azshow" />

							<div class="select-back" v-if="azshow" @click.stop="azshow=!azshow"></div>
							<div class="select-right into" v-if="azshow">
								<div class="select_title">{{langs.cj_zd}}</div>
								<div class="select_socl" v-if="rd && rd.list.length > 1">
								<div v-for="zditem in rd.list" class="select_item " :class="zditem.checked && zditem.checked == '1' ? 'select_item_hover' : ''" @click.stop="goinfo(zditem.key)" >{{zditem.title[systemlang]}}</div>
								<!-- <div class="select_item select_item_hover" @click.stop="goinfo('lxb')">{{langs.cj_lxb}}</div> -->
				
							</div>

							</div>
						</div>

					</div>
						
					</div>
					<div class="d_right" v-else-if="isMobile()&&jscj=='1'">
						<div class="d_right_go" @click="gozudcj1()">
							<div>{{langs.matchyf_qh}}<!-- 横屏看 --></div>
							<div>{{langs.matchyf_lxb}}<!-- 逐洞成绩 --></div>
							
						</div>
					</div>
					<div class="d_right" v-else-if="isMobile()">
						<div class="d_right_go" @click="gozudcj()">
							<div>{{langs.matchyf_qh}}<!-- 横屏看 --></div>
							<div>{{langs.matchyf_lxb}}<!-- 逐洞成绩 --></div>
							
						</div>
					</div>
				</div>
				<div class="lf_match_info_box lf_match_info_box_ top_to">
					<div class="lf_group_tit_left" v-if="info.info && info.info.match_id =='32936'">
						<el-radio-group v-model="radio">
							<el-radio v-if="info.info && info.info.mt_round" v-for="i in info.info.mt_round_num"
								:label="i">{{ systemlang=='cn'?'排位赛': 'Qualifying'}}</el-radio>
						</el-radio-group>
					</div>

					<div class="lf_group_tit_left" v-else>
						<el-radio-group v-model="radio">
							<el-radio v-if="info.info && info.info.mt_round" v-for="i in info.info.mt_round_num"
								:label="i">{{langs.text_di}}{{i}}{{langs.text_lun}}</el-radio>
						</el-radio-group>
					</div>



				</div>
				<div style="width: 100%;" v-if="info.list && info.list <=0"><empty></empty>	</div>
				<div v-else id="pdf" class="lf_course_name_data_box top_to">
					<div v-if="showOpcF" @click="showOpcFun" class="lf_tips_box_opc">{{langs.chengji_tishi}}<!--请将手机切换至横屏查看--></div>
					<div :style="searchBarFixed == true?'width:'+div1sclientWidth+'px;':''" id="lf_score_bgzg_box_tit" :class="searchBarFixed == true ? 'isFixed lf_tec_download' :'lf_tec_download'">
						<div style="cursor:default;" class="lf_score_tips">
							<div style="cursor:default;">{{langs.Amateur}}</div>
							<div style="cursor:default;">{{langs.StartfromBacknine}} </div>
							<div style="cursor:default;"><text  style="margin-right: 0.1rem;"> F </text>{{langs.Confirmed}}</div>
							
							<div style="cursor:default;"><img src="../../assets/images/100.png" alt=""  style="margin-right: 0.1rem;">{{langs.WoninPlayoff}}</div>
						</div>
						<div style="display: flex;align-items: center;" v-if="info.info && info.info.mt_group !='1'">
							<div class="lf_tec_download_img_box" @click="DouwnExcel()">
								<text>{{langs.DownloadExcel}} </text><img src="../../assets/images/Excel.png" alt="">
							</div>
							<div class="lf_tec_download_img_box" @click="Douwn()">
								<text>{{langs.DownloadPDF}} </text><img src="../../assets/images/u2818.png" alt="">
							</div>
						</div>
					</div>
					
					<div class="lf_scroll_box_out">
						<div id="lf_scroll_box" class="lf_scroll_box">
							<div id="lf_scroll_box_left" class="lf_scroll_box_left">
								<div :style="searchBarFixed == true?'left:'+HtmloffsetLeft+'px;width:'+div1sclientWidth+'px;':''" :class="searchBarFixed == true ? 'lf_isFixed lf_course_name_data_tit' :'lf_course_name_data_tit'">
									<div class="lf_1" style="justify-content: center;font-size: 0.24rem;">Pos</div>
									
									<div class="lf_2">Player</div>
									<div class="lf_3">Thru</div>
									<div class="lf_3">ToPar</div>
									<div class="lf_3">Today</div>
									<template v-for="h in info.hole">
										<div class="lf_8">
											<div>{{h.mh_id}}</div>
											<div  style=";font-weight: bold;">{{h.mh_par}}</div>
										</div>
										<div v-if="h.mh_id==9" class="lf_OUT">
											<div>OUT</div>
											<div style="font-weight: bold;">{{jsPar('OUT')}}</div>
										</div>
										<div v-if="h.mh_id==18" class="lf_IN">
											<div>IN</div>
											<div style="font-weight: bold;">{{jsPar('IN')}}</div>
										</div>
									</template>
									<div class="lf_6" v-for="r in radio">R{{r}}</div>
									<div class="lf_7">Total</div>
								</div>
								
								<!--关注的球员-->
								<div v-if="info.list && likepl.length > 0" class="lf_jjx colorRed" style="width: 100%;text-align: center;background-color: #C0153E;">{{systemlang =='cn'?'关注的球员':'Favorites'}} {{systemlang !='cn'?'关注的球员':'Favorites'}}<!--关注的球员--></div>
								<template v-for="(b,index) in info.list" :key="b.su_pl_id">
									<blockquote  v-if="likepl.indexOf(b.su_pl_id) >= 0" >
										<div :style="{backgroundColor:(index%2==0?'#fff':'#FDFDFD')}"
											:class="getClass(b.su_pl_id)">
											<div class="lf_1">
												<!-- ../../assets/images/u2834.png -->
												<img class="lf_aixin" @click="likes(b.su_pl_id)" src="../../assets/images/u2835.png" alt="">
												<img v-if="info.addhole&&b.rank=='1'" style="width: 0.2rem;height: 0.2rem;" src="../../assets/images/100.png" alt="">
												<template v-else>{{b.rank}}</template>
												<div class="lf_1_1" v-if="b.changpos>0 && radio > 1">
													<img src="../../assets/images/up.png" alt="">
													<view class="lf_up">{{b.changpos}}</view>
												</div>
												<div class="lf_1_1" v-if="b.changpos<0 && radio > 1">
													<img src="../../assets/images/down.png" alt="">
													<view class="lf_down">{{Math.abs(b.changpos)}}</view>
												</div>
												<div class="lf_1_1" v-if="b.changpos==0 && radio > 1">
													<img src="../../assets/images/par.png" alt="">
												</div>
											</div>
											
											<div class="lf_2 hoverUnderLine" @click="checkplid(b.su_pl_id,'zc')">
												<img style="width:0.3rem;" v-if="b.pl_country" :src="b.pl_country" alt="">
												<router-link target="_blank" :to="{path:'/playerInfo',query:{bFlag:'career',pl_id:b.mp_player_id,lang:systemlang}}">
												{{b.pl_name}}
												</router-link>
												<div v-if="b.zus&&b.zus.length>0" class="lf_club">
													<banner v-if="b.zus&&b.zus.length>0" heightNew="0.5rem" :type="2" datatype="1" :dataurl="b.zus"></banner>
												</div>
											</div>
											<div :class="'lf_3 '+(b.ok_h=='F'?'lf_thru_F':'')">{{b.ok_h==18?'F':(b.ok_h==''&&b.roundsts==''?b.start_time:b.ok_h)}}<span style="color: #666666;">{{b.hou9=='1'&&b.roundsts==''?'*':''}}</span>
											</div>
											<div class="lf_3" :class="parseInt(b.topar) < 0?'lf_thru_F':''">
												{{b.topar > 0?'+':''}}{{b.topar == '0'?'E':b.topar}}
											</div>
											<div class="lf_3" :class="parseInt(b.today) < 0?'lf_thru_F':''">
												{{b.today > 0?'+':''}}{{b.today == '0'?'E':b.today}}
											</div>
											
											<template v-for="h in info.hole">
												<div class="lf_8"
													:class="info.tp && info.tp[b.su_pl_id] && info.tp[b.su_pl_id][b.su_rd_id] && info.tp[b.su_pl_id][b.su_rd_id][h.mh_id]?info.tp[b.su_pl_id][b.su_rd_id][h.mh_id]:''">
													{{info.cj && info.cj[b.su_pl_id] && info.cj[b.su_pl_id][b.su_rd_id] &&  info.cj[b.su_pl_id][b.su_rd_id][h.mh_id]?info.cj[b.su_pl_id][b.su_rd_id][h.mh_id]:'-'}}
												</div>
												<div v-if="h.mh_id==9" class="lf_OUT">
													{{jsParScore(b.su_pl_id,b.su_rd_id,'OUT')}}
												</div>
												<div v-if="h.mh_id==18" class="lf_IN">
													{{jsParScore(b.su_pl_id,b.su_rd_id,'IN')}}
												</div>
											</template>
											<div class="lf_6" v-for="r in radio">{{b['R'+r]}}</div>
											<div class="lf_7">{{b.total==0?'-':b.total}}</div>
										</div>
									</blockquote>
								</template>
								<!-- 关注的球员结束 -->
								<div v-if="info.list && likepl.length > 0" class="lf_jjx colorRed" style="width: 100%;text-align: center;background-color: #C0153E;">{{systemlang =='cn'?'全部球员':'All Players'}} {{systemlang !='cn'?'全部球员':'All Players'}}<!--关注的球员--></div>

								
								<blockquote v-if="info.list" v-for="(b,index) in info.list" :key="b.su_pl_id">
									<div :style="{backgroundColor:(index%2==0?'#fff':'#FDFDFD')}"
										:class="getClass(b.su_pl_id)">
										<div class="lf_1">
											<!-- ../../assets/images/u2834.png -->
											
											<img  @click="likes(b.su_pl_id)" class="lf_aixin" v-if="likepl.indexOf(b.su_pl_id) >= 0" src="../../assets/images/u2835.png" alt="">
											<img  @click="likes(b.su_pl_id)" class="lf_aixin" v-if="likepl.indexOf(b.su_pl_id) < 0" src="../../assets/images/u2834.png" alt="">
											<img v-if="info.addhole&&b.rank=='1'" style="width: 0.2rem;height: 0.2rem;" src="../../assets/images/100.png" alt="">
											<template v-else>{{b.rank}}</template>
											<div class="lf_1_1" v-if="b.changpos>0 && radio > 1">
												<img src="../../assets/images/up.png" alt="">
												<view class="lf_up">{{b.changpos}}</view>
											</div>
											<div class="lf_1_1" v-if="b.changpos<0 && radio > 1">
												<img src="../../assets/images/down.png" alt="">
												<view class="lf_down">{{Math.abs(b.changpos)}}</view>
											</div>
											<div class="lf_1_1" v-if="b.changpos==0 && radio > 1">
												<img src="../../assets/images/par.png" alt="">
											</div>
										</div>
										
										<div class="lf_2 hoverUnderLine" @click="checkplid(b.su_pl_id,'zc')">
											<img style="width:0.3rem;" v-if="b.pl_country" :src="b.pl_country" alt="">
											<router-link target="_blank" :to="{path:'/playerInfo',query:{bFlag:'career',pl_id:b.mp_player_id,lang:systemlang}}">
												{{b.pl_name}}
												</router-link>
											<div v-if="b.zus&&b.zus.length>0" class="lf_club">
												<banner v-if="b.zus&&b.zus.length>0" heightNew="0.5rem" :type="2" datatype="1" :dataurl="b.zus"></banner>
											</div>
										</div>
										<div :class="'lf_3 '+(b.ok_h=='F'?'lf_thru_F':'')">{{b.ok_h==18?'F':(b.ok_h==''&&b.roundsts==''?b.start_time:b.ok_h)}}<span>{{b.hou9=='1'&&b.roundsts==''?'*':''}}</span>
										</div>
										<div class="lf_3" :class="parseInt(b.topar) < 0?'lf_thru_F':''">
											{{b.topar > 0?'+':''}}{{b.topar == '0'?'E':b.topar}}
										</div>
										<div class="lf_3" :class="parseInt(b.today) < 0?'lf_thru_F':''">
											{{b.today > 0?'+':''}}{{b.today == '0'?'E':b.today}}
										</div>
										
										<template v-for="h in info.hole">
											<div class="lf_8"
												:class="info.tp && info.tp[b.su_pl_id] && info.tp[b.su_pl_id][b.su_rd_id] && info.tp[b.su_pl_id][b.su_rd_id][h.mh_id]?info.tp[b.su_pl_id][b.su_rd_id][h.mh_id]:''">
												{{info.cj && info.cj[b.su_pl_id] && info.cj[b.su_pl_id][b.su_rd_id] &&  info.cj[b.su_pl_id][b.su_rd_id][h.mh_id]?info.cj[b.su_pl_id][b.su_rd_id][h.mh_id]:'-'}}
											</div>
											<div v-if="h.mh_id==9" class="lf_OUT">
												{{jsParScore(b.su_pl_id,b.su_rd_id,'OUT')}}
											</div>
											<div v-if="h.mh_id==18" class="lf_IN">
												{{jsParScore(b.su_pl_id,b.su_rd_id,'IN')}}
											</div>
										</template>
										<div class="lf_6" v-for="r in radio">{{b['R'+r]}}</div>
										<div class="lf_7">{{b.total==0?'-':b.total}}</div>
									</div>

									


									<div v-if="(index+1)%20==0" class="banner" v-show="bannershow && bannershow['RANK_CENTER'] == '1'">
										<banner @EventBack='setbannershow' heightNew="1.22rem" :type="2" abs="RANK_CENTER"></banner>
									</div>

									<el-col
										v-if="(info.check_ok == 0 && info.jinji>0 && b.su_rank == info.jinji && !info.list[index+1]) || (info.check_ok == 0 && info.jinji>0 && b.su_rank == info.jinji && info.list[index+1] && info.list[index+1].su_rank != b.su_rank) || (info.check_ok == 1 && info.jinji>0 && b.su_rank == info.jinji && !info.list[index+1]) || (info.check_ok == 1 && info.jinji>0 && b.su_rank == info.jinji && info.list[index+1] && info.list[index+1].su_rank != b.su_rank)"
										style="position: relative;height: 0.5rem;" :xs="24" :sm="24" :md="24">

										<div class="lf_jjx"
											v-if="(info.nextjiji == '0' && info.check_ok == 0 && info.jinji>0 && b.su_rank == info.jinji && !info.list[index+1]) || (info.nextjiji == '0' && info.check_ok == 0 && info.jinji>0 && b.su_rank == info.jinji && info.list[index+1] && info.list[index+1].su_rank != b.su_rank)"
											style="width: 100%;text-align: center;background-color: #C0153E;">
											{{systemlang == 'cn'?'预测晋级线':'Projected Cut Line'}}
										</div>
										<div class="lf_jjx"
											v-if="(info.nextjiji == '1' && b.su_rank == info.jinji && info.list[index+1] && info.list[index+1].mp_sts != '') || (info.nextjiji == '1' && b.su_rank == info.jinji && !info.list[index+1]) || (info.check_ok == 1 && info.jinji>0 && b.su_rank == info.jinji && !info.list[index+1]) || (info.check_ok == 1 && info.jinji>0 && b.su_rank == info.jinji && info.list[index+1] && info.list[index+1].su_rank != b.su_rank)"
											style="width: 100%;text-align: center;background-color: #C0153E;">
											{{systemlang == 'cn'?'晋级线':'Cut Line'}}
										</div>

									</el-col>
								</blockquote>
							</div>
						</div>
					</div>
				</div>
	</div>
	<Tips @aliclickshowhide="aliclickshowhide" :show="show"></Tips>
	<!-- <Ad></Ad> -->
</template>

<script>
import banner from "../banner.vue";
	import {
		result
	} from 'lodash';
	import html2Canvas from '../../plugs/htmlToPDF';
	import Tips from '../Tips.vue';
	import {
		getSystemLang,
		getLangAll
	} from '../../lang';
	import axios from '../../axios/request';
	import Ad1 from "../Ad1.vue";
	import empty from "../empty.vue";
	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				info: {},
				pmlist: [],
				plname: '',
				checkplidv: '',
				radio: '',
				cradio: '',
				value: '',
				cj: 'cj',
				likepl: [],
				zctype: 'zc', //点击区域
				tmflag: '0',
				OutInFlag: true,
				WebModel: true,
				WebModelfirst:true,
				searchBarFixed:false,
				HtmloffsetTop:0,
				HtmloffsetLeft:0,
				div1sclientWidth:'100%',
				azshow:false,
				bannershow:[],
				showOpcF:true,
				rd:{}
			}
		},
		name: 'Player',
		props: {
			msg: String,
			mt_id: String,
			flag: String,
			round_id: String,
			jscj:String
		},
		components: {
			Ad1,
			Tips,
			banner,
			empty
		},
		created() {

			document.body.addEventListener('click', (e) => {
				// console.log(e.target)
				this.azshow = false;
			});

			axios('matchs/match/matchtj.json', {
						otp: 'matchType',
						mt_id: this.mt_id,
						round_id: this.round_id,
						flag:'zdcj',
						jscj:this.jscj?this.jscj:0,
						mobile:this.isMobile()?1:0
					})
					.then((res) => {
						let data = res.data.data;
						this.rd = data;
						if(this.rd.redirect && this.rd.redirect !='')
						{
							let routeUrl = this.$router.resolve({
								path: "/Match",
								query: {
									lang: this.systemlang,
									id: this.$route.query.id,
									type: 'cj',
									bFlag: this.rd.redirect,
									round_id:this.round_id
								}
							});
							
							window.open(routeUrl.href, '_self');
							return false;
						}
					});


			//  this.$cookies.remove('likepl')
			console.log('likpl', this.$cookies.get(this.mt_id+'likepl'));
			if (this.$cookies.isKey(this.mt_id+'likepl')) {
				console.log('likpl', this.$cookies.get(this.mt_id+'likepl'));
				this.likepl = JSON.parse(this.$cookies.get(this.mt_id+'likepl'));
			}
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			this.cj = this.flag;

			if (this.round_id > 0) {
				this.radio = parseInt(this.round_id);
			}

			var deviceWidth = document.documentElement.clientWidth || window.innerWidth
			if (deviceWidth >= 770) {
				this.WebModel = true;
				this.WebModelfirst = true;
			} else {
				this.WebModel = false;
				this.WebModelfirst = false;
			}
			document.body.style.overflow = "hidden";
			this.getData(()=>{
				document.body.style.overflow = "visible";
				if(this.info.info.ColumnStart && this.info.info.ColumnEnd)
				{
					let  timestamp = new Date().getTime();
					if(timestamp >= this.info.info.ColumnStart*1000 && timestamp <= this.info.info.ColumnEnd*1000)
					{
						setInterval(() => {
							this.getData1();
						}, 600000);
					}
				}
			});
			

		},
		watch: {
			radio: function(newValue, oldValue) {
				console.log(newValue, oldValue);
				if (newValue != oldValue) {
					this.radio = newValue;
					// this.getData1();
					
					this.$emit('goInfo', 'cj', 'zdcj', this.radio);
				}

			},
			cj: function(newValue, oldValue) {
				console.log(newValue, oldValue);
				if (newValue != oldValue) {
					console.log('show', this.radio);
					if (newValue == 'cj') {
						this.$emit('goInfo', 'cj', '', this.radio);
					} else {
						this.$emit('goInfo', 'cj', 'tm', this.radio);
					}


				}

			},
		},
		mounted(){
			
			window.addEventListener('resize', () => {
				var deviceWidth = document.documentElement.clientWidth || window.innerWidth
				if (deviceWidth >= 770) {
					this.WebModel = true;
					
				} else {
					
					this.WebModel = false;
				}
			})
			
		},
		destroyed () {
		 window.removeEventListener('scroll', this.handleScroll);
		 var div1 = document.getElementById('lf_scroll_box');
		 div1.removeEventListener('scroll', this.handleScroll1);
		},
		methods:{
			isMobile(){
				const reg = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  				return reg.test(navigator.userAgent);
			},
			setbannershow(abs, isshow) {
			console.log(abs,isshow,'ssssssssssssssss');
			this.bannershow[abs] = isshow;
			},
			gozudcj(){
				let routeUrl = this.$router.resolve({
						path: "/Match",
						query: {
							lang: this.systemlang,
							id: this.$route.query.id,
							type: 'cj',
							bFlag: 'lxb'
						}
					});
					// console.log(routeUrl.href);
					window.open(routeUrl.href, '_self');
					return false;
			},
			gozudcj1(){
				let routeUrl = this.$router.resolve({
						path: "/Matchnow",
						query: {
							lang: this.systemlang,
							id: this.$route.query.id,
							type: 'cj',
							bFlag: 'lxb'
						}
					});
					// console.log(routeUrl.href);
					window.open(routeUrl.href, '_self');
					return false;
			},
			goinfo(flag){
				this.$emit('goInfo', 'cj', flag, this.radio);
			},
			go_hmk()
			{
				alert('点击了横屏看');
			},
			handleScroll () {
			 var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
				
			  if (scrollTop > this.HtmloffsetTop) {
				this.searchBarFixed = true
				
			  } else {
				this.searchBarFixed = false
			  }
			  console.log(1111);
			  var	div1s = document.getElementById('lf_scroll_box_left');
			  this.HtmloffsetLeft=div1s.getBoundingClientRect().left;
			  this.div1sclientWidth=div1s.clientWidth
			  console.log('this.HtmloffsetLeft',this.HtmloffsetLeft)
			},
			handleScroll1 () {
				console.log(1111);
				var	div1s = document.getElementById('lf_scroll_box_left');
				this.HtmloffsetLeft=div1s.getBoundingClientRect().left;
				this.div1sclientWidth=div1s.clientWidth
				console.log('this.HtmloffsetLeft',this.HtmloffsetLeft)
			},
			getData(callback) {
				//获取banner
				axios('matchs/match/matchtj.json', {
						otp: 'playerScore',
						mt_id: this.mt_id,
						round_id: this.radio
					})
					.then((res) => {
						let data = res.data.data;

						this.info = data;

						this.tmflag = data.tmflag;
						if (this.radio < 1) {
							this.radio = parseInt(data.info.mt_round);
						}

						window.addEventListener('scroll', this.handleScroll);
						var div = document.getElementById('lf_score_bgzg_box_tit');
						setTimeout(()=>{
							var div = document.getElementById('lf_score_bgzg_box_tit');
							this.HtmloffsetTop = div.getBoundingClientRect().top;
						},100);
						console.log(1)
						var div1 = document.getElementById('lf_scroll_box');
						div1.addEventListener('scroll', this.handleScroll1);
						console.log(12)
						callback&&callback();

					});
			},
			checkplid(pl_id, stype) {
				if (stype == 'zc') {
					this.zctype = stype;
				} else {
					this.zctype = stype;
				}

				if (this.checkplidv != pl_id) {
					this.checkplidv = pl_id;
				} else {
					this.checkplidv = '';
				}

			},
			getClass(pl_id) {
				//lf_course_name_data_con_select lf_course_name_data_con
				if (this.checkplidv == pl_id) {
					return 'lf_course_name_data_con_select lf_course_name_data_con';
				}
				return 'lf_course_name_data_con';

			},
			getData1() {
				//获取banner
				axios('matchs/match/matchtj.json', {
						otp: 'playerScore',
						mt_id: this.mt_id,
						round_id: this.radio
					})
					.then((res) => {
						let data = res.data.data;
						this.info = data;
						// console.log(this.info,data,'data');
						this.tmflag = data.tmflag;

						// console.log(this.radio,'radio');

					});
			},
			likes(pl_id) {
				console.log('1', pl_id);
				console.log(JSON.stringify(this.likepl));

				if (this.likepl.indexOf(pl_id) == -1) {
					this.likepl.push(pl_id);
					console.log('2', JSON.stringify(this.likepl));
				} else {

					this.likepl.splice(this.likepl.indexOf(pl_id), 1);
					console.log('3', pl_id);
					console.log(JSON.stringify(this.likepl));
				}
				console.log(JSON.stringify(this.likepl));
				this.$cookies.set(this.mt_id+'likepl', JSON.stringify(this.likepl), -1);
			},
			jsPar($str) {
				if (!(this.info && this.info.hole)) {
					return '-';
				}
				var rsult = 0;
				if ($str == 'OUT') {
					let hole = this.info.hole;
					for (let i in hole) {
						if (hole[i].mh_par && hole[i].mh_id <= 9) {
							rsult += parseInt(hole[i].mh_par);
						}
					}
				}

				if ($str == 'IN') {
					let hole = this.info.hole;
					for (let i in hole) {
						if (hole[i].mh_par && hole[i].mh_id > 9) {
							rsult += parseInt(hole[i].mh_par);
						}
					}
				}

				if (rsult == 0) {
					return '-';
				} else {
					return rsult;
				}

			},
			jsParScore($pl, $rid, $str) {
				if (!(this.info && this.info.cj && this.info.cj[$pl] && this.info.cj[$pl][$rid])) {
					return '-';
				}

				var rsult = 0;
				if ($str == 'OUT') {
					let hole = this.info.cj[$pl][$rid];
					for (let i in hole) {
						if (hole[i] && i <= 9) {
							rsult += parseInt(hole[i]);
						}
					}
				}

				if ($str == 'IN') {
					let hole = this.info.cj[$pl][$rid];
					for (let i in hole) {
						if (hole[i] && i > 9) {
							rsult += parseInt(hole[i]);
						}
					}
				}

				if (rsult == 0) {
					return '-';
				} else {
					return rsult;
				}
			},
			aliclickshowhide($s) {
				if ($s == '1') {
					this.show = '1';
				} else {
					this.show = '0';
				}
			},
			is_weixn() {

				var ua = navigator.userAgent.toLowerCase();
				if (ua.match(/MicroMessenger/i) == "micromessenger") {

					return true;

				}
				return false;

			},
			DouwnExcel() {
				if (this.is_weixn()) {
					this.show = '1';
					return false;
				}

				// console.log(this.$refs.pdf);
				let lang = getSystemLang();
				if (lang == 'cn') {
					lang = 'zh-cn';
				} else if (lang == 'en') {
					lang = 'en-us';
				} else {
					lang = 'zh-cn';
				}
				let domain = window.location.protocol + "//" + window.location.host;
				//技术统计
				// /public/index.php/core/en-us/matchs/match/matchtj.json?otp=playerScoreExport&mt_id=7964&round_id=2
				let url = 'http://www.clpga.org/public/index.php/core/' + lang +
					'/matchs/match/matchtj.json?otp=playerScoreExport&mt_id=' + this.info.info.ID + '&round_id=' + this
					.radio;

				// let url = domain+'/index.php/Home/Match/ExpResult/year/'+this.info.info.year+'/match_id/'+this.info.info.ID+'/lang/'+lang+'/rid/'+this.radio+'/mt_round/'+this.info.info.mt_round_num+'';
				window.open(url, '_blank');
				return false;
				// if(this.pdfDonw)
				// {
				// 	alert('下载中.....');
				// 	return false;
				// }
				// html2Canvas('比赛分数','pdf');
				// alert('下载中.....');
				// this.pdfDonw = true;
				// setTimeout(()=>{
				// 	this.pdfDonw = false;
				// },30000);
			},
			Douwn() {
				if (this.is_weixn()) {
					this.show = '1';
					return false;
				}

				// console.log(this.$refs.pdf);
				let lang = getSystemLang();
				if (lang == 'cn') {
					lang = 'zh-cn';
				} else if (lang == 'en') {
					lang = 'en-us';
				} else {
					lang = 'zh-cn';
				}
				let domain = window.location.protocol + "//" + window.location.host;
				//技术统计
				// let cuurl = domain+'/index.php/Home/Match/expPersonalAllInfoPdf/match_id/'+this.info.info.ID+'/lang/'+lang+'/rid/'+this.radio;
				let url = domain + '/index.php/Home/Match/ExpResult/year/' + this.info.info.year + '/match_id/' + this.info
					.info.ID + '/lang/' + lang + '/rid/' + this.radio + '/mt_round/' + this.info.info.mt_round_num + '';
				window.open(url, '_blank');
				return false;
				// if(this.pdfDonw)
				// {
				// 	alert('下载中.....');
				// 	return false;
				// }
				// html2Canvas('比赛分数','pdf');
				// alert('下载中.....');
				// this.pdfDonw = true;
				// setTimeout(()=>{
				// 	this.pdfDonw = false;
				// },30000);
			},
			OutInChange(a) {
				if (a) {
					this.OutInFlag = false
				} else {
					this.OutInFlag = true
				}
				console.log(this.OutInFlag)
			},
			showOpcFun(){
				this.showOpcF=!this.showOpcF;
			}
		}
	}
</script>

<style scoped="scoped">
	.all_body{
		position: relative;
	}
	.isFixed{
		 position:fixed;
		 top: 0;
		 background-color: #fff;
		 z-index:999;
	 }
	 .lf_isFixed{
		 position:fixed;
		 top: 0.5rem;
		 width: 18.5rem;
		 z-index:999;
	 }
	.lf_group_tit_left{
		margin-top: 0.2rem;
	}
	.d_right{
		float: right;

	}
	.d_right_text{
		float: left;
		color: #C0153E;
		margin-right: 0.2rem;
		font-size: 0.24rem;
		height: 0.3rem;
		line-height:0.3rem;;
	}
	.d_right img{
		width: 0.3rem;
		height: 0.3rem;
		float: right;
		transform: rotate(90deg);
		
	}



	.lf_match_info_box_r {
		display: flex;
		justify-content: flex-end;
	}

	.lf_course_bogo {
		position: relative;
		font-size: 0.2rem;
		color: #fff;
	}

	.lf_course_bogo_des {
		height: 0.6rem;
		display: flex;
		align-items: center;
		text-indent: 0.2rem;
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.498);
		width: 100%;
	}
	.top_to {
		margin-top: 0.2rem;
	}
	.lf_course_name_data_box {
		background-color: #FAFAFA;

			width: 18.5rem;
			margin: 0 auto;
			margin-top: 0.2rem;
	}

	.lf_score_tips {
		display: flex;
		align-items: center;
		color: #666;
			font-size: 0.22rem;
	}

	.lf_score_tips div {
		margin: 0 0.2rem;
	}

	.lf_score_tips div text {
		color: #C0153E;
	}

	.lf_score_tips div img {
		width: 0.2rem !important;
		height: 0.2rem !important;
	}

	.lf_tec_download {
		width: 98%;
		color: #C0153E;
		font-size: 0.22rem;
		height: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
	}

	.lf_tec_download div {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.lf_tec_download img {
		width: 0.24rem;
		height: 0.24rem;
		margin-left: 0.1rem;
	}

	.lf_course_name_data_tit {
		color: #666;
		font-size: 0.24rem;
		height: 0.8rem;
		display: flex;
		align-items: center;
		margin: 0 auto;
		background-color: #FFF1F2;
		overflow: hidden;
	}
	.lf_course_name_data_tit .lf_8 div:nth-of-type(2),
	.lf_course_name_data_tit .lf_OUT div:nth-of-type(2),
	.lf_course_name_data_tit .lf_IN div:nth-of-type(2){
		color: #333;
	}
	.lf_course_name_data_con {
		color: #000;
		font-size: 0.28rem;
		height: 0.7rem;
		display: flex;
		align-items: center;
		margin: 0 auto;
	}

	.lf_course_name_data_con_select {
		background-color: #FFF1F2;
	}

	.lf_course_name_data_tit div,
	.lf_course_name_data_con div {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}

	.lf_course_name_data_tit div,
	.lf_course_name_data_tit div div {
		font-size: 0.24rem;
	}

	.lf_course_name_data_con div,
	.lf_course_name_data_con div div {
		font-size: 0.28rem;
	}

	.lf_thru_F {
		color: #C0153E;
	}
	.lf_thru_F span{
		color: #666;
	}
	.lf_1_1 {
		width: 0.36rem;
		position: absolute;
		bottom: 0;
		right: 0;
	}

	.lf_1_1 img {
		width: 0.16rem;
		height: 0.16rem;
	}

	.lf_1_1 .lf_up {
		font-size: 0.16rem !important;
		color: #C0153E;
		position: relative;
		top: 2px;
		left: 0.02rem;
	}

	.lf_1_1 .lf_down {
		font-size: 0.16rem !important;
		color: #24E42F;
		position: relative;
		top: 2px;
		left: 0.02rem;
	}

	.lf_2 {
		flex-grow: 1;
		display: flex;
		align-items: center;
		flex-direction: row !important;
		height: 100%;
		overflow: hidden;
	}

	.lf_2 img {
		margin-right: 0.1rem;
		margin-left: 0.2rem;
	}

	.lf_course_name_data_con .lf_2 {
		justify-content: flex-start;
		cursor: pointer;
		width: 2rem;
	}

	.lf_course_name_data_tit .lf_1,
	.lf_course_name_data_con .lf_1 {
		display: flex;
		align-items: center;
		width: 0.9rem;
		position: relative;
		height: 100%;
	}
	.lf_1 img.lf_aixin {
		width: 0.2rem;
		height: 0.2rem;
		cursor: pointer;
		position: absolute;
		top: 0;
		left: 0;
	}
	.lf_2 .lf_country {
		width: 20px;
		height: 14px;
		margin-right: 10px;
	}
	.lf_2 a{
		display: flex;
		flex-wrap: wrap;
		height: 100%;
		line-height: normal;
		align-items: center;
		width: 2.7rem;
		flex-grow: 1;
	}
	.lf_2 .lf_club {
		width: 0.73rem;
		height: 0.25rem;
		margin-left: 10px;
	}

	.lf_3 {
		width: 0.9rem;
	}

 
	.lf_6 {
		width: 0.7rem;
	}

	.lf_7 {
		width: 0.92rem;
		background-color: #FFF1F2;
		height: 100%;
	}

	.lf_e {
		background-color: #FFF692;
		color: #C0153E !important;
	}

	.lf_b {
		color: #C0153E !important;
	}

	.lf_bg {
		color: #2C2AE3 !important;
	}

	.lf_dbg {
		color: #fff !important;
		background-color: #2C2AE3;
	}

	.lf_8 {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 0.4rem;
		height: 100%;
	}

	.lf_8 div {
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 0.4rem;
		height: 0.4rem;
		font-size: 0.22rem!important;
	}

	.lf_8 div {
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		
		font-style: normal;

	}

	.lf_OUT,
	.lf_IN {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 0.7rem;
		height: 100%;
		background-color: #FFF1F2;
	}

	.lf_OUT div,
	.lf_IN div {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.22rem;
		line-height: 0.4rem;
		height: 0.4rem;
	}

	.lf_OUT div,
	.lf_IN div {
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
	
		font-style: normal;

	}

	.lf_match_info_box_con {
		margin: 0 2%;
		background-color: #fff;
	}

	.lf_match_info_con {
		display: flex;
		box-sizing: border-box;
		border-bottom: 1px solid #EFEFEF;
	}

	.lf_match_info_con div {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 0.6rem;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.28rem;
		color: #333;
	}

	.lf_match_info_con .lf_21,
	.lf_match_info_con .lf_22 {
		width: 1.1rem;
		height: 0.5rem;
		color: #C0153E;
		flex-grow: 1;
		flex-shrink: 0;
	}
	.lf_Playoff_tit .lf_off_2 {
		color: #666;
	}
	.lf_match_info_con .lf_22 {
		display: flex;
		flex-direction: column;
		height: 0.8rem;
		flex-shrink: 0;
		width: 0.5rem;
	}

	.lf_match_info_con .lf_22 div,
	.lf_match_info_con .lf_11 div {
		height: 0.35rem;
	}

	.lf_match_info_con .lf_11 {
		width: 0.6rem;
		color: #C0153E;
		flex-shrink: 0;
		flex-direction: column;
		height: 0.8rem;
	}

	.lf_match_info_score_con {
		display: flex;
		box-sizing: border-box;
	}

	.lf_match_info_score_con .lf_221 {
		color: #C0153E;
		font-weight: bold;
	}

	.lf_match_info_score_con div {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 0.6rem;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.28rem;
		color: #333;
	}

	.lf_match_info_score_con .lf_21,
	.lf_match_info_score_con .lf_22 {
		width: 1.1rem;
		height: 0.5rem;
		flex-grow: 1;
		flex-shrink: 0;
	}

	.lf_match_info_score_con .lf_22 {
		display: flex;
		flex-direction: column;
		height: 0.5rem;
		flex-shrink: 0;
		width: 0.5rem;
	}

	.lf_match_info_score_con .lf_22 div {
		height: 0.35rem;
	}

	.lf_match_info_score_con .lf_11 {
		width: 0.6rem;
		height: 0.5rem;
		color: #C0153E;
		flex-shrink: 0;
	}

	.lf_match_info_score_con .lf_b {
		color: #B6081D;
	}

	.lf_match_info_score_con .lf_par {
		color: #333;
	}

	.lf_match_info_score_con .lf_e {
		color: #2C2AE3;
	}

	.lf_match_info_con .lf_11 div {
		color: #C0153E !important;
	}

	.lf_match_info_con .lf_221 {
		color: #C0153E !important;
		font-weight: bold;
	}

	.lf_Playoff_box {
		background-color: #FAFAFA;
		margin-top: 0.2rem;
	}
	
	.lf_Playoff_tit {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #EFEFEF;
		color: #333;
		font-size: 0.24rem;
		width: 98%;
		margin: 0 auto;
		height: 0.5rem;
	}
	
	.lf_Playoff_con {
	
		font-size: 0.28rem;
		display: flex;
		align-items: center;
		height: 0.7rem;
		width: 98%;
		margin: 0 auto;
	}
	
	.lf_off_1 {
		width: 0.96rem;
		margin-right: 0.3rem;
		font-size: 0.2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 0.31rem;
	}
	
	.lf_off_1_win {
		background: inherit;
		background-color: rgba(182, 8, 29, 1);
		border: none;
		border-radius: 2px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		color: #FFFFFF;
	
	}
	
	.lf_off_2 {
		display: flex;
		width: 3.15rem;
	
		align-items: center;
	}
	.lf_Playoff_tit .lf_off_2 {
		color: #666;
	}
	.lf_off_2 img {
		width: 0.4rem;
		margin-right: 0.15rem;
	
	}
	
	.lf_off_3 {
		display: flex;
		width: 2rem;
		align-items: center;
		justify-content: center;
		color: #000;
	}
	.lf_Playoff_tit .lf_off_3{
		color: #666;
	}
	.lf_223 {
		display: none !important;
	}

	.lf_jjx {
		height: 0.5rem;
		display: flex;
		align-items: center;
		color: #fff;
		justify-content: center;
		font-size: 0.24rem;
		width: 100%;
	}

	.lf_scroll_box {
		width: 100%;
		overflow-x: scroll;
		display: flex;
	}

	.lf_scroll_box_left {
		position: relative;
		flex-grow: 1;
		width: 18.5rem;
	}

	.lf_scroll_box_left .lf_course_name_data_con:nth-of-type(2n) {}

	.lf_scroll_box_right {
		margin-left: 0;
		width: fit-content;
	}
	.lf_tips_box_opc{
		display: none;
	}
	.banner {
		width: 100%;
		height: 1.85rem;
	}
	
	.banner img {
		width: 100%;
		height: 1.85rem;
	}
	.lf_tec_download_img_box{
		margin-left: 0.4rem;
	}
	.d_right_go{
		width: 1.2rem;
		height: 0.6rem;
		background: inherit;
		background-color: rgba(192, 21, 62, 0);
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(192, 21, 62, 1);
		border-radius: 0.05rem;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-size: 0.24rem;
		color: #C0153E;
		display: flex;
		align-items: center;
		flex-direction: column;
			line-height: 0.24rem;
			margin-right: 0;
			justify-content: center;
			padding-top: 0.05rem;
		}
		.d_right_go div{
			display: flex;
			align-items: center;
			height: fit-content;
			height: 0.24rem;
		}
	@media screen and (min-width: 768px) and (max-width:1199px) {
	    .lf_1 {
	    	width: 7%!important;
	    }
	    .lf_2 {
	    	width: 8%!important;
	    }
	    
	    .lf_3 {
	    	width: 3%!important;
	    }
	    .lf_6 {
	    	width: 4%!important;
	    }
	    
	    .lf_7 {
	    	width: 4%!important;
	    }
	    
	    .lf_8 {
	    	width: 2.2%!important;
	    }
	    .lf_2 .lf_club{
			display: none;
		}
	    .lf_OUT,
	    .lf_IN {
	    	width: 4%!important;
	    }
		/* .lf_scroll_box_left{
			width: 100%;
		}
		.banner{
			width: 100%;
			height: 1.75rem;
			overflow: hidden;
			margin: 0.2rem auto;
			display: none;
		}
		.all_body{
			width: 100%;
		} */
	}
	@media screen and (max-width: 768px) {
		.isFixed{
			 position:fixed;
			 top: 0;
			 background-color: #fff;
			 z-index:999;
		 }
		 .lf_isFixed{
			 position:fixed;
			 top: 0.5rem;
			 width: 100%;
			 z-index:999;
		 }
		.lf_course_name_data_box {
			width: 100%;
			position: relative;
		}
		.lf_2 .lf_club{
			display: none;
		}

		.lf_tec_download,
		.lf_course_name_data_tit {
			height: 0.7rem;
		}

		.lf_tec_download {
			justify-content: space-between;
			width: 100%;
		}

		.lf_score_tips div:nth-of-type(n+4) {
			display: none !important;
		}

		.lf_tec_download_img_box text {
			display: none;
		}
		
		.lf_match_info_con,
		.lf_match_info_score_con {
			justify-content: flex-end !important;
		}

		.lf_jjx {
			height: 0.5rem;
			width: 100%;
		}
		.d_title,
		.d_title span {
			font-size: 0.26rem;
		}

		.lf_course_name_data_tit div,
		.lf_course_name_data_tit div div {
			font-size: 0.24rem;
		}

		.lf_course_name_data_con div,
		.lf_course_name_data_con div div {
			font-size: 0.26rem;
		}

		.lf_course_name_data_con {
			height: 0.7rem;
		}

		.lf_1 {
			width: 1rem !important;
		}

		.lf_1_1 {
			width: .46rem;
		}

		.lf_2 {
			width: 2rem;
		}

		.lf_3 {
			width: 0.66rem;
		}

		.lf_4 {
			width: 0.66rem;
		}

		.lf_5 {
			width: 0.66rem;
		}

		.lf_6 {
			width: 0.55rem;
		}

		.lf_7 {
			width: 0.7rem;
		}

		.lf_8 {
			width: 0.5rem;
		}

		.lf_OUT,
		.lf_IN {
			width: 0.66rem;
		}

		.lf_scroll_box {
			width: 100%;
			overflow-x: scroll;
		}

		.lf_scroll_box_left {
		}

		.lf_scroll_box_out {
			position: relative;
		}

		.lf_tec_download_img_box {
			display: none !important;
		}
		.d_right_go{
			width: 1.2rem;
			height: 0.6rem;
			background: inherit;
			background-color: rgba(192, 21, 62, 0);
			box-sizing: border-box;
			border-width: 1px;
			border-style: solid;
			border-color: rgba(192, 21, 62, 1);
			border-radius: 0.05rem;
			-moz-box-shadow: none;
			-webkit-box-shadow: none;
			box-shadow: none;
			font-size: 0.24rem;
			color: #C0153E;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			flex-direction: column;
			line-height: 0.24rem;
			margin-right: 0;
		}
		.d_right_go div{
			display: flex;
			align-items: center;
			height: fit-content;
			height: 0.24rem;
		}
		.lf_match_info_box_{
			padding:0.05rem 0 0.1rem;
		}
		.lf_group_tit_left{
			margin-top:0;
		}
		.lf_tips_box_opc{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 1.4rem;
			z-index: 9;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgba(0, 0, 0, 0.498);
			font-size: 0.24rem;
			color: #FFFFFF;
			text-align: center;
		}
		.lf_match_info_box_s{
			margin: 0.2rem 0.2rem;
		}
		.banner{
			width: 100%;
			height: 1.75rem;
			overflow: hidden;
			margin: 0.2rem auto;
			display: none;
		}
		.lf_off_3{
			width: 0.8rem;
		}
	}
</style>