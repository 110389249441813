<template>
	<!-- <navs></navs> -->
	<Btn v-if="matchInfo" :mt_id="matchInfo.ID"></Btn>

	<div class="lf_match_banner_box lf_match_banner_box_1">
		<div class="lf_opacity_div">
			<div class="lf_opacity_div_1">{{matchInfo?matchInfo.ColumnName:''}}</div>
			<div class="lf_opacity_div_2">{{matchInfo?matchInfo.mttime1:''}}</div>
			<div class="lf_opacity_div_3">
				<img src="../../assets/images/u1522.png" alt="">
				{{matchInfo?matchInfo.ColumnPlace:''}}
			</div>
		</div>
	</div>
	<div style="clear: both;"></div>
	<div class="banner lf_bannner_sss" v-if="matchInfo.logo && matchInfo.logo.match_banner && matchInfo.logo.match_banner.length > 0">
		<swiper ref="dddd" :modules="modules" :autoplay="autoplayOptions" :pagination="{ clickable: true }" @swiper="onSwiper"
			@slideChange="onSlideChange" class="lf_banner_div_left_img">
			<swiper-slide v-if="matchInfo.logo && matchInfo.logo.match_banner && matchInfo.logo.match_banner.length > 0" v-for="b in matchInfo.logo.match_banner">
				
						<div class="lf_banner_div_left_img_item">
							<img v-if="b" :src="b" :alt="b">
		
						</div>
					
				
			</swiper-slide>
		</swiper>
		
		

		<banner v-if="matchInfo.logo && matchInfo.logo.match_banner && matchInfo.logo.match_banner.length > 0" heightNew="0.5rem" :positions="'inside'" :type="1" datatype="1" :dataurl="matchInfo.logo.match_banner"></banner>
	</div>
	<!-- <videoPlayer v-if="zb.vdr_vod_url && zb.mt_id == matchInfo.match_id" :url="zb.vdr_vod_url"></videoPlayer> -->
	<!-- <videoPlayer url="https://other1-live.situne.cn/phone/other-18.m3u8"></videoPlayer> -->
	<!-- <el-row type="flex" justify="center">
		<el-col :xs="24" :sm="16" :md="16">
			<div class="lf_match_banner_box">
				<img v-if="matchInfo.show_img!=''" class="lf_match_banner_box_img" :src="matchInfo.show_img" alt="">
				<div class="lf_opacity_div">
					<img v-if="matchInfo.logo!=''" :src="matchInfo.logo" alt="">
					<div class="lf_opacity_div_1">{{matchInfo?matchInfo.ColumnName:''}}</div>
					<div class="lf_opacity_div_2">{{langs.text_date}}：{{matchInfo?matchInfo.mttime:''}}</div>
					<div class="lf_opacity_div_3">{{langs.Venue}}：{{matchInfo?matchInfo.ColumnPlace:''}}</div>
					<div class="lf_opacity_div_4">{{langs.TotalPurse}}：{{matchInfo?matchInfo.ColumnPrice:''}}</div>
					<div v-if="zb.vdr_vod_url && zb.mt_id == matchInfo.match_id" class="lf_opacity_div_5">{{langs.WatchLiveStream}}</div>
				</div>
			</div>
		</el-col>
	</el-row> -->
	<!-- <Ad pos="singlematch" abs="SINGLEMATCH_TOP"></Ad> -->
	<div class="all_body_new">
		<div class="lf_tab_tit_box">
			<div class="lf_tab_box_new">

				<div @click="goInfo('cj',bFlag=='per'?'per':'')" :class="tFlag=='cj'?'lf_tab_box_select':''">
					{{langs.Leaderboard1s}}
				</div>
				<div @click="goInfo('match','fzb')" :class="bFlag=='fzb'?'lf_tab_box_select':''">{{langs.TeeTimes}}
				</div>

				<!-- 	<div @click="goInfo('ssgc','ssgc')" :class="tFlag=='ssgc'?'lf_tab_box_select':''">{{langs.Sh}}</div> -->
				<div @click="goInfo('match','csqy')" :class="bFlag=='csqy'?'lf_tab_box_select':''">{{langs.Field}}
				</div>
				<div @click="goInfo('match','jstj')" :class="bFlag=='jstj'?'lf_tab_box_select':''">{{langs.Stats}}
				</div>
				<div @click="goInfo('match','qcjs')" :class="bFlag=='qcjs'?'lf_tab_box_select':''">
					{{langs.CourseIntroduction}}
				</div>
				<div @click="goInfo('match','ssgc')" :class="bFlag=='ssgc'?'lf_tab_box_select':''">
					{{langs.FactSheet}}
				</div>
				<div @click="goInfo('news','xw')" :class="tFlag=='news'?'lf_tab_box_select':''">{{langs.News}}</div>
			</div>
		</div>
	</div>
	<div class="all_body_swiper">
		<swiper ref="aaa" :modules="modules" :autoplay="false" :initialSlide="nextpage" :pagination="{ clickable: true }" @swiper="onSwiper1"
			@slideChange="onSlideChange" class="lf_banner_div_left_img">
			<swiper-slide>

				<div class="lf_tab_tit_box">
					<div class="lf_tab_box_newSwiper">
						<div @click="goInfo('cj',bFlag=='per'?'per':'lxb')" :class="tFlag=='cj'?'lf_tab_box_select':''">
							<div>{{langs.Leaderboard1s}}</div>
						</div>
						<div @click="goInfo('match','fzb')" :class="bFlag=='fzb'?'lf_tab_box_select':''">
							<div>{{langs.TeeTimes}}</div>
						</div>

						<!-- 	<div @click="goInfo('ssgc','ssgc')" :class="tFlag=='ssgc'?'lf_tab_box_select':''">{{langs.Sh}}</div> -->
						<div @click="goInfo('match','csqy')" :class="bFlag=='csqy'?'lf_tab_box_select':''">
							<div>{{langs.Field}}</div>
						</div>
						<div @click="goInfo('match','jstj')" :class="bFlag=='jstj'?'lf_tab_box_select':''">
							<div>{{langs.Stats}}</div>
						</div>
						<div class="rightBtn" @click="slideNext"></div>
					</div>
				</div>

			</swiper-slide>


			<swiper-slide>
				<div class="lf_tab_tit_box">
					<div class="lf_tab_box_newSwiper lf_tab_box_newSwiper_99">
						<div class="leftBtn" @click="slidePrev"></div>
						<div @click="goInfo('match','jstj')" :class="bFlag=='jstj'?'lf_tab_box_select':''">
							<div>{{langs.Stats}}</div>
						</div>
						<div @click="goInfo('match','qcjs')" :class="bFlag=='qcjs'?'lf_tab_box_select':''">
							<div>{{langs.CourseIntroduction}}</div>
						</div>
						<div @click="goInfo('match','ssgc')" :class="bFlag=='ssgc'?'lf_tab_box_select':''">
							<div>{{langs.FactSheet}}</div>
						</div>
						<div @click="goInfo('news','xw')" :class="bFlag=='xw'?'lf_tab_box_select':''">
							<div>{{langs.News}}</div>
						</div>
					</div>
				</div>
			</swiper-slide>
		</swiper>
		
	</div>

	<!-- 	<el-row type="flex" justify="center" v-if="tFlag == 'match' || tFlag == 'news' || tFlag == 'cj'">
		<el-col :xs="24" :sm="16" :md="16">
			<div class="lf_tab_tit_box lf_tab_tit_box999" style="justify-content: center;">
				<div class="lf_tab_little_box">
					<div @click="goInfo('match','ssxx')" :class="bFlag=='ssxx'?'lf_tab_box_select':''">{{langs.ShGk}}</div>
					
					
					
					<div  @click="goInfo('news','xw')" :class="bFlag=='xw'?'lf_tab_box_select':''">{{langs.News}}</div>   
					<div  @click="goInfo('news','tp')" :class="bFlag=='tp'?'lf_tab_box_select':''">{{langs.Pictures}}</div>   
					<div  @click="goInfo('news','sp')" :class="bFlag=='sp'?'lf_tab_box_select':''">{{langs.Video}}</div> 

					<div  @click="goInfo('cj','lxb')" :class="bFlag=='lxb'?'lf_tab_box_select':''">{{langs.cj_lxb}}</div>   
					<div  @click="goInfo('cj','zdcj')" :class="bFlag=='zdcj'?'lf_tab_box_select':''">{{langs.cj_zd}}</div> 
					 
								
					<div  @click="goInfo('cj','tm')" :class="bFlag=='tm'?'lf_tab_box_select':''">{{langs.cj_tm}}</div>
				</div>
			</div>
		</el-col>
	</el-row> -->
	<!-- <ScoreA></ScoreA> -->
	<div style="margin:0 auto">

		<IndexSg v-if="tFlag== 'match' && bFlag=='ssgc' && matchInfo.ID" :mt_id="matchInfo.ID+''"></IndexSg>
		<!-- match ssxx 赛事信息-->
		<Index v-if="tFlag== 'match' && bFlag=='ssxx' && matchInfo.ID" :mt_id="matchInfo.ID+''"></Index>
		<!-- match csqy 参赛球员-->
		<Player v-if="tFlag== 'match' && bFlag=='csqy' && matchInfo.ID" :mt_id="matchInfo.ID+''"></Player>
		<!-- match qcjs 球场介绍-->
		<Course v-if="tFlag== 'match' && bFlag=='qcjs' && matchInfo.ID" :mt_id="matchInfo.ID+''"></Course>
		<!-- match fzb 分组表-->
		<group v-if="tFlag== 'match' && bFlag=='fzb' && matchInfo.ID" :mt_id="matchInfo.ID+''"></group>
		<!-- match jstj 技术统计-->
		<TecStatistics v-if="tFlag== 'match' && bFlag=='jstj' && matchInfo.ID" :mt_id="matchInfo.ID+''"></TecStatistics>
		<!-- <Ad v-if="tFlag== 'match' && bFlag=='jstj' && matchInfo.ID" :mt_id="matchInfo.ID+''"></Ad> -->
		<!-- cj  成绩-->
		<ScoreT @goInfo="goInfo" :round_id="round_id" flag="tm" v-if="tFlag== 'cj' && bFlag=='tm' && matchInfo.ID"
			:mt_id="matchInfo.ID+''"></ScoreT>
		<ScoreHBH @goInfo="goInfo" :round_id="round_id" flag="cj" v-if="tFlag== 'cj' && bFlag=='zdcj' && matchInfo.ID"
			:mt_id="matchInfo.ID+''"></ScoreHBH>
			<ScoreHBHT @goInfo="goInfo" :round_id="round_id" flag="cj" v-if="tFlag== 'cj' && bFlag=='tmzdcj' && matchInfo.ID"
			:mt_id="matchInfo.ID+''"></ScoreHBHT>
		<ScoreA @goInfo="goInfo" :round_id="round_id" flag="cj"
			v-if="tFlag== 'cj' && (bFlag=='' || bFlag=='lxb') && matchInfo.ID" :mt_id="matchInfo.ID+''"></ScoreA>
		<ScoreAT @goInfo="goInfo" :round_id="round_id" flag="cj"
			v-if="tFlag== 'cj' && (bFlag=='tmlxb') && matchInfo.ID" :mt_id="matchInfo.ID+''"></ScoreAT>
		<BDS @goInfo="goInfo" :round_id="round_id" flag="cj" v-if="tFlag== 'cj' && bFlag=='per' && matchInfo.ID"
			:mt_id="matchInfo.ID+''"></BDS>
		<!-- news xw 新闻-->
		<!-- <Ad v-if="tFlag== 'news' && bFlag=='xw' && matchInfo.ID" :mt_id="matchInfo.ID+''"></Ad> -->
		<NewListmore v-if="tFlag== 'news' && bFlag=='xw' && matchInfo.ID" type="match" hide="1" :id="matchInfo.ID+''"
			mt_id="" year="" pl_name="" mt_name=""></NewListmore>
	</div>
	<!-- news tp 图片-->
	<Ad v-if="tFlag== 'news' && bFlag=='tp' && matchInfo.ID" :mt_id="matchInfo.ID+''"></Ad>
	<!-- news sp 视频-->
	<Ad v-if="tFlag== 'news' && bFlag=='sp' && matchInfo.ID" :mt_id="matchInfo.ID+''"></Ad>

	<Footer :year="year" v-if="matchInfo.ID && matchInfo.ID > 0" :mt_id="matchInfo.ID"></Footer>
	
	<Footer></Footer>
	
	<Footer1 :tFlag="tFlag"></Footer1>
</template>

<script>
	// @ is an alias to /src
	import navs from "../../components/nav.vue";
	import Btn from "../../components/Btn.vue";
	import Footer from "../../components/Footer.vue";
	import Footer1 from "../../components/Footer1.vue";
	import Ad from "../../components/Ad.vue";
	import Player from "../../components/Player.vue";
	import Index from "../../components/Index.vue";
	import IndexSg from "../../components/IndexSg.vue"; //IndexSg
	import Group from "../../components/Match/Group.vue";
	import Course from "../../components/Match/Course.vue";
	import TecStatistics from "../../components/Match/TecStatistics.vue";
	import ScoreA from "../../components/Match/ScoreA.vue";
	import ScoreAT from "../../components/Match/ScoreAT.vue";
	import NewListmore from "../../components/NewListmore.vue";
	import videoPlayer from "../../components/videoPlayer.vue";
	import ScoreT from "../../components/Match/ScoreT.vue";
	import BDS from "../../components/Match/BDS.vue";
	import ScoreHBH from "../../components/Match/ScoreHBH.vue"
	import ScoreHBHT from "../../components/Match/ScoreHBHT.vue"
	import banner from "../../components/banner.vue";
	import {
		getSystemLang,
		getLangAll
	} from "../../lang";
	import axios from '../../axios/request';
	//这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue/swiper-vue';

	import {
		Autoplay,
		Pagination
	} from 'swiper';
	import 'swiper/swiper-bundle.css';
	import 'swiper/swiper.min.css';
	//这里就是更新后的路径，以前是import 'swiper/css'

	//自动轮播的配置
	export default {
		data() {
			let round_id = this.$route.query.round_id;
			if(!round_id)
			{
				round_id = 0;
			}
			
			return {
				langs: '',
				systemlang: 'cn',
				opacityF: true,
				round_id: round_id,
				swiper:null,
				options: [{
						value: 'Option1',
						label: 'Option1',
					},
					{
						value: 'Option2',
						label: 'Option2',
					},
					{
						value: 'Option3',
						label: 'Option3',
					},
					{
						value: 'Option4',
						label: 'Option4',
					},
					{
						value: 'Option5',
						label: 'Option5',
					},
				],
				matchInfo: {},
				zb: {},
				nextpage:0,
				year: '',
				tFlag: 'match', //match:赛事信息 cj:成绩信息  news:新闻信息
				bFlag: 'ssgc', //ssxx:赛事信息 csqy:参赛球员 qcjs:球场介绍 fzb:分组表 jstj:技术统计  tm:团队成绩 zdcj:逐洞成绩 lxb:领先榜 per:个人比洞成绩  tmlxb:团队领先榜 tmzdcj:团队逐洞成绩
			}
		},
		props: {

		},
		name: 'Home',
		components: {
			navs,
			Btn,
			Footer,
			Footer1,
			Swiper,
			SwiperSlide,
			Ad,
			Player,
			Index,
			Group,
			Course,
			TecStatistics,
			ScoreA,
			ScoreAT,
			ScoreHBH,
			ScoreHBHT,
			NewListmore,
			IndexSg,
			ScoreT,
			BDS,
			videoPlayer,
			banner
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);

			let id = this.$route.query.id;
			let type = this.$route.query.type;
			let bFlag = this.$route.query.bFlag ? this.$route.query.bFlag : '';
			if (type) {
				this.tFlag = type;
				this.bFlag = bFlag;

				if(this.bFlag=='qcjs'||this.bFlag=='ssgc'||this.bFlag=='xw')
				{
					this.nextpage = 1;
				}

			}
			
			//获取banner
			axios('matchs/match/matchinfo.json', {
					id: id,
					round_id:this.round_id
				})
				.then((res) => {
					let data = res.data.data;
					this.matchInfo = data;
					console.log("matchInfo", this.matchInfo);
					this.year = this.matchInfo.ColumnStart.substring(0, 4);
					if (data.mZt == 'c' && this.bFlag == '' && this.tFlag == 'cj') {
						this.bFlag = 'zdcj';
					} else if (this.bFlag == '' && this.tFlag == 'cj') {
						this.bFlag = 'lxb';
					}
					if (data.perFlag == '1' && this.tFlag == 'cj') {
						this.bFlag = 'per';
					}
					console.log('b_flag', this.bFlag);
				});

			this.getZB();

		},
		setup() {
			let useSwiper = undefined;
			const onSwiper = (swiper) => {
				console.log('111',swiper);
			};
			const onSwiper1 = (swiper) => {
				console.log('111',swiper);
				useSwiper = swiper;
			};
			const onSlideChange = (e) => {
				console.log('slide change123123123', e.activeIndex);
			};
			const slidePrev = () => {
			      console.log(useSwiper)
			      useSwiper.slidePrev()
			    }
			const slideNext = () => {
			  console.log(useSwiper)
			  useSwiper.slideNext()
			}
			const autoplayOptions = {
				delay: 5000,
				disableOnInteraction: false,
				loop: false,
				pauseOnMouseEnter: true,
				reverseDirection: true
			};
			
			return {
				onSwiper,
				onSwiper1,
				onSlideChange,
				autoplayOptions,
				modules: [Pagination, Autoplay],
				slidePrev,
			  slideNext
			};
		},
		mounted () {
		    // 监听resize方法
			
		    window.addEventListener("resize", this.renderResize, false)
		},
		methods: {
			gonextPage(page){
				this.nextpage = page;
				console.log('sssss',this.nextpage);
				
				this.onSwiper.slideNext();
			},
			getZB() {
				axios('matchs/match/zb.json')
					.then((res) => {
						let data = res.data;
						if (data.msgCode == '200') {
							this.zb = data.data;
						}

					});
			},
			changeFun(index, a) {
				var that = this;
				that.$data.opacityF = false;
				setTimeout(function() {
					that.$data.opacityF = true;
				}, 400)
			},
			goInfo(tFlag, bFlag, round_id) {
				let type1 = this.$route.query.type;
				let bFlag1 = this.$route.query.bFlag ? this.$route.query.bFlag : '';
				let round_ids = this.$route.query.round_id ? this.$route.query.round_id : 0;
				if (tFlag != type1 || bFlag1 != bFlag || (round_ids != round_id && tFlag=='cj')) {


					let routeUrl = this.$router.resolve({
						path: "/Match",
						query: {
							lang: this.systemlang,
							id: this.$route.query.id,
							type: tFlag,
							bFlag: bFlag,
							round_id:round_id
						}
					});
					
					if( (tFlag=='cj' && round_ids != '') || (tFlag=='cj' && type1 != 'cj') || tFlag!='cj')
					{
						window.open(routeUrl.href, '_self');
					}
					else
					{
						this.$router.replace({
						path: "/Match",
						query: {
							lang: this.systemlang,
							id: this.$route.query.id,
							type: tFlag,
							bFlag: bFlag,
							round_id:round_id
						}
					});
					}
					return false;
				}
				console.log('cs', tFlag, bFlag, round_id);

				if (round_id > 0) {
					this.round_id = round_id;
				}
				this.tFlag = tFlag;
				this.bFlag = bFlag;



			},
			renderResize() {
				window.onresize = function() {
				    fnResize();
				}
				console.log(11)
			}
		},
		beforeDestroy () {
		    // 移除监听
		    window.removeEventListener("resize", this.renderResize, false)
		}

	}
</script>


<style scoped="scoped">
	.lf_footer1_tit_box {
		height: 1.4rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}
	.all_body_new{
		width: 18.5rem;
		margin: 0 auto;

	}
	.all_body_swiper {
		display: none;
		width: 7.5rem;
		margin: 0 auto;
	}

	.swiper_all {
		height: 1rem;
	}

	.swiper_item {
		height: 1rem;
	}

	.lf_footer1_tit_box .lf_foot_tit_text1 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.26rem;
		color: #C0153E;
	}

	.lf_footer1_tit_box .lf_foot_tit_text2 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.15rem;
		color: #C0153E;
	}

	.lf_footer1_tit_box .lf_foot_tit_line {
		width: 2rem;
		height: 3px;
		background-color: #C0153E;
		margin: 4px 0;
	}

	.lf_tab_tit_box {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin-bottom: 0.2rem;

	}

	.lf_tab_tit_box .el-select {
		position: absolute;
		left: 0.1rem;
	}

	.lf_tab_little_box {

		display: flex;
		align-items: center;
		border: 1px solid #999;
		border-radius: 8px;
		margin-top: 0.5rem;
	}

	.lf_tab_little_box div {
		width: 1.6rem;
		height: 0.5rem;
		font-size: 0.26rem;
		color: #999;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		border-right: 1px solid #999;
		word-break: keep-all;
		cursor: pointer;
	}

	.lf_tab_little_box div:nth-last-of-type(1) {
		border-right: unset;
	}


	.lf_tab_box_new {
	
		height: 0.5rem;
		display: flex;
		align-items: center;
		margin-top: 0.4rem;

	
	}

	.lf_tab_box_new div {
		width: 1.6rem;
		height: 0.5rem;
		font-size: 0.26rem;
		color: #666;
		border: 0.01rem solid rgba(153, 153, 153, 1);
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		left: -0.01rem;
		cursor: pointer;
	
		position: relative;
		
	}

	.lf_tab_box_new div:nth-of-type(1) {
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
		position: relative;
		left:  -0.01rem;
	}

	.lf_tab_box_new div:nth-of-type(2) {
		border-radius: 0;
	}


	.lf_tab_box_new .lf_tab_box_select {
		border-color: #C0153E;
		color: #C0153E;
		position: relative;
		z-index: 2;
		font-weight: bold;
	}

	.lf_opacity_div {
		width: 100%;
		color: #333333;
		z-index: 99;

	}

	.banner {
		width: 18.5rem;
		height: 4rem;
		margin: 0 auto;
		overflow: hidden;
		margin-bottom: 0.2rem;
		margin-top: 0.3rem;
	}


	.lf_opacity_div_1 {
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		font-weight: 700;
		font-style: normal;
		font-size: 0.4rem;
		letter-spacing: 0.01rem;
		color: #C0153E;
		max-width: 100%;
		padding: 0 0.6rem;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		overflow: hidden;
		margin: 0.1rem auto 0;
		width: fit-content;
		border-bottom: 1px solid #fff;
		margin-top: 0.7rem;
	}

	.lf_opacity_div_2 {
		width: 100%;
		text-align: center;
		font-size: 0.24rem;
		color: #C0153E;
		height: 0.25rem;
		line-height: 0.25rem;
		margin-top: 0.1rem;
	}

	.lf_opacity_div_3 {
		width: 100%;
		margin: 0 auto;
		text-align: center;
		font-size: 0.24rem;
		color: #333333;
		height: 0.25rem;
		line-height: 0.25rem;
		margin-top: 0.2rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_opacity_div_4 {
		width: 3.36rem;
		margin: 0 auto;
		text-align: center;
		font-size: 0.16rem;
		color: #fff;
		height: 0.25rem;
		line-height: 0.25rem;
	}

	.lf_opacity_div_5 {
		width: 1rem;
		height: 0.35rem;
		background: inherit;
		background-color: rgba(242, 242, 242, 0);
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(255, 255, 255, 1);
		border-radius: 5px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.14rem;
		color: #FFFFFF;
		margin: 0.25rem auto 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.d_title {
		margin-top: 0.14rem;
		margin-left: 10px;
		color: rgb(192, 21, 62);
		font-size: 0.2rem;
		padding-bottom: 4px;
		/* font-weight: bold; */
		font-family: '微软雅黑';
		border-bottom: 1px solid rgb(192, 21, 62);
		display: inline-block;
		padding-right: 30px;
	}

	.d_title span {
		font-size: 0.14rem;
	}
	
	.lf_match_banner_box {
		height: fit-content;
		width: 100%;
		position: relative;
		overflow: hidden;

	}
	.lf_opacity_div_3 img{
		width: 0.26rem;
		height: 0.26rem;
	}
	.lf_match_banner_box_img {
		height: 4rem;
	}

	.lf_match_info_box_text {
		padding: 0.24rem 10px;
		font-size: 0.14rem;
		color: #333;
		white-space: pre-wrap;
	}

	.lf_match_info_box_con {
		padding: 0.24rem 10px;
	}

	.lf_match_info_con {
		display: flex;
		background-color: #FAFAFA;
		box-sizing: border-box;
	}

	.lf_match_info_con div {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 0.6rem;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.16rem;
		color: #333;
		border-right: 1px solid #F5F5F5;
		border-bottom: 1px solid #F5F5F5;
	}

	.lf_match_info_con .lf_1,
	.lf_match_info_con .lf_12 {
		width: 1.1rem;
		height: 0.5rem;
		color: #C0153E;
		flex-grow: 1;
		flex-shrink: 0;
	}

	.lf_match_info_con .lf_11 {
		width: 0.6rem;
		height: 0.5rem;
		color: #C0153E;
		flex-shrink: 0;
	}

	.lf_match_info_con .lf_2 {
		flex-shrink: 0;
		width: 0.5rem;
	}

	.lf_match_champion_his_box {
		padding: 0.24rem 10px;
		font-size: 0.16rem;

	}

	.lf_match_champion_his_tit {
		display: flex;
		background-color: #FAFAFA;
		height: 0.8rem;
		align-items: center;
		border-bottom: 1px solid #F5F5F5;
		color: #333;
	}

	.lf_champion_his_1 {
		width: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_champion_his_2 {
		width: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-grow: 1;
		position: relative;
	}

	.lf_player_card {
		position: absolute;
		left: 1.5rem;
		top: 0;
		width: 5.2rem;
		height: 2.5rem;
		box-shadow: 0px 0px 4px 2px #f1f1f1;
		display: flex;
		border-radius: 5px;
		background-color: #fff;
		z-index: 9;
	}

	.lf_player_card_img {
		margin: 0.3rem;
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
	}

	.lf_player_card_con {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.lf_player_card_con_1 {
		font-size: 0.24rem;
		color: #333;
		display: flex;
		align-items: center;
		margin-bottom: 0.2rem;
	}

	.lf_player_card_con_1 img {
		width: 0.3rem;
		height: 0.21rem;
		margin-left: 0.3rem;
	}

	.lf_player_card_con_2 {
		font-size: 0.16rem;
		color: #999;
		display: flex;
		align-items: center;
		min-height: 0.35rem;
		display: flex;
		align-items: center;
	}

	.lf_champion_his_3 {
		width: 1.3rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_champion_his_3 img {
		width: 20px;
		height: 14px;
	}

	.lf_champion_his_4 {
		width: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_champion_his_5 {
		width: 3.1rem;
		display: flex;
		flex-direction: column;
	}

	.lf_champion_his_6 {
		width: 1.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_champion_his_7 {
		width: 1.6rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_rd_tit {
		width: 100%;
		height: 0.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_rd_box {
		width: 100%;
		height: 0.4rem;
		display: flex;
		flex-direction: row;
	}

	.lf_match_champion_his_con .lf_rd_box {
		height: ;
	}

	.lf_rd_box div {
		flex-grow: 1;
		height: 0.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_match_champion_his_con {
		display: flex;
		background-color: #FAFAFA;
		color: #999;
	}
	@media screen and (min-width: 768px) and (max-width:1199px) {
		    
			.all_body{
				width: 100%;
			}
		}
	@media screen and (max-width: 768px) {
		.lf_footer1_tit_box {
			margin-top: 0.5rem;
		}

		.all_body_swiper {
			display: block;

		}

		.all_body_new {
			display: none;
		}

		.lf_tab_tit_box {
			align-items: unset;
			overflow: scroll;
			padding-bottom: 0.1rem!important;
		}

		.lf_tab_tit_box999 {
			align-items: unset;
			overflow: scroll;
			justify-content: unset !important;
		}

		.lf_tab_box_new {
			margin-top: 0.2rem;
			
		}

		.lf_match_banner_box {
			width: 100%;
			position: relative;
			padding-bottom: 0.1rem;
		}

		.lf_match_banner_box_img {
			height: 2.5rem;
		}

		.lf_opacity_div {
			height: unset;
		}

		.lf_opacity_div_1 {
			font-size: 0.4rem;
			margin-top: 0.6rem;
		}

		.lf_opacity_div_2 {
			width: 100%;
			font-size: 0.24rem;
			color: #C0153E;
			height: 0.5rem;
			margin-top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.lf_opacity_div_3 {
			width: 100%;
			margin: 0 auto;
			text-align: center;
			font-size: 0.24rem;
			color: #333333;
			height: 0.3rem;
			line-height: 0.3rem;
			
		}

		.lf_opacity_div_4 {
			width: 3.36rem;
			margin: 0 auto;
			text-align: center;
			font-size: 0.16rem;
			color: #fff;
			height: 0.25rem;
			line-height: 0.25rem;
		}

		.lf_opacity_div_5 {
			width: 1rem;
			height: 0.35rem;
			background: inherit;
			background-color: rgba(242, 242, 242, 0);
			box-sizing: border-box;
			border-width: 1px;
			border-style: solid;
			border-color: rgba(255, 255, 255, 1);
			border-radius: 5px;
			-moz-box-shadow: none;
			-webkit-box-shadow: none;
			box-shadow: none;
			font-family: '微软雅黑';
			font-weight: 400;
			font-style: normal;
			font-size: 0.14rem;
			color: #FFFFFF;
			margin: 0.25rem auto 0;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.lf_12 {
			display: none !important;
		}

		.lf_match_info_con {
			justify-content: flex-end !important;
		}

		.lf_match_info_con .lf_2 {
			width: 0.6rem;
		}

		.lf_champion_his_5,
		.lf_champion_his_6 {
			display: none;
		}
		

		.lf_player_card {
			position: absolute;
			left: 0;
			top: 0.4rem;
			width: 5.2rem;
			height: 2.5rem;
			box-shadow: 0px 0px 4px 2px #f1f1f1;
			display: flex;
			border-radius: 5px;
			background-color: #fff;
			z-index: 9;
		}

		.lf_tab_box_new div {
			font-size: 0.24rem;
		}

		.lf_tab_little_box div {
			font-size: 0.24rem;
		}

		.d_title,
		.d_title span {
			font-size: 0.26rem;
		}

		.lf_footer1_tit_box .lf_foot_tit_text1 {
			font-family: '微软雅黑';
			font-weight: 400;
			font-style: normal;
			font-size: 0.28rem;
			color: #C0153E;
		}

		.lf_footer1_tit_box .lf_foot_tit_text2 {
			font-family: '微软雅黑';
			font-weight: 400;
			font-style: normal;
			font-size: 0.26rem;
			color: #C0153E;
		}

		.banner {
			width: 100%;
			height:1.62rem;
		}
		
		.rightBtn{
			width: 0.7rem !important;
			height: 0.5rem;
			
			border:  1px solid rgba(153, 153, 153, 1);
		
			background: url('../../assets/images/u2786.png') no-repeat;
			background-size: 0.3rem;
			background-position: center;
			display: flex;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			left: -0.01rem;
			cursor: pointer;
			position: relative;
			
			
			
		}
		.leftBtn{
			width: 0.7rem !important;
			height: 0.5rem;
			
			border:  1px solid rgba(153, 153, 153, 1);
			border-right: none;
			background: url('../../assets/images/u16135.png') no-repeat;
			background-size: 0.3rem;
			background-position: center;
			display: flex;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			cursor: pointer;
			position: relative;
			
			
			
		}
		.lf_tab_box_newSwiper{
			margin-bottom: 0.1rem;
			box-sizing: border-box;
		}
	}
</style>

<style>
	.all_body_swiper .swiper .swiper-slide {
		height: auto !important;
		width: 100% !important;
	
	}
	
	.all_body_swiper .swiper .swiper-slide img {
		height: auto !important;
		width: 100% !important;
	}
	
	.all_body_swiper  .swiper  .swiper-pagination {
		text-align: left;
		bottom: 1.5rem !important;
		padding-left: 0.5rem;
		display: none;
	}
	
	.all_body_swiper.swiper-pagination-bullet {
		
		background-color: #fff;
		border: #DBDBDB 0.01rem solid;
		opacity: 1;
		width: 0.15rem;
		height: 0.15rem;
		margin-right: 0.02rem !important;
	}
	
	
	.lf_bannner_sss .swiper-pagination{
		display: flex;
		align-items: center;
		justify-content: center;
		bottom: 10px!important;
	}
	@media screen and (max-width: 768px) {
			.all_body_swiper .swiper-pagination {
					
				bottom: 0.2rem !important;
				padding-left: 0;
			}
			.lf_bannner_sss .swiper-pagination{
				padding-left: 0;
			}
			
	}
</style>