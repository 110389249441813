<template>
	
	<Btn cd="xw"></Btn>
	<div class="lf_footer1">
		<el-row>
			<el-col :xs="24" :sm="24" :md="24">
				<div class="lf_footer1_tit_box">
					<div class="lf_foot_tit_text1">{{langs.newslist_news}}</div>
					<div class="lf_foot_tit_line"></div>
					<div class="lf_foot_tit_text2">{{langs.newslist_news_en}}</div>
				</div>
			</el-col>

		</el-row>
	</div>
	<div class="head">
		<div class="head_top">
			<div class="searchimg">
				<img src="../../assets/images/u6324.png" />
			</div>
			<div class="searchbox" style="position: relative;">
				<input :placeholder="langs.PleaseInput1" v-model="input" type="text" />
				<img @click="searchM()" v-if="input!=''" class="hand" style="width: 0.3rem;position: absolute;right: 1.6rem;top: 0.1rem;z-index: 9;" src="../../assets/images/u21104.png" alt="" />
				<div class="searchbutton" @click="getData">{{langs.psearch_search}}</div>
			</div>
		</div>

		<div class="searchword">{{langs.newslist_xinwensousuo}}</div>
	</div>
	<template v-if="search!=''">
		<div class="headwordbox" v-show="listshow">
			<span v-if="systemlang=='cn'">搜索到 {{search}} 相关内容 {{page.count}} 条</span>
			<span v-if="systemlang=='en'">There are ({{page.count}}) results related to "{{search}}"</span>
		</div>
		<div class="newwai">
				<NewListmore v-show="listshow" ref="newslistmore" type="search" hide="1" id="search" mt_id="" category="" year="" pl_name="" :searchstring="search" mt_name="" limit="12" front="" @setPage="setPage"></NewListmore>

		</div>
		</template>
	<Footer></Footer>
	<Footer1></Footer1>
</template>

<script>
	// @ is an alias to /src
	import navs from "../../components/nav.vue";
	import Btn from "../../components/Btn.vue";
	import Footer from "../../components/Footer.vue";
	import Footer1 from "../../components/Footer1.vue";
	import axios from '../../axios/request';
	import NewListmore from "../../components/NewListmore.vue";
	import {
		getSystemLang,
		getLangAll
	} from "../../lang";
	//这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue/swiper-vue';

	import {
		Autoplay,
		Pagination
	} from 'swiper';
	import 'swiper/swiper-bundle.css';
	import 'swiper/swiper.min.css';
	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				search: '',
				input: '',
				page: [],
				listshow: false
			}
		},
		watch: {
			
		},
		name: 'Home',
		components: {
			navs,
			Btn,
			Footer,
			Footer1,
			Swiper,
			SwiperSlide,
			NewListmore
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			let param = this.$route.query;
			if (param.search) {
				this.input = param.search;
				this.search = param.search;
			}
			this.$nextTick(() => {
				if (this.input != ''){
					this.getData();
				}
			})
		},
		setup() {
			const onSwiper = (swiper) => {
				console.log(swiper);
			};
			const onSlideChange = () => {
				// console.log('slide change123123123', e.activeIndex);
			};
			const autoplayOptions = {
				delay: 5000,
				disableOnInteraction: true,
				loop: false,
				pauseOnMouseEnter: true,
				reverseDirection: true
			};
			return {
				onSwiper,
				onSlideChange,
				autoplayOptions,
				modules: [Pagination, Autoplay],
			};
		},
		methods: {
			setPage(page){
				this.page = page;
				this.listshow = true;
			},
			searchM: function() {
				this.input = "";
			},
			getData(){
				if (this.input == '') {
					alert(this.systemlang == 'cn' ? '请输入内容' : "Please enter content");
					return false;
				}else{
					this.search = this.input;
					this.listshow = false;
				}
			}
		}
	}
</script>


<style scoped="scoped">
	
	.newwai{
		width: 18.5rem;
		margin: 0.3rem auto 0;

	}

	@media screen and (max-width: 768px) {
.newwai{
			width: 7rem;
			margin: 0 auto;
		}
	
		
	}
</style>