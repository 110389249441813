<template>	
	<el-row id="pdf"  type="flex" justify="center">
		<el-col :xs="24" :sm="24" :md="24">
			<div class="lf_match_info_box">
				<div class="d_title">{{systemlang=='cn'?'参赛报名':'Sign Up'}} | <span>{{systemlang!='cn'?'参赛报名':'Sign Up'}}</span></div>
			</div>
		</el-col>
	</el-row>
	<el-row type="flex" justify="center">
		<el-col :xs="24" :sm="24" :md="24">
			<el-row>
				<el-col :xs="24" :sm="16" :md="16">
					<div class="lf_entry_info_box_left">
						<div class="lf_entry_info_box_left_tit">{{langs.FactSheet1}}</div>
						<div class="lf_entry_info_box_left_con">
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.FactSheet2}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									{{info.mtinfo?info.mtinfo.ColumnName:''}}
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.Time}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									{{info.mtinfo?info.mtinfo.mt_date:''}}
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.Venue}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									{{info.mtinfo?info.mtinfo.ColumnPlace:''}}
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.EntryFee}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									{{info.mtinfo&&parseInt(info.mtinfo.RegistMatchT) =='5000' && (playerInfo.nvipnation=='CHN'||playerInfo.nvipnation=='MAC'||playerInfo.nvipnation=='HKG'||playerInfo.nvipnation=='TPE')?info.mtinfo.RegistMatchT:''}}
									{{info.mtinfo&&parseInt(info.mtinfo.RegistMatchT)  =='5000' && !(playerInfo.nvipnation=='CHN'||playerInfo.nvipnation=='MAC'||playerInfo.nvipnation=='HKG'||playerInfo.nvipnation=='TPE')?'6000 人民币':''}}
									{{info.mtinfo&&parseInt(info.mtinfo.RegistMatchT)  !='5000'?info.mtinfo.RegistMatchT:''}}
									
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.EntryDeadline}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									{{info.bmconfig?info.bmconfig.RegistEnd:''}}
								</div>
							</div>
							<div class="lf_line"></div>
						</div>
						<div class="lf_entry_info_box_left_tit">{{langs.text_Signupinfo}}</div>
						<div class="lf_entry_info_box_left_con">
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.text_Playersname}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-input v-model="playerInfo.vipname" ></el-input>
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									First Name
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-input v-model="playerInfo.nvipfirstname" ></el-input>
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									Last Name
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-input v-model="playerInfo.nviplastname" ></el-input>
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.text_IDtype}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-select  v-model="playerInfo.nvipidtype" size="default" class="m-2">
										<el-option v-for="item in nvipidtypeArr"
													:key="item.key"
													:label="item.value"
													:value="item.key" />
									</el-select>
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.text_IDnumber}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-input v-model="playerInfo.nvipidnumber" ></el-input>
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.text_Gender}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-radio disabled v-model="playerInfo.nvipsex" label="1">{{langs.nve}}</el-radio>
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.Identity}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-radio-group  v-model="playerInfo.nvippro">
										<el-radio :label="'1'" >{{langs.text_Pro}}</el-radio>
										
									</el-radio-group >
									<el-radio-group  v-model="playerInfo.nvippro">
										
										<el-radio :label="'2'">{{langs.text_Am}}</el-radio>
									</el-radio-group>
									(业余差点须≤4，同时提供有效差点证明)
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.text_Nationality}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-select v-if="info.country" v-model="playerInfo.nvipnation" placeholder="请选择" size="default" class="m-2">
										<el-option v-for="item in info.country"
													:key="item.coun_id"
													:label="item.coun_name"
													:value="item.coun_id" />
									</el-select>
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.text_Birthdate}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-date-picker
									v-model="playerInfo.nvibirth"
									type="date"
									@change="changedata"
									:placeholder="langs.text_Selectdate">
									</el-date-picker>
								</div>
							</div>
							<div v-if="shownre && shownremsg!=''" class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<div style="color: red;">
										{{shownremsg}}
									</div>
								</div>
							</div>
							<div v-if="showjhr" class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.text_Relationtype}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-input v-model="playerInfo.nviprelations"></el-input>
								</div>
							</div>
							<div v-if="showjhr" class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.text_Guardiansname}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-input v-model="playerInfo.nvipguardianname"></el-input>
								</div>
							</div>
							<div v-if="showjhr" class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.text_Contactinfo}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-input v-model="playerInfo.nvipguardiantel"></el-input>
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.MobileNumber}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-input class="lf_sssb" v-model="playerInfo.nviptel_cou" :placeholder="langs.text_Countrycode" style="width: 2rem;margin-right: 0.2rem;"></el-input>
									<el-input class="lf_sssb1" v-model="playerInfo.nviptel" style="width: 3.8rem;"></el-input>
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.EmailAddress}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-input v-model="playerInfo.email"></el-input>
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.text_IDofWorldWomensgolf}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-input v-model="playerInfo.wwgrid" :placeholder="systemlang=='cn'?'四位数字':'4 digits'" ></el-input>
								</div>
							</div>
							
							
							<div class="lf_line"></div>
						</div>
						<div class="lf_entry_info_box_left_tit">{{langs.RelatedService}}</div>
						<div class="lf_entry_info_box_left_con">
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.Hotel}}
									<span class="lf_que_span" @click="downFlag">?</span>
									<div class="lf_que">
										<div class="lf_tips_box" v-if="down">
											<div v-if="info.bmconfig && info.bmconfig.RegistHotelName" class="lf_tips_t">{{langs.OfficialHotel1}}： {{info.bmconfig.RegistHotelName}}</div>
											<div style="display: flex;" v-if="info.bmconfig && info.bmconfig.RegistHotelName&&systemlang=='cn'&&info.holtel&&info.holtel.hc_hotel_file">
												请<text><a target="_blank" :href="'/uploadfile/hotelcaddie/'+mtid+'/hotel/'+info.holtel.hc_hotel_file">下载酒店预订表</a></text>直接
											</div>
											<div v-if="info.bmconfig && info.bmconfig.RegistHotelName&&systemlang=='en'&&info.holtel&&info.holtel.hc_hotel_file">
												<text><a target="_blank" :href="'/uploadfile/hotelcaddie/'+mtid+'/hotel/'+info.holtel.hc_hotel_file">Download</a></text>
											</div>
											<div v-if="info.holtel&&info.holtel.hc_hotel_file&&systemlang=='en'">
												the Official Hotel Booking Sheet
											</div>
											<div v-if="info.holtel&&info.holtel.hc_hotel_file&&systemlang=='cn'">
												发送至酒店进行预订
											</div>
										</div>
									</div>
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-radio-group v-if="info.bmconfig" v-model="playerInfo.jd">
										<el-radio  :label="info.bmconfig.RegistHotelName">{{langs.OfficialHotel}}</el-radio>
									</el-radio-group>
									<div class="lf_que" style="background-color: unset;"></div>
									<el-radio-group v-model="playerInfo.jd">
										<el-radio  :label="'自己安排'">{{langs.SelfArrangement}}</el-radio>
									</el-radio-group>
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.Caddie}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-radio-group v-model="playerInfo.PlayRegCaddie">
										<el-radio  :label="'球会安排'">{{langs.CourseProvided}}</el-radio>
									</el-radio-group>
									<div class="lf_que" style="background-color: unset;"></div>
									<el-radio-group  v-model="playerInfo.PlayRegCaddie">
										<el-radio  :label="'自带球童'">{{langs.SelfProvided}}</el-radio>
									</el-radio-group>
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.Pickupservice}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								</div>
								<div class="lf_entry_info_box_left_con_list_right" style="justify-content: center;color: #666;font-size: .24rem;">
									{{langs.Pickupservicetobeconfirmed}}
								</div>
							</div>
							
							<div style="display:none;" class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.text_Airportpickupinfo}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right" style="flex-wrap: wrap;">
									<text  v-if="info.jiji && info.jiji.length < 1" >{{langs.text_Noticearrangementsforpickup}}</text>
									<el-radio-group  v-for="item in info.jiji" v-if="info.jiji && info.jiji.length >= 1" v-model="playerInfo.jiji">
										<el-radio  :label="item.id">{{item.pickuptime}}</el-radio>
									</el-radio-group>
									
								</div>
							</div>
							<div style="display:none;"  class="lf_entry_info_box_left_con_list"></div>
							<div style="display:none;" class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.text_Airportdropoffinfo}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right" style="flex-wrap: wrap;">
									<text v-if="info.songji && info.songji.length < 1" >{{langs.text_Noticearrangementsforpickup}}</text>
									<el-radio-group  v-for="item in info.songji"  v-if="info.songji && info.songji.length >= 1" v-model="playerInfo.songji">
										<el-radio  :label="item.id">{{item.pickuptime}}</el-radio>
									</el-radio-group>
								</div>
							</div>
							
							<div class="lf_entry_info_box_left_con_list" style="justify-content: center;color: #666;font-size: .24rem;">
								<el-checkbox v-model="playerInfo.checked">{{langs.text_Ihavereadtheevent}}</el-checkbox>
							</div>
							<div class="lf_entry_info_box_left_con_list" style="justify-content: center;">
									<el-button type="danger"  @click="sumitData">{{langs.text_Submitandpayforthesignup}}</el-button>
							
							</div>
							
						</div>
					</div>
				</el-col>
				<el-col :xs="24" :sm="8" :md="8">
					<div class="lf_entry_info_box_right">
						<div class="lf_entry_info_box_right_tit">特别提醒</div>
						<div class="lf_entry_info_box_right_con">根据中国高尔夫球协会规定，运动员参加国家体育总局小球运动管理中心、中国高尔夫球协会主办的职业比赛及业余比赛，均需在中国高尔夫球赛事管理系统进行登记，只有登记过的运动员才可报名参加中国高尔夫球协会组织的该年度的各项高尔夫球比赛。</div>
						<div class="lf_entry_info_box_right_con">登记程序和办法如下：</div>
						<div class="lf_entry_info_box_right_con">一、中国高尔夫球协会赛事管理系统实行登记管理。</div>
						<div class="lf_entry_info_box_right_con">登记网址为：http://www.cgagolf.org.cn/</div>
						<div class="lf_entry_info_box_right_con">二、办理中国高尔夫球协会个人专业会员卡。参考网址</div>
						<div class="lf_entry_info_box_right_con">http://www.cgagolf.org.cn/common/newsDetail/lawDetail?id=1106</div>
						<div class="lf_entry_info_box_right_con">三、运动员登记时，需根据系统要求，如实、细致的填报基本信息，并提交个人照片及证件照片。中国高尔夫球协会将对运动员信息再次复核，确认批准后对有效登记运动员颁发专业会员卡，即为运动员登记完毕。</div>
						<div class="lf_entry_info_box_right_con">四、运动员登记期限为1年，指当年在中国高尔夫球协会办理登记手续截止日到次年办理登记手续截止日，1年后需重新在系统进行登记</div>
						<div class="lf_entry_info_box_right_tit">备注</div>
						<div class="lf_entry_info_box_right_con">所有中国女子职业巡回赛球员仅限网上报名，其他方式报名不予接受。</div>
						<div class="lf_entry_info_box_right_con">如有任何疑问请拨打巡回赛办公室电话</div>
						<div class="lf_entry_info_box_right_con">+86 010 67158937或者</div>
						<div class="lf_entry_info_box_right_con">+86 13810702474咨询。</div>
						<div class="lf_entry_info_box_right_con">参赛球员有义务自行查询报名结果。</div>
						<div class="lf_entry_info_box_right_con">参赛球员本次比赛肖像权归组委会。</div>
						<div class="lf_entry_info_box_right_con">球员有义务参与组委会安排的赛事活动。</div>
						<div class="lf_entry_info_box_right_con">中国女子职业巡回赛对参赛球员的资格有最终决定权。</div>
					</div>
				</el-col>
			</el-row>
		</el-col>
	</el-row>
</template>

<script>

import axios from '../../axios/request';
import axiosx from '../../axios/postx';
import axiojs from '../../axios/postjs';
 import {
        getSystemLang,
        getLangAll
    } from '../../lang';
    export default {
		data() {
		    return {
		        langs: '',
		        systemlang: 'cn',
                banner:{},
				input:'',
				mtid:'',
				showjhr:true,
				playerInfo:{
					vipname:'',//球员姓名
					nvipfirstname:'',
					nviplastname:'',
					nvipidtype:'0', //身份类型
					nvipidnumber:'',//证件号
					nvipsex:'1',//性别
					nvippro:'', //身份
					nvipnation:'CHN',//国籍
					nvibirth:'',//出生日期
					nviprelations:'', //与监护人关系
					nvipguardianname:'', //监护人姓名
					nvipguardiantel:'', //监护人联系方式
					nviptel_cou:'', //国籍区号
					nviptel:'',//电话
					email:'',//邮箱
					wwgrid:'',//世界排名
					jd:'', //酒店信息
					PlayRegCaddie:'',//球童
					jiji:'',
					songji:'',
					checked:false
				},
				nvipidtypeArr:[
					{key:'0',value:'请选择证件类型'},
					{key:'二代身份证',value:'二代身份证'},
					{key:'港澳通行证',value:'港澳通行证'},
					{key:'台湾通行证',value:'台湾通行证'},
					{key:'护照',value:'护照'}
				],
				nvipproArr:[
					{key:'1',value:'职业'},
					{key:'2',value:'业余'}
				],
				shownre:false,
				shownremsg:'',
				info:{},
				down:false,
				jd:'',
				PlayRegCaddie:'',
				signage:0,//不可报名 1：可报名
				mtlist:[{
				  value: '1',
				  label: '1'
				},
				{
				  value: '2',
				  label: '2'
				}],
				value:'',
		    }
		},
        name: 'Player',
        props: {
            msg: String,
			mt_id:String,
			parentgoDan:Object
        },
		components: {
			
        },
		created() {
			this.mtid = this.mt_id;
			this.getData();
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			if(this.systemlang == 'en')
			{
				this.nvipidtypeArr = [
					{key:'0',value:'Please select a certificate type'},
					{key:'二代身份证',value:'Second generation ID card'},
					{key:'港澳通行证',value:'Hong Kong and Macao pass'},
					{key:'台湾通行证',value:'Taiwan Pass'},
					{key:'护照',value:'passport'}
				];
				
			}

			
		},
		watch:{
			mtid(newvalue)
			{
				this.mtid = newvalue;
				this.getData();
			}
		},
		methods:{
			/*跳转到订单列表*/
			sumitgo(){
				console.log('sssss');
				this.parentgoDan('csbmmd');
			},
			changedata(dd){
				if(this.mtid == '7974' || this.mtid == '7956')
				{
					return true;
				}
				var date = dd.getFullYear()+'-'+(dd.getMonth()+1)+'-'+dd.getDate();
				console.log(dd);
				var $url = 'https://clpga.org/user/notvip_check.php?check=ages&birthday='+date+'&lang='+this.systemlang+'&matchid='+this.mtid;
				axiojs($url).then((res)=>{
					let data = res.data;
					data = eval("("+data+")");
					
					if(data['flag'] == '1'){
						this.signage = 0;
						this.shownre =true;
						this.shownremsg = data['mgs'];
						this.showjhr = false;
						
					}else if(data['flag'] == '2'){
						this.signage = 1;
						this.shownre =true;
						this.showjhr = true;
						this.shownremsg = data['mgs'];
					}else if(data['flag'] == '3'){
						this.shownre =true;
						this.signage = 0;
						this.showjhr = false;
						this.shownremsg = data['mgs'];
					}else if(data['flag'] == '4'){
						this.signage = 1;
						this.shownre =true;
						this.showjhr = false;
						this.shownremsg = data['mgs'];
						this.signage = 1;
					}else if(data['flag'] == '5'){
						this.shownre =true;
						this.showjhr = false;
						if(confirm(data['mgs'])){
						this.signage = 1;
					}else{
						this.signage = 1;
						this.showjhr = false;
					}
					}
					
					
				});
			},
			downFlag(){
				if(this.down == true)
				{
					this.down = false;
				}
				else
				{
					this.down = true;
				}
				return false;
			},
			getData()
			{
				let param = {};
				if(this.mtid)
				{
					param.mt_id = this.mtid;
				}
				axios('user/notviporderinfo.json',param)
				.then((res)=>{
					let data = res.data.data;
					this.info = data;
					// this.info.jiji.push({id:158,pickuptime:'2020-12-23'});
					// this.info.jiji.push({id:4,pickuptime:'2020-12-24'});
					// this.info.songji.push({id:5,pickuptime:'2020-12-23'});
					// this.info.songji.push({id:6,pickuptime:'2020-12-24'});
					
					
				});
			},
			sumitData(){
			
				let param = {};
				if(this.mtid == '7974' || this.mtid == '7956')
				{
				
				}
				else
				{
					if(this.signage == 0)
					{
						if(this.systemlang == 'cn')
						{
							alert('年龄不符合规则');
						}
						else
						{
							alert('Age does not conform to the rules');
						}
						
						return false;
					}
				}
				
				if(this.playerInfo.checked == false)
				{
					
					if(this.systemlang == 'cn')
					{
						alert('请勾选相关协议');
					}
					else
					{
						alert('Please tick the relevant agreement');
					}
					
					return false;
				}
				for(let i in this.playerInfo)
				{
					if(!param[i])
					{
						param[i] =  this.playerInfo[i];
					}
				}
			
				if(this.showjhr == true)
				{
					if(this.playerInfo.nviprelations == '' || this.playerInfo.nvipguardianname == '' || this.playerInfo.nvipguardiantel == ''  )
					{
					
						if(this.systemlang == 'cn')
						{
							alert('请填写监护人信息');
						}
						else
						{
							alert('Please fill in the guardian information');
						}
						return false;
					}
				}

				const regEmail = /^([(/.)a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
				if (!regEmail.test(this.playerInfo.email)) {
				// 合法邮箱
				
						if(this.systemlang == 'cn')
						{
							alert('邮箱不符合规则');
						}
						else
						{
							alert('Mailbox does not conform to rules');
						}
						return false;
				}
				if(this.playerInfo.email == '' || this.playerInfo.nviptel_cou == '' || this.playerInfo.nviptel == '' )
				{
					
					if(this.systemlang == 'cn')
						{
							alert('电话邮箱必须填写');
						}
						else
						{
							alert('Phone mailbox must be filled in');
						}
						return false;
				}
				
				param.nvibirth =  param.nvibirth.getFullYear()+'-'+(param.nvibirth.getMonth()+1)+'-'+param.nvibirth.getDate();
				
				param.mt_id = this.mtid;
				axiosx('user/notviporderinfosave.json',param)
				.then((res)=>{
					let data = res.data;
					console.log(data);
					if(data.msgCode != '200')
					{
						alert(data.msg);
					}
					else
					{
						alert('Success');
						this.$emit('changTap','news',data.msg);
					}
					
					
				});
			}
		}
    }
</script>

<style scoped="scoped">
	.d_title {
		margin-top: 0.14rem;
		color: rgb(192, 21, 62);
		font-size: 0.28rem;
		padding-bottom: 4px;
		/* font-weight: bold; */
		font-family: '微软雅黑';
		border-bottom: 1px solid rgb(192, 21, 62);
		display: inline-block;
		padding-right: 30px;
	}

	.d_title span {
		font-size: 0.24rem;
	}
	.lf_match_info_box{
		padding: 0.35rem 0px;
		font-size: 0.24rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.lf_entry_info_box_left{
		padding-bottom: 0.7rem;
		background-color: #FAFAFA;
		margin-left: 0.24rem;
		font-size: 0.24rem;
	}
	.lf_entry_info_box_left_tit{
		padding: 0.35rem 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.24rem;
		color: #C0153E;
	}
	.lf_entry_info_box_left_con_list{
		display: flex;
		align-items: center;
		min-height: 0.8rem;
	}
	.lf_entry_info_box_left_con_list_left{
		width: 2.5rem;
		display: flex;
		justify-content: flex-end;
		font-size: 0.24rem;
		color: #999;
		flex-shrink: 0;
		align-items: center;
	}
	.lf_entry_info_box_left_con_list_left text{
		color: #C0153E;
		display: flex;
		align-items: center;
	}
	.lf_entry_info_box_left_con_list_right{
		display: flex;
		align-items: center;
		margin-left: 0.8rem;
		font-size: 0.24rem;
	}
	.lf_entry_info_box_left_con_list_right .el-select{
		width: 7.8rem;
		height: 0.6rem;
	}
	.lf_entry_info_box_left_con_list_right .el-radio-group{
		width: 1.6rem;
	}
	.lf_entry_info_box_left_con_list_right .lf_que{
		width: 0.14rem;
		height: 0.14rem;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 0.24rem;
		margin-left: 0.1rem;
		margin-top: 2px;
		z-index: 1;
		margin-right: 0.2rem;
		position: relative;
	}
	.lf_entry_info_box_left_con_list_right .el-date-picker{
		width: 7.8rem;
		height: 0.6rem;
	}
	.lf_entry_info_box_left_con_list_right .el-input{
		width: 7.8rem;
		height: 0.6rem;
		background: inherit;
		background-color: rgba(239, 239, 239, 1);
		border: none;
		border-radius: 2px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.24rem;
		text-align: left;
	}
	
	.lf_entry_info_box_right{
		margin-left: 0.24rem;
		margin-right: 0.24rem;
		padding: 0 0.24rem 0.7rem;
		background-color: #FAFAFA;
		font-size: 0.24rem;
	}
	.lf_entry_info_box_right_tit{
		padding: 0.35rem 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.24rem;
		color: #C0153E;
	}
	.lf_entry_info_box_right_con{
		line-height: 0.4rem;
		color: #666666;
		font-size: 0.24rem;
		word-break: break-word;
	}
	.lf_line{
		width: 96%;
		border-bottom: 1px solid #EFEFEF;
		margin: 0.2rem auto 0;
	}
	.lf_tips_box{
		padding: 0.16rem 0.2rem;
		position: absolute;
		left: 0.2rem;
		top: -0.1rem;
		width: 3.2rem;
		background-color: #fff;
		border: 1px solid #A8A8A8;
		border-radius: 0.1rem;
		color: #333;
	}
	.lf_tips_box text{
		color: #C0153E;
	}
	.lf_tips_t{
		margin-bottom: 0.1rem;
	}
	@media screen and (max-width: 768px) {
		.lf_match_info_box{
			padding: 0.35rem 0.2rem;
			margin-left: 0;
		}
		.lf_entry_info_box_left_con_list_left{
			width: 1.8rem;
			display: flex;
			justify-content: flex-end;
			font-size: 0.26rem;
			color: #999;
		}
		.lf_tips_box{
			left: -0.4rem;
			top: 0.3rem;
			width: 3rem;
		}
		.lf_entry_info_box_left_con_list_right{
			margin-left: 0.3rem;
			min-height: 0.8rem;
			font-size: 0.26rem;
		}
		.lf_entry_info_box_left_con_list_right .el-radio-group{
			width: 2rem;
		}
		.lf_entry_info_box_left_con_list_right .lf_que{
			left: 0;
			width: 0.2rem;
			height: 0.2rem;
		}
		.lf_entry_info_box_left_con_list_right .el-input{
			height: 30px;
			font-size: 0.26rem;
		}
		.lf_entry_info_box_right{
			margin-left: 0;
			margin-top: 0.2rem;
			font-size: 0.26rem;
		}
		.el-select {
		    margin-left: 0;
		}
		.lf_entry_info_box_left_tit{
			font-size: 0.26rem;
		}
		.lf_entry_info_box_left_con_list_right .el-select{
			width: 5rem;
			height: 0.6rem;
		}
		.lf_entry_info_box_left_con_list_right .el-date-picker{
			width: 5rem;
			height: 0.6rem;
		}
		.lf_entry_info_box_left_con_list_right .el-input{
			width: 5rem;
			height: 0.6rem;
		}
		.lf_entry_info_box_left_con_list_right .lf_sssb{
			width: 1.6rem!important;
		}
		.lf_entry_info_box_left_con_list_right .lf_sssb1{
			width: 3.2rem!important;
		}
	}
</style>
