<template>
	<div class="tablebox">
		<div class="tablehead">
			<div class="headred">
				<div class="heady">

					{{langs.psta_years}} <!--年份-->
				</div>
				<div class="heady">
					{{langs.psta_match_count}} <!--参赛场次-->
				</div>
				<div class="heady">
					{{langs.psta_match_supper_rank}} <!--最好名次-->
				</div>
				<div class="heady">
					{{langs.psta_match_join_count}} <!--晋级次数-->
				</div>
				<div class="heady">
					{{langs.psta_match_rank_10}} <!--前十次数-->
				</div>
				<div class="heady">
					{{langs.psta_match_rank_3}} <!--前三次数-->
				</div>
				<div class="heady">
					{{langs.psta_match_rank_1}} <!--冠军次数-->
				</div>
				<div class="heady">
					{{langs.psta_match_prize}} <!--获得奖金-->
				</div>
				<div class="heady">
					{{langs.psta_match_clpa_rank}} <!--女子中巡排名-->
				</div>
				<div class="heady">
					{{langs.psta_more}} <!--更多-->
				</div>
			</div>

			<template v-if="show=='1' && stats.length > 0" v-for="(m,index) in stats">
				<div :id="year" class="headpink lf_pl_statistics_list"  :class=" m.years == year ? 'hover':''" @click="shows(m.years)">
					<div class="heady">
						 {{m.years && m.years ? m.years : '-'}}
					</div>
					<div class="heady">
						 {{m.match_count && m.match_count ? m.match_count : '-'}}
					</div>
					<div class="heady">
						 {{m.match_supper_rank && m.match_supper_rank ? m.match_supper_rank : '-'}}
					</div>
					<div class="heady">
						 {{m.match_join_count && m.match_join_count ? m.match_join_count : '-'}}
					</div>
					<div class="heady">
						 {{m.match_rank_10 && m.match_rank_10 ? m.match_rank_10 : '-'}}
					</div>
					<div class="heady">
						 {{m.match_rank_3 && m.match_rank_3 ? m.match_rank_3 : '-'}}
					</div>
					<div class="heady">
						 {{m.match_rank_1 && m.match_rank_1 ? m.match_rank_1 : '-'}}
					</div>
					<div class="heady">
						 {{m.match_prize && m.match_prize ? m.match_prize : '-'}}
					</div>
					<div class="heady">
						 {{m.match_clpa_rank && m.match_clpa_rank ? m.match_clpa_rank : '-'}}
					</div>
					
					<div class="heady " :class="m.years == year ? '':'xuanzhuan'">
						<img src="../../assets/images/r.png" />
					</div>
				</div>
				<div v-if="m.years == year" class="pink_body">
					<div  class="infotablebox">
						<div class="infoTable_item">
							<div class="infotabletitle borderR">#</div>
							<div class="infotabletitlet borderR">{{langs.psta_xm}} <!--统计项目--></div>
							<div class="infotabletitles borderR">{{langs.psta_data}} <!--数 据--></div>
							<div class="infotabletitlep borderR">{{langs.psta_rank}} <!--排 名--></div>
							<div class="infotabletitle borderR">#</div>
							<div class="infotabletitlet borderR">{{langs.psta_xm}} <!--统计项目--></div>
							<div class="infotabletitles borderR">{{langs.psta_data}} <!--数 据--></div>
							<div class="infotabletitlep">{{langs.psta_rank}} <!--排 名--></div>
						</div>
						<div class="infoTable_item">
							<div class="infotabletitle infotableheight borderR">1</div>
							<div class="infotabletitlet infotableheight borderR">{{langs.psta_dldybzg}}<br/>{{langs.psta_ci}} <!--单轮低于标杆--></div>
							<div class="infotabletitles infotableheight borderR"> {{m.match_single_low_par && m.match_single_low_par ? m.match_single_low_par : '-'}} <!--统计项目--></div>
							<div class="infotabletitlep infotableheight borderR"> {{m.match_single_low_par_rank && m.match_single_low_par_rank ? m.match_single_low_par_rank : '-'}} <!--统计项目--></div>
							<div class="infotabletitle infotableheight borderR" >8</div>
							<div class="infotabletitlet infotableheight borderR">{{langs.psta_pjkqjl}}<br/>(yds) <!--平均开球距离--></div>
							<div class="infotabletitles infotableheight borderR">{{m.match_avg_kqjl && m.match_avg_kqjl ? m.match_avg_kqjl : '-'}} <!--match_avg_kqjl--></div>
							<div class="infotabletitlep infotableheight">{{m.match_avg_kqjl_rank && m.match_avg_kqjl_rank ? m.match_avg_kqjl_rank : '-'}} <!--match_avg_kqjl_rank--></div>
						</div>
						<div class="infoTable_item">
							<div class="infotabletitle infotableheight borderR borderR">2</div>
							<div class="infotabletitlet infotableheight borderR">{{langs.psta_pingjunganshu}} <!--平均杆数--></div>
							<div class="infotabletitles infotableheight borderR">{{m.match_avg_g_c && m.match_avg_g_c ? m.match_avg_g_c : '-'}} <!--match_avg_g_c--></div>
							<div class="infotabletitlep infotableheight borderR">{{m.match_avg_g_c_rank && m.match_avg_g_c_rank ? m.match_avg_g_c_rank : '-'}} <!--match_avg_g_c_rank--></div>
							<div class="infotabletitle infotableheight borderR">9</div>
							<div class="infotabletitlet infotableheight borderR">{{langs.psta_sqdlv}} <br/>(%)<!--上球道率--></div>
							<div class="infotabletitles infotableheight borderR">{{m.match_avg_qd && m.match_avg_qd ? m.match_avg_qd : '-'}} <!--match_avg_qd--></div>
							<div class="infotabletitlep infotableheight ">{{m.match_avg_qd_rank && m.match_avg_qd_rank ? m.match_avg_qd_rank : '-'}} <!--match_avg_qd_rank--></div>
							
						</div>
						<div class="infoTable_item">
							<div class="infotabletitle infotableheight borderR">3</div>
							<div class="infotabletitlet infotableheight borderR">{{langs.psta_pjtuigan}} <!--平均推杆--></div>
							<div class="infotabletitles infotableheight borderR">{{m.match_avg_c && m.match_avg_c ? m.match_avg_c : '-'}} <!--match_avg_c--></div>
							<div class="infotabletitlep infotableheight borderR">{{m.match_avg_c_rank && m.match_avg_c_rank ? m.match_avg_c_rank : '-'}} <!--match_avg_c_rank--></div>
							<div class="infotabletitle infotableheight borderR">10</div>
							<div class="infotabletitlet infotableheight borderR">{{langs.psta_sglv}} <br/>(%)<!--上果岭率--></div>
							<div class="infotabletitles infotableheight borderR">{{m.match_avg_gling && m.match_avg_gling ? m.match_avg_gling : '-'}} <!--match_avg_gling--></div>
							<div class="infotabletitlep infotableheight ">{{m.match_avg_gling_rank && m.match_avg_gling_rank ? m.match_avg_gling_rank : '-'}} <!--match_avg_gling_rank--></div>
							
							
						</div>
						<div class="infoTable_item">
							<div class="infotabletitle infotableheight borderR">4</div>
							<div class="infotabletitlet infotableheight borderR">{{langs.psta_laoyinghsu}} <!--老鹰数--></div>
							<div class="infotabletitles infotableheight borderR">{{m.match_g_c && m.match_g_c ? m.match_g_c : '-'}} <!--match_g_c--></div>
							<div class="infotabletitlep infotableheight borderR">{{m.match_g_c_rank && m.match_g_c_rank ? m.match_g_c_rank : '-'}} <!--match_g_c_rank--></div>
							<div class="infotabletitle infotableheight borderR">11</div>
							<div class="infotabletitlet infotableheight borderR">{{langs.psta_skjql}}<br/>(%) <!--沙坑救球率--></div>
							<div class="infotabletitles infotableheight borderR">{{m.match_avg_sand && m.match_avg_sand ? m.match_avg_sand : '-'}} <!--match_avg_sand--></div>
							<div class="infotabletitlep infotableheight">{{m.match_avg_sand_rank && m.match_avg_sand_rank ? m.match_avg_sand_rank : '-'}} <!--match_avg_sand_rank--></div>
							
						</div>
						<div class="infoTable_item">
							<div class="infotabletitle infotableheight borderR">5</div>
							<div class="infotabletitlet infotableheight borderR">{{langs.psta_xiaoniaoshu}} <!--小鸟数--></div>
							<div class="infotabletitles infotableheight borderR">{{m.match_b_c && m.match_b_c ? m.match_b_c : '-'}} <!--match_b_c--></div>
							<div class="infotabletitlep infotableheight borderR">{{m.match_b_c_rank && m.match_b_c_rank ? m.match_b_c_rank : '-'}} <!--match_b_c_rank--></div>
							<div class="infotabletitle infotableheight borderR">12</div>
							<div class="infotabletitlet infotableheight borderR">{{langs.psta_wbsglinglve}}<br/>(%) <!--未标上果岭救球率--></div>
							<div class="infotabletitles infotableheight borderR">{{m.match_avg_glingn && m.match_avg_glingn ? m.match_avg_glingn : '-'}} <!--match_avg_glingn--></div>
							<div class="infotabletitlep infotableheight ">{{m.match_avg_glingn_rank && m.match_avg_glingn_rank ? m.match_avg_glingn_rank : '-'}} <!--match_avg_glingn_rank--></div>
							
						</div>
						<div class="infoTable_item">
							<div class="infotabletitle infotableheight borderR">6</div>
							<div class="infotabletitlet infotableheight borderR">{{langs.psta_bianzhunganshu}} <!--标准杆数--></div>
							<div class="infotabletitles infotableheight borderR">{{m.match_p_c && m.match_p_c ? m.match_p_c : '-'}} <!--match_p_c--></div>
							<div class="infotabletitlep infotableheight borderR">{{m.match_p_c_rank && m.match_p_c_rank ? m.match_p_c_rank : '-'}} <!--match_p_c_rank--></div>
							<div class="infotabletitle infotableheight borderR">13</div>
							<div class="infotabletitlet infotableheight borderR">{{langs.psta_bgglpjtg}} <!--标上果岭平均推杆--></div>
							<div class="infotabletitles infotableheight borderR">{{m.match_avg_gling_gan && m.match_avg_gling_gan ? m.match_avg_gling_gan : '-'}} <!--match_avg_gling_gan--></div>
							<div class="infotabletitlep infotableheight ">{{m.match_avg_gling_gan_rank && m.match_avg_gling_gan_rank ? m.match_avg_gling_gan_rank : '-'}} <!--match_avg_gling_gan_rank--></div>
							
						</div>
						<div class="infoTable_item">
							<div class="infotabletitle infotableheight borderR">7</div>
							<div class="infotabletitlet infotableheight borderR">{{langs.psta_yganjindong}} <!--一杆进洞--></div>
							<div class="infotabletitles infotableheight borderR">{{m.match_one_c && m.match_one_c ? m.match_one_c : '-'}} <!--match_one_c--></div>
							<div class="infotabletitlep infotableheight borderR">{{m.match_one_c_rank && m.match_one_c_rank ? m.match_one_c_rank : '-'}} <!--match_one_c_rank--></div>
							<div class="infotabletitle infotableheight borderR">14</div>
							<div class="infotabletitlet infotableheight borderR">{{langs.psta_pjyts}} <!--平均一推数--></div>
							<div class="infotabletitles infotableheight borderR">{{m.match_avg_one_t && m.match_avg_one_t ? m.match_avg_one_t : '-'}} <!--match_avg_one_t--></div>
							<div class="infotabletitlep infotableheight">{{m.ss && m.match_avg_one_t_rank ? m.match_avg_one_t_rank : '-'}} <!--match_avg_one_t_rank--></div>
							
													
						</div>
							
							
							
						
						
					</div>
				</div>
			</template>
			
			
			<div v-if="show=='1' && stats.length > 0" class="headpink lf_pl_statistics_bottom" style="margin-top: 0.2rem;background-color: #FAFAFA;font-weight: bold;">
				<div class="heady">
					{{langs.psta_zj}}
				</div>
				<div class="heady">
						 {{all.match_count && all.match_count ? all.match_count : '-'}}
					</div>
					<div class="heady">
						 {{all.match_supper_rank && all.match_supper_rank ? all.match_supper_rank : '-'}}
					</div>
					<div class="heady">
						 {{all.match_join_count && all.match_join_count ? all.match_join_count : '-'}}
					</div>
					<div class="heady">
						 {{all.match_rank_10 && all.match_rank_10 ? all.match_rank_10 : '-'}}
					</div>
					<div class="heady">
						 {{all.match_rank_3 && all.match_rank_3 ? all.match_rank_3 : '-'}}
					</div>
					<div class="heady">
						 {{all.match_rank_1 && all.match_rank_1 ? all.match_rank_1 : '-'}}
					</div>
					<div class="heady">
						 {{all.match_prize && all.match_prize ? '￥'+all.match_prize : '-'}}
					</div>
					<div class="heady">
						 {{all.match_clpa_rank && all.match_clpa_rank ? all.match_clpa_rank : '-'}}
					</div>

			</div>
			<!-- {{stats.length}}{{show}} -->
			<!--填写空数据样式-->
			<div v-if="show=='1' && stats.length <= 0">
				<empty></empty>
			</div>
		

		</div>

	</div>
	<div class="p_tablebox">
		<div class="p_tablehead">
			<div class="p_headred">
				<div class="p_heady">
					{{langs.psta_years}} <!--年份-->
				</div>
				<div class="p_heady">
					{{langs.psta_match_count}} <!--参赛场次-->
				</div>
				<div class="p_heady">
					{{langs.psta_match_clpa_rank}} <!--女子中巡排名-->
				</div>
				<div class="p_heady">
					{{langs.psta_more}} <!--更多-->
				</div>
			</div>

			<template v-if="show=='1' && stats.length > 0" v-for="m in stats">

			<div class="p_headpink p_headpinks"  @click="shows(m.years)"  :class=" m.years == year ? 'hover':''">
				<div class="p_heady">
					{{m.years && m.years ? m.years : '-'}}
				</div>
				<div class="p_heady">
					{{m.match_count && m.match_count ? m.match_count : '-'}}
				</div>
				<div class="p_heady">
					{{m.match_clpa_rank && m.match_clpa_rank ? m.match_clpa_rank : '-'}}
				</div>
				<div class="p_heady">
					<div class="img_wai"  :class="m.years == year ? '':'xuanzhuan'">
						<img src="../../assets/images/r.png" />
					</div>
					
				</div>
			</div>
			<div v-if="m.years == year" class="p_pinkbox">
				<div class="p_pinkbody">
					<span style="color: #666666;font-size: 0.24rem;">{{langs.psta_match_supper_rank}}  </span>
				</div>
				<div class="p_pinkbody">
					<span style="color: #666666;font-size: 0.24rem;">{{m.match_supper_rank && m.match_supper_rank ? m.match_supper_rank : '-'}}  </span>
				</div>
				<div class="p_pinkbody">
					<span style="color: #666666;font-size: 0.24rem;">{{langs.psta_match_join_count}} <!--晋级次数-->  </span>
				</div>
				<div class="p_pinkbody">
					<span style="color: #666666;font-size: 0.24rem;"> {{m.match_join_count && m.match_join_count ? m.match_join_count : '-'}}  </span>
				</div>
				<div class="p_pinkbody">
					<span style="color: #666666;font-size: 0.24rem;">{{langs.psta_match_rank_10}}   </span>
				</div>
				<div class="p_pinkbody">
					<span style="color: #666666;font-size: 0.24rem;">{{m.match_rank_10 && m.match_rank_10 ? m.match_rank_10 : '-'}} <!--晋级次数-->  </span>
				</div>
				<div class="p_pinkbody">
					<span style="color: #666666;font-size: 0.24rem;">{{langs.psta_match_rank_3}}  </span>
				</div>
				<div class="p_pinkbody">
					<span style="color: #666666;font-size: 0.24rem;"> {{m.match_rank_3 && m.match_rank_3 ? m.match_rank_3 : '-'}}</span>
				</div>
				<div class="p_pinkbody">
					<span style="color: #666666;font-size: 0.24rem;">{{langs.psta_match_rank_1}} <!--晋级次数-->  </span>
				</div>
				<div class="p_pinkbody">
					<span style="color: #666666;font-size: 0.24rem;"> {{m.match_rank_1 && m.match_rank_1 ? m.match_rank_1 : '-'}} </span>
				</div>
				<div class="p_pinkbody">
					<span style="color: #666666;font-size: 0.24rem;">{{langs.psta_match_prize}}   </span>
				</div>
				<div class="p_pinkbody">
					<span style="color: #666666;font-size: 0.24rem;">{{m.match_prize && m.match_prize ? m.match_prize : '-'}}<!--晋级次数-->  </span>
				</div>

			</div>
			<div v-if="m.years == year" class="p_pinkbox p_padding">
				<div class="p_pinkboxtitle lf_p_pinkboxtitle">
					<div class="p_titles">#</div>
					<div class="p_titlet">{{langs.psta_xm}}</div>
					<div class="p_titlej">{{langs.psta_data}}</div>
					<div class="p_titlep">{{langs.psta_rank}}</div>
				</div>
				
				<div class="p_pinkboxtitle">
							<div class="p_titles">1</div>
							<div class="p_titlet">{{langs.psta_dldybzg}}<br/>{{langs.psta_ci}} <!--单轮低于标杆--></div>
							<div class="p_titlej"> {{m.match_single_low_par && m.match_single_low_par ? m.match_single_low_par : '-'}} <!--统计项目--></div>
							<div class="p_titlep"> {{m.match_single_low_par_rank && m.match_single_low_par_rank ? m.match_single_low_par_rank : '-'}} <!--统计项目--></div>
				</div>		

							<div class="p_pinkboxtitle">
							<div class="p_titles">2</div>
							<div class="p_titlet">{{langs.psta_pingjunganshu}} <!--平均杆数--></div>
							<div class="p_titlej">{{m.match_avg_g_c && m.match_avg_g_c ? m.match_avg_g_c : '-'}} <!--match_avg_g_c--></div>
							<div class="p_titlep">{{m.match_avg_g_c_rank && m.match_avg_g_c_rank ? m.match_avg_g_c_rank : '-'}} <!--match_avg_g_c_rank--></div>
							</div>		

							<div class="p_pinkboxtitle">
							<div class="p_titles">3</div>
							<div class="p_titlet">{{langs.psta_pjtuigan}} <!--平均推杆--></div>
							<div class="p_titlej">{{m.match_avg_c && m.match_avg_c ? m.match_avg_c : '-'}} <!--match_avg_c--></div>
							<div class="p_titlep">{{m.match_avg_c_rank && m.match_avg_c_rank ? m.match_avg_c_rank : '-'}} <!--match_avg_c_rank--></div>
						</div>

							<div class="p_pinkboxtitle">
							<div class="p_titles">4</div>
							<div class="p_titlet">{{langs.psta_laoyinghsu}} <!--老鹰数--></div>
							<div class="p_titlej">{{m.match_g_c && m.match_g_c ? m.match_g_c : '-'}} <!--match_g_c--></div>
							<div class="p_titlep">{{m.match_g_c_rank && m.match_g_c_rank ? m.match_g_c_rank : '-'}} <!--match_g_c_rank--></div>
						</div>

							<div class="p_pinkboxtitle">
							<div class="p_titles">5</div>
							<div class="p_titlet">{{langs.psta_xiaoniaoshu}} <!--小鸟数--></div>
							<div class="p_titlej">{{m.match_b_c && m.match_b_c ? m.match_b_c : '-'}} <!--match_b_c--></div>
							<div class="p_titlep">{{m.match_b_c_rank && m.match_b_c_rank ? m.match_b_c_rank : '-'}} <!--match_b_c_rank--></div>
						</div>

							<div class="p_pinkboxtitle">
							<div class="p_titles">6</div>
							<div class="p_titlet">{{langs.psta_bianzhunganshu}} <!--标准杆数--></div>
							<div class="p_titlej">{{m.match_p_c && m.match_p_c ? m.match_p_c : '-'}} <!--match_p_c--></div>
							<div class="p_titlep">{{m.match_p_c_rank && m.match_p_c_rank ? m.match_p_c_rank : '-'}} <!--match_p_c_rank--></div>
						</div>

							<div class="p_pinkboxtitle">
							<div class="p_titles">7</div>
							<div class="p_titlet">{{langs.psta_yganjindong}} <!--一杆进洞--></div>
							<div class="p_titlej">{{m.match_one_c && m.match_one_c ? m.match_one_c : '-'}} <!--match_one_c--></div>
							<div class="p_titlep">{{m.match_one_c_rank && m.match_one_c_rank ? m.match_one_c_rank : '-'}} <!--match_one_c_rank--></div>
						</div>
						<div class="p_pinkboxtitle lf_p_pinkboxtitle">
							<div class="p_titles">#</div>
							<div class="p_titlet">{{langs.psta_xm}}</div>
							<div class="p_titlej">{{langs.psta_data}}</div>
							<div class="p_titlep">{{langs.psta_rank}}</div>
						</div>
						<div class="p_pinkboxtitle">
							<div class="p_titles">8</div>
							<div class="p_titlet">{{langs.psta_pjkqjl}}<br/>(yds) <!--平均开球距离--></div>
							<div class="p_titlej">{{m.match_avg_kqjl && m.match_avg_kqjl ? m.match_avg_kqjl : '-'}} <!--match_avg_kqjl--></div>
							<div class="p_titlep">{{m.match_avg_kqjl_rank && m.match_avg_kqjl_rank ? m.match_avg_kqjl_rank : '-'}} <!--match_avg_kqjl_rank--></div>
						</div>
							<div class="p_pinkboxtitle">
							<div class="p_titles">9</div>
							<div class="p_titlet">{{langs.psta_sqdlv}} <br/>(%)<!--上球道率--></div>
							<div class="p_titlej">{{m.match_avg_qd && m.match_avg_qd ? m.match_avg_qd : '-'}} <!--match_avg_qd--></div>
							<div class="p_titlep">{{m.match_avg_qd_rank && m.match_avg_qd_rank ? m.match_avg_qd_rank : '-'}} <!--match_avg_qd_rank--></div>
						</div>
							<div class="p_pinkboxtitle">
							<div class="p_titles">10</div>
							<div class="p_titlet">{{langs.psta_sglv}} <br/>(%)<!--上果岭率--></div>
							<div class="p_titlej">{{m.match_avg_gling && m.match_avg_gling ? m.match_avg_gling : '-'}} <!--match_avg_gling--></div>
							<div class="p_titlep">{{m.match_avg_gling_rank && m.match_avg_gling_rank ? m.match_avg_gling_rank : '-'}} <!--match_avg_gling_rank--></div>
						</div>
							<div class="p_pinkboxtitle">
							<div class="p_titles">11</div>
							<div class="p_titlet">{{langs.psta_skjql}}<br/>(%) <!--沙坑救球率--></div>
							<div class="p_titlej">{{m.match_avg_sand && m.match_avg_sand ? m.match_avg_sand : '-'}} <!--match_avg_sand--></div>
							<div class="p_titlep">{{m.match_avg_sand_rank && m.match_avg_sand_rank ? m.match_avg_sand_rank : '-'}} <!--match_avg_sand_rank--></div>
						</div>
							<div class="p_pinkboxtitle">
							<div class="p_titles">12</div>
							<div class="p_titlet">{{langs.psta_wbsglinglve}}<br/>(%) <!--未标上果岭救球率--></div>
							<div class="p_titlej">{{m.match_avg_glingn && m.match_avg_glingn ? m.match_avg_glingn : '-'}} <!--match_avg_glingn--></div>
							<div class="p_titlep">{{m.match_avg_glingn_rank && m.match_avg_glingn_rank ? m.match_avg_glingn_rank : '-'}} <!--match_avg_glingn_rank--></div>
						</div>
							<div class="p_pinkboxtitle">
							<div class="p_titles">13</div>
							<div class="p_titlet">{{langs.psta_bgglpjtg}} <!--标上果岭平均推杆--></div>
							<div class="p_titlej">{{m.match_avg_gling_gan && m.match_avg_gling_gan ? m.match_avg_gling_gan : '-'}} <!--match_avg_gling_gan--></div>
							<div class="p_titlep">{{m.match_avg_gling_gan_rank && m.match_avg_gling_gan_rank ? m.match_avg_gling_gan_rank : '-'}} <!--match_avg_gling_gan_rank--></div>
						</div>
							<div class="p_pinkboxtitle">
							<div class="p_titles">14</div>
							<div class="p_titlet">{{langs.psta_pjyts}} <!--平均一推数--></div>
							<div class="p_titlej">{{m.match_avg_one_t && m.match_avg_one_t ? m.match_avg_one_t : '-'}} <!--match_avg_one_t--></div>
							<div class="p_titlep">{{m.ss && m.match_avg_one_t_rank ? m.match_avg_one_t_rank : '-'}} <!--match_avg_one_t_rank--></div>
						</div>
			</div>
			</template>
			
			<div v-if="show=='1' && stats.length > 0" class="p-headpink" style="margin-top: 0.2rem;background-color: #FAFAFA;font-weight: bold;">
				<div class="p_heady">
					{{langs.psta_zj}}
				</div>
				<div class="p_heady">
					{{all.match_count && all.match_count ? all.match_count : '-'}}
				</div>
				<div class="p_heady">
					{{all.match_clpa_rank && all.match_clpa_rank ? all.match_clpa_rank : '-'}}
				</div>
			</div>

			<div v-if="show=='1' && stats.length <= 0">
				<empty></empty>
			</div>
		</div>

	</div>
</template>

<script>
		import empty from "../../components/empty.vue";
import axios from '../../axios/request';
import {
	getSystemLang,
	getLangAll
} from "../../lang";





//自动轮播的配置
export default {
	data() {
		return {
			langs: '-',
			systemlang: 'cn',
			stats:[],
			all:[],
			year:'',
			loading:false,
			show:false
		}
	},
	props: {
		sid:String,
	},
	watch: {
	},
	name: 'Home',
	components: {
			empty
	},
	created() {

		this.systemlang = getSystemLang();
		this.langs = getLangAll(this.systemlang);
		this.getData(this.sid);
		let year = this.$route.query.year;
		if(year)
		{
			this.shows(year);

			
		}
		
	},
	setup() {
		
		
	},
	methods: {
		shows(years)
		{
			if(this.year!= years){
				this.year = years;
			}
			else
			{
				this.year = '';
			}
		},
		getData(pl_id){
			axios('player/player/playerstats.json',{pl_id:pl_id})
			.then((res)=>{
				console.log(res.data.data);
				this.stats = res.data.data.list;
				this.all = res.data.data.all;
				this.show = '1';
				
				this.$nextTick(() => {
				if (this.$route.query.year) {
					if (location.href.indexOf("#") == -1) {
						location.href += "#" + this.$route.query.year;
					}
				}
			})
			});
		}
		
	}

}
</script>

<style scoped>
	.xuanzhuan{
		transform: rotate(180deg);
	}
	.pink_body {
		width: 17.3rem;
		background-color: #FFF1F2;
		border-radius: 8px;
		margin: 0 auto;
		margin-top: 0.24rem;
		overflow: hidden;
		padding-bottom: 0.2rem;
		margin-bottom: 0.2rem;
	}


	.infotablebox {
		width: 17rem;
		overflow: hidden;
		margin-left: 0.15rem;
		background-color: #fff;
		margin-top: 0.2rem;
		border-right: #DBDBDB 0.01rem solid;
		border-left: #DBDBDB 0.01rem solid;
		border-top: #DBDBDB 0.01rem solid;
	}
	.infoTable_item{
		width: 100%;
		overflow: hidden;
		border-bottom: #DBDBDB 0.01rem solid;
	}



	.infotabletitle {
		width: 0.7rem;
		height: 0.5rem;
		text-align: center;
		line-height: 0.5rem;
		font-size: 0.24rem;
		float: left;
		color: #666;
		background-color: rgba(247,247,247);
	}

	.infotabletitlet {
		float: left;
		width: 3.8rem;
		height: 0.5rem;
		text-align: center;
		line-height: 0.5rem;
		font-size: 0.24rem;
		background-color: rgba(247,247,247);
		color: #666;
	}

	.infotabletitles {
		float: left;
		width: 2rem;
		height: 0.5rem;
		text-align: center;
		line-height: 0.5rem;
		font-size: 0.28rem;
		background-color: #fff;
		font-weight: bold;
		color: #000;
	}

	.infotabletitlep {
		float: left;
		width: 1.9rem;
		height: 0.5rem;
		text-align: center;
		line-height: 0.5rem;
		font-size: 0.28rem;
		background-color: #fff;
		color: #000;


	}

	.infotableheight {
		height: 0.9rem;
		display: flex;
		align-items: center;
	
		justify-content: center;
		line-height: 0.3rem;
		
		
	}
	.borderR{
		border-right: #DBDBDB 0.01rem solid;
	}

	.tablebox {
		width: 18.5rem;
		overflow: hidden;
		background-color: #FAFAFA;
		margin-left: 0.36rem;
		margin-top: 0.4rem;
		padding-bottom: 0.4rem;
	}

	.tablehead {
		width: 17.7rem;
		margin-left: 0.4rem;
		overflow: hidden;
		margin-top: 0.4rem;
		background-color: #fff;
	}

	.headred {
		width: 17.7rem;
		background-color: #C0153E;
		height: 0.8rem;
		color: #fff;
		font-size: 0.24rem;
		line-height: 0.8rem;
	}
	.headred .heady{
		font-size: 0.24rem;
	}


	.heady {
		width: 1.76rem;
		float: left;
		font-size: 0.24rem;
		text-align: center;
		height: 0.8rem;
	}

	.heady img {
		width: 0.3rem;
		height: 0.3rem;
		transform: rotate(270deg);
	}

	.transformnone {
		transform: rotate(90deg) !important;
	}


	.headpink {
		width: 17.7rem;
		background-color: #FAFAFA;
		height: 0.8rem;
		line-height: 0.8rem;
		font-size: 0.28rem;
		overflow: hidden;
	}
	
	.headpink:nth-child(odd) {
	            background: #fff;
	        }


	.hover{
		background-color: #FFF1F2 !important; 
	}

	.p_tablebox {
		display: none;
	}
	.lf_pl_statistics_list{
		cursor: pointer;
	}
	.lf_pl_statistics_list div{
		color: #000;
		font-size: 0.28rem;
	}
	.lf_pl_statistics_list div:nth-of-type(1){
		font-weight: bold;
	}
	.lf_pl_statistics_list:hover{
		background-color: #FFF1F2 !important; 
	}
	.lf_pl_statistics_bottom div{	
		color: #000;
	}
	@media screen and (max-width: 768px) {
		.tablebox {
			display: none;
		}

		.p_tablebox {
			width: 7.5rem;
			display: block;
			overflow: hidden;
			background-color: #FAFAFA;
			margin-top: 0.4rem;
			padding-bottom: 0.4rem;
		}

		.p_tablehead {
			width: 7.1rem;
			margin-left: 0.2rem;
			overflow: hidden;
			margin-top: 0.4rem;
			background-color: #fff;
		}

		.p_headred {
			width: 7.1rem;
			background-color: #C0153E;
			height: 0.5rem;
			color: #fff;
			font-size: 0.24rem;
			line-height: 0.5rem;
			overflow: hidden;
		}

		.p_headpink {
			width: 7.1rem;
		
			height: 0.9rem;
			line-height: 0.9rem;
			font-size: 0.28rem;
		}
		.p_headpink:nth-child(odd) {
	     background-color: #EFEFEF;
	        }	
		.p_heady {
			width: 25%;
			float: left;
			font-size: 0.24rem;
			text-align: center;
		}
		.p_headpinks div{
			color: #000;
		}
		.p_headpink .p_heady{
			font-size: 0.28rem;
		}
		.p_headpink .p_heady:nth-of-type(1){
			font-weight: bold;
		}
		.img_wai{
			width: 0.3rem;
			height: 0.3rem;
			margin-top: 0.25rem;
			margin-left: 0.73rem;
			display: flex;
			align-items: center;
			
		}
		.img_wai img {
			width: 0.3rem;
			height: 0.3rem;
			transform: rotate(270deg);
		}

		.p_pinkbox {
			width: 6.8rem;
			margin-left: 0.15rem;
			background-color: #FFF1F2;
			overflow: hidden;
			margin-top: 0.2rem;
			text-align: center;
			margin-bottom: 0.2rem;
		}

		.p_pinkbody {
			width: 1.69rem;
			height: 0.5rem;
			line-height: 0.5rem;
			float: left;
			font-size: 0.28rem;
			border-left:#fff 1px solid;
			border-bottom: #fff 1px solid;

		}

		.p_pinkboxtable {
			height: 0.9rem;
			line-height: 0.9rem !important;
			text-align: center;
			margin-left: 0.2rem;
		}

		.p_pinkboxtitle {
			height: 0.9rem;
			text-align: center;
			margin-left: 0.2rem;
			width: 6.8rem;
			
		}
		.lf_p_pinkboxtitle{
			height: 0.5rem;
		}
		.p_pinkboxtitle div{
			border-right: 1px solid #EFEFEF;
			border-bottom: 1px solid #EFEFEF;
		}
		.p_padding {
			padding-top: 0.2rem;
			padding-bottom: 0.2rem;
			border-radius: 0.08rem;
			border-left: 1px solid #EFEFEF;
			border-top: 1px solid #EFEFEF;
		}

		.p_tables {
			width: 0.7rem;
			font-size: 0.24rem;
			color: #666666;
			background-color: rgb(247, 247, 247);
			float: left;
			
		}

		.p_tablet {
			width: 3.3rem;
			font-size: 0.24rem;
			color: #666666;
			background-color: rgb(247, 247, 247);
			float: left;
			
		}

		.p_tablej {
			width: 1.2rem;
			font-size: 0.28rem;
			font-weight: bold;
			color: #000;
			background-color: #fff;
			float: left;
		}

		.p_tablep {
			font-size: 0.28rem;
			width: 1.2rem;
			color: #000;
			background-color: #fff;
			float: left;
		}

		.p_titles {
			line-height: normal;
			width: 0.7rem;
			font-size: 0.24rem;
			color: #666666;
			background-color: rgb(247, 247, 247);
			float: left;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.p_titlet {
			line-height: normal;
			width: 3.3rem;
			font-size: 0.24rem;
			color: #666666;
			background-color: rgb(247, 247, 247);
			float: left;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.p_titlej {
			line-height: normal;
			width: 1.2rem;
			font-size: 0.28rem;
			font-weight: bold;
			color: #000;
			background-color: #fff;
			float: left;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.p_titlep {
			line-height: normal;
			font-size: 0.28rem;
			width: 1.2rem;
			color: #000;
			background-color: #fff;
			float: left;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.p-headpink {
			width: 7.1rem;
			background-color: #FFF1F2;
			height: 0.9rem;
			line-height: 0.9rem;
			font-size: 0.28rem;
		}
	}
</style>