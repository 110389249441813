<template>
	<!-- <navs v-if="flag!='min'"></navs> -->
	<Btn cd="xw" v-if="flag!='min'"></Btn>
	<el-row>
		<el-col :xs="24" :sm="24" :md="24" >
			<!-- 新闻内容 -->
			<div class="lf_titles" >{{info.DownloadTitle}}</div>
			<div class="lf_title_time" >{{info.DownloadAddDate}}</div>
			<div class="lf_new_inner" v-html="info.DownloadUrl"></div>
			<!-- info.DownloadUrl 这里是视频地址 -->
			
			<!-- 新闻内容 -->
		</el-col>
	</el-row>
	<Footer1 v-if="flag!='min'"></Footer1>
</template>

<script>
	// @ is an alias to /src
	import navs from "../../components/nav.vue";
	import Btn from "../../components/Btn.vue";
	import Footer1 from "../../components/Footer1.vue";
	import axios from '../../axios/request';
	import {
		getSystemLang,
		getLangAll
	} from "../../lang";
	

	import {
		Autoplay,
		Pagination
	} from 'swiper';
	import 'swiper/swiper-bundle.css';
	import 'swiper/swiper.min.css';
	//这里就是更新后的路径，以前是import 'swiper/css'

	//自动轮播的配置
	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				input:'',
				msgdata:[
					
				],
				info:{},
				flag:''
			}
		},
		props: {

		},
		name: 'Home',
		components: {
			navs,
			Btn,
			Footer1,
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			let id = this.$route.query.id;
			let flag = this.$route.query.flag;
			this.flag = flag;
			//获取巡回赛新闻/news/info/8826.json
			axios('player/player/videoinfo/'+id+'.json')
            .then((res)=>{
                let data = res.data.data;
                this.info = data;
				if(this.info.DownloadTag == '')
				{
					this.info.DownloadTag = this.info.DownloadTitle;
				}
				document.querySelector('meta[name="keywords"]').setAttribute('content', this.info.DownloadTag);
				document.title = this.info.DownloadTitle;
            });
			 
			
		},
		setup() {
			const onSwiper = (swiper) => {
				console.log(swiper);
			};
			const onSlideChange = (e) => {
				console.log('slide change123123123', e.activeIndex);
			};
			const autoplayOptions = {
				delay: 5000,
				disableOnInteraction: false,
				loop: false,
				pauseOnMouseEnter: true,
				reverseDirection: true
			};
			return {
				onSwiper,
				onSlideChange,
				autoplayOptions,
				modules: [Pagination, Autoplay],
			};
		},
		methods: {

		}

	}
</script>


<style scoped="scoped">
	.lf_titles{
		margin: 0 auto;
		font-family: '微软雅黑';
		    font-weight: 400;
		    font-style: normal;
		    font-size: 0.3rem;
		    color: #330006;
			
			text-align: center;
			overflow: hidden;
			margin-top: 0.3rem;
			margin-bottom: 0.3rem;
			
	}
	.lf_title_time{
		font-family: '微软雅黑';
		    font-weight: 400;
		    font-style: normal;
		    font-size: 0.24rem;
		    color: #666666;
			
			text-align: center;
			overflow: hidden;
			margin: 0 auto;
			margin-bottom: 0.1rem;
	}
	.lf_new_inner{
		overflow: hidden;
		width: 17.7rem;
		margin: 0 auto;
		margin-top: 0.2rem;
	}
	@media screen and (max-width: 768px) {
		 .lf_titles{
			 width: 7.02rem;
			 text-align: left;
			 margin-bottom: 0.5rem;
		 }
		 .lf_title_time{
			  width: 7.02rem;
			 text-align: left;
		 }
		 .lf_new_inner{
			 width: 7.02rem;
			
		 }
	}
	
</style>
