<template>
	<!-- <navs></navs> -->
	<div style="width: 100%;height:2.25rem" v-show="bannershow && bannershow['OTHER_TOP'] == '1'">
		<banner heightNew="2.25rem" :type="0" abs="OTHER_TOP"></banner>
	</div>
	<Btn cd=""></Btn>
	<div class="lf_footer1">
		<el-row>
			<el-col :xs="24" :sm="24" :md="24">
				<div class="lf_footer1_tit_box">
					<div class="lf_foot_tit_text1">{{langs.text99_AboutChinaLPGA_cn}}</div>
					<div class="lf_foot_tit_line"></div>
					<div class="lf_foot_tit_text2">{{langs.text99_AboutChinaLPGA_en}}</div>
				</div>
			</el-col>
	
		</el-row>
	</div>
	<div class="wordc totop" v-if="systemlang=='cn'">
		<div class="body">
			<span>
				中国女子职业高尔夫球巡回赛（China LPGA
				Tour，简称女子中巡）是中国境内唯一的女子职业高尔夫球巡回赛，于2008年12月18日在厦门宣布正式成立，由中国高尔夫球协会批准和主办，
				并于2013年3月8日正式加入世界女子职业高尔夫球积分排名组织（WWGR），超过2000人次的各国球员通过女子中巡获得世界积分，是世界五大女子职业巡回赛之一。<br /><br /><br />
			</span>
			<span>
				女子中巡发展至今，举办职业赛事207场，足迹遍布8个国家及地区的37座城市，与USGA、R&A、女子美巡（LPGA）、女子韩巡（KLPGA）、女子欧巡（LET）、女子澳巡（ALPG）等国际组织密切合作。
				拥有来自中国、美国、日本、韩国、新加坡、马来西亚、泰国、澳大利亚、新西兰、中国台北、中国香港等21个国家及地区的近500名注册会员，其中50%以上为海外球员，影响力遍布亚太地区。<br /><br /><br />
			</span>
			<span>
				女子中巡助力球手们不断成长并创造历史。世界顶级名将朴城炫、金孝周均曾在中国女子公开赛夺得桂冠，香港球手陈芷澄和马来西亚球手许莉欣通过女子中巡获得2016年里约奥运会资格。
				登顶世界第一、大满贯冠军得主、女子中巡吉尼斯世界纪录创造者殷若宁，征战美国LPGA赛场的林希妤、刘钰，日巡冠军石昱婷，女子中巡四冠王曾莉棋，
				新生代力量刘艳、殷小雯、张维维等国内高尔夫球星均为女子中巡注册球员，她们都在为更高的目标而奋力拼搏。<br /><br /><br />
			</span>
			<span>
				她力量，梦击扬。女子中巡致力于展现当代女性力量，激励更多年轻女性勇敢追寻自己的梦想。同时，以高尔夫这一最为国际化的运动为纽带，促进中国与世界之间的交流与理解。<br /><br /><br /><br /><br />
			</span>
		</div>
		<div class="bodydown">
			<br /><br /><br />
			<span>China LPGA Tour<br /></span>
			<span>中国女子职业高尔夫巡回赛<br /><br /></span>
			<span>中女职业高尔夫赛事(北京)有限公司<br /></span>
			<span>地址：北京市东城区天坛东路80号中国棋院531室<br /></span>
			<span>电话：010-6715 6483<br /></span>
			<span>邮箱：marketing@clpga.org<br /><br /></span>
			<span>www.clpga.org<br /><br /><br /><br /><br /></span>
		</div>
	</div>
	<div class="worde  totop" v-else>
		<div class="body">
			<span>
				The China LPGA Tour, established on December 18, 2008, is the only women’s professional golf tour
				permitted and sanctioned by the China Golf Association.
				On March 8, 2013, its continuous growth was recognized when the Tour was invited to join the Rolex
				Women’s World Golf Rankings system.
				This recognition has provided nearly 2,000 players from around the world the opportunity to play for
				WWGR points.
				The CLPGA is one of the world’s five major ladies professional golf tours.<br /><br /><br />
			</span>
			<span>
				Over the years, the CLPGA has held 207 events in 37 cities in eight countries and regions. It has
				cooperated with international organizations including the USGA, R&A, US LPGA Tour, LPGA of Korea Tour,
				Ladies European Tour and the Australia LPG Tour, among others. It has nearly 500 registered members from
				China, the United States, Japan, South Korea, Singapore, Malaysia, Thailand, Australia, New Zealand,
				Chinese Taipei, Chinese Hong Kong and other countries and regions.<br /><br /><br />
			</span>
			<span>
				The CLPGA has witnessed and supported players to become stronger and create history. Top-tier players
				such as Park Sung-hyun, Shin Ji-yai, Kim Hyo-joo and Lin Xiyu have all won the Women's China Open. The
				CLPGA also helped Chinese Hong Kong golfer Tiffany Chan and Malaysian Michelle Koh in their 2016 Olympic
				qualification.<br /><br /><br />
			</span>
			<span>
				Major winner Yin Ruoning, a World No. 1, put her name in the Guinness World Records when she won her
				first three tournaments as a professional at CLPGA Tour events.<br /><br /><br />
			</span>
			<span>
				Other CLPGA Tour alumni includes Liu Yu who competes on the US LPGA Tour, Shi Yuting, a winner on the
				Japan LPGA Tour, Zeng Liqi, a four-time champion in China, and emerging talents Liu Yan, Yin Xiaowen and
				Zhang Weiwei, among others. All of these domestic golf stars are registered players on the China LPGA
				Tour. They are all striving for higher goals with great effort.<br /><br /><br />
			</span>
			<span>
				Power She! The CLPGA is dedicated to showcasing the power of modern women and inspiring them to pursue
				their dreams bravely and actively. At the same time, we use golf, the most international sport, to
				enhance communication and understanding between China and the world.<br /><br /><br /><br />
			</span>
		</div>
		<div class="bodydown">
			<br /><br /><br />
			<span>China LPGA Tour<br /></span>
			<span>中国女子职业高尔夫巡回赛<br /><br /></span>
			<span>CLPGA (Beijing) Co., Ltd.<br /></span>
			<span>Add:Rm.531, Qiyuan, No.80 East Tiantan Rd. Dongcheng District,Beijing, China<br /></span>
			<span>Tel: 010-6715 6483<br /></span>
			<span>marketing@clpga.org<br /><br /></span>
			<span>www.clpga.org<br /><br /><br /><br /><br /></span>
		</div>
	</div>
	<Footer></Footer>
	<Footer1></Footer1>
</template>

<script>
	// @ is an alias to /src
	import navs from "../../components/nav.vue";
	import Btn from "../../components/Btn.vue";
	import Footer from "../../components/Footer.vue";
	import Footer1 from "../../components/Footer1.vue";
	import axios from '../../axios/request';
	import banner from "../../components/banner.vue";
	import {
		getSystemLang,
		getLangAll
	} from "../../lang";
	//这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue/swiper-vue';

	import {
		Autoplay,
		Pagination
	} from 'swiper';
	import 'swiper/swiper-bundle.css';
	import 'swiper/swiper.min.css';
	//这里就是更新后的路径，以前是import 'swiper/css'

	var enddata = (new Date()).getFullYear();
	var years = [];
	for (var i = enddata; i >= 2004; i--) {
		years.push({
			value: i,
			label: i
		});
	}

	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				input: '',
				msgdata: [{
						id: 0,
						title: '张雅慧，用理论丰富实践。 学而思，助力梦想'
					},
					{
						id: 0,
						title: '张雅慧，用理论丰富实践。 学而思，助力梦想'
					},
					{
						id: 0,
						title: '张雅慧，用理论丰富实践。 学而思，助力梦想'
					},
					{
						id: 0,
						title: '张雅慧，用理论丰富实践。 学而思，助力梦想'
					},
					{
						id: 0,
						title: '张雅慧，用理论丰富实践。 学而思，助力梦想'
					},
					{
						id: 0,
						title: '张雅慧，用理论丰富实践。 学而思，助力梦想'
					},
					{
						id: 0,
						title: '张雅慧，用理论丰富实践。 学而思，助力梦想'
					},
					{
						id: 0,
						title: '张雅慧，用理论丰富实践。 学而思，助力梦想'
					},
				],
				options: [{
						value: 'Option1',
						label: 'Option1',
					},
					{
						value: 'Option2',
						label: 'Option2',
					},
					{
						value: 'Option3',
						label: 'Option3',
					},
					{
						value: 'Option4',
						label: 'Option4',
					},
					{
						value: 'Option5',
						label: 'Option5',
					},
				],
				value: '',
				years: years,
				year: '',
				matchs: [],
				mt_id: '',
				pl_name: '',
				banner: {},
				wonderful: {},
				xhs: {},
				match: {},
				bannershow: {}
			}
		},
		props: {

		},
		watch: {
			year(newName, oldName) {
				//获取banner
				if (newName != oldName) {
					axios('news/match/list.json', {
							limit: 'all',
							year: newName
						})
						.then((res) => {
							let data = res.data.data;
							this.matchs = data;
						});
					this.mt_id = '';
				}

			}
		},
		name: 'Home',
		components: {
			navs,
			Btn,
			Footer,
			Footer1,
			Swiper,
			SwiperSlide,
			banner
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);

			//获取banner
			axios('news/banner/news.json')
				.then((res) => {
					let data = res.data.data;
					this.banner = data;
				});
			//获取头条新闻
			axios('news/wonderful.json', {
					limit: 10
				})
				.then((res) => {
					let data = res.data.data;
					this.wonderful = data;
				});
			//获取赛事新闻
			axios('news/match.json', {
					limit: 'all'
				})
				.then((res) => {
					let data = res.data.data;
					this.match = data;
				});
			//获取巡回赛新闻
			axios('news/xhs.json', {
					limit: 4
				})
				.then((res) => {
					let data = res.data.data;
					this.xhs = data;
				});
		},
		setup() {
			const onSwiper = (swiper) => {
				console.log(swiper);
			};
			const onSlideChange = () => {
				// console.log('slide change123123123', e.activeIndex);
			};
			const autoplayOptions = {
				delay: 5000,
				disableOnInteraction: true,
				loop: false,
				pauseOnMouseEnter: true,
				reverseDirection: true
			};
			return {
				onSwiper,
				onSlideChange,
				autoplayOptions,
				modules: [Pagination, Autoplay],
			};
		},
		methods: {
			search: function() {
				// console.log(this.mt_id);
				// console.log(this.year);
				// console.log(this.pl_name);
				let matchs = this.matchs;
				let mtname = '';
				for (let i in matchs) {
					if (matchs[i].ID == this.mt_id) {
						mtname = matchs[i].ColumnName;
					}
				}
				let routeUrl = this.$router.resolve({
					path: "/newmod",
					query: {
						type: 'search',
						'mt_id': this.mt_id,
						'year': this.year,
						'pl_name': this.pl_name,
						'mt_name': mtname,
						lang: this.systemlang
					}
				});
				window.open(routeUrl.href, '_blank');
			},
			setbannershow(abs, isshow) {
				// console.log(abs,isshow);
				this.bannershow[abs] = isshow;
			}
		}

	}
</script>


<style scoped="scoped">
	.totop{
		margin-top: 0.6rem;
	}
	.body {
		width: 18rem;
		overflow: hidden;
		margin:0 auto;
		font-size: 0.24rem;
		border-bottom: 1px solid rgba(239, 239, 239);
		color: #333333;
	}

	.bodydown {
		width: 18rem;
		overflow: hidden;
		margin:0 auto;
		font-size: 0.24rem;
		color: #333333;
	}

	@media screen and (max-width: 768px) {
		.body {
			width: 6.7rem;
			overflow: hidden;
			margin-left: 0.4rem;
			font-size: 0.24rem;
			border-bottom: 1px solid rgba(239, 239, 239);
		}

		.bodydown {
			width: 6.7rem;
			overflow: hidden;
			margin-left: 0.4rem;
			font-size: 0.24rem;
		}
	}
</style>