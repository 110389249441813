<template>
    <navs></navs>
	<Btn cd="ph"></Btn>
    <el-row type="flex" justify="center" >
        <el-col :xs="24" :sm="24" :md="24">
            <div class="lf_footer1_tit_box">
                <div class="lf_foot_tit_text1">球员排行榜</div>
                <div class="lf_foot_tit_line"></div>
                <div class="lf_foot_tit_text2">Points/Rankings</div>
            </div>
        </el-col>
    </el-row>
	<el-row type="flex" justify="center">
	    <el-col :xs="24" :sm="16" :md="16">
			<div class="lf_rank_list_tit_box">
			<el-col :xs="16" :sm="12" :md="12">
				<div class="d_title">女子中巡奖金榜 | <span>Order of Merit</span></div>
			</el-col>
			<el-col :xs="8" :sm="12" :md="12" style="display: flex;align-items: center;">
				<div class="d_title_r">
					<img class="hidden-xs-only"  src="../../assets/images/logo_clpga.png" alt="">
					<router-link target="_blank" :to="{path:'/Rankinfo',query:{ranktype:'prizerank',lang:systemlang,'year':prizzeyear}}">
						<div class="lf_go_more">{{langs.More}} &nbsp;<img src="../../assets/images/u107p002.png" alt=""></div>
					</router-link>
				</div>
			</el-col>
			</div>
			
	        <div class="lf_News_list_box">
	            <el-col :xs="24" :sm="16" :md="16">
					
	                <div  class="lf_pos_left_box">
						<div class="lf_nodata" v-if="prizelist.length <= 0">本年度暂无数据</div>
						<div v-if="prizelist.length > 0" class="lf_rank_box_tit">
							<div class="lf_rank_1">{{langs.text_ranking}}</div>
							<div class="lf_rank_2">{{langs.text_pl_country}}</div>
							<div class="lf_rank_4">{{langs.text_player}}</div>
							<div class="lf_rank_5">{{langs.Rounds}}</div>
							<div class="lf_rank_6">{{langs.Rounds1}}</div>
							<div class="lf_rank_7">{{langs.text_price}}</div>
						</div>
						<div v-if="prizelist.length > 0" class="lf_rank_box_con" v-for="(item,index) in prizelist" :key="index">
							<div class="lf_rank_1">{{item.rank}}</div>
							<div class="lf_rank_2"><img :src="item.pl_country_img" alt=""></div>
							<div class="lf_rank_4">{{item.pl_name}}
								<template v-for="(zhanzhupic,index1) in item.zhanzhu" :key="index1">
									<img :src="'/'+zhanzhupic" alt="">
								</template></div>
							<div class="lf_rank_6">{{item.thisyear_match_count}}</div>
							<div class="lf_rank_5">{{item.count_match}}</div>
							<div class="lf_rank_7">{{item.score}}</div>
						</div>
					</div>
	            </el-col>
	            <el-col class="hidden-xs-only" :xs="18" :sm="8" :md="8">
	                <div class="lf_pos_right_box">
						<template  v-for="(b,index) in rightnav">
							<img v-if="index== 0" :src="b.picurl" :alt="index==0">
						</template>
					</div>
	            </el-col>
	        </div>
	    </el-col>
	</el-row>
	<el-row type="flex" justify="center">
	    <el-col :xs="24" :sm="16" :md="16">
			<div class="lf_rank_list_tit_box">
			<el-col :xs="16" :sm="12" :md="12">
				<div class="d_title">劳力士世界排名 | <span>Rolex Women's World Golf Rankings</span></div>
			</el-col>
			<el-col :xs="8" :sm="12" :md="12" style="display: flex;align-items: center;">
				<div class="d_title_r">
					<img class="hidden-xs-only"  src="../../assets/images/logo_clpga.png" alt="">
					<router-link target="_blank" :to="{path:'/Rankinfo',query:{ranktype:'laolishi',lang:systemlang}}">
						<div class="lf_go_more">{{langs.More}} &nbsp;<img src="../../assets/images/u107p002.png" alt=""></div>
					</router-link>
				</div>
			</el-col>
			</div>
	        <div class="lf_News_list_box">
	            <el-col :xs="24" :sm="16" :md="16">
	                <div class="lf_pos_left_box">
						<div class="lf_rank_box_tit">
							<div class="lf_rank_1">{{langs.text_ranking}}</div>
							<div class="lf_rank_2">{{langs.text_pl_country}}</div>
							<div class="lf_rank_4">{{langs.text_player}}</div>
							<div class="lf_rank_5"></div>
							<div class="lf_rank_5">{{langs.Rounds}}</div>
							<div class="lf_rank_7">{{langs.Averageintegral}}</div>
						</div>
						<div class="lf_rank_box_con" v-for="(item,index) in laolishirank" :key="index">
							<div class="lf_rank_1">{{item.rank}}</div>
							<div class="lf_rank_2"><img :src="item.pl_country_img" alt=""></div>
							<div class="lf_rank_4">{{item.pl_name}}
								<template v-for="(zhanzhupic,index1) in item.zhanzhu" :key="index1">
									<img :src="'/'+zhanzhupic" alt="">
								</template>
							</div>
							<div class="lf_rank_5"></div>
							<div class="lf_rank_5">{{item.events}}</div>
							<div class="lf_rank_7">{{item.score}}</div>
						</div>
					</div>
	            </el-col>
	            <el-col class="hidden-xs-only" :xs="18" :sm="8" :md="8">
	                <div class="lf_pos_right_box">
						<template  v-for="(b,index) in rightnav">
							<img v-if="index== 0" :src="b.picurl" :alt="index==0">
						</template>
					</div>
	            </el-col>
	        </div>
	    </el-col>
	</el-row>
	<el-row type="flex" justify="center">
	    <el-col :xs="24" :sm="16" :md="16">
			<div class="lf_rank_list_tit_box">
			<el-col :xs="16" :sm="12" :md="12">
				<div class="d_title">SG杆数得分排名 | <span>Stroke Gained Ranking</span></div>
			</el-col>
			<el-col :xs="8" :sm="12" :md="12" style="display: flex;align-items: center;">
				<div class="d_title_r">
					<img class="hidden-xs-only"  src="../../assets/images/logo_clpga.png" alt="">
					<router-link target="_blank" :to="{path:'/Rankinfo',query:{ranktype:'sg',lang:systemlang}}">
						<div class="lf_go_more">{{langs.More}} &nbsp;<img src="../../assets/images/u107p002.png" alt=""></div>
					</router-link>
				</div>
			</el-col>
			</div>
	        <div class="lf_News_list_box">
	            <el-col :xs="24" :sm="16" :md="16">
	                <div class="lf_pos_left_box">
						<div class="lf_nodata" v-if="sglist!=empty">本年度暂无数据</div>
						<div class="lf_rank_box_tit" v-if="sglist==empty">
							<div class="lf_rank_1">{{langs.text_ranking}}</div>
							<div class="lf_rank_2">{{langs.text_pl_country}}</div>
							<div class="lf_rank_4">{{langs.text_player}}</div>
							<!-- <div class="lf_rank_5">参赛轮</div> -->
							<div class="lf_rank_6">{{langs.Rounds1}}</div>
							<div class="lf_rank_7">{{langs.SGTotal}}</div>
						</div>
						<div class="lf_rank_box_con" v-for="(item,index) in sglist" :key="index">
							<div class="lf_rank_1">{{item.sum_rank}}</div>
							<div class="lf_rank_2"><img :src="item.pl_country_img" alt=""></div>
							<div class="lf_rank_4">
								{{item.pl_name}}
								<template v-for="(zhanzhupic,index1) in item.zhanzhu" :key="index1">
									<img :src="'/'+zhanzhupic" alt="">
								</template>
							</div>
							<div class="lf_rank_5">{{item.events}}</div>
							<!-- <div class="lf_rank_6">{{item.events}}</div> -->
							<div class="lf_rank_7">{{item.sum_sg}}</div>
						</div>
					</div>
	            </el-col>
	            <el-col class="hidden-xs-only" :xs="18" :sm="8" :md="8">
	                <div class="lf_pos_right_box">
						<template  v-for="(b,index) in rightnav">
							<img v-if="index== 0" :src="b.picurl" :alt="index==0">
						</template>
					</div>
	            </el-col>
	        </div>
	    </el-col>
	</el-row>
	<el-row type="flex" justify="center">
	    <el-col :xs="24" :sm="16" :md="16">
			<div class="lf_rank_list_tit_box">
			<el-col :xs="16" :sm="12" :md="12">
				<div class="d_title">中国女子高尔夫运动员排名 | <span>CGA Women's Golf Rankings</span></div>
			</el-col>
			<el-col :xs="8" :sm="12" :md="12" style="display: flex;align-items: center;">
				<div class="d_title_r">
					<img class="hidden-xs-only"  src="../../assets/images/logo_clpga.png" alt="">
					<a href="https://www.cgagolf.org.cn/all_ranking.html">
						<div class="lf_go_more">{{langs.More}} &nbsp;<img src="../../assets/images/u107p002.png" alt=""></div>
					</a>
				</div>
			</el-col>
			
			</div>
			<div class="lf_News_list_box lf_News_list_boxcga">数据由中高协提供，点击查看更多前往中高协官网。</div>
	    </el-col>
	</el-row>
    <Footer></Footer>
    <Footer1></Footer1>
</template>

<script>
    // @ is an alias to /src
    import navs from "../../components/nav.vue";
    import Btn from "../../components/Btn.vue";
    import Footer from "../../components/Footer.vue";
    import Footer1 from "../../components/Footer1.vue";
    import {
        getSystemLang,
        getLangAll
    } from "../../lang";
    //这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'
    import {
        Swiper,
        SwiperSlide
    } from 'swiper/vue/swiper-vue';

    import {
        Autoplay,Pagination
    } from 'swiper';
    import 'swiper/swiper-bundle.css';
    import 'swiper/swiper.min.css';
    import axios from '../../axios/request';
    //这里就是更新后的路径，以前是import 'swiper/css'

    //自动轮播的配置
    export default {
        data() {
            return {
                langs: '',
                systemlang: 'cn',
				msgdata:[
					{
						id:0,
						pos:'1',
						C_img:'../../assets/images/u44.svg',
						P_img:'../../assets/images/u33.png',
						player:'杜墨涵',
						Contestround:'12',
						Statisticalround:'10',
						synthetical:'3.00',
					},
					{
						id:0,
						pos:'1',
						C_img:'../../assets/images/u44.svg',
						P_img:'../../assets/images/u33.png',
						player:'杜墨涵',
						Contestround:'12',
						Statisticalround:'10',
						synthetical:'3.00',
					},
					{
						id:0,
						pos:'1',
						C_img:'../../assets/images/u44.svg',
						P_img:'../../assets/images/u33.png',
						player:'杜墨涵',
						Contestround:'12',
						Statisticalround:'10',
						synthetical:'3.00',
					},
					{
						id:0,
						pos:'1',
						C_img:'../../assets/images/u44.svg',
						P_img:'../../assets/images/u33.png',
						player:'杜墨涵',
						Contestround:'12',
						Statisticalround:'10',
						synthetical:'3.00',
					},
					{
						id:0,
						pos:'1',
						C_img:'../../assets/images/u44.svg',
						P_img:'../../assets/images/u33.png',
						player:'杜墨涵',
						Contestround:'12',
						Statisticalround:'10',
						synthetical:'3.00',
					},
				],
				sglist:[],
				laolishirank:[],
				prizelist:[],
				rightnav:[],
				pyear:(new Date()).getFullYear(),
                curyear:(new Date()).getFullYear(),
				prizzeyear:(new Date()).getFullYear()
            }
        },
        props: {

        },
        name: 'Home',
        components: {
            navs,
            Btn,
            Footer,
            Footer1,
            Swiper,
            SwiperSlide,
        },
        created() {
            this.systemlang = getSystemLang();
            this.langs = getLangAll(this.systemlang);
			var year = (new Date()).getFullYear();
			this.year = year;
			axios('sgrank/getyeardata.json',{year:year})
            .then((res)=>{
				var sglist = res.data.list.sum;
				if(sglist.length==0){
					this.sglist = sglist;
				}else{
					var sglist5 = [];
					for(var key in sglist){
						if(sglist5.length<5){
							if(getSystemLang()=="cn"){
								sglist[key]["pl_name"] = sglist[key]["pl_cn_name"];
							}else{
								sglist[key]["pl_name"] = sglist[key]["pl_en_name"];
							}
							sglist5.push(sglist[key]);
						}else{
							break;
						}
					}
					this.sglist = sglist5;
				}
            });
			axios('sgrank/getlaolishirank.json',{year:year})
            .then((res)=>{
				var laolishirank = res.data;
				if(laolishirank.length==0){
					this.laolishirank = laolishirank;
				}else{
					var laolishirank5 = [];
					for(var key in laolishirank){
						if(laolishirank5.length<5){
							if(getSystemLang()=="cn"){
								laolishirank[key]["pl_name"] = laolishirank[key]["cn_name"];
							}else{
								laolishirank[key]["pl_name"] = laolishirank[key]["en_name"];
							}
							laolishirank5.push(laolishirank[key]);
						}else{
							break;
						}
					}
					this.laolishirank = laolishirank5;
				}
            });
			axios('sgrank/getprizerank.json',{year:this.pyear})
            .then((res)=>{
				var prizelist = res.data;
				// console.log(res.data,'prize')
				if(res.data.data && res.data.data.length < 1)
                {
					axios('sgrank/getprizerank.json',{year:this.pyear-1})
					.then((res)=>{
						var prizelist = res.data;
						this.prizzeyear = this.pyear-1;
						if(prizelist.length==0){
							this.prizelist = prizelist;
						}else{
							var prizelist5 = [];
							for(var key in prizelist){
								if(prizelist5.length<5){
									if(getSystemLang()=="cn"){
										prizelist[key]["pl_name"] = prizelist[key]["cn_name"];
									}else{
										prizelist[key]["pl_name"] = prizelist[key]["en_name"];
									}
									prizelist5.push(prizelist[key]);
								}else{
									break;
								}
							}
							this.prizelist = prizelist5;
						}
					});
					return false;
				}
				// console.log(prizelist);
				if(prizelist.length==0){
					this.prizelist = prizelist;
				}else{
					var prizelist5 = [];
					for(var key in prizelist){
						if(prizelist5.length<5){
							// if(prizelist[key]["pl_photo"]!=""&&prizelist[key]["pl_photo"]!=undefined){
							// 	if(prizelist[key]["pl_photo"].indexOf("uploadfile")!=-1){
							// 		prizelist[key]["pl_photo"] = "http://www.clpga.org/"+prizelist[key]["pl_photo"]
							// 	}else{
							// 		prizelist[key]["pl_photo"] = "http://www.wifigolf.com/club/adm/images/players/"+prizelist[key]["pl_photo"];
							// 	}
							// }
							if(getSystemLang()=="cn"){
								prizelist[key]["pl_name"] = prizelist[key]["cn_name"];
							}else{
								prizelist[key]["pl_name"] = prizelist[key]["en_name"];
							}
							prizelist5.push(prizelist[key]);
						}else{
							break;
						}
					}
					this.prizelist = prizelist5;
				}
            });
			axios('news/banner/RANK.json',{abs:"RIGHTNAV"})
            .then((res)=>{
                let data = res.data.data;
				console.log(data["RIGHTNAV"])
                this.rightnav = data["RIGHTNAV"];
            });
        },
        setup() {
            const onSwiper = (swiper) => {
                console.log(swiper);
            };
            const onSlideChange = (e) => {
                console.log('slide change123123123',e.activeIndex);
            };
            const autoplayOptions = {
                delay: 5000,
                disableOnInteraction: false,
                loop: false,
                pauseOnMouseEnter: true,
                reverseDirection: true
            };
            return {
                onSwiper,
                onSlideChange,
                autoplayOptions,
                modules: [Pagination,Autoplay],
            };
        },
        methods: {
			
        }

    }
</script>


<style scoped="scoped">
    .lf_footer1:nth-last-of-type(1) {
        border-bottom: none;
    }
	.lf_rank_list_tit_box{
		display: flex;
	}
    .d_title_r{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 100%;
	}
	.d_title_r img{
		width: 120px;
		height: 20px;
	}
	.lf_go_more{
		height: 0.4rem;
		font-size: 0.18rem;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		color: #C0153E;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(192, 21, 62, 1);
		border-radius: 2px;
		margin-left: 10px;
		padding-left: 0.1rem;
	}
	.lf_go_more img{
		transform: rotate(180deg);
		width: 0.33rem;
		height: 0.3rem;
	}
    .lf_News_list_box {
        display: flex;
        width: 100%;
        align-items: center;
        padding: 0.3rem 0;
    }
    
    .lf_footer1_tit_box {
        height: 1.4rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    
    .lf_footer1_tit_box .lf_foot_tit_text1 {
        font-family: '微软雅黑';
        font-weight: 400;
        font-style: normal;
        font-size: 0.26rem;
        color: #C0153E;
    }
    
    .lf_footer1_tit_box .lf_foot_tit_text2 {
        font-family: '微软雅黑';
        font-weight: 400;
        font-style: normal;
        font-size: 0.15rem;
        color: #C0153E;
    }
    
    .lf_footer1_tit_box .lf_foot_tit_line {
        width: 2.2rem;
        height: 3px;
        background-color: #C0153E;
        margin: 4px 0;
    }
    .d_title{
    	margin-top: 0.14rem;margin-left: 10px;
    	color: rgb(192, 21, 62);
    	font-size: 0.2rem;
    	padding-bottom: 4px;
    	/* font-weight: bold; */
    	font-family: '微软雅黑';
    	border-bottom: 1px solid rgb(192, 21, 62);
    	display: inline-block;
    	padding-right: 30px;
    }
	.d_title span{
		font-size: 0.14rem;
	}
    .lf_pos_left_box{
		width: 100%;
		height: 3rem;
		background-color: rgba(250, 250, 250, 1);
	}
	.lf_pos_right_box{
		margin-left: 20px;
		width: 100%;
		height: 3rem;
		background-color: rgba(250, 250, 250, 1);
	}
	.lf_pos_right_box img{
		height: 100%;
		object-fit: cover;
	}
	.lf_rank_box_tit{
		width: 7.82rem;
		margin: 0 auto;
		display: flex;
		align-items: center;
		color: #333;
		height: 0.55rem;
		margin-bottom: 0.05rem;
		border-bottom: 1px solid #EFEFEF;
		font-size: 0.16rem;
	}
	.lf_rank_box_con{
		width: 7.82rem;
		margin: 0 auto;
		display: flex;
		align-items: center;
		color: #999;
		height: 0.46rem;
		font-size: 0.16rem;
	}
	.lf_rank_1{
		width: 1.1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
	.lf_rank_2{
		width: 1.1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
	.lf_rank_3{
		width: 1.1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
	.lf_rank_4{
		width: 1.1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		flex-grow: 1;
	}
	.lf_rank_4 img{
		width: 75px;
		height: 25px;
		margin-left: 0.2rem;
	}
	.lf_rank_5{
		width: 1.1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
	.lf_rank_6{
		width: 1.1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
	.lf_rank_7{
		width: 1.1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
	.lf_rank_2 img{
		width: 20px;
		height: 14px;
	}
	.lf_rank_3 img{
		width: 24px;
		height: 24px;
	}
	.lf_nodata{
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		color: #999999;
	}
	.lf_News_list_boxcga{
		width: 66.66666%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		color: #999999;
		padding: 0.2rem 0;
	}
    @media screen and (max-width: 768px) {
        .d_title_r{
			margin-right: 0.1rem;
		}
		.lf_rank_box_tit{
			font-size: 0.24rem;
		}
		.lf_footer1_tit_box{
			margin-top: 0.5rem;
		}
		.lf_rank_box_tit{
		width: 7.5rem;
		}
		.lf_rank_box_con{
		width: 7.5rem;
		}
		.lf_rank_box_tit div:nth-last-of-type(1),.lf_rank_box_con div:nth-last-of-type(1){
			margin-right: 0.1rem;
		}
		.lf_rank_1{
			width: 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
		}
		.lf_rank_2{
			width: 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
		}
		.lf_rank_3{
			width: 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
		}
		.lf_rank_4{
			width: 1rem;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			flex-grow: 1;
			
		}
		.lf_rank_4 img{
			width: 0.75rem;
			height: 0.25rem;
			margin-left: 0.2rem;
		}
		.lf_rank_5{
			width: 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
		}
		.lf_rank_6{
			width: 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
		}
		.lf_rank_7{
			width: 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
		}
		.lf_News_list_boxcga{
			width: 100%;
			text-align: center;
			font-size: 0.26rem;
		}
		.lf_rank_box_con{
			font-size: 0.24rem;
		}
		.d_title,.d_title span{
			font-size: 0.26rem;
		}
		.lf_footer1_tit_box .lf_foot_tit_text1 {
			font-family: '微软雅黑';
			font-weight: 400;
			font-style: normal;
			font-size: 0.28rem;
			color: #C0153E;
		}
		.lf_footer1_tit_box .lf_foot_tit_text2 {
			font-family: '微软雅黑';
			font-weight: 400;
			font-style: normal;
			font-size: 0.26rem;
			color: #C0153E;
		}
		.lf_go_more{
		    width: 1.6rem;
		    height: 0.4rem;
		    font-size: 0.24rem;
		    margin-right: 0.1rem;
		    padding-left: 0.05rem;
		}
    }
</style>
