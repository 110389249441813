<template>
    
        <el-col :xs="24" :sm="24" :md="24">
            <template  v-for="(b,index) in banner">
            <a :href="b.linkurl==''?'javascript:void(0);':b.linkurl">
                <img style="width:100%;" v-if="index== 0" :src="b.picurl" :alt="index==0">
            </a>
            </template>
        </el-col>
   
</template>

<script>
import axios from '../axios/request';
    export default {
		data() {
		    return {
		        langs: '',
		        systemlang: 'cn',
                banner:{}
		    }
		},
        name: 'Ad1',
        props: {
            msg: String,
            abs:{type:String,default:'HOME_NEWS_BOTTOM'},
            pos:{type:String,default:'home'}
        },
		created() {
			 //获取banner
             axios('news/banner/'+this.pos+'.json',{abs:this.abs})
            .then((res)=>{
                let data = res.data.data;
                this.banner = data[this.abs];
            });
		},
		methods:{
			
		}
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    
    @media screen and (max-width: 768px) {
        .lf_login_changeL{
            font-size: 0.12rem;
        }
    }
</style>
