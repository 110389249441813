<template>
	<div class="body">
		<div class="bodymin">
			<template  v-for="item in stats">
			<div v-if="!item.keys" class="bodybox">
				<div class="boxone" :class="systemlang=='cn'?'lf_boxone':''">
					{{item.title}}
				</div>
				<div class="boxtwo" style="white-space: pre-wrap;">
					{{item.value}}
				</div>
			</div>
			<div v-else-if="item.keys=='single_winner'" class="bodybox"><!--成就-->
				<div class="boxone" :class="systemlang=='cn'?'lf_boxone':''">
					{{item.title}}
				</div>
				<div class="boxtwo" style="white-space: pre-wrap;">
					{{item.value}}
				</div>
			</div>
			<div v-else-if="item.keys=='single_desc'" style="white-space: pre-wrap;" class="bodybox_1"><!--个人简介-->
				{{item.value}}
			</div>
			</template>
			
		</div>
	</div>
</template>

<script>
import axios from '../../axios/request';
import {
	getSystemLang,
	getLangAll
} from "../../lang";





//自动轮播的配置
export default {
	data() {
		return {
			langs: '',
			systemlang: 'cn',
			stats:[],
			all:[],
			year:'',
			loading:false
		}
	},
	props: {
		sid:String,
	},
	watch: {
	},
	name: 'Home',
	components: {
		
	},
	created() {

		this.systemlang = getSystemLang();
		this.langs = getLangAll(this.systemlang);
		this.getData(this.sid);
	},
	setup() {
		
		
	},
	methods: {
		shows(years)
		{
			if(this.year!= years){
				this.year = years;
			}
			else
			{
				this.year = '';
			}
		},
		getData(pl_id){
			axios('player/player/playercareer.json',{pl_id:pl_id})
			.then((res)=>{
				this.stats = res.data.data;
				
			});
		}
		
	}

}
</script>

<style scoped>
	.body {
		width: 18.5rem;
		margin-top: 0.5rem;
		overflow: hidden;
		margin-left: 0.36rem;
		background-color: #FAFAFA;
	}

	.bodybox {
		width: 8.9rem;
		height: auto;
		line-height: normal;
		border: 1px solid #ffffff;
		float: left;
		display: flex;
		align-items: center;
	}

	.boxone {
		width: 3.6rem;
		height: 100%;
		text-indent: 0.3rem;
		font-size: 0.26rem;
		color: #666666;
		float: left;
		border-right: 1px solid #fff;
		display: grid;
		align-items: center;

	}
	.lf_boxone{
		text-align: justify;
		text-align-last: justify;
		padding-right: 0.3rem;
	}
	.boxtwo {
		width: 5.2rem;
		height: fit-content;
		padding-left: 0.3rem;
		font-size: 0.28rem;
		color: #333333;
		float: left;
		min-height: 0.7rem;
		line-height: normal;
		display: flex;
		align-items: center;
	}

	.bodymin {
		margin-top: 0.36rem;
		margin-left: 0.36rem;
		overflow: hidden;
		padding-bottom: 0.36rem;
		display: flex;
		flex-wrap: wrap;
	}
	.bodybox_1{
		width:100%;
		padding: 0.36rem;
		margin-top: 0.2rem;
		border: 1px solid #fff;
		font-size: 0.28rem;
	}
	@media screen and (max-width: 768px) {
		.body {
			width: 7.5rem;
			margin-top: 0.5rem;
			overflow: hidden;
			margin-left: 0;
			background-color: #FAFAFA;
		}

		.bodybox {
			width: 7.1rem;
			height: auto;
			line-height: normal;
			border: 0.01rem solid #ffffff;
			float: left;
		}

		.boxone {
			width: 2.88rem;
			font-size: 0.26rem;
			color: #666666;
			border-right: 0.01rem solid #ffffff;
			display: flex;
			align-items: center;

		}

		.boxtwo {
			width: 4.18rem;
			height: fit-content;
			font-size: 0.26rem;
			color: #333333;

		}

		.bodymin {
			margin-top: 0.4rem;
			margin-left: 0.2rem;
			overflow: hidden;
			padding-bottom: 0.4rem;
		}
		.bodybox_1{
			width:7.1rem;
			padding: 0.36rem;
			margin-top: 0.2rem;
			border: 1px solid #fff;
		}
		
	}
</style>