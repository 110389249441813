<template>
    <navs></navs>
    <Btn></Btn>
	<el-row type="flex" justify="center">
		<el-col :xs="24" :sm="24" :md="24">
			<div class="lf_footer1_tit_box">
				<div class="lf_foot_tit_text1">{{matchInfo?matchInfo.ColumnName:''}}</div>
				<div class="lf_foot_tit_line"></div>
				<div class="lf_foot_tit_text2"></div>
			</div>
		</el-col>
	</el-row>
	<ScoreA v-if="matchInfo.ID" :mt_id="matchInfo.ID+''"></ScoreA>
    <Footer1></Footer1>
</template>

<script>
    // @ is an alias to /src
    import navs from "../components/nav.vue";
    import Btn from "../components/Btn.vue";
    import Footer1 from "../components/Footer1.vue";
	import ScoreA from "../components/Match/ScoreA.vue";
    import axios from '../axios/request';
    import {
        getSystemLang,
        getLangAll
    } from "../lang";

    //自动轮播的配置
    export default {
        data() {
            return {
                langs: '',
                systemlang: 'cn',
				matchInfo:{}
            }
        },
        props: {

        },
        name: 'Home',
        components: {
            navs,
            Btn,
            Footer1,
			ScoreA,
        },
        created() {
            this.systemlang = getSystemLang();
            this.langs = getLangAll(this.systemlang);
			let id = this.$route.query.id;
			//获取banner
            axios('matchs/match/matchinfo.json',{id:id})
            .then((res)=>{
                let data = res.data.data;
                this.matchInfo = data;
				console.log(this.matchInfo);
            });
        },
        methods: {
			
        }

    }
</script>


<style scoped="scoped">
	.lf_footer1_tit_box {
		height: 1.4rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		margin-top: 0.5rem;
	}
	
	.lf_footer1_tit_box .lf_foot_tit_text1 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.26rem;
		color: #C0153E;
	}
	
	.lf_footer1_tit_box .lf_foot_tit_text2 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.15rem;
		color: #C0153E;
	}
	
	.lf_footer1_tit_box .lf_foot_tit_line {
		width: 2rem;
		height: 3px;
		background-color: #C0153E;
		margin: 4px 0;
	}
    .lf_Enterprise_box{
        width: 100%;
        height: auto;
        position: relative;
		background-color: #FAFAFB;
		padding: 0.4rem;
		margin-bottom: 1rem;
    }
	.lf_Enterprise_list{
		font-size: 0.2rem;
		color: #555555;
		margin-bottom: 1rem;
	}
	.lf_Enterprise_list img{
		width: 2rem;
		height: 1rem;
	}
    @media screen and (max-width: 768px) {
        .lf_footer1_tit_box {
        	margin-top: 0.5rem;
        }
		.lf_Enterprise_box{
			width: auto;
		}
		.lf_footer1_tit_box .lf_foot_tit_text1 {
			font-family: '微软雅黑';
			font-weight: 400;
			font-style: normal;
			font-size: 0.28rem;
			color: #C0153E;
		}
		.lf_footer1_tit_box .lf_foot_tit_text2 {
			font-family: '微软雅黑';
			font-weight: 400;
			font-style: normal;
			font-size: 0.26rem;
			color: #C0153E;
		}
    }
</style>
