<template>	
	<el-row   type="flex" justify="center">
		<el-col :xs="24" :sm="24" :md="24">
			<div class="lf_match_info_box">
				<div class="d_title">{{systemlang=='cn'?'报名订单':'Register Order'}} | <span>{{systemlang!='cn'?'报名订单':'Register Order'}}</span></div>
			</div>
			<el-row type="flex" justify="center">
				<el-col :xs="24" :sm="24" :md="24">
					<div class="lf_tab_tit_box">
						<div class="lf_tab_little_box">
							<div @click="goFlag('all')" :class="bFlag=='all'?'lf_tab_box_select':''">{{langs.AllOrders}}</div>
							<div @click="goFlag('wsh')" :class="bFlag=='wsh'?'lf_tab_box_select':''">{{langs.WaitingforApproval}}</div>
							<div @click="goFlag('wplay')" :class="bFlag=='wplay'?'lf_tab_box_select':''">{{langs.WaitingforPayment}}</div>
							<div @click="goFlag('ok')" :class="bFlag=='ok'?'lf_tab_box_select':''">{{langs.Completed}}</div>
							<!-- <div :class="bFlag=='jstj'?'lf_tab_box_select':''">{{langs.Attention}}</div>                -->
						</div>
					</div>
				</el-col>
			</el-row>
			<div class="lf_course_name_data_box">
				<div class="lf_course_name_data_tit">
					<div class="lf_8">{{langs.OrderTime}}</div>
					<div class="lf_2">{{langs.OrderNumber}}</div>
					<div class="lf_4">{{langs.AmountYuan}}</div>
					<div class="lf_5">{{langs.StatusofOrder}}</div>
					<div class="lf_6">{{langs.EventPeriod}}</div>
					
					<div class="lf_9">{{langs.Operation}}</div>
				</div>
				<template v-for="(b,index) in list">
				<div class="lf_course_name_data_con" :id="b.v_oid">
					<div class="lf_8">{{b.v_orderdate}}</div>
					<div class="lf_2" @click="showD(index)">
						<div class="lf_2_1">{{langs.OrderNumber}}:{{b.v_oid}}</div>
						<div class="lf_2_2">{{b.v_matchname}}</div>
					</div>
					<div class="lf_4">{{b.v_amount}}</div>
					<div class="lf_5">{{b.o_sts_d_title}}</div>
					<div class="lf_6">{{b.ColumnStart}}</div>
					
					<div class="lf_9"><text @click="showD(index)">{{langs.PrintOrder}}</text><text  v-if="b.v_pstatus =='0' || b.v_pstatus == '2'" @click="qxdd(b.v_oid)">{{langs.oCancel}}</text> <text @click="sqtk(b.v_oid)" v-if="b.v_pstatus =='1' && b.o_sts_d!='wplayturn'">{{langs.Applyforrefund}}</text> <text @click="payOrder(b.v_oid,index)" v-if="b.o_sts == 'wplay'">{{langs.WaitingforPayment}}</text></div>
				</div>
				
				</template>
			</div>
			<!-- <div class="block lf_pageC_change">
			    <el-pagination
			     
			     @current-change = "changePage"
			      v-model:current-page="pagevalue"
			      :page-size="page.limit"
				  :background="true"
				  :pager-count="5"
				  :hide-on-single-page=true
			      layout="prev, pager, next, jumper"
			      :total="page.count">
			    </el-pagination>
			</div> -->
			<div class="block">
				<pageNew @clickPage="changePage" v-if="page.count>0" :pages="page"></pageNew>
			</div>
			<!-- 仅手机上点比赛名显示 弹窗 -->
			<div class="lf_course_name_data_box_phone_box" id="pdf" v-if="showDvs">
				<div class="lf_course_name_data_box_phone">
					<img class="lf_close_img" src="../../assets/images/close.png" @click="close()" alt="">
					<div class="lf_course_name_data_tit_phone">
						<div class="lf_8">{{langs.OrderTime}}</div>
						<!-- <div class="lf_1">{{langs.Order}}</div> -->
						<div class="lf_2">{{langs.OrderNumber}}</div>
						<div class="lf_4">{{langs.AmountYuan}}</div>
						<div class="lf_5">{{langs.StatusofOrder}}</div>
						<div class="lf_3">{{langs.EventName}}</div>
						
						
						<div class="lf_6">{{langs.EventPeriod}}</div>
						<div class="lf_7">{{langs.DeadlinePaymentDate}}</div>
						
						<div class="lf_9">{{langs.Operation}}</div>
					</div>
					<div class="lf_course_name_data_con_phone">
						<div class="lf_8">{{list[showDv].v_orderdate}}</div>
						<!-- <div class="lf_1">{{page.start+showDv+1}}</div> -->
						<div class="lf_2" >{{list[showDv].v_oid}}</div>
						<div class="lf_4">{{list[showDv].v_amount}}</div>
						<div class="lf_5">{{list[showDv].o_sts_d_title}}</div>
						<div class="lf_3">{{list[showDv].v_matchname}}</div>
						
						
						<div class="lf_6">{{list[showDv].mttime}}</div>
						<div class="lf_7">{{list[showDv].Registpayment}}</div>
						
						<div class="lf_9"><text @click="Douwn">{{langs.PrintOrder}}</text> <text @click="sqtk(list[showDv].v_oid)" v-if="list[showDv].v_pstatus =='1' && list[showDv].o_sts_d!='wplayturn'">{{langs.Applyforrefund}}</text><text  v-if="list[showDv].v_pstatus =='0' || list[showDv].v_pstatus == '2'" @click="qxdd(list[showDv].v_oid)">{{langs.oCancel}}</text> <text @click="payOrder(list[showDv].v_oid)" v-if="list[showDv].o_sts == 'wplay'">{{langs.WaitingforPayment}}</text></div>
					
					</div>
				</div>	
			</div>

			

			<div class="lf_course_name_data_box_phone_box"  v-if="paybox">
				
				<div class="lf_course_name_data_box_pay">
					<img class="lf_close_img" src="../../assets/images/close.png" @click="closepayb" alt="" />
					<div class="lf_course_name_data_box_pay_t">{{list[showDv].v_matchname}}</div>
					<div v-if="!paysucess" class="lf_course_name_data_box_pay_M">{{list[showDv].PlayRegClassType}} <text>{{list[showDv].v_amount}}元</text></div>
					<div v-if="paysucess" class="lf_course_name_data_box_pay_M"><text>{{langs.PaidSuccessfully}}</text></div>
					<div v-if="!paysucess" class="lf_course_name_data_box_pay_b">
						<img src="../../assets/images/payz.png" @click="alipay" alt="">
						<img src="../../assets/images/payw.png"  @click="weixinpay" alt="">
					</div>
					<div v-if="wximg != '' && !paysucess" class="lf_course_name_data_box_pay_b1"><img :src="wximg"/></div>
				</div>	
			</div>
		</el-col>
	</el-row>
	<Tips :show="show" @aliclickshowhide="aliclickshowhide"></Tips>
	<form id="dataform" method="post" style="display:none;"></form>

</template>

<script>
import html2Canvas from '../../plugs/htmlToPDF';
import axios from '../../axios/request';
import axiosx from '../../axios/postx';
import Tips from '../Tips.vue';
import pageNew from "../../components/page.vue";
 import {
        getSystemLang,
        getLangAll
    } from '../../lang';
    export default {
		data() {
		    return {
		        langs: '',
		        systemlang: 'cn',
				page:{
					limit:5,
					page:1,
					allpage:0,
					count:0,
					start:0
				},
				pagevalue:1,
				bFlag:'all', // all:全部订单 wsh:等待审核 wplay:等待支付 ok:已完成
				list:[],
				showDv:0,
				showDvs:false,
				timeer:0,
				paybox:false,
				orderid:'',
				wximg:'',
				paysucess:false,
				show:'0'
				
				
		    }
		},
        name: 'Player',
        props: {
            msg: String,
			mt_id:String,
        },
		components: {
			Tips,
			pageNew
        },
		created() {
			this.getData();
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
		},
		watch:{
			bFlag(value)
			{
				this.bFlag = value;
				this.page.page = 1;
				this.page.count = 0;
				this.page.start = 0;
				this.getData();
			},
			
		},
		methods:{
			closepayb(){
				this.paybox = false;
			},
			aliclickshowhide($s){
				if($s == '1')
				{
					this.show = '1';
				}
				else
				{
					this.show = '0';
				}
			},
			is_weixn(){  
			
			var ua = navigator.userAgent.toLowerCase();
			if (ua.match(/MicroMessenger/i) == "micromessenger") {
				
				return true;

			}
			return false;

			},
			Douwn(){
				if(this.is_weixn())
				{
					this.show = '1';
					return false;
				}
				

				console.log(this.$refs.pdf);	
				html2Canvas('订单下载打印','pdf');
				alert('下载中.....');
				
				
			},
			close(){
				this.showDvs = false;
			},
			showD(index)
			{
				// if(document.documentElement.clientWidth > 960)
				// {
				// 	return false;
				// }
				this.showDv = index;
				this.showDvs = true;
			},
			qxdd(orderId){
				this.$confirm(this.langs.oCancel, this.langs.oCancel, {
				confirmButtonText: this.langs.Confirm,
				cancelButtonText: this.langs.Cancel,
				type: 'warning'
				}).then(() => {
					axiosx('user/del.json',{id:orderId})
					.then((res)=>{
						let data = res.data;
						if(data.msgCode!='200')
						{
							this.$message({
								type: 'error',
								message: data.msg
							});
						}
						else
						{
							this.$message({
								type: 'success',
								message: 'success!'
							});
							this.getData();
						}
					});
				
				});
			},
			sqtk(orderId){
				this.$confirm(this.langs.RefundConfirm, this.langs.OperationConfirm, {
				confirmButtonText: this.langs.Confirm,
				cancelButtonText: this.langs.Cancel,
				type: 'warning'
				}).then(() => {
					axiosx('user/ordetrurn.json',{id:orderId})
					.then((res)=>{
						let data = res.data;
						if(data.msgCode!='200')
						{
							this.$message({
								type: 'error',
								message: data.msg
							});
						}
						else
						{
							this.$message({
								type: 'success',
								message: 'success!'
							});
							this.getData();
						}
					});
				
				});
			},
			changePage(value)
			{
				this.page.page = value;
				this.getData();
			},
			goFlag(str)
			{
				this.bFlag = str;
			},
			getData(){
				let  param = {};
				param.limit = this.page.limit;
				param.page = this.page.page;
				param.type = this.bFlag;
				axios('user/order.json',param)
				.then((res)=>{
					let data = res.data;
					if(data.msgCode!='200')
					{
						this.list = [];
						this.page.allpage = 0;
						this.page.count = 0;
						this.page.start = 0;
					}
					else
					{
						this.list = data.data;
						this.page.allpage = data.page.allpage;
						this.page.count = data.page.count;
						this.page.start = data.page.start;
					}
				});
			},
//<el-button type="primary" @click="weixinpay">微信</el-button>
// <el-button type="primary" @click="alipay">支付宝</el-button>
			weixinpay(){
				this.pay('wx',this.orderid);
				// this.paybox =false;
					
			},
			alipay(){
				this.pay('ali',this.orderid);   
				this.paybox =false;
			},
			pay(type,orderid)
			{
				
				let param = {};
				param.type = type;
				param.id = orderid;
				if(type == 'wx')
				{
					axios('user/pay.json',param)
					.then((res)=>{
						let data = res.data;
						
						if(data.msgCode == '200')
						{
							if(data.dev == 'mobile')
							{
								let cs = data.data;
								// window.open(data.sendurl);
								// window.location = data.sendurl; // 将当前页面重定向到 https://www.example.com
								var form1 = window.document.getElementById("dataform");//获取form1对象	
								// let form1 = window.document.createElement("form");
								form1.innerHTML  = '';
								form1.action = data.sendurl;
								form1.target="_blank";
								for(let i in cs)
								{
									let newElement = window.document.createElement("input");
									newElement.setAttribute("name",i);
									newElement.setAttribute("value",cs[i]);
									newElement.style.visibility="hidden";//设置为隐藏
									form1.appendChild(newElement);
								}
								form1.submit();
								this.checkPay(orderid,'wxpay');
							}
							else if(data.dev == 'pc')
							{
								this.wximg = data.msg;
								// this.$alert('<img src="'+data.msg+'"></img>', '微信扫码付款', {
								// dangerouslyUseHTMLString: true
								// });
								this.checkPay(orderid,'wxpay');
							}
							else if(data.dev == 'wx')
							{
								let cs = data.data;
								// window.open(data.sendurl);
								// window.location = data.sendurl; // 将当前页面重定向到 https://www.example.com
								var form1 = window.document.getElementById("dataform");//获取form1对象	
								// let form1 = window.document.createElement("form");
								form1.innerHTML  = '';
								form1.action = data.sendurl;
								// form1.target="_blank";
								for(let i in cs)
								{
									let newElement = window.document.createElement("input");
									newElement.setAttribute("name",i);
									newElement.setAttribute("value",cs[i]);
									newElement.style.visibility="hidden";//设置为隐藏
									form1.appendChild(newElement);
								}
								form1.submit();
								this.checkPay(orderid,'wxpay');
							}
							
						}
						
					});
				}
				else if(type == 'ali')
				{
					axios('user/pay.json',param)
					.then((res)=>{
						let data = res.data;
						if(data.msgCode == '200')
						{
							let cs = data.data;
							var form1 = window.document.getElementById("dataform");//获取form1对象	
							// let form1 = window.document.createElement("form");
							form1.innerHTML  = '';
							form1.action = data.sendurl;
							form1.target="_blank";
							for(let i in cs)
							{
								let newElement = window.document.createElement("input");
								newElement.setAttribute("name",i);
								newElement.setAttribute("value",cs[i]);
								newElement.style.visibility="hidden";//设置为隐藏
								form1.appendChild(newElement);
							}
							
							form1.submit();
							
							this.checkPay(orderid,'alipay');
						}
						
					});
				}
				
			},
			checkPay(id,payway){
				clearInterval(this.timeer);
				this.timeer = setInterval(()=>{
					axiosx('user/checkpay.json',{id:id,payway:payway})
					.then((res)=>{
						let data = res.data;
						if(data.msgCode=='200')
						{
							
							this.paysucess = true;
							this.getData();
							clearInterval(this.timeer);
							setTimeout(()=>{
								this.paysucess = false;
								this.paybox =false;
							},2000);
						}	
					});

				},3000);
				
			},
			payOrder(orderid,check)
			{
				if(check)
				{
					this.showDv = check;
				}
				this.orderid = orderid;
				this.wximg = '';
				this.paybox = true;
				console.log('ssss');
				return false;
				
			}
		}
    }
</script>

<style scoped="scoped">
	.d_title {
		margin-top: 0.14rem;
		color: rgb(192, 21, 62);
		font-size: 0.28rem;
		padding-bottom: 4px;
		/* font-weight: bold; */
		font-family: '微软雅黑';
		border-bottom: 1px solid rgb(192, 21, 62);
		display: inline-block;
		padding-right: 30px;
	}

	.d_title span {
		font-size: 0.24rem;
	}
	.lf_match_info_box{
		padding: 0.35rem 0px;
		font-size: 0.14rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.lf_match_info_box_r{
		display: flex;
		justify-content: flex-end;
	}
	.lf_course_bogo{
		position: relative;
		font-size: 0.2rem;
		color: #fff;
	}
	.lf_course_bogo_des{
		height: 0.6rem;
		display: flex;
		align-items: center;
		text-indent: 0.2rem;
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.498);
		width: 100%;
	}
	.lf_course_name_data_box{
		background-color: #FAFAFA;
	}
	.lf_hide_select{
		display: none!important;
	}
	.lf_course_name_data_tit{
		width: 96%;
		
		color: #999;
		font-size: 0.24rem;
		height: 0.7rem;
		display: flex;
		align-items: center;
		margin: 0 auto;
	}
	.lf_course_name_data_con{
		width: 96%;
		color: #999;
		font-size: 0.14rem;
		min-height: 0.7rem;
		display: flex;
		align-items: center;
		margin: 0 auto;
		padding: 0.2rem 0;
		border-top: 1px solid #efefef;
	}
	.lf_course_name_data_tit div,.lf_course_name_data_con div{
		width: 12%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		flex-direction: column;
	}
	.lf_course_name_data_con div{
		color: #000;
		font-size: 0.24rem;
	}
	.lf_course_name_data_con .lf_4{
		
		font-weight: bold;
	}
	.lf_4{
		width: 2rem!important;
	}
	.lf_5{
		width: 2.2rem!important;
	}
	.lf_8{
		width: 2.8rem!important;
	}
	.lf_2{
		width: 6.3rem!important;
		
	}
	.lf_2 div{
		width: 6.3rem!important;
		align-items: flex-start;
	}
	.lf_2 .lf_2_2{
		font-weight: bold;
		margin-top: 0.1rem;
	}
	.lf_course_name_data_tit .lf_9{
		flex-grow: 1;
		display: flex;
		align-items: center;
		flex-direction: row!important;
	}
	.lf_course_name_data_con .lf_9{
		flex-grow: 1;
		display: flex;
		align-items: center;
		flex-direction: row!important;
	}
	.lf_9 text{
		cursor: pointer;
		color: #C0153E;
		margin-right: 0.2rem;
	}
	.lf_course_name_data_con .lf_2{
		justify-content: center;
		display: flex;
		flex-direction: column;
		cursor: pointer;
	}
	.lf_tab_tit_box {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin-bottom: 0.2rem;
	}
	.lf_tab_little_box{
		height: 30px;
		display: flex;
		align-items: center;
	}
	.lf_tab_little_box div {
		padding: 0 0.16rem;
		height: 0.21rem;
		font-size: 0.24rem;
		color: #666;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		border-right: 1px solid #999;
		word-break: keep-all;
		cursor: pointer;
	}
	.lf_tab_little_box div:nth-last-of-type(1){
		border-right: unset;
	}
	.lf_tab_little_box .lf_tab_box_select {
		color: #C0153E;
	}
	.el-pagination{
		justify-content: center;
	}
	.block{
		margin: 0.2rem auto;
	}
	
	
	.lf_course_name_data_box_phone{
		display: flex;
		align-items: center;
		position: fixed;
		background-color: #fff;
		width: 7rem;
		border-radius: 0.1rem;
		padding: 0.2rem 0.3rem;
	}
	.lf_course_name_data_box_phone .lf_course_name_data_tit_phone{
		width: 30%;
	}
	.lf_course_name_data_box_phone .lf_course_name_data_tit_phone,.lf_course_name_data_box_phone .lf_course_name_data_con_phone{
		flex-direction: column;
		padding: 0.5rem 0;
	}
	.lf_course_name_data_box_phone .lf_course_name_data_tit_phone div,.lf_course_name_data_box_phone .lf_course_name_data_con_phone div{
		flex-direction: row;
		width: 100%!important;
		min-height: 0.6rem;
		padding: 0.1rem 0;
	}
	.lf_course_name_data_box_phone_box{
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 99;
		background-color: rgba(0,0,0,0.3);
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_close_img{
		width: 0.36rem;
		height: 0.36rem;
		position: absolute;
		right: 0.1rem;
		top: 0.1rem;
		cursor: pointer;
	}
	.lf_course_name_data_box_pay{
		display: flex;
		align-items: center;
		position: fixed;
		background-color: #fff;
		width: 5rem;
		border-radius: 0.1rem;
		padding: 0.5rem 0.3rem;
		flex-direction: column;
	}
	.lf_course_name_data_box_pay_t{
		font-size: 0.24rem;
		color: #333;
		margin: 0.2rem 0 0.1rem;
	}
	.lf_course_name_data_box_pay_M{
		font-size: 0.24rem;
		color: #333;
	}
	.lf_course_name_data_box_pay_M text{
		font-size: 0.24rem;
		color: #C0153E;
		margin-left: 0.1rem;
	}
	.lf_course_name_data_box_pay_b{
		display: flex;
		align-items: center;
		margin: 0.2rem 0;
		justify-content: space-between;
	}
	.lf_course_name_data_box_pay_b img{
		height: 0.7rem;
		width: auto;
	}
	.lf_course_name_data_box_pay_b1{
		display: flex;
		align-items: center;
		margin: 0.2rem 0;
		justify-content: center;
	}
	.lf_course_name_data_box_pay_b1 img{
		height: 3rem;
	}
	@media screen and (max-width: 768px) {
		.lf_match_info_box{
			padding: 0.35rem 0px;
			margin-left: 0;
		}
		.lf_course_name_data_tit div,.lf_course_name_data_con div{
			display: none!important;
		}
		.lf_course_name_data_tit div:nth-of-type(6),.lf_course_name_data_con div:nth-of-type(6){
			display: flex!important;
		}
		.lf_course_name_data_tit div:nth-of-type(2),.lf_course_name_data_con div:nth-of-type(2){
			display: flex!important;
		}
		.lf_course_name_data_tit div,.lf_course_name_data_con div{
			width: 65%!important;
		}
		.lf_course_name_data_tit .lf_9,.lf_course_name_data_con .lf_9{
			width: 35%!important;
		}
		.lf_course_name_data_tit .lf_1,.lf_course_name_data_con .lf_1{
			width: 10%!important;
		}
		.lf_hide_select{
			display: unset!important;
		}
		.lf_tab_little_box div {
			padding: 0 0.1rem;
			height: 0.14rem;
			font-size: 0.24rem;
			color: #999;
			display: flex;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			border-right: 1px solid #999;
			word-break: keep-all;
		}
		.lf_course_name_data_box_phone{
			display: flex;
			align-items: center;
			position: fixed;
			background-color: #fff;
			width: 7rem;
			border-radius: 0.1rem;
			padding: 0.2rem 0.3rem;
			font-size: 0.28rem;
		}
		.lf_course_name_data_box_phone .lf_course_name_data_tit_phone{
			width: 30%;
		}
		.lf_course_name_data_box_phone .lf_course_name_data_tit_phone,.lf_course_name_data_box_phone .lf_course_name_data_con_phone{
			flex-direction: column;
			padding: 0.5rem 0;
		}
		.lf_course_name_data_box_phone .lf_course_name_data_tit_phone div,.lf_course_name_data_box_phone .lf_course_name_data_con_phone div{
			flex-direction: row;
			width: 100%!important;
			min-height: 0.6rem;
			padding: 0.1rem 0;
		}
		.lf_course_name_data_box_phone_box{
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 99;
			background-color: rgba(0,0,0,0.3);
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.lf_close_img{
			width: 0.36rem;
			height: 0.36rem;
			position: absolute;
			right: 0.1rem;
			top: 0.1rem;
		}
		.lf_2 .lf_2_1{
			width: 100%!important;
			display: flex!important;
		}
		.lf_2 .lf_2_2{
			width: 100%!important;
			display: flex!important;
		}
	}
</style>
