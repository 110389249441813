<template>	
	<el-row   type="flex" justify="center">
		<el-col :xs="24" :sm="16" :md="16">
			<div v-if="show=='1'" @click="aliclickshowhide" class="lf_course_name_data_box_phone_box" style="background-color: rgba(13,13,13,0.6);">
				<img style="width: 100%;position: absolute;top: 0;" src="../assets/images/00222.png" alt="">
			</div>
			
		</el-col>
	</el-row>
</template>

<script>
    export default {
		data() {
		    return {
		        langs: '',
		        systemlang: 'cn',
				aliclickshow:false,
				
		    }
		},
        name: 'Tips',
        props: {
           show:String,
        },
		components: {
			
        },
		created() {
		
			
		},
		methods:{
			aliclickshowhide(){
				this.$emit('aliclickshowhide',{show:'0'});
			}
			
		}
    }
</script>

<style scoped="scoped">
	.lf_course_name_data_box_phone_box{
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 99;
		background-color: rgba(0,0,0,0.3);
		display: flex;
		align-items: center;
		justify-content: center;
	}
	@media screen and (max-width: 768px) {
		.lf_course_name_data_box_phone_box{
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 99;
			background-color: rgba(0,0,0,0.3);
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
</style>
