
<template>
    <el-row type="flex" justify="center">
        <el-col :xs="24" :sm="16" :md="16">
          <div class="lf_video_box">
            <video ref="videoPlayer" class="video-js" style="margin: auto auto"></video>
          </div>
        </el-col>
  </el-row>
</template>
<script>
import { defineComponent,onMounted, onUnmounted, ref } from "vue"
import videojs from "video.js"
import "video.js/dist/video-js.css"
export default defineComponent({
    props:{
        msg:{
            type: String,
            default: ''
        },
		url:{
		    type: String,
		    default: ''
		}
    },
    setup(props,context){
        console.log('props==>',props.sss);//输出的值是
		console.log('props==>',props);//输出的值是
        var videoPlayer = ref();
        const myPlayer = ref(null)
        onMounted(() => {
            myPlayer.value = videojs(videoPlayer.value, {
                poster: "",//默认图
                controls: true,
                sources: [
                  {
                    src:props.url ,
                    type: 'application/x-mpegURL',
                  }
                ],
                controlBar: {
                  remainingTimeDisplay: {
                    displayNegative: false
                  }
                },
                playbackRates: [0.5, 1, 1.5, 2]
              }, () => {
                myPlayer.value.log("play.....")
              })
        });
        onUnmounted(() => {
          if (myPlayer.value) {
            myPlayer.value.dispose()
          }
        })
        return {videoPlayer}
    }    
})


</script>
<style scoped="scoped">
    .lf_video_box{
        width: 12.8rem;
        height: 7.2rem;
    }
    .video-js{
        width: 100%;
        height: 100%;
    }
    @media screen and (max-width: 768px) {
        .lf_video_box{
            width: 100%;
            height: 4.22rem;
        }
    }
    
</style>


