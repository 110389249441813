<template>
	<navs></navs>
	<Btn cd="ph"></Btn>
	<div class="title">
		<div class="titleima">
			<videoPlayer v-if="info.DownloadUrl && info.DownloadUrl.indexOf('.m3u8') >=0" :url="info.DownloadUrl"></videoPlayer>
		</div>
		<div class="name">
			{{info.DownloadTitle}}
		</div>
		<div class="des" v-html="info.DownloadContent">
			
		</div>
		<div class="time" v-html="info.DownloadAddDate">
			
		</div>
	</div>
	<div style="width: 100%;height: 10px;clear: both;"></div>


	<Footer></Footer>
	<Footer1></Footer1>
</template>

<script>
	// @ is an alias to /src
	import navs from "../../components/nav.vue";
	import Btn from "../../components/Btn.vue";
	import Footer from "../../components/Footer.vue";
	import Footer1 from "../../components/Footer1.vue";
	import axios from '../../axios/request';
	import videoPlayer from "../../components/videoPlayer.vue";
	import {
		getSystemLang,
		getLangAll
	} from "../../lang";
	//这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'
	
	//这里就是更新后的路径，以前是import 'swiper/css'

	



	//自动轮播的配置
	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				info:{},
				
			}
		},
		props: {

		},
		watch: {
			
		},
		name: 'Home',
		components: {
			navs,
			Btn,
			Footer,
			Footer1,
			videoPlayer
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			let id = this.$route.query.id;
			let flag = this.$route.query.flag;
			this.flag = flag;
			//获取巡回赛新闻/news/info/8826.json
			axios('player/player/videoinfo/'+id+'.json')
            .then((res)=>{
                let data = res.data.data;
                this.info = data;
				if(this.info.DownloadTag == '')
				{
					this.info.DownloadTag = this.info.DownloadTitle;
				}
				document.querySelector('meta[name="keywords"]').setAttribute('content', this.info.DownloadTag);
				document.title = this.info.DownloadTitle;
            });
			
		},
		setup() {
			
		},
		methods: {
			
		}

	}
</script>


<style scoped="scoped">
	.title {
		width: 12.8rem;
		height: 9rem;
		overflow: hidden;
		margin-left: 3.3rem;
		margin-top: 0.7rem;
	}

	.titleima img {
		width: 12.8rem;
		height: 7.2rem;
	}

	.name {
		width: 12.8rem;
		height: 0.74rem;
		color: #C0153E;
		line-height: 0.74rem;
		font-size: 0.28rem;
		font-weight: bold;

	}

	.des {
		width: 12.8rem;
		color: #333333;
		font-size: 0.24rem;
	}

	.time {
		width: 12.8rem;
		color: #666666;
		font-size: 0.24rem;
		margin-top: 0.1rem;
	}

	.lf_footer1 {
		width: 1200px;
		margin: 0 auto;
	}

	.lf_footer1_tit_box {
		width: 1200px;
		height: 165px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}

	.lf_footer1_tit_box .lf_foot_tit_text1 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.26rem;
		color: #C0153E;
	}

	.lf_footer1_tit_box .lf_foot_tit_text2 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.15rem;
		color: #C0153E;
	}

	.lf_footer1_tit_box .lf_foot_tit_line {
		width: 2.1rem;
		height: 3px;
		background-color: #C0153E;
		margin: 4px 0;
	}


	@media screen and (max-width: 768px) {

		.title {

			width: 7.5rem;
			height: 6.7rem;
			overflow: hidden;
			margin-left: 0;
			margin-top: 0rem;
		}

		.titleima img {
			width: 7.5rem;
			height: 4.22rem;
		}

		.name {
			width: 7rem;
			height: 0.74rem;
			color: #C0153E;
			line-height: 0.74rem;
			font-size: 0.28rem;
			font-weight: bold;
			margin-left: 0.2rem;
		}

		.des {
			width: 7rem;
			color: #333333;
			margin-left: 0.2rem;
			font-size: 0.24rem;
		}

		.time {
			width: 7rem;
			color: #666666;
			font-size: 0.24rem;
			margin-top: 0.1rem;
			margin-left: 0.2rem;
		}

		.lf_footer1 {
			width: 100%;
			margin: 0 auto;
		}

		.lf_footer1_tit_box {
			width: 100%;
			height: 90px;
		}


		.lf_footer1_tit_box .lf_foot_tit_text2 {
			font-size: 0.26rem;
		}


		.lf_footer1_tit_box .lf_foot_tit_text1 {
			font-family: '微软雅黑';
			font-weight: 400;
			font-style: normal;

			font-size: 0.28rem;
			color: #C0153E;
		}

	}
</style>