<template>


	<el-row type="flex" justify="center">

		<el-col :xs="24" :sm="24" :md="24">

			<div class="lf_match_info_box">
				<div class="d_title">球员成绩表 | <span>Leaderboard</span></div>

			</div>
			<div class="lf_match_info_box">

				<div class="lf_group_tit_left">
					<el-radio-group v-model="radio">
						<el-radio v-if="info.info && info.info.mt_round" v-for="i in info.info.mt_round_num"
							:label="i">{{langs.text_di}}{{i}}{{langs.text_lun}}</el-radio>
					</el-radio-group>
				</div>
			</div>
			<div v-if="info.list" id="pdf" class="lf_course_name_data_box">
				<div class="lf_font_size_26 lf_jinji3216">{{info.promotion}}</div>
				<!--循环开始-->
				<template v-for="(pk,seq) in info.list">
					<div v-if="seq <= info.count">
						<div class="lf_bds_list" :class="checkeditem == seq?'lf_pink':''" @click="checkItemClick(seq)">
							<div class="lf_bds_list_1">
								<img class="lf_bds_list_country_img"
									v-if="info.player[pk[pk.pk[0]]['pl_id']].pl_country!=''"
									:src="info.player[pk[pk.pk[0]]['pl_id']].pl_country" alt="">
								<div class="lf_font_size_28 lf_bds_list_pl_name">
									{{systemlang=='cn'?info.player[pk[pk.pk[0]]['pl_id']].pl_cn_name:info.player[pk[pk.pk[0]]['pl_id']].pl_en_name}}
								</div>
								<img class="lf_bds_list_zzs_img" v-if="info.player[pk[pk.pk[0]]['pl_id']].mp_logo!=''"
									:src="info.player[pk[pk.pk[0]]['pl_id']].mp_logo" alt="">
								<div class="lf_bds_list_win lf_font_size_24"
									v-if="pk.team_win_pk!='' && pk.team_win_pk == pk.pk[0]">WIN</div>
								<img v-if="pk[pk.pk[0]].team_win_up > 0 && pk.team_win_pk==''"
									class="lf_bds_list_ll_img" src="../../assets/images/LL.png" alt="">
							</div>
							<div class="lf_bds_list_2">

								<div class="lf_bds_list_2_middle lf_font_size_30"
									v-if="pk.team_o_hole <= 0 && pk.team_win_pk == ''">VS</div>
								<div class="lf_bds_list_2_bottom lf_font_size_20"
									v-if="pk.team_o_hole <= 0 && pk.team_win_pk == ''"><span
										:class="pk.team_sub_ok=='1'?'lf_bds_red_F':''">{{ pk.start_time }}</span>
								</div>

								<div class="lf_bds_list_2_middle lf_font_size_30"
									v-if="pk.team_o_hole > 0 && pk.team_win_pk == '' && !info.addhole[seq]">
									{{ pk.team_win_up==0?'AS': pk.team_win_up+'UP'}}
								</div>
								<div class="lf_bds_list_2_bottom lf_font_size_20"
									v-if="pk.team_o_hole > 0 && pk.team_win_pk == '' && !info.addhole[seq]">THRU
									<span :class="pk.team_sub_ok=='1'?'lf_bds_red_F':''">{{ pk.team_o_hole }}</span>
								</div>

								<div class="lf_bds_list_2_middle lf_font_size_30"
									v-if="pk.team_o_hole > 0 && pk.team_win_pk == '' && info.addhole[seq]">HAVLED
								</div>
								<div class="lf_bds_list_2_bottom lf_font_size_20"
									v-if="pk.team_o_hole > 0 && pk.team_win_pk == '' && info.addhole[seq]">PLAYOFF
									<span
										:class="pk.team_sub_ok=='1'?'lf_bds_red_F':''">#{{ info.addhole[seq].addholecount }}</span>
								</div>

								<div class="lf_bds_list_2_middle lf_font_size_30"
									v-if="info.player[pk[pk.pk[0]]['pl_id']].mp_sts!='' || info.player[pk[pk.pk[1]]['pl_id']].mp_sts!=''">
								</div>
								<div class="lf_bds_list_2_bottom lf_font_size_20"
									v-if="info.player[pk[pk.pk[0]]['pl_id']].mp_sts!='' || info.player[pk[pk.pk[1]]['pl_id']].mp_sts!=''">
									THRU <span :class="pk.team_sub_ok=='1'?'lf_bds_red_F':''">F</span></div>

								<div class="lf_bds_list_2_middle lf_font_size_30"
									v-if="pk.team_o_hole <= 0 && pk.team_win_pk != '' && !(info.player[pk[pk.pk[0]]['pl_id']].mp_sts!='' || info.player[pk[pk.pk[1]]['pl_id']].mp_sts!='')">
									{{ pk.team_win_up==0?'AS': pk.team_win_up+'UP'}}
								</div>
								<div class="lf_bds_list_2_bottom lf_font_size_20"
									v-if="pk.team_o_hole <= 0 && pk.team_win_pk != '' && !(info.player[pk[pk.pk[0]]['pl_id']].mp_sts!='' || info.player[pk[pk.pk[1]]['pl_id']].mp_sts!='')">
									THRU <span :class="pk.team_sub_ok=='1'?'lf_bds_red_F':''">F</span></div>

								<div class="lf_bds_list_2_middle lf_font_size_30"
									v-if="pk.team_o_hole > 0 && pk.team_win_pk != '' && !info.addhole[seq] && !(info.player[pk[pk.pk[0]]['pl_id']].mp_sts!='' || info.player[pk[pk.pk[1]]['pl_id']].mp_sts!='')">
									{{ pk.team_o_hole==pk.team_all_hole?(pk.team_win_up==0?'HAVLED':pk.team_win_up+'UP'):pk.team_win_up+'&'+(pk.team_all_hole-pk.team_o_hole) }}
								</div>
								<div class="lf_bds_list_2_bottom lf_font_size_20"
									v-if="pk.team_o_hole > 0 && pk.team_win_pk != '' && !info.addhole[seq] && !(info.player[pk[pk.pk[0]]['pl_id']].mp_sts!='' || info.player[pk[pk.pk[1]]['pl_id']].mp_sts!='')">
									THRU <span :class="pk.team_sub_ok=='1'?'lf_bds_red_F':''">F</span></div>

								<div class="lf_bds_list_2_middle lf_font_size_30"
									v-if="pk.team_o_hole > 0 && pk.team_win_pk != '' && info.addhole[seq]">HAVLED
								</div>
								<div class="lf_bds_list_2_bottom lf_font_size_20"
									v-if="pk.team_o_hole > 0 && pk.team_win_pk != '' && info.addhole[seq]">PLAYOFF
									<span
										:class="pk.team_sub_ok=='1'?'lf_bds_red_F':''">#{{ info.addhole[seq].addholecount }}</span>
								</div>
								<!-- <div class="lf_bds_list_2_middle lf_font_size_30" v-if="pk.team_win_pk !=''">VS</div>
							<div class="lf_bds_list_2_bottom lf_font_size_20">PLAYOFF <span class="lf_bds_red_F">#1</span></div> -->

							</div>
							<div class="lf_bds_list_3">
								<div class="lf_bds_list_win lf_font_size_24"
									v-if="pk.team_win_pk!='' && pk.team_win_pk == pk.pk[1]">WIN</div>
								<img class="lf_bds_list_zzs_img" v-if="info.player[pk[pk.pk[1]]['pl_id']].mp_logo!=''"
									:src="info.player[pk[pk.pk[1]]['pl_id']].mp_logo" alt="">
								<div class="lf_font_size_28 lf_bds_list_pl_name">
									{{systemlang=='cn'?info.player[pk[pk.pk[1]]['pl_id']].pl_cn_name:info.player[pk[pk.pk[1]]['pl_id']].pl_en_name}}
								</div>
								<img class="lf_bds_list_country_img"
									v-if="info.player[pk[pk.pk[1]]['pl_id']].pl_country!=''"
									:src="info.player[pk[pk.pk[1]]['pl_id']].pl_country" alt="">
								<img v-if="pk[pk.pk[1]].team_win_up > 0 && pk.team_win_pk==''"
									class="lf_bds_list_ll_img" src="../../assets/images/LL.png" alt="">
							</div>
						</div>
						<div style="position: relative;" v-if="checkeditem == seq">
							<div class="lf_bds_scroll_list">
								<div class="lf_left_scroll">
									<div class="lf_bds_scroll_list_1 lf_font_size_24">
										<div class="lf_bds_scroll_list_name">
											Hole
										</div>
									</div>
									<div class="lf_bds_scroll_list_2 lf_font_size_24">
										<div class="lf_bds_scroll_list_name">
											Par
										</div>
									</div>
									<div class="lf_bds_scroll_list_3 lf_font_size_24">
										<div class="lf_bds_scroll_list_name">
											<img class="lf_bds_list_country_img"
												v-if="info.player[pk[pk.pk[0]]['pl_id']].pl_country!=''"
												:src="info.player[pk[pk.pk[0]]['pl_id']].pl_country" alt="">
											{{systemlang=='cn'?info.player[pk[pk.pk[0]]['pl_id']].pl_cn_name:info.player[pk[pk.pk[0]]['pl_id']].pl_en_name}}
											<img class="lf_bds_list_zzs_img"
												v-if="info.player[pk[pk.pk[0]]['pl_id']].mp_logo!=''"
												:src="info.player[pk[pk.pk[0]]['pl_id']].mp_logo" alt="">
										</div>
									</div>
									<div class="lf_bds_scroll_list_4 lf_font_size_24">
										<div class="lf_bds_scroll_list_name">
											<img class="lf_bds_list_country_img"
												v-if="info.player[pk[pk.pk[1]]['pl_id']].pl_country!=''"
												:src="info.player[pk[pk.pk[1]]['pl_id']].pl_country" alt="">
											{{systemlang=='cn'?info.player[pk[pk.pk[1]]['pl_id']].pl_cn_name:info.player[pk[pk.pk[1]]['pl_id']].pl_en_name}}
											<img class="lf_bds_list_zzs_img"
												v-if="info.player[pk[pk.pk[1]]['pl_id']].mp_logo!=''"
												:src="info.player[pk[pk.pk[1]]['pl_id']].mp_logo" alt="">
										</div>
									</div>
								</div>
								<div class="lf_right_scroll">
									<div class="lf_bds_scroll_list_1 lf_font_size_24">
										<div class="lf_bds_scroll_list_score" v-for="(h,hindex) in info.hole">
											{{h.ho_id}}
										</div>
										<div class="lf_bds_scroll_list_status">STATUS</div>
									</div>
									<div class="lf_bds_scroll_list_2 lf_font_size_24">
										<div class="lf_bds_scroll_list_score" v-for="(h,hindex) in info.hole">
											{{h.par}}
										</div>
										<div class="lf_bds_scroll_list_status"></div>
									</div>
									<div class="lf_bds_scroll_list_3 lf_font_size_24">
										<div class="lf_bds_scroll_list_score" v-for="(h,hindex) in info.hole">
											<div class="lf_bds_scroll_list_score_60"
												:class="info.pkscore[pk.pk[0]]&&info.pkscore[pk.pk[0]][h.ho_id]&&info.pkscore[pk.pk[0]][h.ho_id].team_win_flag == 'win'?'lf_bds_scroll_list_score_win':''">
												{{info.pkscore[pk.pk[0]]&&info.pkscore[pk.pk[0]][h.ho_id]?info.pkscore[pk.pk[0]][h.ho_id].score:''}}
											</div>
											<div class="lf_bds_scroll_list_score_up"
												v-if="!info.pkscore[pk.pk[0]] || !info.pkscore[pk.pk[1]]  || !info.pkscore[pk.pk[1]][h.ho_id] || !info.pkscore[pk.pk[0]][h.ho_id] || info.pkscore[pk.pk[0]][h.ho_id].team_win_up_roller<0">
												&nbsp;</div>
											<div class="lf_bds_scroll_list_score_up"
												v-else-if="info.pkscore[pk.pk[0]][h.ho_id]&&info.pkscore[pk.pk[0]][h.ho_id].team_win_up_roller=='0'">
												AS</div>
											<div class="lf_bds_scroll_list_score_up"
												v-else-if="info.pkscore[pk.pk[0]][h.ho_id]&&info.pkscore[pk.pk[0]][h.ho_id].team_win_up_roller>'0'">
												{{info.pkscore[pk.pk[0]][h.ho_id].team_win_up_roller+'UP'}}
											</div>

										</div>

										<div class="lf_bds_scroll_list_status lf_font_size_30"
											v-if="pk.team_o_hole <= 0 && pk.team_win_pk == pk.pk[0]">
											{{ pk.team_win_up==0?'AS': pk.team_win_up+'UP'}}
										</div>
										<div class="lf_bds_scroll_list_status lf_font_size_30"
											v-else-if="pk.team_o_hole > 0 && pk.team_win_pk == pk.pk[0] && !info.addhole[seq]">
											{{ pk.team_o_hole==pk.team_all_hole?pk.team_win_up+'UP':pk.team_win_up+'&'+(pk.team_all_hole-pk.team_o_hole) }}
										</div>
										<div class="lf_bds_scroll_list_status lf_font_size_30"
											v-else-if="pk.team_o_hole > 0 && pk.team_win_pk != '' && (info.addhole[seq] || (pk.team_o_hole==pk.team_all_hole && (pk.team_o_hole==pk.team_all_hole && pk.team_win_up== '0')) )">
											AS</div>
										<div class="lf_bds_scroll_list_status lf_font_size_30"
											v-else-if="pk.team_win_pk != ''"></div>

										<div class="lf_bds_scroll_list_status lf_font_size_30"
											v-else-if="pk.team_o_hole > 0 ">
											{{pk[pk.pk[0]].team_win_up >0?pk[pk.pk[0]].team_win_up+'UP':(pk[pk.pk[0]].team_win_up ==0?'AS':'')}}
										</div>
										<div class="lf_bds_scroll_list_status lf_font_size_30" v-else>STATUS</div>
									</div>
									<div class="lf_bds_scroll_list_4 lf_font_size_24">
										<div class="lf_bds_scroll_list_score" v-for="(h,hindex) in info.hole">
											<div class="lf_bds_scroll_list_score_up"
												v-if="!info.pkscore[pk.pk[1]] || !info.pkscore[pk.pk[0]] || !info.pkscore[pk.pk[0]][h.ho_id] || !info.pkscore[pk.pk[1]][h.ho_id] || info.pkscore[pk.pk[1]][h.ho_id].team_win_up_roller<0">
												&nbsp;</div>
											<div class="lf_bds_scroll_list_score_up"
												v-else-if="info.pkscore[pk.pk[1]][h.ho_id]&&info.pkscore[pk.pk[1]][h.ho_id].team_win_up_roller=='0'">
												AS</div>
											<div class="lf_bds_scroll_list_score_up"
												v-else-if="info.pkscore[pk.pk[1]][h.ho_id]&&info.pkscore[pk.pk[1]][h.ho_id].team_win_up_roller>'0'">
												{{info.pkscore[pk.pk[1]][h.ho_id].team_win_up_roller+'UP'}}
											</div>
											<div class="lf_bds_scroll_list_score_60 "
												:class="info.pkscore[pk.pk[1]] && info.pkscore[pk.pk[1]][h.ho_id]&&info.pkscore[pk.pk[1]][h.ho_id].team_win_flag == 'win'?'lf_bds_scroll_list_score_win':''">
												{{info.pkscore[pk.pk[1]]&& info.pkscore[pk.pk[1]][h.ho_id]?info.pkscore[pk.pk[1]][h.ho_id].score:''}}
											</div>
										</div>

										<div class="lf_bds_scroll_list_status lf_font_size_30"
											v-if="pk.team_o_hole <= 0 && pk.team_win_pk == pk.pk[1]">
											{{ pk.team_win_up==0?'AS': pk.team_win_up+'UP'}}
										</div>
										<div class="lf_bds_scroll_list_status lf_font_size_30"
											v-else-if="pk.team_o_hole > 0 && pk.team_win_pk == pk.pk[1] && !info.addhole[seq]">
											{{ pk.team_o_hole==pk.team_all_hole?pk.team_win_up+'UP':pk.team_win_up+'&'+(pk.team_all_hole-pk.team_o_hole) }}
										</div>
										<div class="lf_bds_scroll_list_status lf_font_size_30"
											v-else-if="pk.team_o_hole > 0 && pk.team_win_pk != '' && (info.addhole[seq] || (pk.team_o_hole==pk.team_all_hole && pk.team_win_up== '0'))">
											AS</div>
										<div class="lf_bds_scroll_list_status lf_font_size_30"
											v-else-if="pk.team_win_pk != ''"></div>
										<div class="lf_bds_scroll_list_status lf_font_size_30"
											v-else-if="pk.team_o_hole > 0 ">
											{{pk[pk.pk[1]].team_win_up >0?pk[pk.pk[1]].team_win_up+'UP':(pk[pk.pk[1]].team_win_up ==0?'AS':'')}}
										</div>
										<div class="lf_bds_scroll_list_status lf_font_size_30" v-else>STATUS</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 加洞 -->
						<div v-if="info.addhole[seq] && checkeditem == seq" style="position: relative;">
							<div class="lf_bds_scroll_list lf_bds_scroll_list_add">
								<div class="lf_left_scroll">
									<div class="lf_bds_scroll_list_1 lf_font_size_24">
										<div class="lf_bds_scroll_list_name">
											PLAYOFF
										</div>

									</div>
									<div class="lf_bds_scroll_list_2 lf_font_size_24">
										<div class="lf_bds_scroll_list_name">
											Hole
										</div>

									</div>
									<div class="lf_bds_scroll_list_2 lf_font_size_24">
										<div class="lf_bds_scroll_list_name">
											Par
										</div>

									</div>
									<div class="lf_bds_scroll_list_3 lf_bds_scroll_list_3_add lf_font_size_24">
										<div class="lf_bds_scroll_list_name">
											<img class="lf_bds_list_country_img"
												v-if="info.player[pk[pk.pk[0]]['pl_id']].pl_country!=''"
												:src="info.player[pk[pk.pk[0]]['pl_id']].pl_country" alt="">
											{{systemlang=='cn'?info.player[pk[pk.pk[0]]['pl_id']].pl_cn_name:info.player[pk[pk.pk[0]]['pl_id']].pl_en_name}}
											<img class="lf_bds_list_zzs_img"
												v-if="info.player[pk[pk.pk[0]]['pl_id']].mp_logo!=''"
												:src="info.player[pk[pk.pk[0]]['pl_id']].mp_logo" alt="">
										</div>

									</div>
									<div class="lf_bds_scroll_list_4 lf_bds_scroll_list_4_add lf_font_size_24">
										<div class="lf_bds_scroll_list_name">
											<img class="lf_bds_list_country_img"
												v-if="info.player[pk[pk.pk[1]]['pl_id']].pl_country!=''"
												:src="info.player[pk[pk.pk[1]]['pl_id']].pl_country" alt="">
											{{systemlang=='cn'?info.player[pk[pk.pk[1]]['pl_id']].pl_cn_name:info.player[pk[pk.pk[1]]['pl_id']].pl_en_name}}
											<img class="lf_bds_list_zzs_img"
												v-if="info.player[pk[pk.pk[1]]['pl_id']].mp_logo!=''"
												:src="info.player[pk[pk.pk[1]]['pl_id']].mp_logo" alt="">
										</div>
									</div>
								</div>
								<div class="lf_right_scroll">
									<div class="lf_bds_scroll_list_1 lf_font_size_24">

										<div class="lf_bds_scroll_list_score lf_bds_scroll_list_score_add"
											v-for="(addhole,addholeindex) in info.addhole[seq].addhole">
											{{addhole.index}}
										</div>
										<div class="lf_bds_scroll_list_status">STATUS</div>
									</div>
									<div class="lf_bds_scroll_list_2 lf_font_size_24">

										<div class="lf_bds_scroll_list_score lf_bds_scroll_list_score_add"
											v-for="(addhole,addholeindex) in info.addhole[seq].addhole">
											{{addhole.ho_id}}
										</div>
										<div class="lf_bds_scroll_list_status"></div>
									</div>
									<div class="lf_bds_scroll_list_2 lf_font_size_24">

										<div class="lf_bds_scroll_list_score lf_bds_scroll_list_score_add"
											v-for="(addhole,addholeindex) in info.addhole[seq].addhole">
											{{info.hole[addhole.ho_id].par}}
										</div>
										<div class="lf_bds_scroll_list_status"></div>
									</div>
									<div class="lf_bds_scroll_list_3 lf_bds_scroll_list_3_add lf_font_size_24">

										<div class="lf_bds_scroll_list_score lf_bds_scroll_list_score_add"
											v-for="(addhole,addholeindex) in info.addhole[seq].addhole">
											<div class="lf_bds_scroll_list_score_60 lf_bds_scroll_list_score_59"
												:class="addhole[pk.pk[0]].flag == 'win'?'lf_bds_scroll_list_score_win':''">
												{{addhole[pk.pk[0]].score}}
											</div>

										</div>
										<div class="lf_bds_scroll_list_status lf_bds_scroll_list_status_addHole lf_font_size_24"
											v-if="info.addhole[seq].player[pk[pk.pk[0]]['pl_id']].flag=='win'">WNR
										</div>
										<div class="lf_bds_scroll_list_status lf_bds_scroll_list_status_addHole lf_font_size_24"
											v-if="info.addhole[seq].player[pk[pk.pk[0]]['pl_id']].flag=='lose'">ELM
										</div>
										<div class="lf_bds_scroll_list_status lf_bds_scroll_list_status_addHole lf_font_size_24"
											v-if="info.addhole[seq].player[pk[pk.pk[0]]['pl_id']].flag=='ping'">
										</div>
									</div>
									<div class="lf_bds_scroll_list_4 lf_bds_scroll_list_4_add lf_font_size_24">

										<div class="lf_bds_scroll_list_score lf_bds_scroll_list_score_add"
											v-for="(addhole,addholeindex) in info.addhole[seq].addhole">

											<div class="lf_bds_scroll_list_score_60 lf_bds_scroll_list_score_59  "
												:class="addhole[pk.pk[1]].flag == 'win'?'lf_bds_scroll_list_score_win':''">
												{{addhole[pk.pk[1]].score}}
											</div>
										</div>
										<div class="lf_bds_scroll_list_status lf_bds_scroll_list_status_addHole lf_font_size_24"
											v-if="info.addhole[seq].player[pk[pk.pk[1]]['pl_id']].flag=='win'">WNR
										</div>
										<div class="lf_bds_scroll_list_status lf_bds_scroll_list_status_addHole lf_font_size_24"
											v-if="info.addhole[seq].player[pk[pk.pk[1]]['pl_id']].flag=='lose'">ELM
										</div>
										<div class="lf_bds_scroll_list_status lf_bds_scroll_list_status_addHole lf_font_size_24"
											v-if="info.addhole[seq].player[pk[pk.pk[1]]['pl_id']].flag=='ping'">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
				<!--循环结束-->
				<div class="lf_font_size_26 lf_jinji3216 lf_man_box_jinji3216">{{info.promotion}}</div>
				<!--男子循环-->
				<template v-for="(pk,seq) in info.list">
					<div class="lf_man_box" v-if="seq > info.count">
						<div class="lf_bds_list" :class="checkeditem == seq?'lf_pink':''" @click="checkItemClick(seq)">
							<div class="lf_bds_list_1">
								<img class="lf_bds_list_country_img"
									v-if="info.player[pk[pk.pk[0]]['pl_id']].pl_country!=''"
									:src="info.player[pk[pk.pk[0]]['pl_id']].pl_country" alt="">
								<div class="lf_font_size_28 lf_bds_list_pl_name">
									{{systemlang=='cn'?info.player[pk[pk.pk[0]]['pl_id']].pl_cn_name:info.player[pk[pk.pk[0]]['pl_id']].pl_en_name}}
								</div>
								<img class="lf_bds_list_zzs_img" v-if="info.player[pk[pk.pk[0]]['pl_id']].mp_logo!=''"
									:src="info.player[pk[pk.pk[0]]['pl_id']].mp_logo" alt="">
								<div class="lf_bds_list_win lf_font_size_24"
									v-if="pk.team_win_pk!='' && pk.team_win_pk == pk.pk[0]">WIN</div>
								<img v-if="pk[pk.pk[0]].team_win_up > 0 && pk.team_win_pk==''"
									class="lf_bds_list_ll_img" src="../../assets/images/LLM.png" alt="">
							</div>
							<div class="lf_bds_list_2">
								<div class="lf_bds_list_2_middle lf_font_size_30"
									v-if="pk.team_o_hole <= 0 && pk.team_win_pk == ''">VS</div>
								<div class="lf_bds_list_2_bottom lf_font_size_20"
									v-if="pk.team_o_hole <= 0 && pk.team_win_pk == ''"><span
										:class="pk.team_sub_ok=='1'?'lf_bds_red_F':''">{{ pk.start_time }}</span>
								</div>

								<div class="lf_bds_list_2_middle lf_font_size_30"
									v-if="pk.team_o_hole > 0 && pk.team_win_pk == '' && !info.addhole[seq]">
									{{ pk.team_win_up==0?'AS': pk.team_win_up+'UP'}}
								</div>
								<div class="lf_bds_list_2_bottom lf_font_size_20"
									v-if="pk.team_o_hole > 0 && pk.team_win_pk == '' && !info.addhole[seq]">THRU
									<span :class="pk.team_sub_ok=='1'?'lf_bds_red_F':''">{{ pk.team_o_hole }}</span>
								</div>

								<div class="lf_bds_list_2_middle lf_font_size_30"
									v-if="pk.team_o_hole > 0 && pk.team_win_pk == '' && info.addhole[seq]">HAVLED
								</div>
								<div class="lf_bds_list_2_bottom lf_font_size_20"
									v-if="pk.team_o_hole > 0 && pk.team_win_pk == '' && info.addhole[seq]">PLAYOFF
									<span
										:class="pk.team_sub_ok=='1'?'lf_bds_red_F':''">#{{ info.addhole[seq].addholecount }}</span>
								</div>

								<div class="lf_bds_list_2_middle lf_font_size_30"
									v-if="info.player[pk[pk.pk[0]]['pl_id']].mp_sts!='' || info.player[pk[pk.pk[1]]['pl_id']].mp_sts!=''">
								</div>
								<div class="lf_bds_list_2_bottom lf_font_size_20"
									v-if="info.player[pk[pk.pk[0]]['pl_id']].mp_sts!='' || info.player[pk[pk.pk[1]]['pl_id']].mp_sts!=''">
									THRU <span :class="pk.team_sub_ok=='1'?'lf_bds_red_F':''">F</span></div>


								<div class="lf_bds_list_2_middle lf_font_size_30"
									v-if="pk.team_o_hole <= 0 && pk.team_win_pk != '' && !(info.player[pk[pk.pk[0]]['pl_id']].mp_sts!='' || info.player[pk[pk.pk[1]]['pl_id']].mp_sts!='')">
									{{ pk.team_win_up==0?'AS': pk.team_win_up+'UP'}}
								</div>
								<div class="lf_bds_list_2_bottom lf_font_size_20"
									v-if="pk.team_o_hole <= 0 && pk.team_win_pk != '' && !(info.player[pk[pk.pk[0]]['pl_id']].mp_sts!='' || info.player[pk[pk.pk[1]]['pl_id']].mp_sts!='')">
									THRU <span :class="pk.team_sub_ok=='1'?'lf_bds_red_F':''">F</span></div>

								<div class="lf_bds_list_2_middle lf_font_size_30"
									v-if="pk.team_o_hole > 0 && pk.team_win_pk != '' && !info.addhole[seq] && !(info.player[pk[pk.pk[0]]['pl_id']].mp_sts!='' || info.player[pk[pk.pk[1]]['pl_id']].mp_sts!='')">
									{{ pk.team_o_hole==pk.team_all_hole?(pk.team_win_up==0?'HAVLED':pk.team_win_up+'UP'):pk.team_win_up+'&'+(pk.team_all_hole-pk.team_o_hole) }}
								</div>
								<div class="lf_bds_list_2_bottom lf_font_size_20"
									v-if="pk.team_o_hole > 0 && pk.team_win_pk != '' && !info.addhole[seq] && !(info.player[pk[pk.pk[0]]['pl_id']].mp_sts!='' || info.player[pk[pk.pk[1]]['pl_id']].mp_sts!='')">
									THRU <span :class="pk.team_sub_ok=='1'?'lf_bds_red_F':''">F</span></div>

								<div class="lf_bds_list_2_middle lf_font_size_30"
									v-if="pk.team_o_hole > 0 && pk.team_win_pk != '' && info.addhole[seq]">HAVLED
								</div>
								<div class="lf_bds_list_2_bottom lf_font_size_20"
									v-if="pk.team_o_hole > 0 && pk.team_win_pk != '' && info.addhole[seq]">PLAYOFF
									<span
										:class="pk.team_sub_ok=='1'?'lf_bds_red_F':''">#{{ info.addhole[seq].addholecount }}</span>
								</div>
								<!-- <div class="lf_bds_list_2_middle lf_font_size_30" v-if="pk.team_win_pk !=''">VS</div>
							<div class="lf_bds_list_2_bottom lf_font_size_20">PLAYOFF <span class="lf_bds_red_F">#1</span></div> -->

							</div>
							<div class="lf_bds_list_3">
								<div class="lf_bds_list_win lf_font_size_24"
									v-if="pk.team_win_pk!='' && pk.team_win_pk == pk.pk[1]">WIN</div>
								<img class="lf_bds_list_zzs_img" v-if="info.player[pk[pk.pk[1]]['pl_id']].mp_logo!=''"
									:src="info.player[pk[pk.pk[1]]['pl_id']].mp_logo" alt="">
								<div class="lf_font_size_28 lf_bds_list_pl_name">
									{{systemlang=='cn'?info.player[pk[pk.pk[1]]['pl_id']].pl_cn_name:info.player[pk[pk.pk[1]]['pl_id']].pl_en_name}}
								</div>
								<img class="lf_bds_list_country_img"
									v-if="info.player[pk[pk.pk[1]]['pl_id']].pl_country!=''"
									:src="info.player[pk[pk.pk[1]]['pl_id']].pl_country" alt="">
								<img v-if="pk[pk.pk[1]].team_win_up > 0 && pk.team_win_pk==''"
									class="lf_bds_list_ll_img" src="../../assets/images/LLM.png" alt="">
							</div>
						</div>
						<div style="position: relative;" v-if="checkeditem == seq">
							<div class="lf_bds_scroll_list">
								<div class="lf_left_scroll">
									<div class="lf_bds_scroll_list_1 lf_font_size_24">
										<div class="lf_bds_scroll_list_name">
											Hole
										</div>
									</div>
									<div class="lf_bds_scroll_list_2 lf_font_size_24">
										<div class="lf_bds_scroll_list_name">
											Par
										</div>
									</div>
									<div class="lf_bds_scroll_list_3 lf_font_size_24">
										<div class="lf_bds_scroll_list_name">
											<img class="lf_bds_list_country_img"
												v-if="info.player[pk[pk.pk[0]]['pl_id']].pl_country!=''"
												:src="info.player[pk[pk.pk[0]]['pl_id']].pl_country" alt="">
											{{systemlang=='cn'?info.player[pk[pk.pk[0]]['pl_id']].pl_cn_name:info.player[pk[pk.pk[0]]['pl_id']].pl_en_name}}
											<img class="lf_bds_list_zzs_img"
												v-if="info.player[pk[pk.pk[0]]['pl_id']].mp_logo!=''"
												:src="info.player[pk[pk.pk[0]]['pl_id']].mp_logo" alt="">
										</div>
									</div>
									<div class="lf_bds_scroll_list_4 lf_font_size_24">
										<div class="lf_bds_scroll_list_name">
											<img class="lf_bds_list_country_img"
												v-if="info.player[pk[pk.pk[1]]['pl_id']].pl_country!=''"
												:src="info.player[pk[pk.pk[1]]['pl_id']].pl_country" alt="">
											{{systemlang=='cn'?info.player[pk[pk.pk[1]]['pl_id']].pl_cn_name:info.player[pk[pk.pk[1]]['pl_id']].pl_en_name}}
											<img class="lf_bds_list_zzs_img"
												v-if="info.player[pk[pk.pk[1]]['pl_id']].mp_logo!=''"
												:src="info.player[pk[pk.pk[1]]['pl_id']].mp_logo" alt="">
										</div>
									</div>
								</div>
								<div class="lf_right_scroll">
									<div class="lf_bds_scroll_list_1 lf_font_size_24">
										<div class="lf_bds_scroll_list_score" v-for="(h,hindex) in info.hole">
											{{h.ho_id}}
										</div>
										<div class="lf_bds_scroll_list_status">STATUS</div>
									</div>
									<div class="lf_bds_scroll_list_2 lf_font_size_24">
										<div class="lf_bds_scroll_list_score" v-for="(h,hindex) in info.hole">
											{{h.par}}
										</div>
										<div class="lf_bds_scroll_list_status"></div>
									</div>
									<div class="lf_bds_scroll_list_3 lf_font_size_24">
										<div class="lf_bds_scroll_list_score" v-for="(h,hindex) in info.hole">
											<div class="lf_bds_scroll_list_score_60"
												:class="info.pkscore[pk.pk[0]]&&info.pkscore[pk.pk[0]][h.ho_id]&&info.pkscore[pk.pk[0]][h.ho_id].team_win_flag == 'win'?'lf_bds_scroll_list_score_win':''">
												{{info.pkscore[pk.pk[0]]&&info.pkscore[pk.pk[0]][h.ho_id]?info.pkscore[pk.pk[0]][h.ho_id].score:''}}
											</div>
											<div class="lf_bds_scroll_list_score_up"
												v-if="!info.pkscore[pk.pk[0]] || !info.pkscore[pk.pk[1]]  || !info.pkscore[pk.pk[1]][h.ho_id] || !info.pkscore[pk.pk[0]][h.ho_id] || info.pkscore[pk.pk[0]][h.ho_id].team_win_up_roller<0">
												&nbsp;</div>
											<div class="lf_bds_scroll_list_score_up"
												v-else-if="info.pkscore[pk.pk[0]][h.ho_id]&&info.pkscore[pk.pk[0]][h.ho_id].team_win_up_roller=='0'">
												AS</div>
											<div class="lf_bds_scroll_list_score_up"
												v-else-if="info.pkscore[pk.pk[0]][h.ho_id]&&info.pkscore[pk.pk[0]][h.ho_id].team_win_up_roller>'0'">
												{{info.pkscore[pk.pk[0]][h.ho_id].team_win_up_roller+'UP'}}
											</div>

										</div>

										<div class="lf_bds_scroll_list_status lf_font_size_30"
											v-if="pk.team_o_hole <= 0 && pk.team_win_pk == pk.pk[0]">
											{{ pk.team_win_up==0?'AS': pk.team_win_up+'UP'}}
										</div>
										<div class="lf_bds_scroll_list_status lf_font_size_30"
											v-else-if="pk.team_o_hole > 0 && pk.team_win_pk == pk.pk[0] && !info.addhole[seq]">
											{{ pk.team_o_hole==pk.team_all_hole?pk.team_win_up+'UP':pk.team_win_up+'&'+(pk.team_all_hole-pk.team_o_hole) }}
										</div>
										<div class="lf_bds_scroll_list_status lf_font_size_30"
											v-else-if="pk.team_o_hole > 0 && pk.team_win_pk != '' && (info.addhole[seq] || (pk.team_o_hole==pk.team_all_hole && (pk.team_o_hole==pk.team_all_hole && pk.team_win_up== '0')) )">
											AS</div>
										<div class="lf_bds_scroll_list_status lf_font_size_30"
											v-else-if="pk.team_win_pk != ''"></div>

										<div class="lf_bds_scroll_list_status lf_font_size_30"
											v-else-if="pk.team_o_hole > 0 ">
											{{pk[pk.pk[0]].team_win_up >0?pk[pk.pk[0]].team_win_up+'UP':(pk[pk.pk[0]].team_win_up ==0?'AS':'')}}
										</div>
										<div class="lf_bds_scroll_list_status lf_font_size_30" v-else>STATUS</div>
									</div>
									<div class="lf_bds_scroll_list_4 lf_font_size_24">
										<div class="lf_bds_scroll_list_score" v-for="(h,hindex) in info.hole">
											<div class="lf_bds_scroll_list_score_up"
												v-if="!info.pkscore[pk.pk[1]] || !info.pkscore[pk.pk[0]] || !info.pkscore[pk.pk[0]][h.ho_id] || !info.pkscore[pk.pk[1]][h.ho_id] || info.pkscore[pk.pk[1]][h.ho_id].team_win_up_roller<0">
												&nbsp;</div>
											<div class="lf_bds_scroll_list_score_up"
												v-else-if="info.pkscore[pk.pk[1]][h.ho_id]&&info.pkscore[pk.pk[1]][h.ho_id].team_win_up_roller=='0'">
												AS</div>
											<div class="lf_bds_scroll_list_score_up"
												v-else-if="info.pkscore[pk.pk[1]][h.ho_id]&&info.pkscore[pk.pk[1]][h.ho_id].team_win_up_roller>'0'">
												{{info.pkscore[pk.pk[1]][h.ho_id].team_win_up_roller+'UP'}}
											</div>
											<div class="lf_bds_scroll_list_score_60 "
												:class="info.pkscore[pk.pk[1]] && info.pkscore[pk.pk[1]][h.ho_id]&&info.pkscore[pk.pk[1]][h.ho_id].team_win_flag == 'win'?'lf_bds_scroll_list_score_win':''">
												{{info.pkscore[pk.pk[1]]&& info.pkscore[pk.pk[1]][h.ho_id]?info.pkscore[pk.pk[1]][h.ho_id].score:''}}
											</div>
										</div>

										<div class="lf_bds_scroll_list_status lf_font_size_30"
											v-if="pk.team_o_hole <= 0 && pk.team_win_pk == pk.pk[1]">
											{{ pk.team_win_up==0?'AS': pk.team_win_up+'UP'}}
										</div>
										<div class="lf_bds_scroll_list_status lf_font_size_30"
											v-else-if="pk.team_o_hole > 0 && pk.team_win_pk == pk.pk[1] && !info.addhole[seq]">
											{{ pk.team_o_hole==pk.team_all_hole?pk.team_win_up+'UP':pk.team_win_up+'&'+(pk.team_all_hole-pk.team_o_hole) }}
										</div>
										<div class="lf_bds_scroll_list_status lf_font_size_30"
											v-else-if="pk.team_o_hole > 0 && pk.team_win_pk != '' && (info.addhole[seq] || (pk.team_o_hole==pk.team_all_hole && pk.team_win_up== '0'))">
											AS</div>
										<div class="lf_bds_scroll_list_status lf_font_size_30"
											v-else-if="pk.team_win_pk != ''"></div>
										<div class="lf_bds_scroll_list_status lf_font_size_30"
											v-else-if="pk.team_o_hole > 0 ">
											{{pk[pk.pk[1]].team_win_up >0?pk[pk.pk[1]].team_win_up+'UP':(pk[pk.pk[1]].team_win_up ==0?'AS':'')}}
										</div>
										<div class="lf_bds_scroll_list_status lf_font_size_30" v-else>STATUS</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 加洞 -->
						<div v-if="info.addhole[seq] && checkeditem == seq" style="position: relative;">
							<div class="lf_bds_scroll_list lf_bds_scroll_list_add">
								<div class="lf_left_scroll">
									<div class="lf_bds_scroll_list_1 lf_font_size_24">
										<div class="lf_bds_scroll_list_name">
											PLAYOFF
										</div>

									</div>
									<div class="lf_bds_scroll_list_2 lf_font_size_24">
										<div class="lf_bds_scroll_list_name">
											Hole
										</div>

									</div>
									<div class="lf_bds_scroll_list_2 lf_font_size_24">
										<div class="lf_bds_scroll_list_name">
											Par
										</div>

									</div>
									<div class="lf_bds_scroll_list_3 lf_bds_scroll_list_3_add lf_font_size_24">
										<div class="lf_bds_scroll_list_name">
											<img class="lf_bds_list_country_img"
												v-if="info.player[pk[pk.pk[0]]['pl_id']].pl_country!=''"
												:src="info.player[pk[pk.pk[0]]['pl_id']].pl_country" alt="">
											{{systemlang=='cn'?info.player[pk[pk.pk[0]]['pl_id']].pl_cn_name:info.player[pk[pk.pk[0]]['pl_id']].pl_en_name}}
											<img class="lf_bds_list_zzs_img"
												v-if="info.player[pk[pk.pk[0]]['pl_id']].mp_logo!=''"
												:src="info.player[pk[pk.pk[0]]['pl_id']].mp_logo" alt="">
										</div>

									</div>
									<div class="lf_bds_scroll_list_4 lf_bds_scroll_list_4_add lf_font_size_24">
										<div class="lf_bds_scroll_list_name">
											<img class="lf_bds_list_country_img"
												v-if="info.player[pk[pk.pk[1]]['pl_id']].pl_country!=''"
												:src="info.player[pk[pk.pk[1]]['pl_id']].pl_country" alt="">
											{{systemlang=='cn'?info.player[pk[pk.pk[1]]['pl_id']].pl_cn_name:info.player[pk[pk.pk[1]]['pl_id']].pl_en_name}}
											<img class="lf_bds_list_zzs_img"
												v-if="info.player[pk[pk.pk[1]]['pl_id']].mp_logo!=''"
												:src="info.player[pk[pk.pk[1]]['pl_id']].mp_logo" alt="">
										</div>
									</div>
								</div>
								<div class="lf_right_scroll">
									<div class="lf_bds_scroll_list_1 lf_font_size_24">

										<div class="lf_bds_scroll_list_score lf_bds_scroll_list_score_add"
											v-for="(addhole,addholeindex) in info.addhole[seq].addhole">
											{{addhole.index}}
										</div>
										<div class="lf_bds_scroll_list_status">STATUS</div>
									</div>
									<div class="lf_bds_scroll_list_2 lf_font_size_24">

										<div class="lf_bds_scroll_list_score lf_bds_scroll_list_score_add"
											v-for="(addhole,addholeindex) in info.addhole[seq].addhole">
											{{addhole.ho_id}}
										</div>
										<div class="lf_bds_scroll_list_status"></div>
									</div>
									<div class="lf_bds_scroll_list_2 lf_font_size_24">

										<div class="lf_bds_scroll_list_score lf_bds_scroll_list_score_add"
											v-for="(addhole,addholeindex) in info.addhole[seq].addhole">
											{{info.hole[addhole.ho_id].par}}
										</div>
										<div class="lf_bds_scroll_list_status"></div>
									</div>
									<div class="lf_bds_scroll_list_3 lf_bds_scroll_list_3_add lf_font_size_24">

										<div class="lf_bds_scroll_list_score lf_bds_scroll_list_score_add"
											v-for="(addhole,addholeindex) in info.addhole[seq].addhole">
											<div class="lf_bds_scroll_list_score_60 lf_bds_scroll_list_score_59"
												:class="addhole[pk.pk[0]].flag == 'win'?'lf_bds_scroll_list_score_win':''">
												{{addhole[pk.pk[0]].score}}
											</div>

										</div>
										<div class="lf_bds_scroll_list_status lf_bds_scroll_list_status_addHole lf_font_size_24"
											v-if="info.addhole[seq].player[pk[pk.pk[0]]['pl_id']].flag=='win'">WNR
										</div>
										<div class="lf_bds_scroll_list_status lf_bds_scroll_list_status_addHole lf_font_size_24"
											v-if="info.addhole[seq].player[pk[pk.pk[0]]['pl_id']].flag=='lose'">ELM
										</div>
										<div class="lf_bds_scroll_list_status lf_bds_scroll_list_status_addHole lf_font_size_24"
											v-if="info.addhole[seq].player[pk[pk.pk[0]]['pl_id']].flag=='ping'">
										</div>
									</div>
									<div class="lf_bds_scroll_list_4 lf_bds_scroll_list_4_add lf_font_size_24">

										<div class="lf_bds_scroll_list_score lf_bds_scroll_list_score_add"
											v-for="(addhole,addholeindex) in info.addhole[seq].addhole">

											<div class="lf_bds_scroll_list_score_60 lf_bds_scroll_list_score_59  "
												:class="addhole[pk.pk[1]].flag == 'win'?'lf_bds_scroll_list_score_win':''">
												{{addhole[pk.pk[1]].score}}
											</div>
										</div>
										<div class="lf_bds_scroll_list_status lf_bds_scroll_list_status_addHole lf_font_size_24"
											v-if="info.addhole[seq].player[pk[pk.pk[1]]['pl_id']].flag=='win'">WNR
										</div>
										<div class="lf_bds_scroll_list_status lf_bds_scroll_list_status_addHole lf_font_size_24"
											v-if="info.addhole[seq].player[pk[pk.pk[1]]['pl_id']].flag=='lose'">ELM
										</div>
										<div class="lf_bds_scroll_list_status lf_bds_scroll_list_status_addHole lf_font_size_24"
											v-if="info.addhole[seq].player[pk[pk.pk[1]]['pl_id']].flag=='ping'">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
		</el-col>
	</el-row>

	<Tips @aliclickshowhide="aliclickshowhide" :show="show"></Tips>
	<!-- <Ad></Ad> -->
</template>

<script>
	import {
		result
	} from 'lodash';
	import html2Canvas from '../../plugs/htmlToPDF';
	import Tips from '../Tips.vue';
	import {
		getSystemLang,
		getLangAll
	} from '../../lang';
	import axios from '../../axios/request';
	import Ad1 from "../Ad1.vue";
	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				banner: {},
				info: {},
				pmlist: [],
				plname: '',
				checkplidv: '',
				radio: '',
				cradio: '',
				value: '',
				cj: 'cj',
				likepl: [],
				zctype: 'zc', //点击区域
				tmflag: '0',
				OutInFlag: true,
				WebModel: true,
				checkeditem: ''

			}
		},
		name: 'Player',
		props: {
			msg: String,
			mt_id: String,
			flag: String,
			round_id: String
		},
		components: {
			Ad1,
			Tips
		},
		created() {
			this.getData();
			//  this.$cookies.remove('likepl')

			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			this.cj = this.flag;
			if (this.round_id > 0) {
				this.radio = this.round_id;
			}
			var deviceWidth = document.documentElement.clientWidth || window.innerWidth
			if (deviceWidth >= 768) {
				this.WebModel = true;
			} else {
				this.WebModel = false;
			}

		},
		watch: {
			radio: function(newValue, oldValue) {
				if (newValue != oldValue) {
					this.radio = newValue;
					this.getData1();
				}

			},
			cj: function(newValue, oldValue) {

				// if(newValue != oldValue)
				// {
				// 	console.log('show',this.radio);
				// 	if(newValue == 'cj')
				// 	{
				// 		this.$emit('goInfo','cj','',this.raido);
				// 	}
				// 	else
				// 	{
				// 		this.$emit('goInfo','cj','tm',this.raido);
				// 	}


				// }

			},
		},
		methods: {
			checkItemClick(e) {
				console.log(e);
				if (e == this.checkeditem) {
					this.checkeditem = '';
				} else {
					this.checkeditem = e;
				}
			},
			getData() {
				//获取banner
				axios('matchs/match/matchtj.json', {
						otp: 'playerScoreSingle',
						mt_id: this.mt_id,
						round_id: this.radio
					})
					.then((res) => {
						let data = res.data.data;
						this.info = data;
						if (this.radio < 1) {
							this.radio = parseInt(data.info.mt_round);
						}

						this.cradio = parseInt(data.info.mt_round_num);
						// console.log(this.radio,'radio');

					});
			},
			checkplid(pl_id, stype) {
				if (stype == 'zc') {
					this.zctype = stype;
				} else {
					this.zctype = stype;
				}

				if (this.checkplidv != pl_id) {
					this.checkplidv = pl_id;
				} else {
					this.checkplidv = '';
				}

			},
			getClass(pl_id) {
				//lf_course_name_data_con_select lf_course_name_data_con
				if (this.checkplidv == pl_id) {
					return 'lf_course_name_data_con_select lf_course_name_data_con';
				}
				return 'lf_course_name_data_con';

			},
			getData1() {
				//获取banner
				axios('matchs/match/matchtj.json', {
						otp: 'playerScoreSingle',
						mt_id: this.mt_id,
						round_id: this.radio
					})
					.then((res) => {
						let data = res.data.data;
						this.info = data;

						// console.log(this.radio,'radio');

					});
			},
			likes(pl_id) {
				console.log('1', pl_id);
				console.log(JSON.stringify(this.likepl));

				if (this.likepl.indexOf(pl_id) == -1) {
					this.likepl.push(pl_id);
					console.log('2', JSON.stringify(this.likepl));
				} else {

					this.likepl.splice(this.likepl.indexOf(pl_id), 1);
					console.log('3', pl_id);
					console.log(JSON.stringify(this.likepl));
				}
				console.log(JSON.stringify(this.likepl));
				this.$cookies.set('likepl', JSON.stringify(this.likepl), -1);
			},
			jsPar($str) {
				if (!(this.info && this.info.hole)) {
					return '-';
				}
				var rsult = 0;
				if ($str == 'OUT') {
					let hole = this.info.hole;
					for (let i in hole) {
						if (hole[i].mh_par && hole[i].mh_id <= 9) {
							rsult += parseInt(hole[i].mh_par);
						}
					}
				}

				if ($str == 'IN') {
					let hole = this.info.hole;
					for (let i in hole) {
						if (hole[i].mh_par && hole[i].mh_id > 9) {
							rsult += parseInt(hole[i].mh_par);
						}
					}
				}

				if (rsult == 0) {
					return '--';
				} else {
					return rsult;
				}

			},

			jsParScore($pl, $rid, $str) {
				if (!(this.info && this.info.cj && this.info.cj[$pl] && this.info.cj[$pl][$rid])) {
					return '-';
				}

				var rsult = 0;
				if ($str == 'OUT') {
					let hole = this.info.cj[$pl][$rid];
					for (let i in hole) {
						if (hole[i] && i <= 9) {
							rsult += parseInt(hole[i]);
						}
					}
				}

				if ($str == 'IN') {
					let hole = this.info.cj[$pl][$rid];
					for (let i in hole) {
						if (hole[i] && i > 9) {
							rsult += parseInt(hole[i]);
						}
					}
				}

				if (rsult == 0) {
					return '--';
				} else {
					return rsult;
				}
			},
			aliclickshowhide($s) {
				if ($s == '1') {
					this.show = '1';
				} else {
					this.show = '0';
				}
			},
			is_weixn() {

				var ua = navigator.userAgent.toLowerCase();
				if (ua.match(/MicroMessenger/i) == "micromessenger") {

					return true;

				}
				return false;

			},
			DouwnExcel() {
				if (this.is_weixn()) {
					this.show = '1';
					return false;
				}

				// console.log(this.$refs.pdf);
				let lang = getSystemLang();
				if (lang == 'cn') {
					lang = 'zh-cn';
				} else if (lang == 'en') {
					lang = 'en-us';
				} else {
					lang = 'zh-cn';
				}
				let domain = window.location.protocol + "//" + window.location.host;
				//技术统计
				// /public/index.php/core/en-us/matchs/match/matchtj.json?otp=playerScoreExport&mt_id=7964&round_id=2
				let url = 'http://www.clpga.org/public/index.php/core/' + lang +
					'/matchs/match/matchtj.json?otp=playerScoreExport&mt_id=' + this.info.info.ID + '&round_id=' + this
					.radio;

				// let url = domain+'/index.php/Home/Match/ExpResult/year/'+this.info.info.year+'/match_id/'+this.info.info.ID+'/lang/'+lang+'/rid/'+this.radio+'/mt_round/'+this.info.info.mt_round_num+'';
				window.open(url, '_blank');
				return false;
				// if(this.pdfDonw)
				// {
				// 	alert('下载中.....');
				// 	return false;
				// }
				// html2Canvas('比赛分数','pdf');
				// alert('下载中.....');
				// this.pdfDonw = true;
				// setTimeout(()=>{
				// 	this.pdfDonw = false;
				// },30000);
			},
			Douwn() {
				if (this.is_weixn()) {
					this.show = '1';
					return false;
				}

				// console.log(this.$refs.pdf);
				let lang = getSystemLang();
				if (lang == 'cn') {
					lang = 'zh-cn';
				} else if (lang == 'en') {
					lang = 'en-us';
				} else {
					lang = 'zh-cn';
				}
				let domain = window.location.protocol + "//" + window.location.host;
				//技术统计
				// let cuurl = domain+'/index.php/Home/Match/expPersonalAllInfoPdf/match_id/'+this.info.info.ID+'/lang/'+lang+'/rid/'+this.radio;
				let url = domain + '/index.php/Home/Match/ExpResult/year/' + this.info.info.year + '/match_id/' + this.info
					.info.ID + '/lang/' + lang + '/rid/' + this.radio + '/mt_round/' + this.info.info.mt_round_num + '';
				window.open(url, '_blank');
				return false;
				// if(this.pdfDonw)
				// {
				// 	alert('下载中.....');
				// 	return false;
				// }
				// html2Canvas('比赛分数','pdf');
				// alert('下载中.....');
				// this.pdfDonw = true;
				// setTimeout(()=>{
				// 	this.pdfDonw = false;
				// },30000);
			},
			OutInChange(a) {
				if (a) {
					this.OutInFlag = false
				} else {
					this.OutInFlag = true
				}
				console.log(this.OutInFlag)
			}
		}
	}
</script>

<style scoped="scoped">
	.lf_font_size_20 {
		font-size: 0.2rem;
	}

	.lf_font_size_24 {
		font-size: 0.24rem;
	}

	.lf_font_size_26 {
		font-size: 0.26rem;
	}

	.lf_font_size_28 {
		font-size: 0.28rem;
	}

	.lf_font_size_30 {
		font-size: 0.3rem;
	}


	.lf_group_tit_left {
		
		display: flex;
		align-items: center;
		margin-top: 0.2rem;

	}


	

	.lf_match_info_box_r {
		display: flex;
		justify-content: flex-end;
	}

	.lf_course_name_data_box {
		width: 18.5rem;
		background-color: #FAFAFA;
		padding: 0.2rem 0.3rem 0.3rem;
		margin-bottom: 0.4rem;
		margin-top: 0.3rem;
	}

	.lf_jinji3216 {
		width: 2.07rem;
		height: 0.6rem;
		background: inherit;
		background-color: rgba(255, 241, 242, 1);
		border: none;
		border-radius: 0.1rem;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		color: #A30014;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 0.1rem;
	}

	.lf_bds_list {
		height: 1rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 0.1rem;
	}

	.lf_bds_list .lf_bds_list_1 {
		width: 7.78rem;
		height: 100%;
		display: flex;
		align-items: center;
		background-color: rgba(255, 255, 255, 1);
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(215, 215, 215, 1);
		box-shadow: none;
		position: relative;
	}

	.lf_bds_list .lf_bds_list_2 {
		width: 2.26rem;
		height: 100%;
		display: flex;
		align-items: center;
		background-color: rgba(255, 255, 255, 1);
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(215, 215, 215, 1);
		box-shadow: none;
		position: relative;
		justify-content: center;
		color: #000;
	}

	.lf_bds_list .lf_bds_list_3 {
		width: 7.78rem;
		height: 100%;
		display: flex;
		align-items: center;
		background-color: rgba(255, 255, 255, 1);
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(215, 215, 215, 1);
		box-shadow: none;
		justify-content: flex-end;
		position: relative;
	}

	.lf_bds_list_country_img {
		width: 0.32rem;
		height: 0.26rem;
		margin: 0 0.2rem;
	}

	.lf_bds_list_zzs_img {
		max-width: 0.73rem;
		max-height: 0.25rem;
		margin: 0 0.2rem;
	}

	.lf_bds_list_pl_name {
		font-family: 'Arial Normal', 'Arial';
		font-weight: 400;
		color: #333645;
	}

	.lf_bds_list_2_bottom {
		position: absolute;
		bottom: 0;
	}

	.lf_bds_list_win {
		width: 1rem;
		height: 0.35rem;
		background: inherit;
		background-color: rgba(182, 8, 29, 1);
		border: none;
		border-radius: 0.02rem;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_bds_list_1 .lf_bds_list_win {
		position: absolute;
		right: 0;
	}

	.lf_bds_list_3 .lf_bds_list_win {
		position: absolute;
		left: 0;
	}

	.lf_bds_scroll_list {
		display: flex;
		align-items: center;
	}

	.lf_bds_scroll_list div {
		display: flex;
		align-items: center;
	}

	.lf_bds_scroll_list_1 {
		height: 0.4rem;
		background-color: #A6071A;
		color: #fff;
	}

	.lf_bds_scroll_list_2 {
		height: 0.4rem;
		background-color: #F0F3F6;
		color: #000;
	}

	.lf_bds_scroll_list_3 {
		height: 1rem;
		background-color: #fff;
		color: #000;
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		font-weight: 700;
	}

	.lf_bds_scroll_list_4 {
		height: 1rem;
		background-color: #fff;
		border-top: 1px solid #d7d7d7;
		color: #000;
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		font-weight: 700;
	}

	.lf_bds_scroll_list_score {
		flex-grow: 1;
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 0.47rem;
	}

	.lf_bds_scroll_list_3 .lf_bds_scroll_list_score {
		display: flex;
		flex-direction: column;
	}

	.lf_bds_scroll_list_4 .lf_bds_scroll_list_score {
		display: flex;
		flex-direction: column;
	}

	.lf_bds_scroll_list_3 .lf_bds_scroll_list_score div {
		display: flex;
		align-items: center;
		justify-content: center;

	}

	.lf_bds_scroll_list_4 .lf_bds_scroll_list_score div {
		display: flex;
		align-items: center;
		justify-content: center;

	}

	.lf_bds_scroll_list_1 .lf_bds_scroll_list_status {

		font-style: normal;
	}

	.lf_bds_scroll_list_name {
		width: 2.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}

	.lf_bds_scroll_list_status {
		width: 0.96rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		font-style: italic;
		color: #333;
	}
	.lf_bds_scroll_list_1 .lf_bds_scroll_list_status{
		color: #fff;
	}
	.lf_bds_scroll_list_score_60 {
		background-color: #FCF5F6;
		border-top: 1px solid #fff;
		border-right: 1px solid #fff;
		height: 0.6rem;
		width: 100%;
	}

	.lf_bds_scroll_list_score_59 {
		height: 0.59rem;
		color: #333;
	}

	.lf_bds_scroll_list_score_win {
		background-color: #E99BB1;
		border-top: 1px solid #fff;
		border-right: 1px solid #fff;
	}

	.lf_bds_scroll_list_score_up {
		border-top: 1px solid #fff;
		border-right: 1px solid #fff;
		width: 100%;
		height: 0.38rem;
		color: #333;
		font-family: '微软雅黑';
		font-weight: 400;
		color: #666;
	}

	.lf_bds_list_2_middle {
		font-style: italic;
	}

	.lf_bds_scroll_list_score_add {
		width: 0.5rem;
		flex-grow: unset !important;
		flex-shrink: 1 !important;
	}

	.lf_bds_scroll_list_3_add,
	.lf_bds_scroll_list_4_add {
		height: 0.6rem !important;
	}

	.lf_bds_scroll_list_add {
		width: fit-content;
	}

	.lf_left_scroll {
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 2.5rem;
		flex-shrink: 0;
		position: absolute;
		left: 0;
		z-index: 11;
	}

	.lf_right_scroll {
		display: flex;
		align-items: center;
		flex-direction: column;
		flex-grow: 1;
		margin-left: 2.5rem;
	}

	.lf_right_scroll .lf_bds_scroll_list_1,
	.lf_right_scroll .lf_bds_scroll_list_2,
	.lf_right_scroll .lf_bds_scroll_list_3,
	.lf_right_scroll .lf_bds_scroll_list_4 {
		flex-grow: 1;
		width: 100%;
	}

	.lf_bds_scroll_list {
		border-bottom: 0.1rem solid #FAFAFA;
	}

	.lf_bds_red_F {
		color: #B6081D;
	}

	.lf_pink .lf_bds_list_1,
	.lf_pink .lf_bds_list_2,
	.lf_pink .lf_bds_list_3 {
		background-color: rgba(255, 241, 242, 1);
	}

	.lf_bds_scroll_list_status_addHole {
		font-style: normal;
		font-weight: 400;
		color: #333;
	}

	.lf_bds_list_ll_img {
		position: absolute;
		right: 0;
		top: 0;
		width: 0.35rem;
		height: 0.35rem;
	}

	.lf_bds_list_3 .lf_bds_list_ll_img {
		position: absolute;
		right: unset;
		left: 0;
		top: 0;
		width: 0.35rem;
		height: 0.35rem;
		transform: rotate(270deg);
	}


	.lf_man_box .lf_bds_list_win {
		background-color: #3080DD;
	}

	.lf_man_box .lf_bds_scroll_list_1 {
		background-color: #3080DD;
	}

	.lf_man_box .lf_bds_scroll_list_score_60 {
		background-color: #f3f4fc;
	}

	.lf_man_box .lf_bds_scroll_list_score_win {
		background-color: #B0E1E7;
	}

	.lf_man_box .lf_pink .lf_bds_list_1,
	.lf_man_box .lf_pink .lf_bds_list_2,
	.lf_man_box .lf_pink .lf_bds_list_3 {
		background-color: #f3f4fc;
	}

	.lf_man_box .lf_bds_red_F {
		color: #3080DD;
	}

	.lf_man_box_jinji3216 {
		background-color: #F3F4FC;
		color: #3080DD;
		margin-top: 0.2rem;
	}

	@media screen and (max-width: 768px) {
		.lf_font_size_20 {
			font-size: 0.20rem;
		}

		.lf_font_size_24 {
			font-size: 0.24rem;
		}

		.lf_font_size_26 {
			font-size: 0.26rem;
		}

		.lf_font_size_28 {
			font-size: 0.28rem;
		}

		.lf_font_size_30 {
			font-size: 0.3rem;
		}

		.lf_course_name_data_box {
			width: 7.5rem;
			padding: 0.1rem 0;
		}

		.lf_bds_scroll_list {
			width: 100%;
			overflow-x: scroll;
		}

		.lf_bds_list_country_img {
			width: 0.32rem;
			height: 0.26rem;
			margin: 0 0.1rem;
		}

		.lf_bds_list_zzs_img {
			max-width: 0.73rem;
			max-height: 0.25rem;
			margin: 0 0.2rem;
			position: absolute;
			bottom: 0;

		}

		.lf_bds_list_1 {
			width: 3.09rem !important;
		}

		.lf_bds_list_2 {
			width: 1.32rem !important;
		}

		.lf_bds_list_3 {
			width: 3.09rem !important;
		}

		.lf_bds_list_1 .lf_bds_list_zzs_img {
			left: 0.52rem;
			margin: 0 0;
		}

		.lf_bds_list_3 .lf_bds_list_zzs_img {
			right: 0.52rem;
			margin: 0 0;
		}

		.lf_bds_list {
			height: 1rem;
		}

		.lf_bds_scroll_list_status {
			width: 1.3rem;
		}

		.lf_bds_scroll_list_score {
			width: 0.7rem;
		}

		.lf_match_info_box {
			margin-left: 0.3rem;
		}

		.lf_jinji3216 {
			margin-left: 0.3rem;
		}
		.lf_group_tit_left {
			flex-direction: column;
			margin: 0.1rem 0;
		}
		
	}
</style>