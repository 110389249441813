<template>
	<Btn cd="ph"></Btn>
	<div class="select-back" v-if="lettershow" @click.stop="openletter"></div>

	<div class="lf_footer1">
		<el-row>
			<el-col :xs="24" :sm="24" :md="24">
				<div class="lf_footer1_tit_box">
					<div class="lf_foot_tit_text1">{{systemlang=='cn'?'球员/数据':'Players & Stats'}}</div>
					<div class="lf_foot_tit_line"></div>
					<div class="lf_foot_tit_text2">{{systemlang=='cn'?'Players & Stats':'球员/数据'}}</div>
				</div>
			</el-col>

		</el-row>
	</div>

	<div class="head">
		<div class="head_top">
			<div class="searchimg">
				<img src="../../assets/images/u6324.png" />
			</div>
			<div class="searchbox" style="position: relative;">
				<!-- 	<el-input placeholder="请输入内容" v-model="input" clearable class="searchinput">
				</el-input> -->
				<input v-model="searchkey" placeholder="" @keyup.enter="search" type="text" />
				<img @click.stop="searchM()" v-if="searchkey!=''" style="width: 0.3rem;position: absolute;right: 1.6rem;top: 0.1rem;z-index: 9;" src="../../assets/images/u21104.png" alt="" />
				<div @click.stop="search" class="searchbutton">

					{{langs.psearch_search}} <!--查找-->
				</div>
			</div>
			<div class="search hand">
				<span @click.stop="openletter" class="kindbox">
					{{leter==''?langs.psearch_seaz:leter}} <!--筛选 A~Z-->
				</span>
				<img @click.stop="openletter" src="../../assets/images/u182.png" />

				<div v-if="lettershow" class="select-right into">
					<div class="select_title"  @click.stop="select('')">{{langs.psearch_seaz}}</div>
					<div class="select_socl">
						<div @click.stop="select(wk)" :class="leter == wk?'select_item_hover':''" class="select_item "
							v-for="wk in w">
							{{wk}}
							<div v-if="leter == wk && !isMobile" @click.stop="select('')" style="z-index:99999999" class="close_search">×</div>
						</div>

					</div>
				</div>
			</div>
		</div>

		<div class="searchword">{{langs.psearch_contentdesc}} <!--可输入 球员全部/部分中文或英文名字、世界积分ID、国家/地区的中/英文--></div>
	</div>
	<div v-if="type!='l'" class="headwordbox">
		<!-- 搜索结果 杜 相关内容 2条 -->
		<span class="country" v-if="type == 'c'">{{typevalue}}{{pages&&pages.count?'('+pages.count+')':'(0)'}}</span>
		<!-- 搜索结果 杜 相关内容 2条 -->
		<span class="country" v-if="(type != 'c' && type !='l') && systemlang=='cn'">搜索结果 {{searchkey1}} 相关内容
			{{pages&&pages.count?'('+pages.count+')':'(0)'}}条</span>
		<span class="country"
			v-if="(type != 'c' && type !='l') && systemlang=='en'">There are {{pages&&pages.count?'('+pages.count+')':'(0)'}}
			results related to "{{searchkey1}}"</span>
	</div>


	<div v-if="show=='1' && data.length>0 && type !='l'" class="newsbox">
		<div class="news" v-for="item in data">

			<div class="newsimg">
				<img :src="item.country_img" alt="" />
			</div>
			<router-link target="_blank" :to="{path:'/playerInfo',query:{pl_id:item.pl_id,lang:systemlang}}">
				<div class="newstitle hoverUnderLine">
					{{systemlang=='cn'?item.pl_cn_name:item.pl_name}} 
				</div>
				<div class="newsinfo hoverUnderLine">
					{{systemlang!='cn'?item.pl_cn_name:item.pl_name}} {{item.pl_type == 'AMA'?" (A)":''}} 
				</div>
			</router-link>
			
				<div class="headlogo">
					<banner heightNew="0.5rem" :type="2" datatype="1" :dataurl="item.zhanzhu"></banner>
				</div>
			

			<!-- <div class="headlogo">
				<img :src="item.country_img" alt="" />
			</div> -->
		</div>
		<div @click="loadmore" v-if="pages&&pages.next>0 && type !='l'" class="newsmore">{{langs.psearch_more}}
			<!--点击加载更多-->
		</div>
	</div>



	<!-- L字母搜索内容 -->
	<div v-for="(zm,indexss) in zmlist">
		<div class="headwordbox" v-if='type =="l"'>
			<span class="country">{{zm.zm}}({{zm.count}})</span>
		</div>
		<div v-if="show=='1' && data.length>0 && type =='l'" class="newsbox">
			<template v-for="item in data">
				<div class="news" v-if="item.szm == zm.zm">
					<div class="newsimg">
						<img :src="item.country_img" alt="" />
					</div>
					<router-link target="_blank" class="lf_a_flex" :to="{path:'/playerInfo',query:{pl_id:item.pl_id,lang:systemlang}}">
						<div class="newstitle hoverUnderLine">
							{{systemlang=='cn'?item.pl_cn_name:item.pl_name}}
						</div>
						<div class="newsinfo hoverUnderLine">
							{{systemlang!='cn'?item.pl_cn_name:item.pl_name}}{{item.pl_type == 'AMA'?" (A)":''}} 
						</div>
					</router-link>
					
					
						<div class="headlogo">
							<banner heightNew="0.5rem" :type="2" datatype="1" :dataurl="item.zhanzhu"></banner>
						</div>
					
				</div>
			</template>
		</div>
	</div>

	<pageNew @clickPage="go" v-if=" type =='l'" :pages="pages"></pageNew>
	<div v-if="show=='1'&&data.length <=0">
		<empty></empty>
	</div>

	<Footer></Footer>
	<Footer1></Footer1>
</template>

<script>
	// @ is an alias to /src
	import empty from "../../components/empty.vue";
	import pageNew from "../../components/page.vue";
	import navs from "../../components/nav.vue";
	import Btn from "../../components/Btn.vue";
	import Footer from "../../components/Footer.vue";
	import Footer1 from "../../components/Footer1.vue";
	import axios from '../../axios/request';
	import banner from "../../components/banner.vue";
	import {
		getSystemLang,
		getLangAll
	} from "../../lang";
	//这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'




	//自动轮播的配置
	export default {
		data() {
			return {
				input: '',
				langs: '',
				systemlang: 'cn',
				searchkey: '',
				searchkey1: '',
				leter: '', //球员收字母
				page: 1,
				w: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
					'U', 'V', 'W', 'X', 'Y', 'Z'
				],
				data: [],
				show: false,
				type: '', //球员搜索类型  c:国家 l:字母 n:姓名 w:世界积分ID
				typevalue: '',
				pages: {},
				lettershow: false,
				lettershowmobile: false,
				zmlist: [],
				isMobile:false,
				//xhs:巡回赛新闻 match:赛站新闻
			}
		},
		props: {

		},
		watch: {
			lettershow:function(newVal,oldVal){
				var width = document.body.clientWidth;
				if(width<=750 && newVal == true){
				document.body.style.overflow = 'hidden'
				}else{
				document.body.style.overflow = 'unset'
				}
				
			},

		},
		name: 'Home',
		components: {
			navs,
			Btn,
			Footer,
			Footer1,
			empty,
			pageNew,
			banner
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			let param = this.$route.query;
			if (param.leter) {
				this.leter = param.leter;
			}
			if (param.searchkey) {
				this.searchkey = param.searchkey;
				
			}
			this.search();

			document.body.addEventListener('click', (e) => {
			
				this.lettershow = false;
			});

			var width = document.body.clientWidth;
				if(width<=750){
				this.isMobile = true;
				}



		},
		setup() {

		},
		methods: {
			go(page) {
				this.getData(page, this.searchkey, (res) => {
					this.data = res.data;
					this.pages = res.page;
					this.show = true;
					this.zmlist = res.zmlist;
				});
			},
			openletter() {
				var width = document.body.clientWidth;
				
				this.lettershow = !this.lettershow
				if(this.lettershow==true && width<=750){
					document.body.style.overflow = 'hidden'
				}else{
					document.body.style.overflow = 'unset'
				}
				
				
				this.$nextTick(() => {

					const parent = document.querySelector(`.select_item_hover`).offsetTop;


					document.querySelector(`.select_socl`).scrollTo(0, parent - 100)
				});

			},
			select: function(k) {

				let keys = this.searchkey;
				// if (keys == '' && k == '') {
				// 	alert(this.systemlang == 'cn' ? '请输入内容' : "Please enter content");
				// 	return false;
				// }

				this.leter = k;
				if (keys == '') {
					this.type = 'l';
				}
				// if (this.type == 'l') {
				// 	this.searchkey = k;
				// }
				// if (!this.searchkey) {
				// 	this.searchkey = k;
				// }
				this.searchkey = '';
				this.search();
				this.lettershow = !this.lettershow;

			},
			searchM:function()
			{
				this.searchkey = '';
				this.search();

			},
			search: function() {
			
				let keys = this.searchkey;
				// if (keys == '') {
				// 	alert(this.systemlang == 'cn' ? '请输入内容' : "Please enter content");
				// 	return false;
				// }
				if (keys == '') {
					this.type = 'l';
				}
				this.getData('1', this.searchkey, (res) => {
					this.data = res.data;
					this.pages = res.page;
					this.type = res.type ? res.type : '';
					this.typevalue = res.value ? res.value : '';
					this.searchkey1 = this.searchkey;
					this.zmlist = res.zmlist;
					this.show = true;
				});

				let param = this.$route.query;
				param.leter = this.leter;
				param.searchkey = this.searchkey;
				param.page = this.page;

				let routeUrl = this.$router.resolve({
					query: param
				});
				window.history.replaceState(null, '', routeUrl.href);
				

			},
			loadmore: function() {
				if (this.pages.next > 0) {
					this.getData(this.pages.next, this.searchkey, (res) => {
						this.pages = res.page;
						let data = this.data;
						for (let i in res.data) {
							data.push(res.data[i]);
						}
						this.data = data;
						this.show = true;
					});
				}

			},
			getData(page, keys, callback) {
				axios('/player/player/list.json', {
						page: page,
						keys: this.searchkey,
						leter: this.leter
					})
					.then((res) => {
						let data = res.data;
						if (!data.page || data.page.count <= 0) {
							callback && callback(data);
						} else {
							callback && callback(data);
						}

					});
			},
		}

	}
</script>


<style scoped="scoped">
	.newsbox {
		width: 18.5rem;
		overflow: hidden;
		margin-left: 0.4rem;
	}

	.news {
		width: 18rem;
		height: 1.2rem;
		margin-left: 0.25rem;
		float: left;
		border-bottom: 0.01rem solid #DBDBDB;
	}

	.newsmore {
		width: 18.5rem;
		height: 0.6rem;
		line-height: 0.6rem;
		text-align: center;
		background-color: #FAFAFA;
		float: left;
		font-size: 0.24rem;
		color: #666666;
		margin-top: 0.2rem;
	}
.newsmore:hover{
	cursor: pointer;
}
	.newsimg {
		width: 0.36rem;
		height: 0.3rem;
		margin-top: 0.3rem;
		float: left;
		margin-left: 0.7rem;
		margin-top: 0.45rem;
	}

	.newsimg img {
		width: 0.34rem;
	}


	.newstitle {
		float: left;
		margin-left: 0.3rem;
		height: 1.2rem;
		line-height: 1.2rem;
		font-size: 0.28rem;
		color: #000000;

	}

	.newsinfo {
		float: left;
		height: 1.2rem;
		line-height: 1.2rem;
		font-size: 0.26rem;
		color: #000000;
		margin-left: 0.3rem;
	}

	.country {
		font-size: 0.3rem;
		color: #C0153E;
		font-weight: bold;

	}

	.bold {
		font-weight: bold;
	}




	.headlogo {
		width: 1.5rem;
		height: 0.5rem;
		float: right;
		margin-top: 0.3rem;
	}

	.headlogo img {
		width: 1.5rem;
		height: 0.5rem;
	}
	.page_foot{
		cursor: pointer;
	}

	@media screen and (max-width: 768px) {



		.headlogo {
			width: 1.5rem;
			margin-top: 0;
			float: right;
		}

		.newsbox {
			width: 7.5rem;
			overflow: hidden;
			margin-left: 0rem;
		}

		.news {
			width: 6.88rem;
			margin-left: 0.3rem;
			display: flex;
			align-items: center;
		}
.news a{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
		.newsmore {
			width: 6.8rem;
			margin: 0.2rem 0 0.2rem 0.35rem;
		}
		.newsimg{
			margin-left: 0.9rem;
			margin-top: unset;
		}
		.lf_a_flex{
			display: flex;
			align-items: center;
		}
		.newstitle{
			line-height: normal;
			height: auto;
		}
		.newsinfo{
			line-height: normal;
			height: auto;
		}

	}
</style>