<template>	
	<el-row id="pdf"  type="flex" justify="center">
		<el-col :xs="24" :sm="24" :md="24">
			<div class="lf_match_info_box">
				<div class="d_title">{{systemlang=='cn'?'接送机预定':'Shuttle Booking'}} | <span>{{systemlang!='cn'?'接送机预定':'Shuttle Booking'}}</span></div>
			</div>
			<el-row type="flex" justify="center">
				<el-col :xs="24" :sm="24" :md="24">
					<div class="lf_tab_tit_box">
						<div class="lf_tab_little_box">   
							<div @click="goFlag('all')" :class="bFlag=='all'?'lf_tab_box_select':''">{{langs.PickupService}}</div>
							<div @click="goFlag('wsh')" :class="bFlag=='wsh'?'lf_tab_box_select':''">{{langs.DropoffService}}</div>       
						</div>
					</div>
				</el-col>
			</el-row>
			<div class="lf_course_name_data_box">
				<div class="lf_course_name_data_tit">
					<div class="lf_2">{{langs.user_jsj_time}}</div>
					<div class="lf_2">{{langs.user_jsj_match}}</div>
					<div class="lf_3">{{langs.user_jsj_amount}}</div>
					<div class="lf_4">{{langs.StatusofOrder}}</div>
					<div class="lf_9">{{langs.Operation}}</div>
				</div>
				<template v-for="(b,index) in list">
				<div class="lf_course_name_data_con" :id="b.v_oid">
					<div v-if="b.order_time != '1'" class="lf_2">{{b.feiji.length > 0 ? b.feiji[0].pickuptime : '-'}}</div>
					<div v-if="b.order_time == '1'" class="lf_2">{{b.order_d}}</div>
					<div class="lf_2">{{b.v_matchname}}({{b.ColumnStart}})</div>
					<div class="lf_3">{{b.v_amount}}</div>
					<div class="lf_lf_45">{{b.o_sts_d_title}}</div>
					<div class="lf_9">
						<text @click="showJJ('pickup',index)" v-if="b.feiji_ok && b.feiji_ok =='1' && bFlag == 'all' && b.order_ok!='1'">{{langs.Submit}}</text>
						<text @click="showJJ('giveaway',index)" v-if="b.feiji_ok && b.feiji_ok =='1'  && bFlag == 'wsh' && b.order_ok!='1'">{{langs.Submit}}</text>
						<text v-if="b.order_ok=='1'">{{b.order_d}}</text>
					</div>
				</div>
				
				
				
				</template>
			</div>
			<div class="block lf_pageC_change">
				<!-- <el-pagination
			     @current-change = "changePage"
			      v-model:current-page="pagevalue"
			      :page-size="page.limit"
				  :background="true"
				  :pager-count="5"
				  :hide-on-single-page=true
			      layout="prev, pager, next, jumper"
			      :total="page.count">
			    </el-pagination> -->
				<pageNew @clickPage="changePage" v-if="page.count>0" :pages="page"></pageNew>
			</div>
		</el-col>
	</el-row>
	<div class="lf_alert_box" v-if="jijishowFlag">
		<div class="lf_alert_con_box">
			<img class="lf_close_img" src="../../assets/images/close.png" @click="closejj" alt="">
			<div class="lf_alert_tit"  v-if="bFlag == 'all'">{{langs.PickupInfo}}</div>
			<div class="lf_alert_tit"  v-if="bFlag == 'wsh'">{{langs.DropoffInfo}}</div>
			<div class="lf_alert_con">
				<template v-if="list[checkindex].feiji_ok" v-for="f in list[checkindex].feiji">
				<el-radio-group v-model="jijiID">
					<el-radio  :label="f.id">{{f.pickuptime}}</el-radio>
				</el-radio-group>
				</template>

				<el-radio-group v-model="jijiID">
					<el-radio v-if="bFlag == 'all'" label="0">{{langs.NoneedforPickup}}</el-radio>
					<el-radio v-if="bFlag == 'wsh'" label="0">{{langs.NoneedforDropoff}}</el-radio>
				</el-radio-group>
			</div>
			<div class="lf_alert_btn">
				<div @click="closejj" class="lf_alert_btn_c">{{langs.Cancel}}</div>
				<div @click="sumitjj" class="lf_alert_btn_s">{{langs.Confirm}}</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '../../axios/request';
import axiosx from '../../axios/postx';
import pageNew from "../../components/page.vue";
 import {
        getSystemLang,
        getLangAll
    } from '../../lang';
    export default {
		data() {
		    return {
		        langs: '',
		        systemlang: 'cn',
                page:{
					limit:5,
					page:1,
					allpage:0,
					count:0,
					start:0
				},
				pagevalue:1,
				bFlag:'all', // all:全部订单 wsh:等待审核 wplay:等待支付 ok:已完成
				list:[],
				showDv:0,
				showDvs:false,
				timeer:0,
				jijiID:'0',
				jijishowFlag:false,
				checkindex:0
		    }
		},
        name: 'Player',
        props: {
            msg: String,
			mt_id:String,
        },
		components: {
			pageNew
        },
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			this.getData();
		},
		watch:{
			bFlag(value)
			{
				this.bFlag = value;
				this.page.page = 1;
				this.page.count = 0;
				this.page.start = 0;
				this.getData();
			},
		},
		methods:{
			sumitjj(){
				//提交接机预定
				
					let param = {};
					param.pickupid = this.jijiID;
					param.columnrela = this.list[this.checkindex].ColumnRela;
					param.pickuptype = this.bFlag == 'all' ? 'pickup' :'giveaway';
					axiosx('user/addjieji.json',param)
					.then((res)=>{
						let data = res.data;
						if(data.msgCode=='200')
						{
							this.$message({
								type: 'success',
								message: data.msg
							});
							this.jijishowFlag = false;
							this.getData();

						}
						else
						{
							this.$message({
								type: 'error',
								message: data.msg
							});
							this.jijishowFlag = false;
						}	
					});
			},
			showJJ(type,pickupid){
				this.checkindex = pickupid;
				this.jijishowFlag = true;
			},
			closejj(){
				this.jijishowFlag = false;
			},
			close(){
				this.showDvs = false;
			},
			showD(index)
			{
				this.showDv = index;
				this.showDvs = true;
			},
			sqtk(orderId){
				this.$confirm('确认需要退款操作？', '操作确认', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
				}).then(() => {
					axiosx('user/ordetrurn.json',{id:orderId})
					.then((res)=>{
						let data = res.data;
						if(data.msgCode!='200')
						{
							this.$message({
								type: 'error',
								message: data.msg
							});
						}
						else
						{
							this.$message({
								type: 'success',
								message: 'success!'
							});
							this.getData();
						}
					});
				
				});
			},
			changePage(value)
			{
				this.page.page = value;
				this.getData();
			},
			goFlag(str)
			{
				this.bFlag = str;
			},
			getData(){
				let  param = {};
				param.limit = this.page.limit;
				param.page = this.page.page;
				param.type = this.bFlag;
				axios('user/orderjieji.json',param)
				.then((res)=>{
					let data = res.data;
					if(data.msgCode!='200')
					{
						this.list = [];
						this.page.allpage = 0;
						this.page.count = 0;
						this.page.start = 0;
					}
					else
					{
						this.list = data.data;
						this.page.allpage = data.page.allpage;
						this.page.count = data.page.count;
						this.page.start = data.page.start;
					}
				});
			},

			pay(type,orderid)
			{
				let param = {};
				param.type = type;
				param.id = orderid;
				if(type == 'wx')
				{
					axios('user/pay.json',param)
					.then((res)=>{
						let data = res.data;
						if(data.msgCode == '200')
						{
							this.$alert('<img src="'+data.msg+'"></img>', '微信扫码付款', {
							dangerouslyUseHTMLString: true
							});
							this.checkPay(orderid,'wxpay');
						}
						
					});
				}
				else if(type == 'ali')
				{
					axios('user/pay.json',param)
					.then((res)=>{
						let data = res.data;
						if(data.msgCode == '200')
						{
							let cs = data.data;
							var form1 = window.document.getElementById("dataform");//获取form1对象	
							// let form1 = window.document.createElement("form");
							form1.innerHTML  = '';
							form1.action = "http://www.clpga.org/user/include/alipaydo/pagepay/pagepay.php";
							form1.target="_blank";
							for(let i in cs)
							{
								let newElement = window.document.createElement("input");
								newElement.setAttribute("name",i);
								newElement.setAttribute("value",cs[i]);
								newElement.style.visibility="hidden";//设置为隐藏
								form1.appendChild(newElement);
							}
							
							form1.submit();
							
							this.checkPay(orderid,'alipay');
						}
						
					});
				}
				
			},
			checkPay(id,payway){
				clearInterval(this.timeer);
				this.timeer = setInterval(()=>{
					axiosx('user/checkpay.json',{id:id,payway:payway})
					.then((res)=>{
						let data = res.data;
						if(data.msgCode=='200')
						{
							this.$message({
								type: 'success',
								message: '支付成功!'
							});
							this.getData();
						}	
					});

				},10000);
				
			},
			payOrder(orderid)
			{
				this.$confirm('', '请选择支付方式', {
				confirmButtonText: '微信',
				cancelButtonText: '支付宝',
				center: true,
				cancelButtonClass:'el-button el-button--primary',
				confirmButtonClass:'el-button el-button--primary',
				type: 'warning'
				}).then(() => {
					this.pay('wx',orderid);
				}).catch(() => {
					this.pay('ali',orderid);         
				});
			}
		}
    }
</script>

<style scoped="scoped">
	.d_title {
		margin-top: 0.14rem;
		color: rgb(192, 21, 62);
		font-size: 0.28rem;
		padding-bottom: 4px;
		/* font-weight: bold; */
		font-family: '微软雅黑';
		border-bottom: 1px solid rgb(192, 21, 62);
		display: inline-block;
		padding-right: 30px;
	}

	.d_title span {
		font-size: 0.24rem;
	}
	.lf_match_info_box{
		padding: 0.35rem 0px;
		font-size: 0.24rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.lf_match_info_box_r{
		display: flex;
		justify-content: flex-end;
	}
	.lf_course_bogo{
		position: relative;
		font-size: 0.2rem;
		color: #fff;
	}
	.lf_course_bogo_des{
		height: 0.6rem;
		display: flex;
		align-items: center;
		text-indent: 0.2rem;
		position: absolute;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.498);
		width: 100%;
	}
	.lf_course_name_data_box{
		background-color: #FAFAFA;
	}
	.lf_hide_select{
		display: none!important;
	}
	.lf_course_name_data_tit{
		width: 96%;
		border-bottom: 1px solid #efefef;
		color: #333;
		font-size: 0.24rem;
		height: 0.7rem;
		display: flex;
		align-items: center;
		margin: 0 auto;
	}
	.lf_course_name_data_con{
		width: 96%;
		color: #000;
		font-size: 0.24rem;
		min-height: 0.7rem;
		display: flex;
		align-items: center;
		margin: 0 auto;
		padding: 0.2rem 0;
		border-top: 1px solid #efefef;
	}
	.lf_course_name_data_tit div,.lf_course_name_data_con div{
		width: 20%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		flex-direction: column;
	}
	.lf_course_name_data_tit .lf_2{
		flex-grow: 1;
		display: flex;
		align-items: center;
		flex-direction: row!important;
	}
	.lf_course_name_data_con .lf_2{
		flex-grow: 1;
		display: flex;
		align-items: center;
		flex-direction: row!important;
		font-weight: bold;
	}
	.lf_9 text{cursor: pointer;
		color: #C0153E;
		margin-right: 0.1rem;
	}
	.lf_course_name_data_con .lf_2,.lf_course_name_data_tit .lf_2{
		justify-content: flex-start;
	}
	.lf_tab_tit_box {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin-bottom: 0.2rem;
	}
	.lf_tab_little_box{
		height: 30px;
		display: flex;
		align-items: center;
	}
	.lf_tab_little_box div {
		padding: 0 0.16rem;
		height: 0.14rem;
		font-size: 0.24rem;
		color: #999;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		border-right: 1px solid #999;
		word-break: keep-all;
		cursor: pointer;
	}
	.lf_tab_little_box div:nth-last-of-type(1){
		border-right: unset;
	}
	.lf_tab_little_box .lf_tab_box_select {
		color: #C0153E;
	}
	.el-pagination{
		justify-content: center;
	}
	.block{
		margin: 0.2rem auto;
	}
	.lf_alert_box{
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		background-color: rgba(0, 0, 0, 0.498039215686275);
		z-index: 99;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_alert_con_box{
		width: 12rem;
		background: inherit;
		background-color: rgba(250, 250, 251, 1);
		border: none;
		border-radius: 5px;
		box-shadow: 5px 5px 50px rgb(55 55 55 / 31%);
		padding: 0.2rem 1.4rem;
		position: relative;
	}
	.lf_close_img{
		position: absolute;
		width: 0.3rem;
		height: 0.3rem;
		right: 0.3rem;
		top: 0.3rem;
	}
	.lf_alert_tit{
		margin: 0.7rem auto;
		color: #333;
		font-size: 0.24rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_alert_con{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.lf_alert_btn{
		margin: 0.6rem auto;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_alert_btn_c{
		width: 1.3rem;
		height: 0.35rem;
		background-color: rgba(227, 227, 227, 1);
		border: none;
		border-radius: 2px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.2rem;
		color: #666666;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 0.2rem;
	}
	.lf_alert_btn_s{
		width: 1.3rem;
		height: 0.35rem;
		background: inherit;
		background-color: rgba(192, 21, 62, 1);
		border: none;
		border-radius: 2px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.2rem;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 0.2rem;
	}
	@media screen and (max-width: 768px) {
		.lf_match_info_box{
			padding: 0.35rem 0px;
			margin-left: 0;
		}
		.lf_course_name_data_tit div,.lf_course_name_data_con div{
			width: 20%!important;
		}
		.lf_course_name_data_tit .lf_2,.lf_course_name_data_con .lf_2{
			padding-right: 0.1rem;
		}
		.lf_hide_select{
			display: unset!important;
		}
		.lf_tab_little_box div {
			padding: 0 0.1rem;
			height: 0.14rem;
			font-size: 0.24rem;
			color: #999;
			display: flex;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			border-right: 1px solid #999;
			word-break: keep-all;
		}
		.lf_close_img{
			width: 0.36rem;
			height: 0.36rem;
			position: absolute;
			right: 0.1rem;
			top: 0.1rem;
		}
		.lf_alert_con_box{
			width: 6rem;
		}
		.lf_alert_con_box{
			padding: 0.2rem;
		}
		.lf_alert_con{
			display: flex;
			flex-direction: column;
		}
		.el-radio-group{
			width: 4rem;
			
		}
	}
</style>
