<template>
	<!--  -->
	
	<div class="all_body" >
		<div v-if="kong=='1' && (east==''||north=='')">
					<empty></empty>
				</div>
		<div v-else class="content_body">
			<div class="content_body_nei">
				<template v-for="it in data.matchextend" v-if="data.matchextend && data.matchextend.length > 0">

				<div class="content_item" v-if="it.content && it.content.length > 0 && it.pos_key=='fj'">
					<div class="content_item_left">
						<div>{{systemlang == 'cn'?it.pos_title:it.post_title_en}}</div>
						<div>{{systemlang != 'cn'?it.pos_title:it.post_title_en}}</div>
					</div>
					<div class="content_item_right">
						<div v-for="fj in it.content"  style="white-space: pre-wrap;"><a download style="border-bottom: 2px solid #0B0003;" :href="fj.annex_url">{{fj.annex_titile}}</a></div>
					</div>
				</div>
				<div class="content_item" v-else-if="it.content && it.content.length > 0">
					<div class="content_item_left">
						<div>{{systemlang == 'cn'?it.pos_title:it.post_title_en}}</div>
						<div>{{systemlang != 'cn'?it.pos_title:it.post_title_en}}</div>
					</div>
					<div class="content_item_right">
						<div style="white-space: pre-wrap;">{{it.content}}</div>
					</div>
				</div>
				</template>

				<div v-if="east!='' && north!=''" class="content_item">
					<div class="content_item_left">
						<div>{{systemlang == 'cn'?'路线图':'Location'}}</div>
						<div>{{systemlang != 'cn'?'路线图':'Location'}}</div>
					</div>
					<div class="content_item_right">
						<baidu-map 
						    class="bm-view"
						    :center="{ lng: east, lat: north }" 
						    :zoom="15"
						  >
						  <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>

						  <bm-marker :position="{lng: east, lat: north}" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
      <bm-label content="" :labelStyle="{border:0,color: '#666666', fontSize : '18px'}" :offset="{width: -35, height: 30}"/>
    </bm-marker>

						  </baidu-map>
						  <!-- <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation> -->
					</div>
				</div>

				
			</div>
		</div>

	</div>

	
</template>

<script>
	import axios from '../axios/request';
	import empty from "./empty.vue";
	import {
		getSystemLang,
		getLangAll
	} from "../lang";
	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				data: [],
				east:'',
				north:'',
				kong:'',

			}
		},
		name: 'IndexSg',
		props: {
			mt_id: String
		},
		components: {

			empty
		},

		created() {



		},
		mounted() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			let param = {};
			var that = this
			axios('matchs/match/matchtj.json', {
					otp: 'getnotice',
					mt_id: this.mt_id,
					lang: this.systemlang
				})
				.then((res) => {
					this.data = res.data.data;
					
					this.east = res.data.jwd?res.data.jwd.east:'';
					this.north = res.data.jwd?res.data.jwd.north:'';
					this.kong = res.data.kong;
					console.log(res.data,'res.data')
					console.log(this.east,this.north)
					setTimeout(function() {
						
						that.initMap()
					}, 10000)
					// console.log(res.data,'aaaaaa')
				});

		},
		methods: {
			initMap() {

			}
		}
	}
</script>

<style scoped="scoped">

	.content_body {
		width: 100%;
		background-color: #FAFAFA;
		padding: 0.4rem;
		margin-top: 0.6rem;
	}

	.content_body_nei {
		width: 100%;
		background-color: #fff;
	}

	.content_item {
		width: 17.7rem;
		overflow: hidden;
		border-bottom: #DBDBDB 1px solid;
		font-size: 0.28rem;

	}

	.content_item div div {
		width: 100%;

	}

	.content_item_left {
		padding-top: 0.2rem;
		padding-bottom: 0.2rem;
		width: 2.7rem;
		min-height: 1.2rem;
		float: left;

		color: #666666;
		padding-right: 0.28rem;
		display: flex;
		justify-content: center;
		flex-direction: column;
		text-align: right;


	}
	.content_item_left div:nth-last-of-type(1){
		font-size: 0.24rem;
	}
	.content_item_right {
		padding-top: 0.2rem;
		padding-bottom: 0.2rem;
		width: 14.9rem;
		min-height: 1.2rem;
		border-left: #DBDBDB 1px solid;
		float: right;
		text-align: left;
		color: #0B0003;
		padding-left: 0.28rem;
		display: flex;
		justify-content: center;
		flex-direction: column;
		text-align: left;

	}

	@media screen and (max-width: 768px) {
		.content_body {
			width: 100%;
			background-color: #FAFAFA;
			padding: 0.2rem;
			margin-top: 0.6rem;
		}

		.content_body_nei {
			width: 7.1rem;
			background-color: #fff;
		}

		.content_item {
			width: 7.1rem;
			/* height: 1.24rem; */
			border-bottom: #DBDBDB 1px solid;
			font-size: 0.28rem;

		}

		.content_item div div {
			width: 100%;
		}

		.content_item_left {
			width: 2rem;
			/* height: 1.24rem; */
			float: left;

			color: #666666;
			padding-right: 0.28rem;
			display: flex;
			justify-content: center;
			flex-direction: column;
			text-align: right;


		}

		.content_item_right {
			width: 5.09rem;
			/* height: 1.24rem; */
			border-left: #DBDBDB 1px solid;
			float: right;
			text-align: left;
			color: #0B0003;
			padding-left: 0.28rem;
			display: flex;
			justify-content: center;
			flex-direction: column;
			text-align: left;

		}
	}
	.bm-view {
	 width: 98% !important;
	  height: 6.2rem;
	  /* height: 800px; */
	 
	}
 
</style>