<template>
	
	<div class="page_foot">
		<div class="page_left">
			<div  @click="go(pages.page-1 <=1?1:pages.page-1)" class="page_item">
				<img  src="../assets/images/u28140.png"/>
			</div>
			<div @click="go(item)" v-for="item in showpagenum" :class="pages.page == item?'hover':''" class="page_item">{{item}}</div>
			<div @click="go(pages.page+1 >=pages.allpage?pages.allpage:pages.page+1)" class="page_item right_img">
				<img  src="../assets/images/u28140.png"/>
			</div>
		</div>
		<div class="page_center">Go to</div>
		<div class="page_right">
			<input type="text" @keydown.enter="go(gopage)" v-on:keypress="checkNumber($event)" @change="checkNumber($event)"  v-model="gopage" />
			
		</div>
	</div>
</template>
<script>
import axios from '../axios/request';
	
	import {
		getSystemLang,
		getLangAll
	} from "../lang";
	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				showpagenum:[],
				gopage:1
			}
		},
		name: 'Player',
		props: {
			pages: Object,
		},
		components: {
			
		},
		watch:{
				
                // 要监视的属性
                pages :{
                   
                    handler(newValue,oldValue){
						
					
						this.showpagenum = [];
						let pages  = newValue;
						if(pages.page >= pages.allpage)
						{
							for(let i = (pages.allpage-2 <=1?1:pages.allpage-2); i <= pages.allpage; i++)
							{
								this.showpagenum.push(i);
							}
						}
						else if(pages.allpage > pages.page && pages.allpage <=3)
						{
							for(let i = 1; i <= pages.allpage; i++)
							{
								this.showpagenum.push(i);
							}
						}
						else if(pages.allpage > pages.page && pages.allpage > 3 && pages.page=='1')
						{
							for(let i = 1; i <= 3; i++)
							{
								this.showpagenum.push(i);
							}
						}
						else if(pages.allpage > pages.page && pages.allpage > 3)
						{
							for(let i = ((pages.page-1)<=1?1:(pages.page-1)); i <= ((pages.page+1)>pages.allpage?pages.allpage:(pages.page+1)); i++)
							{
								this.showpagenum.push(i);
							}
						}
					}		
                }
 
            },
		created() {
			this.showpagenum = [];
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			let pages = this.pages;
			
			if(pages.page >= pages.allpage)
			{
				for(let i = (pages.allpage-2 <=1?1:pages.allpage-2); i <= pages.allpage; i++)
				{
					this.showpagenum.push(i);
				}
			}
			else if(pages.allpage > pages.page && pages.allpage <=3)
			{
				for(let i = 1; i <= pages.allpage; i++)
				{
					this.showpagenum.push(i);
				}
			}
			else if(pages.allpage > pages.page && pages.allpage > 3 && pages.page=='1')
			{
				for(let i = 1; i <= 3; i++)
				{
					this.showpagenum.push(i);
				}
			}
			else if(pages.allpage > pages.page && pages.allpage > 3)
			{
				for(let i = ((pages.page-1)<=1?1:(pages.page-1)); i <= ((pages.page+1)>pages.allpage?pages.allpage:(pages.page+1)); i++)
				{
					this.showpagenum.push(i);
				}
			}
			
		},
		methods: {
			checkNumber(event) {
				const key = event.keyCode;
				if (key >= 48 && key <= 57 || key == 8 || key == 46) {
					return true;
				} else {
					event.returnValue = false;
					event.preventDefault();
					return false;
				}
			},
			go(page)
			{
			
				if(page <=1)
				{
					page = 1;
				}
				if(page > this.pages.allpage)
				{
					page = this.pages.allpage;
				}
				this.gopage = parseInt(page);
				this.updatePage(this.gopage);
				this.$emit('clickPage',page);

				
			},
			updatePage(newValue){
				
						this.showpagenum = [];
						this.pages.page = newValue;
						let pages  = this.pages;
						
						if(pages.page >= pages.allpage)
						{
							
							for(let i = (pages.allpage-2 <=1?1:pages.allpage-2); i <= pages.allpage; i++)
							{
								this.showpagenum.push(i);
							}
						}
						else if(pages.allpage > pages.page && pages.allpage <=3)
						{
							for(let i = 1; i <= pages.allpage; i++)
							{
								this.showpagenum.push(i);
							}
							
						}
						else if(pages.allpage > pages.page && pages.allpage > 3 && pages.page=='1')
						{
							for(let i = 1; i <= 3; i++)
							{
								this.showpagenum.push(i);
							}
							
						}
						else if(pages.allpage > pages.page && pages.allpage > 3)
						{
							
							for(let i = ((pages.page-1)<=1?1:(pages.page-1)); i <= ((pages.page+1)>pages.allpage?pages.allpage:(pages.page+1)); i++)
							{
								this.showpagenum.push(i);
							}
						}

						
			}
		}
	}
</script>

<style>
	.page_foot{
		display: flex;
	
		justify-content: center;
		overflow: hidden;

		height: 0.6rem;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	
	}
	.right_img img{
		transform: rotate(180deg);
	}
	.page_left{
		float: left;
	}
	.page_left img{
		width: 0.3rem;
		height: 0.3rem;
		margin:  0 auto;
		margin-top: -0.05rem;
	}
	.page_item{
		width: 0.4rem;
		height: 0.4rem;
		float: left;
		margin-right: 0.1rem;
		background-color: #F5F7FA;
		border-radius: 0.04rem;
		line-height: 0.4rem;
		text-align: center;
		margin-top: 0.1rem;
		font-size: 0.24rem;
		cursor: pointer;
	}
	.hover{
		background-color: #C0153E;
		color: #fff;
	}
	.page_center{
		float: left;
		height: 0.6rem;
		line-height: 0.6rem;
		font-size: 0.18rem;
		color: #333333;
		margin-left: 0.2rem;
		cursor: pointer;
	}
	.page_right{
		margin-left: 0.2rem;
		float: left;
	
		height: 0.6rem;
		
	}
	.page_right input{
		width: 0.7rem;
		height: 0.4rem;
		margin-top: 0.1rem;
		font-size: 0.24rem;
		text-align: center;
		border: 0.01rem solid rgba(239,239,239);
		border-radius: 0.04rem;
	}
	
	
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
	  -webkit-appearance: none !important;
	}
	input[type='number'] {
	  -moz-appearance: textfield;
	}
	
</style>