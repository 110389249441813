<template>
	<div class="all_body">
		<el-row type="flex" justify="center">
			<el-col :xs="24" :sm="24" :md="24">

				<div class="lf_News_list_box_all" v-for="b in banner">
					<div class="lf_News_list_box " >
						<router-link target="_blank" :to="{path:'/PlayerVideo',query:{id:b.ID,lang:systemlang}}">
							<img class="lf_News_cons_img" v-if="b.DownloadPicture" :src="b.DownloadPicture" alt="">
							<img class="lf_News_cons_img" v-else src="../assets/images/u121245.png" />
							<img class="lf_News_cons_img lf_News_cons_img_s" src="../assets/images/ICON_video.png" />
							
								<div class="lf_News_cons_box">
									<div class="lf_News_cons_box_1">{{b.DownloadTitle}}</div>
									<div class="lf_News_cons_box_2">{{b.DownloadBrief ? b.DownloadBrief : ''}}</div>
									<div class="lf_News_cons_box_3">{{b.DownloadAddDate}}</div>
								</div>
						</router-link>

					</div>
				</div>

			</el-col>
		</el-row>
		<el-row type="flex" justify="center" v-if="show=='1' && next_page!==0">
			<el-col :xs="24" :sm="24" :md="24">

				<div v-on:click="gonext" class="lf_footer1_btn_go">
					{{langs.psearch_more}}
				</div>

			</el-col>
		</el-row>

		<el-row type="flex" justify="center" v-if="show=='1' && banner.length <= 0">
			<el-col :xs="24" :sm="24" :md="24">

				<!-- <div v-on:click="gonext" class="lf_footer1_btn_go">
				NONE
			</div>
			-->
				<empty></empty>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import empty from "./empty.vue";
	import {
		throwStatement
	} from '@babel/types';
	import {
		getSystemLang,
		getLangAll
	} from "../lang";
	import axios from '../axios/request';
	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				banner: [],
				page: 1,
				next_page: 'null',
				matchname: false,
				search: '',
				show: false

			};
		},
		name: 'NewList',
		props: {
			msg: String,
			type: String,
			id: String,
			year: String,
			pl_name: String,
			mt_name: String,
			mt_id: String,
			hide: String
		},
		components: {

			empty
		},
		created() {
			//获取banner
			let s = [];
			if (this.year && this.year > 0) {
				s.push(this.year);
			}
			if (this.mt_name && this.mt_name != '' && this.mt_name != undefined) {
				s.push(this.mt_name);
			}
			if (this.pl_name && this.pl_name != '' && this.pl_name != undefined) {
				s.push(this.pl_name);
			}
			this.search = s.join('-');
			this.getData();
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
		},
		methods: {
			getData() {
				if (this.next_page === 0) {
					return false;
				}
				if (this.next_page == 'null') {
					this.next_page = 1;
				}


				let params = {
					id: this.id,
					page: this.next_page,
					limit: 8
				};

				axios('player/player/video.json', params)
					.then((res) => {
						// console.log('next',res.data.page.next);
						let data = res.data.data;
						this.next_page = res.data.page.next;
						if (res.data.matchname) {
							this.matchname = res.data.matchname;
						}
						for (let s in data) {
							this.banner.push(data[s]);
						}

						this.show = 1;
					});
			},
			gonext() {
				this.getData();
			}
		}

	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.lf_footer1:nth-last-of-type(1) {
		border-bottom: none;
	}

	.lf_News_list_box {



		align-items: center;

		padding: 0.3rem 0 0.3rem 0.67rem;

		overflow: hidden;
		position: relative;


		margin-bottom: 0.02rem;



	}



	.lf_News_list_box_all :hover {

		background-color: #C0153E;



	}

	.lf_News_list_box_all :hover .lf_News_cons_box_1,
	.lf_News_list_box_all :hover .lf_News_cons_box_2,
	.lf_News_list_box_all :hover .lf_News_cons_box_3 {

		color: #fff !important;

	}


	.lf_News_cons_img {

		width: 2.14rem;

		height: 1.2rem;
		position: relative;
		z-index: 98;
		float: left;

	}
	.lf_News_cons_img_s{
		    position: absolute;
			left:0.67rem;
			top:0.3rem;
		    z-index: 99;
			cursor: pointer;
			
			
			
	}
	.lf_News_list_box_all .lf_News_cons_img_s:hover{
		background-color: unset;
	}



	.lf_News_list_box_select {

		background-color: #C0153E;

	}



	.lf_News_cons_box {

		float: left;

		width: 9.2rem;

		margin-left: 0.4rem;


	}




	.lf_News_cons_box_1 {
		font-size: 0.28rem;
		width: 98%;
		white-space: nowrap;
		overflow: hidden;
		height: 0.3rem;
		line-height: 0.3rem;
		text-overflow: ellipsis;
		margin: 0.08rem 0;
		color: #000;
		font-weight: bold;
	}

	.lf_News_cons_box_2 {
		font-size: 0.26rem;
		width: 98%;
		white-space: nowrap;
		overflow: hidden;
		height: 0.3rem;
		line-height: 0.3rem;
		text-overflow: ellipsis;
		margin: 0.08rem 0;
		color: #666666;
	}

	.lf_News_cons_box_3 {
		font-size: 0.24rem;
		color: #666666;
		height: 0.3rem;
		line-height: 0.3rem;
	}

	.lf_News_list_box_select .lf_News_cons_box_1 {
		color: #fff;
	}

	.lf_News_list_box_select .lf_News_cons_box_2 {
		color: #fff;
	}

	.lf_News_list_box_select .lf_News_cons_box_3 {
		color: #fff;
	}

	.lf_footer1_tit_box {
		height: 1.4rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}

	.lf_footer1_tit_box .lf_foot_tit_text1 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.26rem;
		color: #C0153E;
	}

	.lf_footer1_tit_box .lf_foot_tit_text2 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.15rem;
		color: #C0153E;
	}

	.lf_footer1_tit_box .lf_foot_tit_line {
		width: 1.37rem;
		height: 3px;
		background-color: #C0153E;
		margin: 4px 0;
	}

	.lf_footer1_btn_go {
		width: 18.5rem;
		height: 0.62rem;
		background: inherit;
		background-color: rgba(250, 250, 250, 1);
		border: none;
		border-radius: 2px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.24rem;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0.5rem auto;
	}

	.lf_footer1_btn_go a {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
	}

	@media screen and (max-width: 768px) {
		.lf_News_list_box_all {
			width: 7.5rem;
			margin: 0 auto;
			height: 1.5rem;
			overflow: hidden;


		}

		.lf_News_cons_box {
			width: 5.02rem;
			float: left;
			overflow: hidden;
			margin-left: 0.2rem;
			height: 1.2rem;
		}
		.lf_News_cons_img_s{
			    position: absolute;
				left:0;
				top:0.3rem;
			    z-index: 99;
				cursor: pointer;
		}
		.lf_News_cons_box_1 {
			margin-top: 0;

			margin-bottom: 0.01rem;
		}

		.lf_News_cons_box_2 {
			margin-top: 0;
			margin-bottom: 0.01rem;
		}

		.lf_News_cons_box_3 {
			margin-top: 0;
			margin-bottom: 0.01rem;
		}

		.lf_footer1 {
			width: 100%;
			margin: 0 auto;
		}

		.lf_link_con_box_img {
			width: 130px;
			height: 65px;
			margin: 0 auto;
		}

		.lf_News_cons_img {
			width: 1.6rem;
			height: 0.9rem;
			margin: 0 auto;
			margin-left: 0.24rem;
		}

		.lf_News_list_box {
			padding: 0.3rem 0;
		}

		.lf_footer1_btn_go {
			width: 100%;
		}

		.lf_footer1_tit_box .lf_foot_tit_text2 {
			font-size: 0.26rem;
		}

		.lf_footer1_tit_box .lf_foot_tit_text1 {
			font-size: 0.26rem;
		}

		.lf_footer1_btn_go {
			height: 0.6rem;
			font-size: 0.32rem;
		}

	}
</style>