<template>
	<navs></navs>
	<Btn></Btn>
	<el-row type="flex" justify="center">
		<el-col :xs="24" :sm="24" :md="24">
			<div class="lf_footer1_tit_box">
				<div class="lf_foot_tit_text1">非会员报名</div>
				<div class="lf_foot_tit_line"></div>
				<div class="lf_foot_tit_text2">Non-member sign up</div>
			</div>
		</el-col>
	</el-row>
	<el-row type="flex" justify="center">
		<el-col :xs="24" :sm="16" :md="16">
			<div class="lf_tab_tit_box">
				<div class="lf_tab_box">
					<div @click="go('matchc')" :class="tFlag=='matchc'?'lf_tab_box_select':''">{{langs.FactSheet1}}</div>
					<div @click="go('match')" :class="tFlag=='match'?'lf_tab_box_select':''">{{langs.text_Signup}}</div>
					<div @click="go('news')" :class="tFlag=='news'?'lf_tab_box_select':''">{{langs.text_Signupinfo1}}</div>
					
				</div>
			</div>
		</el-col>
	</el-row>
	<Listsnovipmatch v-if="tFlag=='matchc'" @changTap="changTap" :mt_id="mt_id"></Listsnovipmatch>
	<Entry v-if="tFlag=='match'" @changTap="changTap" :mt_id="mt_id"></Entry>
	<Listnovip v-if="tFlag=='news'" @changTap="changTap" :order_id="orderid" :mt_id="mt_id"></Listnovip>
	


	<Footer :year="year" :mt_id="matchInfo.ID"></Footer>
	<Footer1></Footer1>
</template>

<script>
	// @ is an alias to /src
	import navs from "../../components/nav.vue";
	import Btn from "../../components/Btn.vue";
	import Footer from "../../components/Footer.vue";
	import Footer1 from "../../components/Footer1.vue";
	import Ad from "../../components/Ad.vue";
	import Group from "../../components/Match/Group.vue";
	import Entry from "../../components/Login/Entrynovip.vue";

	import Listnovip from "../../components/Login/Listsnovip.vue";
	import Listsnovipmatch from "../../components/Login/Listsnovipmatch.vue";


	import {
		getSystemLang,
		getLangAll
	} from "../../lang";
	import axios from '../../axios/request';
	//这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue/swiper-vue';

	import {
		Autoplay,
		Pagination
	} from 'swiper';
	import 'swiper/swiper-bundle.css';
	import 'swiper/swiper.min.css';
	//这里就是更新后的路径，以前是import 'swiper/css'

	//自动轮播的配置
	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				opacityF: true,
				options: [{
						value: 'Option1',
						label: 'Option1',
					},
					{
						value: 'Option2',
						label: 'Option2',
					},
					{
						value: 'Option3',
						label: 'Option3',
					},
					{
						value: 'Option4',
						label: 'Option4',
					},
					{
						value: 'Option5',
						label: 'Option5',
					},
				],
				matchInfo:{},
				zb:{},
				year:'',
				tFlag:'matchc', //match:赛事信息 cj:成绩信息  news:新闻信息
				bFlag:'ssxx',  //ssxx:赛事信息 csqy:参赛球员 qcjs:球场介绍 fzb:分组表 jstj:技术统计
				mt_id:'',
				orderid:'' //订单ID
			}
		},
		props: {

		},
		name: 'Home',
		components: {
			navs,
			Btn,
			Footer,
			Footer1,
			Swiper,
			SwiperSlide,
			Ad,
			Entry,
			Listnovip,
			Listsnovipmatch
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);

			let id = this.$route.query.mt_id;
			this.mt_id = id;
		},
		
		methods: {
			go($flag){
				this.orderid = '';
				this.tFlag =$flag;
				
			},
			changTap($flag,$orderid)
			{
				this.orderid = $orderid;
				this.tFlag =$flag;
				
			}
			
		}

	}
</script>


<style scoped="scoped">
	.lf_footer1_tit_box {
		height: 1.4rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}

	.lf_footer1_tit_box .lf_foot_tit_text1 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.3rem;
		color: #C0153E;
	}

	.lf_footer1_tit_box .lf_foot_tit_text2 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.26rem;
		color: #C0153E;
	}

	.lf_footer1_tit_box .lf_foot_tit_line {
		width: 2rem;
		height: 3px;
		background-color: #C0153E;
		margin: 4px 0;
	}
	
	.lf_tab_tit_box {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin-bottom: 0.2rem;
		border: none;
	}

	.lf_tab_tit_box .el-select {
		position: absolute;
		left: 0.1rem;
	}
	.lf_tab_little_box{
		height: 30px;
		display: flex;
		align-items: center;
	}
	.lf_tab_little_box div {
		padding: 0 16px;
		height: 0.14rem;
		font-size: 0.24rem;
		color: #999;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		border-right: 1px solid #999;
		word-break: keep-all;
		cursor: pointer;
	}
	.lf_tab_little_box div:nth-last-of-type(1){
		border-right: unset;
	}
	.lf_tab_little_box .lf_tab_box_select {
		color: #C0153E;
	}
	.lf_tab_box{
		justify-content: center;
	}

	.lf_tab_box div:nth-of-type(2) {
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
		position: relative;
	}
	.lf_tab_box div:nth-of-type(3) {
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
		position: relative;
	}
	.lf_tab_box .lf_tab_box_select {
		border-color: #C0153E;
		color: #C0153E;
		position: relative;
		z-index: 2;
	}
	.lf_opacity_div {
		width: 100%;
		position: absolute;
		left: 0%;
		top: 0;
		background-color: rgba(0, 0, 0, 0.5);
		color: #fff;
		height: 4rem;
		z-index: 99;
		padding: 0.5rem 0;
	}
	.lf_opacity_div img{
		max-width: 3.36rem;
		height: 0.65rem;
		margin: 0 auto;
		width: auto;
	}
	.lf_opacity_div_1{
		height: 0.7rem;
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		font-weight: 700;
		font-style: normal;
		font-size: 0.24rem;
		letter-spacing: 0.04rem;
		color: #FFFFFF;
		width: 3.36rem;
		line-height: 0.7rem;
		text-align: center;
		overflow: hidden;
		margin: 0.1rem auto 0;
		border-bottom: 1px solid #fff;
	}
	.lf_opacity_div_2{
		width: 100%;
		margin: 0.25rem auto 0;
		text-align: center;
		font-size: 0.16rem;
		color: #fff;
		height: 0.25rem;
		line-height: 0.25rem;
	}
	.lf_opacity_div_3{
		width: 100%;
		margin: 0 auto;
		text-align: center;
		font-size: 0.16rem;
		color: #fff;
		height: 0.25rem;
		line-height: 0.25rem;
	}
	.lf_opacity_div_4{
		width: 3.36rem;
		margin: 0 auto;
		text-align: center;
		font-size: 0.16rem;
		color: #fff;
		height: 0.25rem;
		line-height: 0.25rem;
	}
	.lf_opacity_div_5{
		width: 1rem;
		height: 0.35rem;
		background: inherit;
		background-color: rgba(242, 242, 242, 0);
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(255, 255, 255, 1);
		border-radius: 5px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.14rem;
		color: #FFFFFF;
		margin: 0.25rem auto 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.d_title {
		margin-top: 0.14rem;
		margin-left: 10px;
		color: rgb(192, 21, 62);
		font-size: 0.2rem;
		padding-bottom: 4px;
		/* font-weight: bold; */
		font-family: '微软雅黑';
		border-bottom: 1px solid rgb(192, 21, 62);
		display: inline-block;
		padding-right: 30px;
	}

	.d_title span {
		font-size: 0.14rem;
	}
	.lf_match_banner_box{
		height: 4rem;
		width: 100%;
		position: relative;
	}
	.lf_match_banner_box_img{
		height: 4rem;
	}
	.lf_match_info_box_text{
		padding: 0.24rem 10px;
		font-size: 0.14rem;
		color: #333;
		white-space: pre-wrap;
	}
	.lf_match_info_box_con{
		padding: 0.24rem 10px;
	}
	.lf_match_info_con{
		display: flex;
		background-color: #FAFAFA;
		box-sizing: border-box;
	}
	.lf_match_info_con div{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 0.6rem;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.16rem;
		color: #333;
		border-right: 1px solid #F5F5F5;
		border-bottom: 1px solid #F5F5F5;
	}
	.lf_match_info_con .lf_1,.lf_match_info_con .lf_12{
		width: 1.1rem;
		height: 0.5rem;
		color: #C0153E;
		flex-grow: 1;
		flex-shrink: 0;
	}
	.lf_match_info_con .lf_11{
		width: 0.6rem;
		height: 0.5rem;
		color: #C0153E;
		flex-shrink: 0;
	}
	.lf_match_info_con .lf_2{
		flex-shrink: 0;
		width: 0.5rem;
	}
	.lf_match_champion_his_box{
		padding: 0.24rem 10px;
		font-size: 0.16rem;
		
	}
	.lf_match_champion_his_tit{
		display: flex;
		background-color: #FAFAFA;
		height: 0.8rem;
		align-items: center;
		border-bottom: 1px solid #F5F5F5;
		color: #333;
	}
	.lf_champion_his_1{
		width: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_champion_his_2{
		width: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-grow: 1;
		position: relative;
	}
	.lf_player_card{
		position: absolute;
		left: 1.5rem;
		top: 0;
		width: 5.2rem;
		height: 2.5rem;
		box-shadow: 0px 0px 4px 2px #f1f1f1;
		display: flex;
		border-radius: 5px;
		background-color: #fff;
		z-index: 9;
	}
	.lf_player_card_img{
		margin: 0.3rem;
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
	}
	.lf_player_card_con{
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.lf_player_card_con_1{
		font-size: 0.24rem;
		color: #333;
		display: flex;
		align-items: center;
		margin-bottom: 0.2rem;
	}
	.lf_player_card_con_1 img{
		width: 0.3rem;
		height: 0.21rem;
		margin-left: 0.3rem;
	}
	.lf_player_card_con_2{
		font-size: 0.16rem;
		color: #999;
		display: flex;
		align-items: center;
		min-height: 0.35rem;
		display: flex;
		align-items: center;
	}
	.lf_champion_his_3{
		width: 1.3rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_champion_his_3 img{
		width: 20px;
		height: 14px;
	}
	.lf_champion_his_4{
		width: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_champion_his_5{
		width: 3.1rem;
		display: flex;
		flex-direction: column;
	}
	.lf_champion_his_6{
		width: 1.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_champion_his_7{
		width: 1.6rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_rd_tit{
		width: 100%;
		height: 0.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_rd_box{
		width: 100%;
		height: 0.4rem;
		display: flex;
		flex-direction: row;
	}
	.lf_match_champion_his_con .lf_rd_box{
		height: ;
	}
	.lf_rd_box div{
		flex-grow: 1;
		height: 0.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_match_champion_his_con{
		display: flex;
		background-color: #FAFAFA;
		color: #999;
	}
	@media screen and (max-width: 768px) {
		.lf_footer1_tit_box {
			margin-top: 0.5rem;
		}

		.lf_tab_tit_box {
			align-items: unset;
		}

		.lf_tab_box {
			margin-top: 0.2rem;
		}
		.lf_match_banner_box{
			height: 2.5rem;
			width: 100%;
			position: relative;
		}
		.lf_match_banner_box_img{
			height: 2.5rem;
		}
		.lf_opacity_div {
			width: 100%;
			position: absolute;
			left: 0;
			top: 0;
			background-color: rgba(0, 0, 0, 0.5);
			color: #fff;
			height: 100%;
			z-index: 99;
			padding: 0.1rem 0;
		}
		.lf_opacity_div img{
			display: none;
		}
		.lf_opacity_div_1{
			height: 0.7rem;
			font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
			font-weight: 700;
			font-style: normal;
			font-size: 0.24rem;
			letter-spacing: 0.04rem;
			color: #FFFFFF;
			width: 3.36rem;
			line-height: 0.7rem;
			text-align: center;
			overflow: hidden;
			margin: 0.42rem auto 0;
			border-bottom: 1px solid #fff;
		}
		.lf_opacity_div_2{
			display: none;
		}
		.lf_opacity_div_3{
			display: none;
		}
		.lf_opacity_div_4{
			display: none;
		}
		.lf_opacity_div_5{
			width: 1.1rem;
			height: 0.45rem;
			padding: 0.04rem;
		}
		.lf_12{
			display: none!important;
		}
		.lf_match_info_con{
			justify-content: flex-end!important;
		}
		.lf_match_info_con .lf_2{
			width: 0.6rem;
		}
		.lf_champion_his_5,.lf_champion_his_6{
			display: none;
		}
		.lf_player_card{
			position: absolute;
			left: 0;
			top: 0.4rem;
			width: 5.2rem;
			height: 2.5rem;
			box-shadow: 0px 0px 4px 2px #f1f1f1;
			display: flex;
			border-radius: 5px;
			background-color: #fff;
			z-index: 9;
		}
		.lf_tab_box div {
			font-size: 0.26rem;
		}
		.lf_footer1_tit_box .lf_foot_tit_text1 {
			font-family: '微软雅黑';
			font-weight: 400;
			font-style: normal;
			font-size: 0.28rem;
			color: #C0153E;
		}
		.lf_footer1_tit_box .lf_foot_tit_text2 {
			font-family: '微软雅黑';
			font-weight: 400;
			font-style: normal;
			font-size: 0.26rem;
			color: #C0153E;
		}
	}
</style>
