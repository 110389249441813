<template>	
	<el-row id="pdf"  type="flex" justify="center">
		<el-col :xs="24" :sm="24" :md="24">
			<div class="lf_match_info_box">
				<div class="d_title">{{systemlang=='cn'?'参赛报名':'Sign Up'}} | <span>{{systemlang!='cn'?'参赛报名':'Sign Up'}}</span></div>
			</div>
		</el-col>
	</el-row>
	<el-row type="flex" justify="center">
		<el-col :xs="24" :sm="24" :md="24">
			<el-row>
				<el-col :xs="24" :sm="16" :md="16">
					<div class="lf_entry_info_box_left">
						<div class="lf_entry_info_box_left_tit">{{langs.FactSheet}}</div>
						<div class="lf_entry_info_box_left_con">
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.FactSheet2}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-select v-if="info.mtlist" v-model="mtid" size="default" class="m-2">
										<el-option v-for="item in info.mtlist"
													:key="item.ID"
													:label="item.ColumnName"
													:value="item.ID" />
									</el-select>
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									<text>*</text>{{langs.SignedUpEvents}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-checkbox v-model="PlayRegClassTypeValue">{{langs.SignUp}}</el-checkbox>
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.Time}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									{{info.mtinfo?info.mtinfo.mt_date:''}}
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.Venue}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									{{info.mtinfo?info.mtinfo.ColumnPlace:''}}
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.EntryFee}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									{{langs.EntryFee1}} {{info.bmconfig?info.bmconfig.RegistMatchT:''}}
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.EntryDeadline}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									{{info.bmconfig?info.bmconfig.RegistEnd:''}}
								</div>
							</div>
							<div class="lf_line"></div>
						</div>
						<div class="lf_entry_info_box_left_tit">{{langs.FactSheet3}}</div>
						<div class="lf_entry_info_box_left_con">

							<div class="lf_entry_info_box_left_con_list" v-if="info.info" v-html="info.info?info.info.ArticleContent:''">
								
							</div>

							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.ChineseName}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									{{info.pinfo?info.pinfo.pl_cn_name:''}}
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.EnglishName}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									{{info.pinfo?info.pinfo.pl_en_name:''}}
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.Nationality}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									{{info.pinfo?info.pinfo.country_name:''}}
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.IDNumber}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									{{info.pinfo?info.pinfo.pCard:''}}
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.MobileNumber}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									{{info.pinfo?info.pinfo.pTel:''}}
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.EmailAddress}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									{{info.pinfo?info.pinfo.pEmail:''}}
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.Identity}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-radio-group v-if="info.pinfo"  v-model="info.pinfo.pap">
										<el-radio :label="'PRO'" disabled >PRO</el-radio>
										
									</el-radio-group >
									<el-radio-group v-if="info.pinfo" v-model="info.pinfo.pap">
										
										<el-radio disabled :label="'AM'">AM</el-radio>
									</el-radio-group>
									
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.Attribution}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-input v-if="info.pinfo" v-model="info.pinfo.pClub"></el-input>
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.Bank}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									{{info.pinfo?info.pinfo.pBank:''}}
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.NameofAccountBank}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									{{info.pinfo?info.pinfo.pBankName:''}}
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.BankAccountNumber}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									{{info.pinfo?info.pinfo.pBankNumber:''}}
									
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.InvoiceTitle}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<!-- {{info.pinfo?info.pinfo.pinvoice:''}} -->
									<el-input v-if="info.o" v-model="info.o.PlayRegInvoice"></el-input>
									<el-input v-else-if="info.pinfo" v-model="info.pinfo.pinvoice"></el-input>
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.TaxIdentificationNumber}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<!-- {{info.pinfo?info.pinfo.pSwiftCode:''}} -->
									<el-input v-if="info.o" v-model="info.o.PlayRegSwiftCode"></el-input>
									<el-input v-else-if="info.pinfo" v-model="info.pinfo.pSwiftCode"></el-input>
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									<text>*</text>{{langs.CGAMembershipNumber}}
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-input v-if="info.pinfo" v-model="info.pinfo.pl_chinacard"></el-input>
								</div>
							</div>
							
							<div class="lf_line"></div>
						</div>
						<div class="lf_entry_info_box_left_tit">{{langs.RelatedService}}</div>
						<div class="lf_entry_info_box_left_con">
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.Hotel}}<span class="lf_que_span" v-if="info.hole&&info.hole.hc_hotel_file" @click="downFlag">?</span>
									<span class="lf_que_span" style="background:none;" v-if="!info.hole || !info.hole.hc_hotel_file"></span>
									<div class="lf_que" v-if="systemlang=='cn'">
										
										<div class="lf_tips_box" v-if="down">
											<div class="lf_tips_t">{{langs.OfficialHotel1}}： {{info.bmconfig.RegistHotelName}}</div>
											<div v-if="info.hole&&info.hole.hc_hotel_file" style="display: flex;">
												请<text><a target="_blank" :href="'/uploadfile/hotelcaddie/'+info.hole.hc_match+'/hotel/'+info.hole.hc_hotel_file">下载酒店预订表</a></text>直接
											</div>
											<div v-if="info.hole&&info.hole.hc_hotel_file">
												发送至酒店进行预订
											</div>
										</div>
									</div>
									<div class="lf_que"  v-if="systemlang=='en'">
										
										<div class="lf_tips_box" v-if="down">
											<div class="lf_tips_t">{{langs.OfficialHotel1}}： {{info.bmconfig.RegistHotelName}}</div>
											<div v-if="info.hole&&info.hole.hc_hotel_file">
												<text><a target="_blank" :href="'/uploadfile/hotelcaddie/'+info.hole.hc_match+'/hotel/'+info.hole.hc_hotel_file">Download </a></text>
											</div>
											<div v-if="info.hole&&info.hole.hc_hotel_file">
												the Official Hotel Booking Sheet
											</div>
										</div>
									</div>
								</div>
								<div class="lf_entry_info_box_left_con_list_right">
									<el-radio-group v-if="info.bmconfig" v-model="jd">
										<el-radio  :label="info.bmconfig.RegistHotelName">{{langs.OfficialHotel}}</el-radio>
									</el-radio-group>
									<div class="lf_que" style="background-color: unset;"></div>
									<el-radio-group v-model="jd">
										<el-radio  :label="'自己安排'">{{langs.SelfArrangement}}</el-radio>
									</el-radio-group>
								</div>
							</div>
							
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.Caddie}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								</div>
								<div class="lf_entry_info_box_left_con_list_right" style="flex-wrap: wrap">
									<template v-for="qt in hc_caddie_info">
										<el-radio-group v-model="PlayRegCaddie">
											<el-radio  :label="qt.key">{{qt.title}}</el-radio>
										</el-radio-group>
										<div class="lf_que" style="background-color: unset;"></div>
									</template>
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list">
								<div class="lf_entry_info_box_left_con_list_left">
									{{langs.Pickupservice}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								</div>
								<div class="lf_entry_info_box_left_con_list_right" style="justify-content: center;color: #666;font-size: .24rem;">
									{{langs.Pickupservicetobeconfirmed}}
								</div>
							</div>
							<div class="lf_entry_info_box_left_con_list" style="justify-content: center;">
								
									<el-button type="danger" v-if="!info.o" @click="sumitData">{{langs.Submit}}</el-button>
									<el-button type="danger" v-if="info.o" @click="sumitgo" >{{langs.Theorderhasbeensubmitted}}</el-button>
								
							</div>
						</div>
					</div>
				</el-col>
				<el-col :xs="24" :sm="8" :md="8">
					<div class="lf_entry_info_box_right">
						<div class="lf_entry_info_box_right_tit">特别提醒</div>
						<div class="lf_entry_info_box_right_con">根据中国高尔夫球协会规定，运动员参加国家体育总局小球运动管理中心、中国高尔夫球协会主办的职业比赛及业余比赛，均需在中国高尔夫球赛事管理系统进行登记，只有登记过的运动员才可报名参加中国高尔夫球协会组织的该年度的各项高尔夫球比赛。</div>
						<div class="lf_entry_info_box_right_con">登记程序和办法如下：</div>
						<div class="lf_entry_info_box_right_con">一、中国高尔夫球协会赛事管理系统实行登记管理。</div>
						<div class="lf_entry_info_box_right_con">登记网址为：http://www.cgagolf.org.cn/</div>
						<div class="lf_entry_info_box_right_con">二、办理中国高尔夫球协会个人专业会员卡。参考网址</div>
						<div class="lf_entry_info_box_right_con">http://www.cgagolf.org.cn/common/newsDetail/lawDetail?id=1106</div>
						<div class="lf_entry_info_box_right_con">三、运动员登记时，需根据系统要求，如实、细致的填报基本信息，并提交个人照片及证件照片。中国高尔夫球协会将对运动员信息再次复核，确认批准后对有效登记运动员颁发专业会员卡，即为运动员登记完毕。</div>
						<div class="lf_entry_info_box_right_con">四、运动员登记期限为1年，指当年在中国高尔夫球协会办理登记手续截止日到次年办理登记手续截止日，1年后需重新在系统进行登记</div>
						<div class="lf_entry_info_box_right_tit">备注</div>
						<div class="lf_entry_info_box_right_con">所有中国女子职业巡回赛球员仅限网上报名，其他方式报名不予接受。</div>
						<div class="lf_entry_info_box_right_con">如有任何疑问请拨打巡回赛办公室电话</div>
						<div class="lf_entry_info_box_right_con">+86 010 67158937或者</div>
						<div class="lf_entry_info_box_right_con">+86 13810702474咨询。</div>
						<div class="lf_entry_info_box_right_con">参赛球员有义务自行查询报名结果。</div>
						<div class="lf_entry_info_box_right_con">参赛球员本次比赛肖像权归组委会。</div>
						<div class="lf_entry_info_box_right_con">球员有义务参与组委会安排的赛事活动。</div>
						<div class="lf_entry_info_box_right_con">中国女子职业巡回赛对参赛球员的资格有最终决定权。</div>
					</div>
				</el-col>
			</el-row>
		</el-col>
	</el-row>
</template>

<script>

import axios from '../../axios/request';
import axiosx from '../../axios/postx';
 import {
        getSystemLang,
        getLangAll
    } from '../../lang';
    export default {
		data() {
		    return {
		        langs: '',
		        systemlang: 'cn',
                banner:{},
				mtid:'',
				PlayRegClassTypeValue:true,
				info:{},
				down:false,
				jd:'',
				PlayRegCaddie:'',
				sumitzb:true,
				hc_caddie_info:{}
				
		    }
		},
        name: 'Player',
        props: {
            msg: String,
			mt_id:String,
			parentgoDan:Object
        },
		components: {
			
        },
		created() {
			this.mtid = this.mt_id;
			this.getData();
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
		},
		watch:{
			mtid(newvalue)
			{
				this.getData();
				
			}
		},
		methods:{
			/*跳转到订单列表*/
			sumitgo(){
				console.log('sssss');
				this.parentgoDan('csbmmd');
			},
			downFlag(){
				if(this.down == true)
				{
					this.down = false;
				}
				else
				{
					this.down = true;
				}
			},
			getData()
			{
				
				let param = {};
				if(this.mtid)
				{
					param.mt_id = this.mtid;
				}
				
				axios('user/orderinfo.json',param)
				.then((res)=>{
					let data = res.data.data;
					this.info = data;
					this.mtid = data.mtinfo.ID;
					this.jd = data.bmconfig.RegistHotelName;
					this.PlayRegCaddie = '';
					if(param.mt_id > 0)
					{
						this.hc_caddie_info = JSON.parse(JSON.stringify(data.hole.hc_caddie_info));
						// if(this.hc_caddie_info[1] && this.hc_caddie_info[1].key)
						// {
						// 	this.PlayRegCaddie = this.hc_caddie_info[1].key;
						// }
					}
					
					
					if(data.o)
					{
						if(data.o.PlayRegClassType == '正赛报名' || data.o.PlayRegClassType == 'Tour Entry')
						{
							this.PlayRegClassTypeValue = true;
						}
						else
						{
							this.PlayRegClassTypeValue = false;
						}
						
						
						this.info.pinfo.pap = data.o.PlayRegClass;
						this.jd = data.o.PlayRegHotel;
						this.PlayRegCaddie = data.o.PlayRegCaddie;
						this.info.pinfo.pClub = data.o.PlayRegClub;
						
						

					}
					
				});
			},
			sumitData(){
				// console.log(this.jd,this.PlayRegCaddie,this.info.pinfo,this.PlayRegClassTypeValue,this.info.mtinfo)
				let param = {};
				param.jd = this.jd;
				param.PlayRegCaddie = this.PlayRegCaddie;
				param.PlayRegClassTypeValue = this.PlayRegClassTypeValue;
				let that = this;
				
				if(this.PlayRegCaddie == '')
				{
					if(this.systemlang == 'en')
					{
						alert('Please select caddie');
					}
					else
					{
						alert('请选择球童');
					}
					
					return false;
				}

				for(let i in this.info.pinfo)
				{
					if(!param[i])
					{
						param[i] =  this.info.pinfo[i];
					}
				}
				for(let i in this.info.mtinfo)
				{
					if(!param[i])
					{
						param[i] =  this.info.mtinfo[i];
					}
				}
				param.matchplace = this.info.mtinfo.mt_date+','+this.info.mtinfo.ColumnName+','+this.info.bmconfig.RegistEnd;
				axiosx('user/ordersave.json',param)
				.then((res)=>{
					let data = res.data;
					if(data.msgCode != '200')
					{
						alert(data.msg);
					}
					else
					{
						if(that.systemlang == 'en')
						{
							alert('successed');
						}
						else
						{
							alert('提交成功');
						}
						
						this.sumitgo();
						this.getData();
					}
					
					
				});
			}
		}
    }
</script>

<style scoped="scoped">
	.d_title {
		margin-top: 0.14rem;
		color: rgb(192, 21, 62);
		font-size: 0.28rem;
		padding-bottom: 4px;
		/* font-weight: bold; */
		font-family: '微软雅黑';
		border-bottom: 1px solid rgb(192, 21, 62);
		display: inline-block;
		padding-right: 30px;
	}

	.d_title span {
		font-size: 0.24rem;
	}
	.lf_match_info_box{
		padding: 0.35rem 0px;
		font-size: 0.14rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.lf_entry_info_box_left{
		padding-bottom: 0.7rem;
		background-color: #FAFAFA;
		margin-left: 0.24rem;
	}
	.lf_entry_info_box_left_tit{
		padding: 0.35rem 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.24rem;
		color: #C0153E;
	}
	.lf_entry_info_box_left_con_list{
		display: flex;
		align-items: center;
		min-height: 0.8rem;
	}
	.lf_entry_info_box_left_con_list_left{
		width: 2.5rem;
		display: flex;
		justify-content: flex-end;
		font-size: 0.24rem;
		color: #999;
	}
	.lf_entry_info_box_left_con_list_left text{
		color: #C0153E;
		display: flex;
		align-items: center;
	}
	.lf_entry_info_box_left_con_list_right{
		display: flex;
		align-items: center;
		margin-left: 0.8rem;
		font-size: 0.24rem;
	}
	.lf_entry_info_box_left_con_list_right .el-select{
		width: 7.8rem;
		height: 0.6rem;
	}
	.lf_entry_info_box_left_con_list_right .el-radio-group{
		width: 1.6rem;
	}
	.lf_entry_info_box_left_con_list_right .lf_que{
		width: 0.14rem;
		height: 0.14rem;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-size: 0.24rem;
		margin-left: 0.1rem;
		margin-top: 2px;
		z-index: 1;
		margin-right: 0.2rem;
		position: relative;
	}
	
	.lf_entry_info_box_right{
		margin-left: 0.24rem;
		margin-right: 0.24rem;
		padding: 0 0.24rem 0.7rem;
		background-color: #FAFAFA;
	}
	.lf_entry_info_box_right_tit{
		padding: 0.35rem 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.24rem;
		color: #C0153E;
	}
	.lf_entry_info_box_right_con{
		line-height: 0.4rem;
		color: #666666;
		font-size: 0.24rem;
		word-break: break-word;
	}
	.lf_line{
		width: 96%;
		border-bottom: 1px solid #EFEFEF;
		margin: 0.2rem auto 0;
	}
	.lf_tips_box{
		padding: 0.16rem 0.2rem;
		position: absolute;
		left: 0.2rem;
		top: -0.1rem;
		width: 3.2rem;
		background-color: #fff;
		border: 1px solid #A8A8A8;
		border-radius: 0.1rem;
		color: #333;
	}
	.lf_tips_box text{
		color: #C0153E;
	}
	.lf_tips_t{
		margin-bottom: 0.1rem;
	}
	@media screen and (max-width: 768px) {
		.lf_match_info_box{
			padding: 0.35rem 0px;
			margin-left: 0;
		}
		.lf_entry_info_box_left{
			margin-left: 0;
		}
		.lf_entry_info_box_left_con_list_left{
			width: 1.6rem;
			display: flex;
			justify-content: flex-end;
			font-size: 0.24rem;
			color: #999;
		}
		.lf_tips_box{
			left: -0.4rem;
			top: 0.3rem;
			width: 3rem;
		}
		.lf_entry_info_box_left_con_list_right .el-radio-group{
			width: 2rem;
		}
		.lf_entry_info_box_left_con_list_right .lf_que{
			left: 0;
			width: 0.2rem;
			height: 0.2rem;
		}
		.lf_entry_info_box_left_con_list_right{
			font-size: 0.24rem;
		}
		.lf_entry_info_box_right{
			margin-left: 0;
			margin-top: 0.2rem;
			font-size: 0.24rem;
			margin-right: 0;
		}
		.lf_entry_info_box_right_tit{
			font-size: 0.24rem;
		}
		.lf_entry_info_box_right_con{
			font-size: 0.24rem;
		}
		.el-input__inner{
			font-size: 0.24rem;
		}
		.lf_entry_info_box_left_tit{
			font-size: 0.24rem;
		}
	}
</style>
