<template>
	<el-row type="flex" justify="center">
		<el-col :xs="24" :sm="16" :md="16">
			<div class="lf_match_info_box">
				<div class="d_title">{{info.info?info.info.ColumnPlace:''}} | <span>Course</span></div>
				<div class="lf_match_info_box_text">
					{{info.info?info.info.qc_desc:''}}
				</div>
				<div class="d_title">比赛码数/标准杆 | <span>Par</span></div>
				<div  class="lf_match_info_box_con">
					<el-row>
						<el-col :xs="24" :sm="12" :md="12">
							<div class="lf_match_info_con">
								<div class="lf_1">Hole</div>
								<div v-for="i in hole1" class="lf_2">{{info.tee&&info.tee[i-1].mh_id?info.tee[i-1].mh_id:''}}</div>
								<div class="lf_11">OUT</div>
							</div>
							<div class="lf_match_info_con">
								<div class="lf_1">Par</div>
								<div v-for="i in hole1" class="lf_2">{{info.tee&&info.tee[i-1].mh_par?info.tee[i-1].mh_par:''}}</div>
								<div class="lf_11">{{jssj('mh_par','q')}}</div>
							</div>
							<div class="lf_match_info_con">
								<div class="lf_1">Yardage</div>
								<div v-for="i in hole1" class="lf_2">{{info.tee&&info.tee[i-1].tee_y?info.tee[i-1].tee_y:''}}</div>
								<div class="lf_11">{{jssj('tee_y','q')}}</div>
							</div>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12">
							<div class="lf_match_info_con">
								<div v-for="i in hole2" class="lf_2">{{info.tee&&info.tee[i-1].mh_id?info.tee[i-1].mh_id:''}}</div>
								<div class="lf_11">IN</div>
								<div class="lf_12">TOTAL</div>
							</div>
							<div class="lf_match_info_con">
								<div v-for="i in hole2" class="lf_2">{{info.tee&&info.tee[i-1].mh_par?info.tee[i-1].mh_par:''}}</div>
								<div class="lf_11">{{jssj('mh_par','h')}}</div>
								<div class="lf_12">{{jssj('mh_par','q')+jssj('mh_par','h')}}</div>
							</div>
							<div class="lf_match_info_con">
								<div v-for="i in hole2" class="lf_2">{{info.tee&&info.tee[i-1].tee_y?info.tee[i-1].tee_y:''}}</div>
								<div class="lf_11">{{jssj('tee_y','h')}}</div>
								<div class="lf_12">{{jssj('tee_y','q')+jssj('tee_y','h')}}</div>
							</div>
						</el-col>
					</el-row>
				</div>
				<div class="d_title">比赛形式 | <span>Format</span></div>
				<div class="lf_match_info_box_text">
					{{info.info?info.info.bisxs:''}}
				</div>
				<div class="d_title">门票信息 | <span>Ticket</span></div>
				<div class="lf_match_info_box_text">
					{{info.info?info.info.mpxx:''}}
				</div>
				<div class="d_title">赛事历史 | <span>History</span></div>
				<div class="lf_match_info_box_text">
					{{info.info?info.info.ssls:''}}
				</div>
				<div class="d_title">往年冠军 | <span>Past Champions</span></div>
				<div class="lf_match_champion_his_box" v-if="info.round">
					<div class="lf_match_champion_his_tit">
						<div class="lf_champion_his_1">{{langs.Year}}</div>
						<div class="lf_champion_his_2">{{langs.Champion}}</div>
						<div class="lf_champion_his_3">{{langs.text_pl_country}}</div>
						<div class="lf_champion_his_4">{{langs.text_price}}</div>
						<div class="lf_champion_his_5">
							<div class="lf_rd_tit">{{langs.RoundScore}}</div>
							<div class="lf_rd_box">
								<div  v-for="gj in info.round">R{{gj}}</div>
								
							</div>
						</div>
						<div class="lf_champion_his_6">{{langs.TotalScore}}</div>
						<div class="lf_champion_his_7"></div>
					</div>
					<div class="lf_match_champion_his_con" v-for="(b,index) in info.gj">
						<div class="lf_champion_his_1">{{b.year}}</div>
						<div class="lf_champion_his_2">
							{{b.pl_name}}
							<div class="lf_player_card" v-if="index == kan">
								<img class="lf_player_card_img" :src="b.pl_photo?b.pl_photo:''" alt="">
								<div class="lf_player_card_con">
									<div class="lf_player_card_con_1">{{b.pl_name}} <img v-if="b.pl_country!=''" :src="b.pl_country" alt=""></div>
									<div class="lf_player_card_con_2">{{langs.Events}}：{{b.mt_name}}</div>
									<div class="lf_player_card_con_2">{{langs.Period}}：{{b.mt_date}}</div>
									<div class="lf_player_card_con_2">{{langs.WinstoPar}}：{{jszgs(b.cj)}}{{jszgsstr(b.cj)}}</div>
									<div class="lf_player_card_con_2">{{langs.text_price}}：{{b.prize}}</div>
								</div>
							</div>
						</div>
						<div class="lf_champion_his_3"><img v-if="b.pl_country!=''" :src="b.pl_country" alt=""></div>
						<div class="lf_champion_his_4">{{b.pr_amount}}</div>
						<div class="lf_champion_his_5">
							<div class="lf_rd_box">
								<div v-for="gj in info.round">{{b.cj[gj-1]?b.cj[gj-1].su_score:'--'}}</div>
								
							</div>
						</div>
						<div class="lf_champion_his_6">{{jszgs(b.cj)}}</div>
						<div class="lf_champion_his_7" @click="kand(index)">{{langs.More}}</div>
					</div>
				</div>
			</div>
		</el-col>
	</el-row>
</template>

<script>
import axios from '../axios/request';
import { getSystemLang, getLangAll } from "../lang";
    export default {
		data() {
		    return {
		        langs: '',
		        systemlang: 'cn',
                banner:{},
				info:{},
				hole1:[1,2,3,4,5,6,7,8,9],
				hole2:[10,11,12,13,14,15,16,17,18],
				kan:'none'
		    }
		},
        name: 'Index',
        props: {
            msg: String,
			mt_id:String
        },
		
		created() {
			console.log(this.mt_id,'mt_id');
			axios('matchs/match/matchtj.json',{otp:'info',mt_id:this.mt_id})
            .then((res)=>{
                let data = res.data.data;
				this.info = data;
				console.log('info',this.info);
                
            });
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
		},
		methods:{
			kand(index)
			{
				if(this.kan == index)
				{
					this.kan = 'none';
				}
				else
				{
					this.kan = index;
				}
				
			},
			//夺冠成绩单轮显示
			jszgsstr(obj){
				let all = [];
				for(let i in obj)
				{
					all.push(obj[i].su_score);
				}
				return '('+all.join("+")+')';
			},
			//计算总杆数
			jszgs(obj){
				let all = 0;
				for(let i in obj)
				{
					all+=obj[i].su_score;
				}
				return all;
			},
			jssj($key,$index){
				if(this.info.tee)
				{
					let result = 0;
					for(let i in this.info.tee)
					{
						if($index == 'q' && this.info.tee[i].mh_id<=9)
						{
							result += this.info.tee[i][$key];
						}
						else if($index == 'h' && this.info.tee[i].mh_id>9)
						{
							result += this.info.tee[i][$key];
						}	
					}
					return result;
				}

				return '';
			}
		}
    }
</script>

<style scoped="scoped">
	.lf_footer1_tit_box {
		height: 1.4rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}

	.lf_footer1_tit_box .lf_foot_tit_text1 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.26rem;
		color: #C0153E;
	}

	.lf_footer1_tit_box .lf_foot_tit_text2 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.15rem;
		color: #C0153E;
	}

	.lf_footer1_tit_box .lf_foot_tit_line {
		width: 2rem;
		height: 3px;
		background-color: #C0153E;
		margin: 4px 0;
	}
	
	.lf_tab_tit_box {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin-bottom: 0.2rem;
	}

	.lf_tab_tit_box .el-select {
		position: absolute;
		left: 0.1rem;
	}
	.lf_tab_little_box{
		height: 30px;
		display: flex;
		align-items: center;
	}
	.lf_tab_little_box div {
		padding: 0 16px;
		height: 0.14rem;
		font-size: 0.16rem;
		color: #999;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		border-right: 1px solid #999;
		word-break: keep-all;
	}
	.lf_tab_little_box div:nth-last-of-type(1){
		border-right: unset;
	}
	.lf_tab_little_box .lf_tab_box_select {
		color: #C0153E;
	}
	.lf_tab_box {
		width: 330px;
		height: 30px;
		display: flex;
		align-items: center;
		margin-top: 40px;
	}

	.lf_tab_box div {
		width: 110px;
		height: 30px;
		font-size: 0.16rem;
		color: #999;
		border: 1px solid rgba(153, 153, 153, 1);
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		border-radius: 5px;
	}

	.lf_tab_box div:nth-of-type(1) {
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	.lf_tab_box div:nth-of-type(2) {
		border-radius: 0;
	}

	.lf_tab_box div:nth-of-type(3) {
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}
	.lf_tab_box .lf_tab_box_select {
		border-color: #C0153E;
		color: #C0153E;
	}
	.lf_opacity_div {
		width: 100%;
		position: absolute;
		left: 0%;
		top: 0;
		background-color: rgba(0, 0, 0, 0.5);
		color: #fff;
		height: 4rem;
		z-index: 99;
		padding: 0.5rem 0;
	}
	.lf_opacity_div img{
		max-width: 3.36rem;
		height: 0.65rem;
		margin: 0 auto;
		width: auto;
	}
	.lf_opacity_div_1{
		height: 0.7rem;
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		font-weight: 700;
		font-style: normal;
		font-size: 0.24rem;
		letter-spacing: 0.04rem;
		color: #FFFFFF;
		width: 3.36rem;
		line-height: 0.7rem;
		text-align: center;
		overflow: hidden;
		margin: 0.1rem auto 0;
		border-bottom: 1px solid #fff;
	}
	.lf_opacity_div_2{
		width: 3.36rem;
		margin: 0.25rem auto 0;
		text-align: center;
		font-size: 0.16rem;
		color: #fff;
		height: 0.25rem;
		line-height: 0.25rem;
	}
	.lf_opacity_div_3{
		width: 3.36rem;
		margin: 0 auto;
		text-align: center;
		font-size: 0.16rem;
		color: #fff;
		height: 0.25rem;
		line-height: 0.25rem;
	}
	.lf_opacity_div_4{
		width: 3.36rem;
		margin: 0 auto;
		text-align: center;
		font-size: 0.16rem;
		color: #fff;
		height: 0.25rem;
		line-height: 0.25rem;
	}
	.lf_opacity_div_5{
		width: 1rem;
		height: 0.35rem;
		background: inherit;
		background-color: rgba(242, 242, 242, 0);
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(255, 255, 255, 1);
		border-radius: 5px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.14rem;
		color: #FFFFFF;
		margin: 0.25rem auto 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.d_title {
		margin-top: 0.14rem;
		margin-left: 10px;
		color: rgb(192, 21, 62);
		font-size: 0.2rem;
		padding-bottom: 4px;
		/* font-weight: bold; */
		font-family: '微软雅黑';
		border-bottom: 1px solid rgb(192, 21, 62);
		display: inline-block;
		padding-right: 30px;
	}

	.d_title span {
		font-size: 0.14rem;
	}
	.lf_match_banner_box{
		height: 4rem;
		width: 100%;
		position: relative;
	}
	.lf_match_banner_box_img{
		height: 4rem;
	}
	.lf_match_info_box_text{
		padding: 0.24rem 10px;
		font-size: 0.14rem;
		color: #333;
		white-space: pre-wrap;
		
	}
	.lf_match_info_box_con{
		padding: 0.24rem 10px;
	}
	.lf_match_info_con{
		display: flex;
		background-color: #FAFAFA;
		box-sizing: border-box;
	}
	.lf_match_info_con div{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 0.6rem;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.16rem;
		color: #333;
		border-right: 1px solid #F5F5F5;
		border-bottom: 1px solid #F5F5F5;
	}
	.lf_match_info_con .lf_1,.lf_match_info_con .lf_12{
		width: 1.1rem;
		height: 0.5rem;
		color: #C0153E;
		flex-grow: 1;
		flex-shrink: 0;
	}
	.lf_match_info_con .lf_11{
		width: 0.6rem;
		height: 0.5rem;
		color: #C0153E;
		flex-shrink: 0;
	}
	.lf_match_info_con .lf_2{
		flex-shrink: 0;
		width: 0.5rem;
	}
	.lf_match_champion_his_box{
		padding: 0.24rem 10px;
		font-size: 0.16rem;
		
	}
	.lf_match_champion_his_tit{
		display: flex;
		background-color: #FAFAFA;
		height: 0.8rem;
		align-items: center;
		border-bottom: 1px solid #F5F5F5;
		color: #333;
	}
	.lf_champion_his_1{
		width: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_champion_his_2{
		width: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-grow: 1;
		position: relative;
	}
	.lf_player_card{
		position: absolute;
		left: 1.5rem;
		top: 0;
		width: 5.2rem;
		height: 2.5rem;
		box-shadow: 0px 0px 4px 2px #f1f1f1;
		display: flex;
		border-radius: 5px;
		background-color: #fff;
		z-index: 9;
	}
	.lf_player_card_img{
		margin: 0.3rem;
		width: 1rem;
		height: 1rem;
		border-radius: 50%;
	}
	.lf_player_card_con{
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.lf_player_card_con_1{
		font-size: 0.24rem;
		color: #333;
		display: flex;
		align-items: center;
		margin-bottom: 0.2rem;
	}
	.lf_player_card_con_1 img{
		width: 0.3rem;
		height: 0.21rem;
		margin-left: 0.3rem;
	}
	.lf_player_card_con_2{
		font-size: 0.16rem;
		color: #999;
		display: flex;
		align-items: center;
		min-height: 0.35rem;
		display: flex;
		align-items: center;
	}
	.lf_champion_his_3{
		width: 1.3rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_champion_his_3 img{
		width: 20px;
		height: 14px;
	}
	.lf_champion_his_4{
		width: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_champion_his_5{
		width: 3.1rem;
		display: flex;
		flex-direction: column;
	}
	.lf_champion_his_6{
		width: 1.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_champion_his_7{
		width: 1.6rem;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	.lf_rd_tit{
		width: 100%;
		height: 0.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_rd_box{
		width: 100%;
		height: 0.4rem;
		display: flex;
		flex-direction: row;
	}
	.lf_match_champion_his_con .lf_rd_box{
		height: ;
	}
	.lf_rd_box div{
		flex-grow: 1;
		height: 0.4rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_match_champion_his_con{
		display: flex;
		background-color: #FAFAFA;
		color: #999;
	}
	@media screen and (max-width: 768px) {
		.lf_footer1_tit_box {
			margin-top: 0.5rem;
		}

		.lf_tab_tit_box {
			align-items: unset;
		}

		.lf_tab_box {
			margin-top: 0.2rem;
		}
		.lf_match_banner_box{
			height: 2.5rem;
			width: 100%;
			position: relative;
		}
		.lf_match_banner_box_img{
			height: 2.5rem;
		}
		.lf_opacity_div {
			width: 100%;
			position: absolute;
			left: 0;
			top: 0;
			background-color: rgba(0, 0, 0, 0.5);
			color: #fff;
			height: 100%;
			z-index: 99;
			padding: 0.1rem 0;
		}
		.lf_opacity_div img{
			display: none;
		}
		.lf_opacity_div_1{
			height: 0.7rem;
			font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
			font-weight: 700;
			font-style: normal;
			font-size: 0.24rem;
			letter-spacing: 0.04rem;
			color: #FFFFFF;
			width: 3.36rem;
			line-height: 0.7rem;
			text-align: center;
			overflow: hidden;
			margin: 0.42rem auto 0;
			border-bottom: 1px solid #fff;
		}
		.lf_opacity_div_2{
			display: none;
		}
		.lf_opacity_div_3{
			display: none;
		}
		.lf_opacity_div_4{
			display: none;
		}
		.lf_opacity_div_5{
			width: 1.1rem;
			height: 0.45rem;
			padding: 0.04rem;
		}
		.lf_12{
			display: none!important;
		}
		.lf_match_info_con{
			justify-content: flex-end!important;
		}
		.lf_match_info_con .lf_2{
			width: 0.6rem;
		}
		.lf_champion_his_5,.lf_champion_his_6{
			display: none;
		}
		.lf_player_card{
			position: absolute;
			left: 0;
			top: 0.4rem;
			width: 5.2rem;
			height: 2.5rem;
			box-shadow: 0px 0px 4px 2px #f1f1f1;
			display: flex;
			border-radius: 5px;
			background-color: #fff;
			z-index: 9;
		}
		.d_title span {
			font-size: 0.24rem;
		}
		.lf_match_info_con div{
			font-size: 0.24rem;
		}
		.d_title,.d_title span{
			font-size: 0.26rem;
		}
	}
</style>
