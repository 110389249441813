<template>
	<div style="width: 100%;height:2.25rem" v-show="bannershow && bannershow['OTHER_TOP'] == '1'">
		<banner heightNew="2.25rem" :type="0" abs="OTHER_TOP"></banner>
	</div>
	<Btn cd="ph"></Btn>
<div class="select-back" v-if="typeshow || yearshow" @click="typeshow=false;yearshow=false;unlock();"></div>

	<div class="lf_footer1">
		<el-row>
			<el-col :xs="24" :sm="24" :md="24">
				<div class="lf_footer1_tit_box">
					<div class="lf_foot_tit_text1">{{langs["Players & Stats"]}}</div>
					<div class="lf_foot_tit_line"></div>
					<div class="lf_foot_tit_text2">{{langs["Players & Stats_en"]}}</div>
				</div>
			</el-col>
		</el-row>
	</div>
	<div class="head">
		<div class="head_top">
			<div class="searchimg">
				<img src="../../assets/images/u6324.png" />
			</div>
			<div class="searchbox" style="position: relative;">
				<input v-model="searchkey" placeholder="" type="text" @keyup.enter="search(searchkey,'input')"/>
				<img @click="searchM()" v-if="searchkey!=''" style="width: 0.3rem;position: absolute;right: 1.6rem;top: 0.1rem;z-index: 9;" src="../../assets/images/u21104.png" alt="" />
				<div class="searchbutton hand" @click="search(searchkey,'input')">{{langs.psearch_search}}</div>
			</div>
		</div>

		<div class="searchword">{{langs.psearch_contentdesc}}</div>
	</div>
	<div class="all_body">

		<div class="changebox">
			<div class="changekind hand">
				<div class="lf_match_info_box">

					<div @click.stop="changetypeshow">
						<div class="d_title" v-if="titlearr!=undefined">{{titlearr[curstatstype].titleleft}} | <span>{{titlearr[curstatstype].titleright}}</span></div>
						<img src="../../assets/images/u182.png" class="d_img" />
					</div>
					<div class="select-left into" v-if="typeshow">
						<div class="select_title">{{titlearr[curstatstype].titleleft}} | {{titlearr[curstatstype].titleright}}</div>
						<div class="select_socl">
							<div class="select_item" :class="curstatstype==item.value?'select_item_hover':''" v-for="item in options" @click="changestatstype(item.value)">{{item.label}}</div>
						</div>
					</div>


				</div>
			</div>
			<div class="changeTimeAll" >
			<div class="changetime_new hand" @click.stop="changeyearshow">
				<span class="timeword_new">
					{{year=="all"?langs.quanbuniandu:(year+langs.year_cn)}}
				</span>
				<img src="../../assets/images/u2764.png" />
				
			</div>
			<div class="changetime changetime_s" @click.stop="changeyearshow">
				<span class="timeword">
					{{year=="all"?langs.quanbuniandu:(year+langs.year_cn)}}
				</span>
				<div class="changetimeimg">
					<img src="../../assets/images/u182.png" />
				</div>
				
			</div>
			<div class="select-right into" style="top: 0.6rem;" v-if="yearshow">
				<div class="select_title">{{year=="all"?langs.quanbuniandu:(year+langs.year_cn)}}</div>
				<div class="select_socl">
					<div class="select_item" :class="year=='all'?'select_item_hover':''" @click="changeyear('all')">{{langs.quanbu}}</div>
					<div class="select_item" :class="year==item.value?'select_item_hover':''" @click="changeyear(item.value)" v-for="item in yearoptions">{{item.label}}</div>
					
				</div>
			</div>
			</div>
		</div>
		<div class="lf_match_info_box lf_match_info_box_S" v-if="'getfronttennum'==curstatstype">
			<div class="lf_group_tit_left">
				<el-radio-group v-model="topnum">
					<el-radio :label="10" @click="changetopnum(10)">TOP 10</el-radio>
					<el-radio :label="5" @click="changetopnum(5)">TOP 5</el-radio>
					<el-radio :label="3" @click="changetopnum(3)">TOP 3</el-radio>
					<el-radio :label="1" @click="changetopnum(1)">TOP 1</el-radio>
				</el-radio-group>
			</div>
		</div>

		<div class="table" v-loading="loading">
			<template v-if="list.length>0">
				<div class="tableitem ">
					<span class="headwordp">{{langs.text_ranking}}</span>
					<span class="headwordq">{{langs.text_player}}</span>
					<span class="headwords headwords_lf" v-if="fieldarr!=undefined">{{fieldarr[curstatstype][0]}}<span style="color: #C0153E;" v-if="curstatstype=='getfronttennum'">(Top {{topnum}})</span></span>
					<span class="headwords" v-if="fieldarr!=undefined">{{fieldarr[curstatstype][1]}}</span>
				</div>
			<!-- <div class="tableitemBody" v-for="item in 10">
				<div class="bodywordp">1</div>
				<div class="bodywordq">
					<img src="../../assets/images/u33.png" alt="" class="country" />
					<div class="center">
						<div class="bodyname">刘文博</div>
						<div class="bodynamee">Wenbo LIU</div>
					</div>

					<div class="headlogo">
						<img src="../../assets/images/u33.png" alt="" />
					</div>
				</div>
				<span class="headwords">

					12
				</span>
				<div class="headwords">
					<span style="color: #000;">24</span>
				</div>
			</div> -->
				<template  v-for="(item,index) in list" :key="index">
					<div class="banner" v-if="index%20==0&&index!=0" v-show="bannershow && bannershow['STATS_CENTER'] == '1'">
						<banner heightNew="1.85rem" :type="2" abs="STATS_CENTER"></banner>
					</div>
					<div class="tableitemBody" :id="item.pl_id-200000">
						<div class="bodywordp">{{item.rank_code}}{{item.rank}}</div>
						<div class="bodywordq">
							<router-link target="_blank" :to="{path:'/playerInfo',query:{pl_id:item.pl_id-200000,lang:systemlang}}">
								<img :src="item.pl_country_img" alt="" class="country" />
								<div class="center">
									<div class="bodyname hoverUnderLine">{{item.pl_cn_name}}</div>
									<div class="bodynamee hoverUnderLine">{{item.pl_en_name}}{{item.pl_type=="AMA"?"(A)":""}}</div>
								</div>
								<div class="headlogo">
									<banner heightNew="0.5rem" :type="2" datatype="1" :dataurl="item.zhanzhu"></banner>
								</div>
							</router-link>
						</div>
						<span class="headwords" style="color: #333;">
							<span v-if="'getfronttennum'==curstatstype||'getbirdienum'==curstatstype||'geteaglenum'==curstatstype">{{item.num}}</span>
							<span v-else>{{item.num.toFixed(1)}}</span>
						</span>
						<div class="headwords">
							<span style="color: #333;">{{item.matchnum}}</span>
						</div>
					</div>
				</template>
			</template>
			<div v-else>
				<empty></empty>
			</div>
		</div>

	</div>

	<Footer></Footer>
	<Footer1></Footer1>
</template>

<script>
	// @ is an alias to /src
	import banner from "../../components/banner.vue";
	import navs from "../../components/nav.vue";
	import Btn from "../../components/Btn.vue";
	import Footer from "../../components/Footer.vue";
	import Footer1 from "../../components/Footer1.vue";
	import axios from '../../axios/request';
	import empty from "../../components/empty.vue";
	import {
		getSystemLang,
		getLangAll
	} from "../../lang";
	//这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue/swiper-vue';

	import {
		Autoplay,
		Pagination
	} from 'swiper';
	import 'swiper/swiper-bundle.css';
	import 'swiper/swiper.min.css';
	//这里就是更新后的路径，以前是import 'swiper/css'

	var enddata = (new Date()).getFullYear();
	var years = [];
	for (var i = enddata; i >= 2004; i--) {
		years.push({
			value: i,
			label: i
		});
	}



	//自动轮播的配置
	export default {
		data() {
			return {
				input: '',
				langs: '',
				systemlang: 'cn',
				input: '',
				value: '',
				years: years,
				year: '',
				matchs: [],
				mt_id: '',
				pl_name: '',
				banner: {},
				wonderful: {},
				xhs: {},
				match: {},
				//xhs:巡回赛新闻 match:赛站新闻
				statstype:"",
				curstatstype:"",
				list:[],
				topnum:10,
				searchkey:"",
				w:['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
				typeshow:false,
				yearshow:false,
				bannershow:{},
				loading: false,
				pl_id:""
			}
		},
		props: {

		},
		watch: {
			topnum:function(newVal,oldVal){
				// if(this.systemlang == "cn"){
				// 	var arr = {"10":"十","5":"五","3":"三","1":"一"};
				// 	var arr_en = {"10":"10","5":"5","3":"3","1":"1"};
				// }else{
				// 	var arr = {"10":"10","5":"5","3":"3","1":"1"};
				// 	var arr_en = {"10":"十","5":"五","3":"三","1":"一"};
				// }
				// this.titlearr["getfronttennum"].titleleft = this.langs["qianncishu_qian"]+arr[newVal]+this.langs["qianncishu_cishu"];
				// this.titlearr["getfronttennum"].titleright = this.langs["qianncishu_qian_en"]+arr_en[newVal]+this.langs["qianncishu_cishu_en"];
			}
		},
		name: 'Home',
		components: {
			navs,
			Btn,
			Footer,
			Footer1,
			Swiper,
			SwiperSlide,
			banner,
			empty
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			this.pl_id = this.$route.query.pl_id;
			var year = this.$route.query.year;
			this.topnum = this.$route.query.topnum?parseInt(this.$route.query.topnum):10;
			var maxyear = (new Date()).getFullYear();
			var yearoptions = [];
			for(var i=maxyear;i>=2010;i--){
				yearoptions.push({"value":i,"label":i})
			}
			this.yearoptions = yearoptions;
			this.year = year;
			var statstype = this.$route.query.statstype;
			this.statstype = statstype
			this.curstatstype = statstype
			axios('tecstatistics/getstatslist',{year:year})
			.then((res)=>{
				var list = res.data.data.data;
				var options = []
				var titlearr = {}
				var fieldarr = {}
				for(var key in list){
					titlearr[list[key].type] = {};
					if(getSystemLang() == "cn"){
						options.push({value:list[key].type,label:list[key].titleleft})
						titlearr[list[key].type].titleleft = list[key].titleleft
						titlearr[list[key].type].titleright = list[key].titleright
						fieldarr[list[key].type] = list[key].field
					}else{
						options.push({value:list[key].type,label:list[key].titleright})
						titlearr[list[key].type].titleleft = list[key].titleright
						titlearr[list[key].type].titleright = list[key].titleleft
						fieldarr[list[key].type] = list[key].field
					}
				}
				this.options = options;
				this.titlearr = titlearr
				this.fieldarr = fieldarr
				this.getdata(year,statstype);
			})
			document.body.addEventListener('click', (e) => {
				this.typeshow = false;
				this.yearshow = false;
				this.unlock();
			});
		},
		setup() {
			
		},
		methods: {
			search: function(searchkey,type) {
				if(searchkey==""){
					alert(this.systemlang=='cn'?'请输入内容':"Please enter content");
					return;
				}
				// PlayerSearch?lang=cn&leter=B&searchkey=B&page=1
				if(type == "leter"){
					var leter = searchkey;
				}else{
					var leter = ""
				}
				var params = {
					lang:getSystemLang(),
					leter:leter,
					searchkey:searchkey
				}
				let routeUrl = this.$router.resolve({
					path: "/PlayerSearch",
					query: params
				});
				// console.log(routeUrl.href);
				window.open(routeUrl.href, '_self');
			},
			getdata(year,statstype){
				this.list = [];
				this.loading = true;
				var that = this
				axios(("tecstatistics/"+statstype),{year:year,topnum:this.topnum})
				.then((res)=>{
					var list = res.data.data.data.list;
					this.year = res.data.data.data.year;
					for(var key in list){
						if (getSystemLang() == "cn") {
							list[key]["pl_cn_name"] = list[key]["pl_cn_name"];
							list[key]["pl_en_name"] = list[key]["pl_en_name"];
						} else {
							var temp = list[key]["pl_cn_name"];
							list[key]["pl_cn_name"] = list[key]["pl_en_name"];
							list[key]["pl_en_name"] = temp;
						}
					}
					var fieldarr = that.fieldarr;
					console.log(fieldarr);
					if(fieldarr[statstype][1]!=undefined){
						this.fieldarr1 = true;
					}else{
						this.fieldarr1 = false;
					}
					that.list = list;
					this.loading = false;
					this.$nextTick(()=>{
						if(this.pl_id){
							if(location.href.indexOf("#") == -1){
								location.href += "#"+this.pl_id;
							}
						}
					})
				})
			},
			changeyear(year){
				this.topnum = 10;
				this.year = year;
				this.typeshow = false;
				this.yearshow = false;
				this.unlock();
				this.curstatstype = this.statstype;
				this.getdata(this.year,this.statstype);
			},
			changestatstype(statstype){
				this.topnum = 10;
				this.statstype = statstype
				this.typeshow = false;
				this.yearshow = false;
				this.unlock();
				this.curstatstype = this.statstype;
				this.getdata(this.year,this.statstype);
			},
			changetopnum(topnum){
				this.topnum = topnum;
				this.curstatstype = this.statstype;
				this.getdata(this.year,this.statstype);
			},
			changetypeshow(){
				this.typeshow = !this.typeshow;
				var width = document.body.clientWidth;
				if(this.typeshow==true && width<=750){
					document.body.style.overflow = 'hidden'
				}else{
					document.body.style.overflow = 'unset'
				}
				this.$nextTick(() => {
					const parent = document.querySelector(`.select_item_hover`).offsetTop;
					document.querySelector(`.select_socl`).scrollTo(0,parent-100)
				});
			},
			changeyearshow(){
				this.yearshow = !this.yearshow;
				var width = document.body.clientWidth;
				if(this.yearshow==true && width<=750){
					document.body.style.overflow = 'hidden'
				}else{
					document.body.style.overflow = 'unset'
				}
				this.$nextTick(() => {
					const parent = document.querySelector(`.select_item_hover`).offsetTop;
					document.querySelector(`.select_socl`).scrollTo(0,parent-100)
				});
			},
			searchM:function()
			{
				this.searchkey = '';
			},
			setbannershow(abs,isshow){
				this.bannershow[abs] = isshow;
			},
			unlock(){
				document.body.style.overflow = 'unset'
			}
		}

	}
</script>

<style scoped="scoped">
	.select_item{
		cursor: pointer;
	}
.lf_group_tit_left{
		margin-left: 0.2rem;
	}
	.banner {
		width: 18.5rem;
		height: 1.85rem;
		overflow: hidden;
	}
	
	.banner img {
		width: 100%;
		height: 1.85rem;
	}
	
	.table {
		width: 100%;
		overflow: hidden;
		background-color: #FAFAFA;
		margin-top: 0.2rem;
	
	}
	
	.tableitem {
		width: 17.88rem;
		margin: 0 auto;
		height: 0.9rem;
		line-height: 0.9rem;
		color: #666666;
		font-size: 0.24rem;
		text-align: center;
		border-bottom: 0.01rem solid #DBDBDB;
		overflow: hidden;
	}
	
	.tableitemBody {
		width: 17.88rem;
		height: 1.1rem;
		margin: 0 auto;
		line-height: 0.9rem;
		color: #666666;
		font-size: 0.28rem;
		text-align: center;
		border-bottom: 0.01rem solid #DBDBDB;
		overflow: hidden;
		display: flex;
		align-items: center;
		background-color: #fdfdfd;
	}
	.tableitemBody:nth-of-type(2n){
		background-color: #fafafa;
	}
	
	
	.headwordq {
		width: 5.9rem;
		height: 0.9rem;
		float: left;
	}
	
	
	.headwordp {
		width: 3rem;
		height: 0.9rem;
		float: left;
	}
	
	.headwords {
		width: 4.3rem;
		height: 0.9rem;
		float: left;
	
	}
	
	.bodywordp {
		width: 3rem;
		height: 0.9rem;
		font-size: 0.28rem;
		color: #000;
		float: left;
	}
	
	.bodywordq {
		width: 5.9rem;
		height: 0.9rem;
		float: left;
		font-size: 0.28rem;
		color: #000;
	}
	.bodywordq a{
		display: flex;
		align-items: center;
		height: 0.9rem;
	}
	.backWhite{
		background-color: #fff !important;
	}
	
	.center {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		height: 0.9rem;
		
	}
	
	.bodyname {
		color: #000;
		margin-left: 0.2rem;
		height: unset!important;
		display: flex;
		align-items: center;
		line-height: normal;
		text-align: left;
		
	}
	
	
	.bodynamee {
		font-size: 0.24rem;
		margin-left: 0.2rem;
		height: unset!important;
		display: flex;
		align-items: center;
		line-height: normal;
		text-align: left;
	}
	
	.country {
		width: 0.4rem;
		float: left;
	}
	
	.headlogo {
		width: 1.5rem;
		height: 0.5rem;
		float: right;
	
	}
	
	.headlogo img {
		width: 1.5rem;
		height: 0.5rem;
	
	}
	.changeTimeAll{
		position: relative;
	}
	
	
	
	.changetime_new {
		
		width: 2.6rem;
		text-align: center;
		background-color: #C0153E;
		border-radius: 0.1rem;
		height: 0.5rem;
		line-height: 0.5rem;
	
	
		font-size: 0.28rem;
		color: #fff;
		font-weight: bold;
		float: right;
	}
	
	.changeday {
		text-align: right;
		border-radius: 0.1rem;
		height: 0.5rem;
		line-height: 0.5rem;
	
	
		font-size: 0.22rem;
		color: #666666;
		float: right;
	}
	
	.changetime_new img {
		width: 0.3rem;
		height: 0.3rem;
		float: right;
		margin-top: 0.1rem;
		margin-right: 0.5rem;
	}
	
	.timeword_new {
		width: 1.2rem;
		float: left;
		margin-left: 0.5rem;
		height: 0.5rem;
	}
	
	
	.headwordbox {
		width: 18.5rem;
		height: 0.4rem;
		line-height: 0.4rem;
		color: #C0153E;
	
		text-indent: 0.4rem;
		font-size: 0.3rem;
		background-color: #FAFAFA;
		margin-top: 0.5rem;
		margin-left: 0.3rem;
	}
	
	.changetime{
		display: none;
		font-weight: bold;
	}
	.changetimeimg{
		float: right;
		transform: rotate(90deg);
	}
	.country {
		font-size: 0.3rem;
		color: #C0153E;
		font-weight: bold;
	}
	
	@media screen and (max-width: 768px) {
		.lf_match_info_box_S{
			margin-left: 0.25rem;
		}
		.changetime_new{
			display: none;
		}
		.changetime_s{
			display: flex;
			align-items: center;
		}
		.changetime_s .timeword {
			line-height: normal;
		}
		.tableitem {
			width: 7.02rem;
			margin: 0 auto;
			display: flex;
			align-items: center;
			height: auto;
		}
	
		.tableitemBody {
			width: 7.02rem;
			margin: 0 auto;
			height: 1.2rem;
			line-height: 1.2rem;
	
		}
		.changeday{
			width: 100%;
			
		}
		.headlogo {
			margin-top: 0.35rem;
			display: none;
		}
	
		.country {
	
			margin-top: 0;
		}
	
		.headwordp {
			width: 1.1rem;
			height: 100%;
			float: left;
			
		}
		.bodywordq a{
			display: flex;
			align-items: center;
			height: 1.2rem;
		}
		.headwordq {
			width: 3.5rem;
			height: 100%;
			float: left;
		}
	
		.headwords {
			width: 1.2rem;
			height: 100%;
			float: left;
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: normal;
			flex-wrap: wrap;
		}
		.headwords_lf{
			position: relative;
		}
		.headwords_lf span{
			position: absolute;
			top: 95%;
		}
		    
	
		.bodywordp {
			width: 1.1rem;
			height: 100%;
			font-size: 0.28rem;
			color: #000;
			float: left;
		}
	
		.bodywordq {
			width: 3.5rem;
			height: 1.2rem;
			float: left;
			font-size: 0.28rem;
			color: #000;
			
			
		}
	
		.center {
			height: 1.2rem;
			overflow: hidden;
			line-height: 0.5rem;
			display: flex;
			flex-wrap:unset;
			flex-direction: column;
			align-items:flex-start;
			justify-content: center;
		}
	
		.bodyname {
			margin-top: 0rem;
			color: #000;
			height: unset!important;
			text-align: left;
			line-height: normal;
		}
	
	
		.bodynamee {
			font-size: 0.24rem;
			height: unset!important;
			justify-content: flex-start;
			text-align: left;
			line-height: normal;
	
		}
		.banner{
			width: 100%;
			height: 0.75rem;
			overflow: hidden;
			margin: 0.2rem auto;
		}
	}
</style>