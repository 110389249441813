<template>
	
	<Btn v-if="flag!='min'"></Btn>
	<el-row>
		<el-col :xs="24" :sm="24" :md="24" class="lf_new_link_t">
			
			<router-link target="_blank" :to="{path:'/news',query:{lang:systemlang,year:info.year,category:category}}">{{info.year}}{{langs.year_cn}}</router-link>
			&nbsp;/&nbsp;
			<template v-for="(item,index) in typelist">
			<router-link v-if="category == item.id" target="_blank" :to="{path:'/news',query:{lang:systemlang,year:info.year,category:category}}">{{systemlang=='cn'?item.cn_name:item.en_name}}</router-link>
			</template>
			{{info.ColumnID?'&nbsp;/&nbsp;':''}}
			<router-link target="_blank" v-if="info.ColumnID" :to="{path:'/news',query:{lang:systemlang,year:info.year,category:category,mt_id:info.ColumnID}}">{{info.ColumnName}}</router-link>
		</el-col>
		<el-col :xs="24" :sm="24" :md="24" >
			<!-- 新闻内容 -->
			<div class="lf_titles" >{{info.ArticleTitle}}</div>
			<!-- <div class="lf_title_key" >{{langs.newslist_guanjianci}}：{{info.ArticleKeyword}}</div> -->
			<div class="lf_title_time" >{{langs.fbrq}}{{info.ArticleTime}}</div>
			<div class="lf_new_inner" v-html="info.ArticleContent"></div>
			
			<!-- 新闻内容 -->
		</el-col>
	</el-row>
	<Footer></Footer>
	<Footer1 v-if="flag!='min'"></Footer1>
</template>

<script>
	// @ is an alias to /src
	import navs from "../../components/nav.vue";
	import Btn from "../../components/Btn.vue";
	import Footer from "../../components/Footer.vue";
	import Footer1 from "../../components/Footer1.vue";
	import axios from '../../axios/request';
	import {
		getSystemLang,
		getLangAll
	} from "../../lang";
	

	import {
		Autoplay,
		Pagination
	} from 'swiper';
	import 'swiper/swiper-bundle.css';
	import 'swiper/swiper.min.css';
	//这里就是更新后的路径，以前是import 'swiper/css'

	//自动轮播的配置
	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				input:'',
				msgdata:[
					
				],
				category:0,
				info:{},
				flag:'',
				typelist: []
			}
		},
		props: {

		},
		name: 'Home',
		components: {
			navs,
			Btn,
			Footer,
			Footer1,
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);

			this.typelist = [{
					id: 0,
					cn_name: this.langs["newslist_quanbu"],
					en_name: this.langs["newslist_quanbu"]
				},
				{
					id: 1,
					cn_name: this.langs["newslist_pinpai"],
					en_name: this.langs["newslist_pinpai"]
				},
				{
					id: 2,
					cn_name: this.langs["newslist_qiuyuan"],
					en_name: this.langs["newslist_qiuyuan"]
				},
				{
					id: 3,
					cn_name: this.langs["newslist_guoji"],
					en_name: this.langs["newslist_guoji"]
				},
				{
					id: 4,
					cn_name: this.langs["newslist_zhuangbei"],
					en_name: this.langs["newslist_zhuangbei"]
				},
				{
					id: 5,
					cn_name: this.langs["newslist_jiaoxue"],
					en_name: this.langs["newslist_jiaoxue"]
				}
			];
			console.log('aaaaaa',this.langs)

			if(this.$route.query.category)
			{
				this.category = this.$route.query.category;
			}

			let id = this.$route.query.id;
			let flag = this.$route.query.flag;
			this.flag = flag;
			//获取巡回赛新闻/news/info/8826.json
			axios('news/info/'+id+'.json')
            .then((res)=>{
                let data = res.data.data;
                this.info = data;
				if(this.info.ArticleIntroduction == '')
				{
					this.info.ArticleIntroduction = this.info.ArticleTitle;
				}
				document.querySelector('meta[name="keywords"]').setAttribute('content', this.info.ArticleIntroduction);
				document.title = this.info.ArticleTitle;
            });
			 
			
		},
		setup() {
			const onSwiper = (swiper) => {
				console.log(swiper);
			};
			const onSlideChange = (e) => {
				console.log('slide change123123123', e.activeIndex);
			};
			const autoplayOptions = {
				delay: 5000,
				disableOnInteraction: false,
				loop: false,
				pauseOnMouseEnter: true,
				reverseDirection: true
			};
			return {
				onSwiper,
				onSlideChange,
				autoplayOptions,
				modules: [Pagination, Autoplay],
			};
		},
		methods: {

		}

	}
</script>


<style scoped="scoped">
	.lf_titles{
		margin: 0 auto;
		font-family: '微软雅黑';
		    font-weight: 400;
		    font-style: normal;
		    font-size: 0.3rem;
		    color: #333;
			font-weight: bold;
			text-align: center;
			overflow: hidden;
			margin-top: 0.3rem;
			margin-bottom: 0.3rem;
			
	}
	.lf_title_key{
		font-family: '微软雅黑';
		    font-weight: 400;
		    font-style: normal;
		    font-size: 0.24rem;
		    color: #666666;
			
			text-align: center;
			overflow: hidden;
			margin: 0 auto;
			margin-bottom: 0.1rem;
	}
	.lf_title_time{
		font-family: '微软雅黑';
		    font-weight: 400;
		    font-style: normal;
		    font-size: 0.24rem;
		    color: #666666;
			
			text-align: center;
			overflow: hidden;
			margin: 0 auto;
			margin-bottom: 0.1rem;
	}
	.lf_new_inner{
		overflow: hidden;
		width: 17.7rem;
		margin: 0 auto;
		margin-top: 0.2rem;
	}
	.lf_new_link_t{
		font-size:0.24rem;
		color:#C0153E;
		margin:0.5rem 0 0.3rem 0.75rem;
	}
	.lf_new_link_t a{
		text-decoration: underline;

	}
	@media screen and (max-width: 768px) {
		 .lf_titles{
			 width: 7.02rem;
			 text-align: left;
			 margin-bottom: 0.5rem;
		 }
		 .lf_title_key{
		 			  width: 7.02rem;
		 			 text-align: left;
		 		
		 }
		 .lf_title_time{
			  width: 7.02rem;
			 text-align: left;
			 margin-bottom: 0.3rem;
		 }
		 .lf_new_inner{
			 width: 7.02rem;
			
		 }
		 .lf_new_inner img{
		 	max-width:7.02rem;
			height:unset;
		 }
	}
	
</style>
