<template>
	<div style="width: 100%;" v-if="!info.list">
						<empty></empty>
						 
					</div>
<div v-else class="all_body">
	

		
			<div class="lf_match_info_box">
				<div class="d_title">{{systemlang=='cn'?'分组表':'Tee Times'}} | <span>{{systemlang!='cn'?'分组表':'Tee Times'}}</span></div>
			</div>
			<div class="lf_match_info_box" style="justify-content: space-between;margin-top: 0.2rem;">
				<div class="lf_group_tit_left">
					<el-radio-group v-model="radio">
						<el-radio v-if="info.info && info.info.mt_round_num" v-for="(i,inex) in info.info.mt_round_num" :label="(inex+1)+''">{{langs.text_di}}{{(inex+1)}}{{langs.text_lun}}</el-radio>

					</el-radio-group>
					<div class="lf_des_text_box">
						<div class="lf_des_text">
							<text> *</text> {{langs.SubjecttotheLocalTimeZone}}
						</div>
						<div v-if="info.list && info.list[radio]" class="lf_tec_download_img_box" @click="Douwn()">
							<text>{{langs.DownloadExcel}} </text><img src="../../assets/images/Excel.png" alt="">
						</div>
					</div>
				</div>
				<div v-if="info.list && info.list[radio]" class="lf_match_info_box_r">
					<div class="lf_tec_download_img_box" @click="Douwn()">
						<text>{{langs.DownloadExcel}} </text><img src="../../assets/images/Excel.png" alt="">
					</div>
				</div>
				
			</div>
			<div class="lf_match_info_box">

				<div class="lf_boxboxbox">
					<div class="lf_player_list_tit">
						<div class="lf_1">{{langs.Gruop}}</div>
						<div class="lf_2">{{langs.Start}}</div>
						<div class="lf_3">{{langs.Tee}}</div>
						<div class="lf_4">{{langs.matchyf_player1}}</div>
						<div class="lf_4">{{langs.matchyf_player2}}</div>
						<div class="lf_4">{{langs.matchyf_player3}}</div>
						<div v-if="info.info && (info.info.match_id == '33540'|| info.info.match_id == '34382' || info.info.match_id == '34554')" class="lf_4">{{langs.matchyf_player4}}</div>
						
					</div>

					<div style="width: 100%;" v-if="!info.list">
						<empty></empty>
						 
					</div>
					<div style="width: 100%;" v-if="info.list && !info.list[radio]">
						<empty></empty>
						 
					</div>

					<template v-if="info.list && info.list[radio]" v-for="(b,index) in info.list[radio]">
							<div :class="index%2==0?'lf_player_list_con_B lf_player_list_con':'lf_player_list_con'" v-if="plname == '' || b.searchkey.indexOf(plname) != -1">
								<div class="lf_player_list_con_con">
									<div class="lf_1">{{b.gp_seq}}</div>
									<div class="lf_2">{{b.gp_start_time}}</div>
									<div class="lf_3">{{b.gp_start_hole}}</div>
									<div class="lf_44" v-for="bi in b.player">
										<div class="lf_4_top hoverUnderLine">
											<img v-if="bi.pl_country" :src="bi.pl_country" alt="" class="img_left">
											<text>
												<router-link target="_blank" :to="{path:'/playerInfo',query:{bFlag:'career',pl_id:bi.pl_id,lang:systemlang}}">
												{{systemlang=='cn'?bi.pl_cn_name:bi.pl_en_name_c}} {{bi.pl_type=='AMA'?'(A)':''}}
												</router-link>
											</text>
											<div v-if="info&&info.zhanzhubyname&&info.zhanzhubyname[bi.pl_cn_name]&&info.zhanzhubyname[bi.pl_cn_name].length>0" class="img_right">
												<banner  :type="2" datatype="1" :dataurl="info.zhanzhubyname[bi.pl_cn_name]"></banner>
											</div>
										</div>
										<div class="lf_4_bottom hoverUnderLine">
											<router-link target="_blank" :to="{path:'/playerInfo',query:{bFlag:'career',pl_id:bi.pl_id,lang:systemlang}}">
												{{systemlang!='cn'?bi.pl_cn_name:bi.pl_en_name_c}}
											</router-link>
										</div>
									</div>
								
									<div class="lf_44" v-if="b.player && info.info && (info.info.match_id == '33540' || info.info.match_id == '34382' || info.info.match_id == '34554')" v-for="bi in (4-(b.player && b.player.length ? b.player.length : 3))"></div>
									<div class="lf_44" v-else-if="b.player && b.player.length" v-for="bi in (3-((b.player && b.player.length ? b.player.length : 3)))"></div>
								</div>
							</div>
							
							<div v-if="index%10==0" style="height:1.85rem;width:18.50rem;" class="banner" v-show="bannershow && bannershow['GROUP_CENTER'] == '1'">
										<banner @EventBack='setbannershow'  :type="2" abs="GROUP_CENTER"></banner>
							</div>
						
					</template>
				</div>
				<div class="lf_boxboxboxs">
					<div style="width: 100%;" v-if="!info.list">
						<empty></empty>
						 
					</div>
					<div style="width: 100%;" v-if="info.list && !info.list[radio]">
						<empty></empty>
						 
					</div>
					<template v-if="info.list && info.list[radio]" v-for="(b,index) in info.list[radio]">
					<div class="lf_pinktitle">
						<div class="lf_pinktitlepeople">Group<span
								style="font-size: 0.28rem;font-weight: bold;">{{b.gp_seq}}</span><span style="font-size: 0.28rem;">
								- {{b.gp_start_time}} Tee {{b.gp_start_hole}}</span></div>
						<div class="lf_pinktitleimg"></div>
						<div class="lf_pinktitleother">
							Topar
						</div>
						<div class="lf_pinktitleother">
							R{{radio}}
						</div>
						<div class="lf_pinktitleother">
							Thru
						</div>
					</div>

					<div class="lf_group_list_box" v-for="bi in b.player">
						<div class="lf_pinktitlepeople">
							<router-link target="_blank" :to="{path:'/playerInfo',query:{bFlag:'career',pl_id:bi.pl_id,lang:systemlang}}">
							<div class="lf_pinktitlename">
								<img v-if="bi.pl_country" :src="bi.pl_country">
								<div style="float: left;line-height: 0.3rem;margin-left: 0.1rem;">{{systemlang=='cn'?bi.pl_cn_name:bi.pl_en_name_c}} {{bi.pl_type=='AMA'?'(A)':''}}</div>
							</div>
							<div class="lf_pinktitlenamee">{{systemlang!='cn'?bi.pl_cn_name:bi.pl_en_name_c}} </div>
							</router-link>
						</div>
						<div class="lf_pinktitleimg"  ><banner v-if="info&&info.zhanzhubyname&&info.zhanzhubyname[bi.pl_cn_name]&&info.zhanzhubyname[bi.pl_cn_name].length>0"  :type="2" datatype="1" :dataurl="info.zhanzhubyname[bi.pl_cn_name]"></banner></div>
						
						<div v-if="info.score&&info.score[radio]&&info.score[radio][bi.pl_id]&&info.score[radio][bi.pl_id].topar!==''&&info.score[radio][bi.pl_id].topar<'0'" style="color:#C0153E;" class="lf_pinktitleother">
							{{info.score&&info.score[radio]&&info.score[radio][bi.pl_id]?(info.score[radio][bi.pl_id].topar>0?'+'+info.score[radio][bi.pl_id].topar:(info.score[radio][bi.pl_id].topar =='0'?"E":info.score[radio][bi.pl_id].topar)):'-'}}
						</div>
						<div v-else-if="info.score&&info.score[radio]&&info.score[radio][bi.pl_id]&&info.score[radio][bi.pl_id]['topar'+radio]&&(info.score[radio][bi.pl_id]['topar'+radio]!='')" class="lf_pinktitleother">
							{{info.score[radio][bi.pl_id]['topar'+radio]}}
						</div>
						<div v-else class="lf_pinktitleother">
							{{info.score&&info.score[radio]&&info.score[radio][bi.pl_id]?(info.score[radio][bi.pl_id].topar>0?'+'+info.score[radio][bi.pl_id].topar:(info.score[radio][bi.pl_id].topar =='0'?"E":info.score[radio][bi.pl_id].topar)):'-'}}
						</div>
						
						<div class="lf_pinktitleother">
							{{info.score&&info.score[radio]&&info.score[radio][bi.pl_id]&&info.score[radio][bi.pl_id]['R'+radio]?info.score[radio][bi.pl_id]['R'+radio]:'-'}}
						</div>
						<div class="lf_pinktitleother" :class="(info.score&&info.score[radio]&&info.score[radio][bi.pl_id]&&info.score[radio][bi.pl_id].ok_h=='F'?'lf_thru_F':'')">
							{{info.score&&info.score[radio]&&info.score[radio][bi.pl_id]?(info.score[radio][bi.pl_id].ok_h==18?'F':info.score[radio][bi.pl_id].ok_h):'-'}}
						</div>
					</div>
					<div v-if="index%10==0" class="banner" v-show="bannershow && bannershow['GROUP_CENTER'] == '1'">
										<banner @EventBack='setbannershow'  :type="2" abs="GROUP_CENTER"></banner>
							</div>
				</template>
				</div>
			</div>

</div>
</template>

<script>
	import axios from '../../axios/request';
	import Ad1 from "../Ad1.vue";
	import banner from "../banner.vue";
	import empty from "../empty.vue";
	import {
		getSystemLang,
		getLangAll
	} from '../../lang';
	export default {
		data() {
			return {
				langs: '',
				systemlang: 'cn',
				banner: {},
				list: {},
				pmlist: [],
				plname: '',
				radio: '',
				info: {},
				bannershow:[],
				datas:{}
			}
		},
		name: 'Player',
		props: {
			msg: String,
			mt_id: String,
		},
		components: {
			Ad1,
			banner,
			empty
		},
		created() {
			//获取banner
			axios('matchs/match/matchtj.json', {
					otp: 'groups',
					mt_id: this.mt_id
				})
				.then((res) => {
					let data = res.data.data;
					this.info = data;
					this.radio = data.info.mt_round + '';
					this.datas = data;
					console.log(this.radio, 'infffff');
					console.log(this.info,'infffff');
					console.log(this.info.info.mt_round_num,'infffff');

				});
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
		},
		methods: {
			setbannershow(abs, isshow) {
			console.log(abs,isshow,'ssssssssssssssss');
			this.bannershow[abs] = isshow;
			},
			Douwn(){
				
				var $url = "http://overview.wifigolf.com/customer/excel/group_excel.php?lang="+(this.systemlang=='cn'?'1':'2')+"&mt_id="+this.info.info.match_id+"&rd_id="+this.radio+"&tour_id=136&cl_id=482";
				window.open($url, '_self');
				alert(this.langs.xinwenjingxuan_down);
			}
		}
	}
</script>

<style scoped="scoped">
.lf_thru_F{
	color:#c0153e;
}
	.lf_footer1_tit_box {
		height: 1.4rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}

	.lf_footer1_tit_box .lf_foot_tit_text1 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.26rem;
		color: #C0153E;
	}

	.lf_footer1_tit_box .lf_foot_tit_text2 {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.15rem;
		color: #C0153E;
	}

	.lf_footer1_tit_box .lf_foot_tit_line {
		width: 2rem;
		height: 3px;
		background-color: #C0153E;
		margin: 4px 0;
	}

	.lf_tab_tit_box {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		padding: 0.2rem;
	}

	.lf_tab_tit_box .el-select {
		position: absolute;
		left: 0.1rem;
	}

	.lf_tab_little_box {
		height: 30px;
		display: flex;
		align-items: center;
	}

	.lf_tab_little_box div {
		padding: 0 16px;
		height: 0.14rem;
		font-size: 0.16rem;
		color: #999;
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		border-right: 1px solid #999;
		word-break: keep-all;
	}

	.lf_tab_little_box div:nth-last-of-type(1) {
		border-right: unset;
	}

	.lf_tab_little_box .lf_tab_box_select {
		color: #C0153E;
	}

	.lf_tab_box {
		width: 330px;
		height: 30px;
		display: flex;
		align-items: center;
		margin-top: 40px;
	}

	.lf_tab_box div {
		width: 110px;
		height: 30px;
		font-size: 0.16rem;
		color: #999;
		border: 1px solid rgba(153, 153, 153, 1);
		display: flex;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		border-radius: 5px;
	}

	.lf_tab_box div:nth-of-type(1) {
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	.lf_tab_box div:nth-of-type(2) {
		border-radius: 0;
	}

	.lf_tab_box div:nth-of-type(3) {
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}

	.lf_tab_box .lf_tab_box_select {
		border-color: #C0153E;
		color: #C0153E;
	}

	.lf_opacity_div {
		width: 100%;
		position: absolute;
		left: 0%;
		top: 0;
		background-color: rgba(0, 0, 0, 0.5);
		color: #fff;
		height: 4rem;
		z-index: 99;
		padding: 0.5rem 0;
	}

	.lf_opacity_div img {
		max-width: 3.36rem;
		height: 0.65rem;
		margin: 0 auto;
		width: auto;
	}

	.lf_opacity_div_1 {
		height: 0.7rem;
		font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
		font-weight: 700;
		font-style: normal;
		font-size: 0.24rem;
		letter-spacing: 0.04rem;
		color: #FFFFFF;
		width: 3.36rem;
		line-height: 0.7rem;
		text-align: center;
		overflow: hidden;
		margin: 0.1rem auto 0;
		border-bottom: 1px solid #fff;
	}

	.lf_opacity_div_2 {
		width: 100%;
		margin: 0.25rem auto 0;
		text-align: center;
		font-size: 0.16rem;
		color: #fff;
		height: 0.25rem;
		line-height: 0.25rem;
	}

	.lf_opacity_div_3 {
		width: 100%;
		margin: 0 auto;
		text-align: center;
		font-size: 0.16rem;
		color: #fff;
		height: 0.25rem;
		line-height: 0.25rem;
	}

	.lf_opacity_div_4 {
		width: 3.36rem;
		margin: 0 auto;
		text-align: center;
		font-size: 0.16rem;
		color: #fff;
		height: 0.25rem;
		line-height: 0.25rem;
	}

	.lf_opacity_div_5 {
		width: 1rem;
		height: 0.35rem;
		background: inherit;
		background-color: rgba(242, 242, 242, 0);
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(255, 255, 255, 1);
		border-radius: 5px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.14rem;
		color: #FFFFFF;
		margin: 0.25rem auto 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.lf_match_banner_box {
		height: 4rem;
		width: 100%;
		position: relative;
	}

	.lf_match_banner_box_img {
		height: 4rem;
	}


	.lf_group_tit_left {
		
		display: flex;
		align-items: center;

	}

	.lf_des_text {
	
		margin-left: 0.4rem;
		
	}

	.lf_des_text text {
	}

	.lf_match_info_box_r {
		display: flex;
		justify-content: flex-end;
	}

	.lf_tec_download_img_box {
		float: right;
		color: #C0153E;
		font-size: 0.2rem;
		height: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
		cursor: pointer;
	}
	.lf_des_text_box .lf_tec_download_img_box{
		display: none;
	}

	.lf_tec_download_img_box img {
		width: 0.24rem;
		height: 0.24rem;
		margin-left: 0.1rem;
	}

	.lf_match_info_text_ {
		font-family: '微软雅黑';
		font-weight: 400;
		font-style: normal;
		font-size: 0.2rem;
		color: #999999;
		display: flex;
		align-items: center;
		justify-content: center;

		flex-shrink: 0;
	}

	.lf_player_img_box {
		padding: 0 10px 0.35rem;
		font-size: 0.14rem;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.lf_player_img_div {
		width: 2.95rem;
		height: 2rem;
		display: flex;
		flex-direction: column;
		background-color: rgba(250, 250, 250, 1);
		margin-right: 0.2rem;
		margin-bottom: 0.2rem;
	}

	.lf_player_img_div:nth-of-type(4n) {
		margin-right: 0;
	}

	.lf_player_bottom_img_box {
		height: 0.45rem;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: 1px solid #f5f5f5;
	}

	.lf_player_bottom_img {
		width: 1.4rem;
		height: 0.24rem;
	}

	.lf_player_card {
		width: 100%;
		height: 1.55rem;
		display: flex;
	}

	.lf_player_card_img {
		margin: 0.16rem;
		width: 0.9rem;
		height: 0.9rem;
		border-radius: 50%;
	}

	.lf_player_card_con {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.lf_player_card_con_1 {
		font-size: 0.2rem;
		color: #333;
		display: flex;
		align-items: center;
		margin-bottom: 0.06rem;
	}

	.lf_player_card_con_1 img {
		width: 0.3rem;
		height: 0.21rem;
		margin-left: 0.3rem;
	}

	.lf_player_card_con_2 {
		font-size: 0.14rem;
		color: #999;
		display: flex;
		align-items: center;
		min-height: 0.25rem;
		display: flex;
		align-items: center;
	}

	.lf_player_list_tit {
		width: 96%;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #f5f5f5;
		height: 0.7rem;
		margin: 0 auto;
	}

	.lf_player_list_tit div,
	.lf_player_list_con div div {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.24rem;
		color: #666666;
	}

	.lf_player_list_con {
		display: flex;
		width: 100%;
		margin: 0 auto;
		color: #999999;
		height: 1.5rem;
		background-color: #FAFAFA;
	}

	.lf_player_list_con_B{
		background-color: #FdFdFd;
	}

	.lf_player_list_con_con {
		display: flex;
		width: 96%;
		margin: 0 auto;
		color: #000000;
		border-bottom: 1px solid #dbdbdb;
	}

	.lf_player_list_con_con div {
		font-size: 0.28rem !important;
		color: #000000 !important;
		flex-shrink: 0;
	}
	.lf_1{
		width: 1.5rem;
	}
	.lf_2{
			width: 1.8rem;
		}
	.lf_3{
			width: 1.4rem;
		}
	.lf_5 {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.lf_4 {
		width: 3.2rem;
		flex-grow: 1;
	}
	.lf_44 {
		display: flex!important;
		width: 3.2rem;
		flex-grow: 1;
		flex-shrink: 0;
		flex-direction: column;
		align-items: flex-start!important;
	}

	.lf_4_top {
		display: flex;
		width: 100% !important;
		overflow: hidden;
		align-items: center;
		justify-content: flex-start!important;
	}

	.lf_4_top text {
		line-height: normal;
		display: flex;
		align-items: center;
		line-height: normal;

	}

	.img_left {
		width: 0.34rem;
		margin-right: 0.1rem;
		float: left;
	}

	.img_right {
		width: .75rem;
		height: 0.25rem;
		float: right;
		margin-right: 0.1rem;
	}

	.lf_4_bottom {
		display: flex;
		justify-content: flex-start;
		flex-direction: row;
	}


	.lf_5 div {
		height: 0.4rem;
		width: 100% !important;
	}

	.lf_4 img {
		width: 20px;
		height: 14px;
	}

	.lf_5 {
		width: 25% !important;
		justify-content: flex-start !important;
	}

	.lf_5 img {
		width: 73px;
		height: 25px;
		margin-left: 0.4rem;
	}

	.lf_boxboxbox {
		background-color: #FAFAFA;
		width: 100%;
		margin-top: 0.2rem;
	}

	.lf_boxboxboxs {
		display: none;
	}

	@media screen and (max-width: 768px) {
		.lf_boxboxbox {
			display: none;
		}
		.lf_des_text{
			float: right;
	
			margin-left:0;
			margin-right:0;
		}
		.lf_des_text_box{
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 6.7rem;
		}
		.lf_tec_download_img_box{
			display: none;
		}
		.lf_des_text_box .lf_tec_download_img_box{
			margin: 0;
			display: flex;
		}
		.lf_boxboxboxs {
			display: block;
			background-color: #FAFAFA;
			width: 100%;
			margin-top: 0.2rem;
			overflow: hidden;
		}

		.lf_group_list_box {
			width: 7.1rem;
			height: 1.2rem;
			margin-left: 0.2rem;
			line-height: 1.2rem;
			font-size: 0.28rem;
			border-bottom: 0.01rem solid #dbdbdb;
			background-color: #fff;
			overflow: hidden;
			display: flex;
			align-items: center;
			
		}

		.lf_group_list_box:nth-child(odd) {
			background: #FAFAFA;
		}
		.lf_group_list_box .lf_pinktitlepeople{
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-indent: 0;
		}
		.lf_group_list_box .lf_pinktitlepeople .lf_pinktitlename{
			margin-bottom: 0.1rem;
		}
		.lf_pinktitle {
			background-color: #FFF1F2;
			overflow: hidden;
			width: 7.1rem;
			height: 0.5rem;
			margin-left: 0.2rem;
			margin-top: 0.2rem;
			line-height: 0.5rem;
			font-size: 0.24rem;
			border-bottom: 0.01rem solid #dbdbdb;
			display: flex;
			align-items: center;
			color: #666;
		}

		.lf_pinktitlepeople {
			text-indent: 0.1rem;
			width: 3.2rem;
			flex-grow: 1;
			
		}

		.lf_pinktitlename {
			margin-left: 0.2rem;
			display: flex;
			align-items: center;
			
		}

		.lf_pinktitlenamee {
			width: 3.2rem;
			line-height: normal;
			margin-left: 0.2rem;
			font-size: 0.24rem;
		}

		.lf_pinktitlename img {
			width: 0.34rem;
			position: relative;
			top: -0.03rem;
		}

		.lf_pinktitleimg {
			width: 0.75rem;
			height: 0.25rem;
			display: none;
		}


		.lf_pinktitleother {
			width: 1rem;
			height: 100%;
			/* 	height: 0.5rem; */
			float: left;
			text-align: center;
		}

		.lf_footer1_tit_box {
			margin-top: 0.5rem;
		}

		.lf_tab_tit_box {
			align-items: unset;
		}

		.lf_tab_box {
			margin-top: 0.2rem;
		}

		.lf_match_banner_box {
			height: 2.5rem;
			width: 100%;
			position: relative;
		}

		.lf_match_banner_box_img {
			height: 2.5rem;
		}

		.lf_opacity_div {
			width: 100%;
			position: absolute;
			left: 0;
			top: 0;
			background-color: rgba(0, 0, 0, 0.5);
			color: #fff;
			height: 100%;
			z-index: 99;
			padding: 0.1rem 0;
		}

		.lf_opacity_div img {
			display: none;
		}

		.lf_opacity_div_1 {
			height: 0.7rem;
			font-family: '微软雅黑 Bold', '微软雅黑 Regular', '微软雅黑';
			font-weight: 700;
			font-style: normal;
			font-size: 0.24rem;
			letter-spacing: 0.04rem;
			color: #FFFFFF;
			width: 3.36rem;
			line-height: 0.7rem;
			text-align: center;
			overflow: hidden;
			margin: 0.42rem auto 0;
			border-bottom: 1px solid #fff;
		}

		.lf_opacity_div_2 {
			display: none;
		}

		.lf_opacity_div_3 {
			display: none;
		}

		.lf_opacity_div_4 {
			display: none;
		}

		.lf_opacity_div_5 {
			width: 1.1rem;
			height: 0.45rem;
			padding: 0.04rem;
		}

		.lf_player_img_div {
			width: 3.45rem;
			height: 2.26rem;
		}

		.lf_player_card {
			height: 1.8rem;
		}

		.lf_player_img_div:nth-of-type(2n) {
			margin-right: 0;
		}


		.lf_match_info_box_r {
			align-items: flex-start;
			position: relative;
			top: -0.16rem;
			margin-right: 0.2rem;
		}

		.lf_5 {
			width: 36% !important;
		}

		.lf_5 img {
			width: 73px;
			height: 25px;
			margin-left: 0.1rem !important;
		}

		.lf_group_tit_left {
			flex-direction: column;
			margin: 0.1rem 0 0.1rem 0.2rem;
			align-items: baseline;
		}
		.banner{
			width: 100%;
			height: 0.75rem;
			overflow: hidden;
			margin: 0.2rem auto;
		}
	}
</style>