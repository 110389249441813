<template>
    <!-- <navs></navs> -->
	<div class="banner_top" v-show="bannershow && bannershow['OTHER_TOP'] == '1'">
		<banner :type="0" abs="OTHER_TOP"></banner>
	</div>
    <Btn></Btn>
	<div class="lf_footer1">
		<el-row type="flex" justify="center">
			<el-col :xs="24" :sm="24" :md="24">
				<div class="lf_footer1_tit_box">
					<div class="lf_foot_tit_text1">企业介绍</div>
					<div class="lf_foot_tit_line"></div>
					<div class="lf_foot_tit_text2">Introduction</div>
				</div>
			</el-col>
		</el-row>
	</div>
	<el-row type="flex" justify="center">
		<el-col :xs="24" :sm="24" :md="24">
			<div class="lf_Enterprise_box">
			    <div class="lf_Enterprise_list" v-for="b in list">
					<img v-if="b.LinkLogoUrl" :src="b.LinkLogoUrl" alt="">
					<div>{{b.LinkWebIntro}}</div>
				</div>
			</div>
		</el-col>
	</el-row>
    <Footer1></Footer1>
</template>

<script>
    // @ is an alias to /src
    import navs from "../components/nav.vue";
    import Btn from "../components/Btn.vue";
    import Footer1 from "../components/Footer1.vue";
    import banner from "../components/banner.vue";
    import axios from '../axios/request';
    import {
        getSystemLang,
        getLangAll
    } from "../lang";
 
    //自动轮播的配置
    export default {
        data() {
            return {
                langs: '',
                systemlang: 'cn',
				list:{},
				bannershow:{}
            }
        },
        props: {

        },
        name: 'Home',
        components: {
            navs,
            Btn,
            Footer1,
			banner
        },
        created() {
            this.systemlang = getSystemLang();
            this.langs = getLangAll(this.systemlang);

			let id = this.$route.query.id;
			let mt_id = this.$route.query.mt_id;
			 //获取banner
			 if(id == 'gongying')
			 {
				if(mt_id)
				{
					axios('news/link/gongying.json',{mt_id:mt_id,limit:'all'})
					.then((res)=>{
						let data = res.data.data;
						this.list = data;
					});
				}
				else
				{
					axios('news/link/gongying.json',{limit:'all'})
				.then((res)=>{
					let data = res.data.data;
					this.list = data;
				});
				}
				
			 }
			 else if(id == 'hezuo')
			 {
				if(mt_id)
				{
					axios('news/link/hezuo.json',{mt_id:mt_id,limit:'all'})
				.then((res)=>{
					let data = res.data.data;
					this.list = data;
				});
				}
				else
				{
					axios('news/link/hezuo.json',{limit:'all'})
				.then((res)=>{
					let data = res.data.data;
					this.list = data;
				});
				}
				
			 }
			

            

        },
        methods: {
			setbannershow(abs,isshow){
				this.bannershow[abs] = isshow;
			}
        }

    }
</script>


<style scoped="scoped">
    .lf_Enterprise_box{
        width: 18.5rem;
        overflow: hidden;
        margin:0 auto;
        font-size: 0.24rem;
        border-bottom: 1px solid rgba(239, 239, 239);
        color: #333333;
		padding: 0.4rem;
		margin-bottom: 1rem;
    }
	.lf_Enterprise_list{
		font-size: 0.24rem;
		color: #333;
		margin-bottom: 1rem;
		white-space: pre-wrap;
		display: flex;
		flex-direction: column;
		align-items: center;
		
	}
	.lf_Enterprise_list img{
		width: unset!important;
		height: unset!important;
		margin: 0 auto 0.3rem;
		max-width: 100%;
	}
	.banner_top{
		width: 100%;
		height: 2.25rem;
	}
    @media screen and (max-width: 768px) {
        
		.lf_Enterprise_box{
			width: auto;
		}
		.lf_Enterprise_list img{
			max-width: 98%;
		}
		.lf_Enterprise_list{
			font-size: 0.24rem;
		}
		.banner_top{
			width: 100%;
			height: 0.88rem;
		}
    }
</style>
